import React from 'react';
import TextFields from './textField/TextFields';
import ComposedTextField from './components/ComposedTextField';
import TextFieldMargins from './layout/TextFieldMargins';
import InputAdornments from './inputAdornments/InputAdornments';
import FormattedInputs from './formattedInputs/FormattedInputs';
import Inputs from './inputs/Inputs';
import CardBox from 'components/CardBox';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'util/IntlMessages';
import { NavLink, withRouter,useHistory } from 'react-router-dom';
const TextField = ({match}) => {
  return (
  <div>
          <TextFields/>
  </div>
  );
};

export default (TextField);

