import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';


const Form = ({match}) => (
  <div className="">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/main`}/>
      <Route path={`${match.url}/index`} component={asyncComponent(() => import('./routes/index/index'))}/>
      <Route path={`${match.url}/eform`} component={asyncComponent(() => import('./routes/eform/eform'))}/>
      <Route path={`${match.url}/form`} component={asyncComponent(() => import('./routes/form/form'))}/>
      <Route path={`${match.url}/form_army`} component={asyncComponent(() => import('./routes/form_army/form_army'))}/>
      <Route path={`${match.url}/guard`} component={asyncComponent(() => import('./routes/guard/guard'))}/>
      <Route path={`${match.url}/index_action`} component={asyncComponent(() => import('./routes/index_action/index_action'))}/>
      <Route path={`${match.url}/index_army`} component={asyncComponent(() => import('./routes/index_army/index_army'))}/>
      <Route path={`${match.url}/list`} component={asyncComponent(() => import('./routes/list/list'))}/>
      <Route path={`${match.url}/list_action`} component={asyncComponent(() => import('./routes/list_action/list_action'))}/>
      <Route path={`${match.url}/list_army`} component={asyncComponent(() => import('./routes/list_army/list_army'))}/>
      <Route path={`${match.url}/list_guard`} component={asyncComponent(() => import('./routes/list_guard/list_guard'))}/>
      <Route path={`${match.url}/list_index`} component={asyncComponent(() => import('./routes/list_index/list_index'))}/>
      <Route path={`${match.url}/main`} component={asyncComponent(() => import('./routes/main/main'))}/>


      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Form;
