import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';


const Form = ({match}) => (
  <div className="">
    <Switch>

      <Redirect exact from={`${match.url}/`} to={`${match.url}/newsindex`}/>
      <Route path={`${match.url}/newsindex`} component={asyncComponent(() => import('./routes/index/index'))}/>
      <Route path={`${match.url}/newsform`} component={asyncComponent(() => import('./routes/form/index'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Form;
