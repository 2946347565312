import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
 import Button from '@material-ui/core/Button';
import { TextField, InputAdornment } from "@material-ui/core";
 import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';

let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}

const columnData = [
  {id: 'name', align: true, disablePadding: false, label: 'ชื่อ'},
  {id: 'calories', align: true, disablePadding: false, label: 'ไฟล์แนบ'},
  {id: 'fat', align: true, disablePadding: false, label: 'ผูใช้สร้าง'},
  {id: 'carbs', align: true, disablePadding: false, label: 'หน่ยวงาน'},
  {id: 'protein', align: true, disablePadding: false, label: 'วันที่สร้าง'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [
        createData('เอกสารประจำการ', '', 'พลทหาร วีระ','ยก', '20/10/2020'),
        createData('เอกสารหน่วย', '', 'พลทหาร ชิด','ยก','20/10/2020'),
        createData('เอกสารทั่วไป', '',  'พลทหาร ยอด','ยก','20/10/2020'),
      ].sort((a, b) => (a.calories < b.calories ? -1 : 1)),
      page: 0,
      rowsPerPage: 5,
    };
  }

  render() {
    const {data, order, orderBy, selected, rowsPerPage, page} = this.state;

    return (
    <div>
    <div class="page-heading d-sm-flex justify-content-sm-between align-items-sm mb-0 p-2  bg-geekblue ">
          <h5 class="title p-1 mb-sm-0"></h5>
              <nav class="mb-0" aria-label="breadcrumb">
                      <ol class="breadcrumb">
                                    <div class="breadcrumb-item">
                                        <div   style={{color: 'gray', textDecoration: 'none'}}  >
                                         การจัดการระบบ
                                        </div>
                                        <div className="pl-1 pr-1"> > </div>
                                        <div   style={{color: 'white', textDecoration: 'none'}}  >
                                         ระบบฐานข้อมูล
                                        </div>
                                     </div>
                      </ol>
              </nav>
     </div>
    <div className="p-2 pt-3" >
    <ProfileHeader/>

    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                       <label className="ml-4 mt-4 text-white"><h3>ติดตั้ง PostgreSql</h3></label>
                            <div className="row ml-3 mr-3 mb-3" style={{color:'#fff'}}>
                                <div className="col-md-12 col-12">

                                   <label className="">
                                     pgAdmin 4 เป็นอินเทอร์เฟซแบบกราฟิกสำหรับการโต้ตอบกับฐานข้อมูล PostgreSQL เป็นอีกทางเลือกหนึ่งสำหรับเทอร์มินัลหรือคอนโซล pSQL คุณสามารถอ่านเพิ่มเติมได้ที่เว็บไซต์ pgAdmin
                                   </label>
                                </div>
                                <div className="col-md-12 col-12">
                                   <label className="">
                                     https://translate.googleusercontent.com/translate_c?depth=1&pto=aue&rurl=translate.google.com&sl=en&sp=nmt4&tl=th&u=https://www.pgadmin.org/&usg=ALkJrhiDcjKjofQh9mkwGwMqW18ZQu-cBQ
                                   </label>
                                </div>

                              <div className="col-md-12 col-12 m-1">

                                   <label className="">
                                     1.ตอนนี้ตัวติดตั้ง pgAdmin 4 พร้อมใช้งานแล้ว คลิกถัดไป
                                   </label>
                                </div>
                                 <img  src={require("assets/images/pg/1pgadmin.png")}></img>
                                  <div className="col-md-12 col-12 m-1">

                                   <label className="">
                                     2.จากนั้นวิซาร์ดจะขอให้คุณอ่านข้อตกลงใบอนุญาต คุณควรตกลงและยอมรับ จากนั้นคลิกถัดไป
                                   </label>
                                </div>
                                  <img  src={require("assets/images/pg/2pgadmin.png")}></img>
                                  <div className="col-md-12 col-12 m-1">
                                   <label className="">
                                     3.จากนั้นจะถามว่าคุณต้องการบันทึกไฟล์โปรแกรมของคุณไว้ที่ใดสำหรับ pgAdmin 4 ฉันเก็บไว้ในโฟลเดอร์เริ่มต้น / แนะนำ เมื่อเสร็จแล้วให้เลือกถัดไป:
                                   </label>
                                </div>
                                   <img  src={require("assets/images/pg/3pgadmin.png")}></img>
                                  <div className="col-md-12 col-12 m-1">
                                   <label className="">
                                    4.ตอนนี้จะเริ่มติดตั้ง
                                   </label>
                                </div>
  <img  src={require("assets/images/pg/4pgadmin.png")}></img>
                                  <div className="col-md-12 col-12 m-1">
                                   <label className="">
                                    5.เมื่อติดตั้งเสร็จแล้วคุณสามารถเลือก Launch pgAdmin 4 และ Finish
                                   </label>
                                </div>
                                   <img  src={require("assets/images/pg/5pgadmin.png")}></img>
                                  <div className="col-md-12 col-12 m-1">
                                   <label className="">
                                   6.pgAdmin 4 อาจใช้เวลาสักครู่ในการเปิดตัวและเมื่อเปิดใช้งานจะมีลักษณะดังนี้:
                                   </label>
                                </div>
                                   <img  src={require("assets/images/pg/6pgadmin.png")}></img>
                                  <div className="col-md-12 col-12 m-1">
                                   <label className="">
                                   7.จะมีเซิร์ฟเวอร์ PostgreSQL เริ่มต้นในระบบของคุณ คุณสามารถเข้าถึงได้บน pgAdmin ทางซ้ายมือเบราว์เซอร์ให้คลิก + ที่อยู่ถัดจากเซิร์ฟเวอร์และจะแสดงเซิร์ฟเวอร์ มันจะตั้งชื่อบางอย่างเช่น PostgreSQL 9.6 สำหรับเซิร์ฟเวอร์ PostgreSQL จะมีผู้ใช้เริ่มต้นที่เรียกว่า "postgres" เสมอ สำหรับคอมพิวเตอร์ Windows คุณจะต้องสร้างรหัสผ่านสำหรับผู้ใช้เริ่มต้นนี้ (อย่าลืมเก็บรหัสผ่านไว้)
                                   </label>
                                </div>
                                <img  src={require("assets/images/pg/7pgadmin.png")}></img>
                                  <div className="col-md-12 col-12 m-1">
                                   <label className="">
                                   8.เมื่อใช้ pgAdmin 4 คุณสามารถสำรวจเซิร์ฟเวอร์ PostgreSQL ที่โฮสต์ฐานข้อมูลของคุณได้ มันจะค่อนข้างว่างเปล่า
                                   </label>
                                </div>
                            </div>
                            <div className="row m-3" style={{color:'#fff'}}>


                            </div>
                 </div>

        <div className="flex-auto">

        </div>
   </div>
 </div>
 </div>
 </div>
      </div>
      </div>
    );
  }
}

export default index;