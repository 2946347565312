import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import axios from "axios";
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {NavLink, withRouter,Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import EditIcon from '@material-ui/icons/Edit';
import { TextField, InputAdornment } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import cookie from 'react-cookies';
let counter = 0;





class index extends React.Component {



  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 5,
      name:'',
      type:'',
      path:'',
      news_group1:'',
      news_group2:'',
      news_group3:'',
      news_group4:'',
      Period:'',
      title:'',
      name_job:'',
      users:[],
      openmsg:false,
      sEdit:'',
      id:'',
      delid:''

    };




       var location = window.location.href;
        const str = '' + location;
        const id = str.split("=")[1];

        fetch('http://1.10.184.64:3000/news/find.php?id='+id)
          .then(response => response.json())
          .then(data => {
               //console.log(JSON.stringify(data)+"datataa")
               this.setState({
               Period:data[0].period,
               news_group1 :data[0].news_group1,
               news_group2 :data[0].news_group2,
               news_group3 :data[0].news_group3,
               news_group4 :data[0].news_group4
               })
          });


  }
  backpage =()=>
  {
          this.props.history.push("/app/dashboard/crypto");
  }





  render() {
            const {data, order, orderBy, selected, rowsPerPage, page} = this.state;

            let __users = this.state.users;
                    let usersItems = __users.map((users) =>
                       <MenuItem    key={users.id} value={users.id}>
                       <span className="ml-2"> {users.fname} </span></MenuItem>
            );


    return (
    <div className="p-2 pt-3" >
    <ProfileHeader/>

    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-white  border border-primary"  >
                       <label className="ml-4 mt-4"><h3>ข่าวประจำวัน</h3></label>
                            <div className="row ml-3 mr-3 mb-3" style={{color:'#000'}}>
                                <div className="col-md-12 col-12 mb-3">
                                   <h3>
                                       <div className="">

                                                สรุปสถานณ์ข่าวประจำวัน ในห้วงเวลา
                                                {
                                                    this.state.Period==""?'':
                                                    " "+this.state.Period.substring(8,10)+this.state.Period.split('T')[1].split('.')[0].replaceAll(':','').substring(0,4)+' '
                                                }
                                                { this.state.Period.substring(5,7)=="01"?"ม.ค.":""}
                                                { this.state.Period.substring(5,7)=="02"?"ก.พ.":""}
                                                { this.state.Period.substring(5,7)=="03"?"มี.ค.":""}
                                                { this.state.Period.substring(5,7)=="04"?"เม.ย.":""}
                                                { this.state.Period.substring(5,7)=="05"?"พ.ค.":""}
                                                { this.state.Period.substring(5,7)=="06"?"มิ.ย.":""}
                                                { this.state.Period.substring(5,7)=="07"?"ก.ค.":""}
                                                { this.state.Period.substring(5,7)=="08"?"ส.ค.":""}
                                                { this.state.Period.substring(5,7)=="09"?"ก.ย.":""}
                                                { this.state.Period.substring(5,7)=="10"?"ต.ค.":""}
                                                { this.state.Period.substring(5,7)=="11"?"พ.ย.":""}
                                                { this.state.Period.substring(5,7)=="12"?"ธ.ค.":""}
                                                {
                                                    " "+String((parseInt(this.state.Period.substring(0,4))+543)).substring(2,4)+" "
                                                }
                                                  ดังนี้

                                       </div>
                                   </h3>
                                           <h4>
                                               <div className="mt-2">
                                                   1.เหตุการณ์ที่อยู่ในความสนใจ
                                               </div>
                                           </h4>
                                       <div>
                                       {this.state.news_group1==""?"ไม่ปรากฎเหตุการณ์ที่สำคัญ":this.state.news_group1}
                                       </div>

                                           <h4>
                                               <div className="mt-2 ">
                                                   2.เหตุการ์ก่อความไม่สงบภานในประเทศ
                                               </div>
                                           </h4>
                                       <div>
                                        {this.state.news_group2==""?"ไม่ปรากฎเหตุการณ์ที่สำคัญ":this.state.news_group2}
                                       </div>

                                            <h4>
                                               <div className="mt-2 ">
                                                   3.เหตุการณ์ก่อการร้ายสากล
                                               </div>
                                           </h4>
                                       <div>
                                        {this.state.news_group3==""?"ไม่ปรากฎเหตุการณ์ที่สำคัญ":this.state.news_group3}
                                       </div>
                                           <h4>
                                               <div className="mt-2">
                                                   4.อื่นๆ
                                               </div>
                                           </h4>
                                       <div>
                                        {this.state.news_group4==""?"ไม่ปรากฎเหตุการณ์ที่สำคัญ":this.state.news_group4}
                                       </div>
                                </div>
                            </div>
                   <div className="row m-3" style={{color:'#fff'}}>
                   <Button onClick={this.backpage}
                                   className="bg-blue">
                                     <ArrowBackIcon />
                   </Button>
                 </div>
                 </div>


        <div className="flex-auto">
          <div className="table-responsive-material bg-geekblue border border-primary">

          </div>
        </div>
   </div>
 </div>
 </div>
 </div>
      <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.openmsg}
            autoHideDuration={1000}
              onClose={() => this.setState({openmsg: false})}
            message="บันทึกสำเร็จ"
             action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />
           <Dialog fullWidth={true}
                        maxWidth={'xs'} open={this.state.open1} onClose={this.handleRequestClose}>
                        <DialogContent className="bg-geekblue text-white"  >
                            <InfoIcon className="text-primary " />   ยืนยันการลบ.
                        <hr />
                        </DialogContent>
                        <DialogActions className="bg-geekblue">
                            <Button onClick={this._del} className="bg-success text-white">
                                    <CheckCircleIcon /> ใช่
                            </Button>

                            <Button onClick={()=>{this.setState({open1:false})}} className="bg-danger text-white">
                                     <CancelIcon /> ยกเลิก
                            </Button>
                        </DialogActions>
          </Dialog>
      </div>
    );
  }
}

export default index;