import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CustomScrollbars from 'util/CustomScrollbars';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IntlMessages from 'util/IntlMessages';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {mailFolderListItems, otherMailFolderListItems} from '../tileData';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import Map from '../../map/routes/simple/Components/SimpleMap.js';

import {GoogleMap, withGoogleMap,} from 'react-google-maps';
import MapGL,{ NavigationControl ,ScaleControl, FullscreenControl,AttributionControl,GeolocateControl  }   from '@urbica/react-map-gl';
import Draw from '@urbica/react-map-gl-draw';
import  './mapbox-gl.css';
import  './mapbox-gl-draw.css';
const drawerWidth = 330;

const styles = theme => ({


  root: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  appBar: {
    position: 'absolute',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    height: '100%',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    width: '100%',
    marginLeft: -drawerWidth,
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      content: {
        height: 'calc(100% - 64px)',
        marginTop: 64,
      },
    },
  },
  contentShift: {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

class PersistentDrawer extends React.Component {
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState({open: true});

  };

  handleDrawerClose = () => {
    this.setState({open: false});

  };


  constructor() {
    super();
    this.state = {
      isToggle: null,

      searchMail: '',
      noContentFoundMessage: 'No mail found in selected folder',
      alertMessage: '',
      showMessage: false,
      drawerStateMin: false,
      drawerState: false,
      optionName: 'None',
      anchorEl: null,

      loader: true,
      currentMail: null,
      user: {
        name: 'Robert Johnson',
        email: 'robert@example.com',
        avatar: 'https://via.placeholder.com/150x150'
      },
      selectedMails: 0,
      selectedFolder: 0,
      composeMail: false,
      labelMenuState: false,
      folderMenuState: false,
      optionMenuState: false
    }

  }

  render() {
    const {classes, theme} = this.props;

    return (

      <div style={{backgroundColor: 'rgb(181 63 63 / 0%)'}} className={classes.root}>

        <div className={classes.appFrame}  >

          <AppBar
           className={classNames('rgb(181 63 63 / 0%)', classes.appBar, this.state.open && classes.appBarShift)}
              style={{backgroundColor: 'rgb(181 63 63 / 0%)',
                boxShadow:'0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%)'
              }}

          >

            <Toolbar disableGutters={!this.state.open}  style={{backgroundColor: 'rgb(181 63 63 / 0%)'}}>
            <div className='m-2 p-0' style={{backgroundColor: '#3f51b5' }}>
              <IconButton

                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                style={{backgroundColor: '#3f51b5',margin:0}}
                className={classNames(classes.menuButton, this.state.open && classes.hide)}>

                <MenuIcon className="text-white"/>
              </IconButton>
          </div>
            </Toolbar>
          </AppBar>

          <Drawer
            variant="persistent"
            classes={{
              paper: classes.drawerPaper,
            }}
            open={this.state.open}
          >
             <CustomScrollbars className="module-side-scroll scrollbar"
                          style={{height: this.props.width >= 1200 ? 'calc(100vh - 70px)' : 'calc(100vh - 70px)'}}>

            <div className={classes.drawerInner} style={{color:'#fff',backgroundColor: '#3f51b5' ,white:'300px'}} >
              <div className={classes.drawerHeader} style={{color:'#fff',backgroundColor: '#3f51b5'}} >
                <IconButton onClick={this.handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                </IconButton>
              </div>
              <Divider/>
       <Row style={{color:'#fff'}}>
         <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="พื้นที่สนใจ (AI)"/>
                </label>
                </div>

               </div>
         </Col>
           <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>
       </Row>

         <Row style={{color:'#fff'}}>

         <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="พื้นที่โดยรอบ(Area)"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>
       </Row>



         <Row style={{color:'#fff'}}>


         <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="ทิศเหนือ"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>

       </Row>



         <Row style={{color:'#fff'}}>

         <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="ทิศใต้"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>


       </Row>



         <Row style={{color:'#fff'}}>

         <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="ทิศตะวันออก"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>

       </Row>


   <Row style={{color:'#fff'}}>

     <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="ทิศตะวันตก"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>
       </Row>



   <Row style={{color:'#fff'}}>

   <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="พื้นที่รวมพลฉุกเฉิน (LS)"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>
       </Row>



<hr class="MuiDivider-root"/>

       <Row style={{color:'#fff'}}>
             <Col md="7"  className="align-middle">
                <ul className="module-nav">
                    <li className="module-nav-label p-2">
                      <IntlMessages id="ตำแหน่ง"/>
                    </li>
                </ul>

             </Col>
         </Row>

          <Row style={{color:'#fff'}}>

          <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="อันตรายฝ่ายตรงข้าม(OPN)"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>
       </Row>

        <Row style={{color:'#fff'}}>

           <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="การปฏิบัติ ณ ที่หมาย (SH)"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>
       </Row>

         <Row style={{color:'#fff'}}>

           <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="จุดรวมพล/บก เหตุการณ์ (HA/LZ)"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>

       </Row>


          <Row style={{color:'#fff'}}>


              <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="จุดประสานการปฎิบัติขั้นสุดภัย (STL)"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>
       </Row>
<hr class="MuiDivider-root"/>
        <Row style={{color:'#fff'}}>
             <Col md="7"  className="align-middle">
                <ul className="module-nav">
                    <li className="module-nav-label p-2">
                      <IntlMessages id="นำทาง"/>
                    </li>
                </ul>

             </Col>
         </Row>
          <Row style={{color:'#fff'}}>
              <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="เส้นทางหลัก"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>

       </Row>

   <Row style={{color:'#fff'}}>
              <Col xs={8} className="align-middle">
               <div className="user-profile d-flex flex-row align-items-center">
                 <label className="m-2">
                     <Switch classes={{ checked: 'text-success', bar: 'bg-success',}} aria-label="checkedE" />
                </label>
                <label className="m-2">
                      <i class="zmdi zmdi-square-o"></i>
                </label>
                 <div className="user-detail m-2">
                <label className="m-2">
                    <IntlMessages id="HA - เส้นทางรอง"/>
                </label>
                 </div>

               </div>
         </Col>
         <Col xs={2} className="align-middle">
           <div className="user-detail mt-3">
                   <p><i class="zmdi zmdi-pin zmdi-hc-2x"></i></p>
                 </div>
           </Col>
       </Row>
         <Divider/>
                </div>
                 </CustomScrollbars>
                    </Drawer>


                      <main className={classNames(classes.content, this.state.open && classes.contentShift)}
                      style={{ padding: '0px',marginTop:-10}}>
                      <MapGL
                            style={{  height:'100%', width: '2000px'}}
                            mapStyle={{
                                  "version": 8,
                                  "name": "OSM",
                                  "metadata": {

                                  },
                                  "sources": {

                                    "osm": {
                                      "type": "raster",
                                      "tiles": [
                                        "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                      ]
                                    }

                                  },

                                  "layers": [
                                    {
                                      "id": "osm",
                                      "type": "raster",
                                      "source": "osm"
                                    }
                                  ],
                                  "id": "klokantech-basic"
                                }}
                            onViewportChange={viewport => console.log(this.props.width)}
                            accessToken=''
                            latitude={15.8700}
                            longitude={100.9925}
                            zoom={3}
                     >



<NavigationControl  showZoom  position='bottom-right' />
     <GeolocateControl position='bottom-right' />
      <FullscreenControl position='bottom-right' />
                <Draw position='bottom-right' />




                 </MapGL>
          </main>

        </div>

      </div>

    );
  }
}

PersistentDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(PersistentDrawer);