import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import Widget from "components/Widget/index";
import TableBody from '@material-ui/core/TableBody';
import MapIcon from '@material-ui/icons/Map';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { NavLink, withRouter,useHistory } from 'react-router-dom';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment'
import imgmarkerterrorist from "./map-marker_terrorist.png";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
 import mapboxgl,{MarkerViewOptions ,LatLng} from 'mapbox-gl';
 import Button from '@material-ui/core/Button';
 import DehazeIcon from '@material-ui/icons/Dehaze';
  import Avatar from '@material-ui/core/Avatar';
 import AlbumIcon from '@material-ui/icons/Album';
 import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TextField, InputAdornment } from "@material-ui/core";
 import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import ReactSpeedometer from "react-d3-speedometer"
import DescriptionIcon from '@material-ui/icons/Description';
import EventIcon from '@material-ui/icons/Event';
import NotificationsIcon from '@material-ui/icons/Notifications';
import data1 from './data1';
import data2 from './data2';
import data3 from './data3';
import data4 from './data4';
import data5 from './data5';
import data6 from './data6';
import axios from "axios";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import imgmarkerlocation from "./map-markerlocation.png";
import imgmarkerdistance from "./map-markerdistance.png";
import imgmarkerbk from "./map-markerbk.png";
import imgmarkersniper from "./map-markersniper.png";
import imggeolocation from "./geolocation2.png";
import imggeolocation2 from "./geolocation2.png";
import BarChartIcon from '@material-ui/icons/BarChart';
import {Bar} from 'react-chartjs-2';
import {Cell,Pie, PieChart, ResponsiveContainer, Sector} from 'recharts';

import ReactFontLoader from 'react-font-loader'


let counter = 0;
    const { point } = require('@turf/helpers')
            const distance = require('@turf/distance').default
            const drawerWidth = 330;
        var   lngLT =0;
         var   latLT =0;
              var _latitude="";
         var _longitude="";
              var   actionDistance ="false";

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}

const columnData = [
  {id: '1', align: true, disablePadding: false, label: 'วันเวลา'},
  {id: '2', align: true, disablePadding: false, label: 'หัวข้อข่าวสำคัญ'},

  {id: '3', align: true, disablePadding: false, label: 'เวรติดตามสถานการณ์'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white',fontFamily: 'Kanit' }}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


const columnData2 = [
  {id: '1', align: true, disablePadding: false, label: 'วันเวลา'},
  {id: '2', align: true, disablePadding: false, label: 'เหตการณ์'},

  {id: '3', align: true, disablePadding: false, label: 'ผู้รายงาน'},
];

class DataTableHead2 extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData2.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white',fontFamily: 'Kanit'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


const columnData3 = [
  {id: '1', align: true, disablePadding: false, label: 'วันเวลา'},
  {id: '2', align: true, disablePadding: false, label: 'สั่งการ'},
  {id: '3', align: true, disablePadding: false, label: 'ผู้ปฎิบัติ'},
  {id: '4', align: true, disablePadding: false, label: 'ผู้รับทราบ'},
];

class DataTableHead3 extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData3.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white',fontFamily: 'Kanit'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {

      hideMassage:'',
       hideMin:'none',
      order: 'asc',
      orderBy: '',
      selected: [],
         poi_plan_report:[],
      data: [
        createData('เอกสารประจำการ', '', 'พลทหาร วีระ','ยก', '20/10/2020'),
        createData('เอกสารหน่วย', '', 'พลทหาร ชิด','ยก','20/10/2020'),
        createData('เอกสารทั่วไป', '',  'พลทหาร ยอด','ยก','20/10/2020'),
      ].sort((a, b) => (a.calories < b.calories ? -1 : 1)),
      page: 0,
      rowsPerPage: 5,
      activeIndex: 0,
      _data1 : [{name: 'กำลังพล', value: 400}],
      _data2 : [{name: 'อาวุธ/ยุทโธปกรณ์', value: 400}],
      _data3 : [{name: 'ยานพาหนะ', value: 400}],
      _data4 : [{name: 'การติดต่อสื่อสาร', value: 400}],
      _data5 : [{name: 'แผนการเคลื่อนย้าย', value: 400}],
       _data6 : [{name: 'แผนปฏิบัติการ', value: 400}],
       ARdata :[],
       AMdata :[],
       army:'',
      weapon:'',
      vehicle:'',
      communication:'',
      moving_plan:'',
      action_plan:'',
      open:'',
      time_count:'',
      close:'',
      alert:'',
      timer:'',
      days: '',
      hours: '',
      minutes: '',
      seconds: 0,
      timeStart: '',
      status:'',
      news:[],
      users:[],
      open1:false,
      open2:false,
      dgraph:0,
      Period:'',
      title:'',
      news_group1:'',
      news_group2:'',
      news_group3:'',
      news_group4:'',
      name_job:'',
      total:'',
        graph:{},
      army_total:[],
      event_name:''

    };

      // var dt = '2021-5-07';

       var today = new Date();

       var dt = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

       fetch('http://1.10.184.64:3000/army_statue/agency_total.php?sdt='+dt)
                                     .then(response => response.json())
                                     .then(_data => {

                                     console.log(JSON.stringify(_data)+"xxxxxxxx")
                                     var labels =[];
                                     var graphdata =[];
                                     var nographdata =[];
                                      for(var i =0;i<_data.length;i++)
                                      {
                                        labels.push(_data[i].name.trim());
                                         graphdata.push(_data[i].action);
                                          nographdata.push(_data[i].noaction);

                                      }

                                    /* var tgraph ={
                                        labels:labels,
                                        datasets: [
                                                {
                                                  label: 'จำนวน',
                                                  backgroundColor: 'rgba(75,192,192,1)',
                                                  borderColor: 'rgba(0,0,0,1)',
                                                  borderWidth: 2,
                                                  data: graphdata,

                                                }

                                        ]}*/

                                        var tgraph ={
                                                labels: labels,
                                                datasets: [

                                                 {
                                                    label: 'จำหน่าย',
                                                    backgroundColor: '#FF5733',
                                                    borderColor: '#FF5733',
                                                    borderWidth: 1,
                                                    stack: 1,
                                                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                    hoverBorderColor: 'rgba(255,99,132,1)',
                                                    data: nographdata
                                                  },
                                                  {
                                                    label: 'ยอดปฎิบัติงาน',
                                                  backgroundColor: '#08AA29',
                                                  borderColor: '#08AA29',
                                                    borderWidth: 1,
                                                    stack: 1,
                                                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                    hoverBorderColor: 'rgba(255,99,132,1)',
                                                    data: graphdata
                                                  }

                                                ]}

                                        this.setState({graph:tgraph})
                                        //console.log(JSON.stringify(_d.labels)+"dddddd")

                                          var d ="";
                                          var m ="";
                                          var y ="";
                                          d =dt.substring(8,10);

                                          m=dt.substring(5,7)=="01"?" ม.ค.":m;
                                          m=dt.substring(5,7)=="02"?" ก.พ.":m;
                                          m=dt.substring(5,7)=="03"?" มี.ค.":m;
                                          m=dt.substring(5,7)=='04'?" เม.ย.":m;
                                          m=dt.substring(5,7)=="05"?" พ.ค.":m;
                                          m=dt.substring(5,7)=="06"?" มิ.ย.":m;
                                          m=dt.substring(5,7)=="07"?" ก.ค.":m;
                                          m=dt.substring(5,7)=="08"?" ส.ค.":m;
                                          m=dt.substring(5,7)=="09"?" ก.ย.":m;
                                          m=dt.substring(5,7)=="10"?" ต.ค.":m;
                                          m=dt.substring(5,7)=="11"?" พ.ย.":m;
                                          m=dt.substring(5,7)=="12"?" ธ.ค.":m;

                                          y= String((parseInt(dt.substring(0,4))+543)).substring(2,4)

                                          //this.setState({data:_data,dt:d+" "+m+" "+y});

                                          this.setState({dt:d+" "+m+" "+y});
                                    });

       const config = {
                    headers: {
                               'Content-Type': 'application/x-www-form-urlencoded'
                             }
                      }
       const qs = require('querystring')

       axios.get('http://1.10.184.64:3000/report/last.php')
            .then((result) => {
                                 this.setState({total: result.data});
            })

       fetch('http://1.10.184.64:3000/army_statue/now_total.php')
                                     .then(response => response.json())
                                     .then(_data => {
                                          this.setState({army_total:_data});
                                    });
       fetch('http://1.10.184.64:3000/news/select.php')
           .then(response => response.json())
           .then(_data => {
                var data  = _data.filter(x=>x.flat=='0').sort((a, b) => (a.id > b.id ? -1 : 1))
                           this.setState({data: data,open1:false});
          });

       fetch('http://1.10.184.64:3000/actionplan/report.php')
          .then(response => response.json())
          .then(data => {

              data.sort((a, b) => (a.id < b.id ? 1 : -1))
              this.setState({ ARdata: data, loading: false });
          });

       fetch('http://1.10.184.64:3000/users/index.php')
          .then(response => response.json())
          .then(data => {
              this.setState({ users: data, loading: false });
          });

       fetch('http://1.10.184.64:3000/actionplan/report.php')
          .then(response => response.json())
          .then(data => {

              data.sort((a, b) => (a.id < b.id ? 1 : -1))
              this.setState({ AMdata: data, loading: false });
          });

          fetch('http://1.10.184.64:3000/actionplan/newselect.php')
                       .then(response => response.json())
                           .then(data => {
                                                                       var xid =data;

                                                                       if (xid != null) {

                                                                              fetch('http://1.10.184.64:3000/actionplan/select.php?id='+xid)
                                                                              //fetch('http://1.10.184.64:3000/actionplan/select.php?id=')
                                                                                  .then(response => response.json())
                                                                                  .then(data => {


                                                                                  fetch('http://1.10.184.64:3000/bldg/autocomplete.php?id='+data.name)
                                                                                         .then(response => response.json())
                                                                                         .then(data => {
                                                                                          this.setState({ listblg: data });
                                                                                        })

                                                             var day = moment(data.open_datetime, 'YYYY/MM/DD').date();
                                                             var  _day = day<=9?"0"+day:day
                                                             //console.log(day.toString.length+"month.toString.length")
                                                             let month =  moment(data.open_datetime, 'YYYY/MM/DD').month();

                                                             //var  _month = month<=9?"0"+month:month
                                                             //console.log(month.toString.length+"month.toString.length")

                                                            let _year = moment(data.open_datetime, 'YYYY/MM/DD').year()+543;
                                                            let _year2 = moment(data.open_datetime, 'YYYY/MM/DD').year();
                                                            var monthNamesThai = ["มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤษจิกายน","ธันวาคม"];
                                                            var dd   = _day+" "+monthNamesThai[month]+" "+_year;

                                                            var time = data.time_count;
                                                             month = month+1;
                                                            var dateStart   = _day+"/"+month+"/"+_year2;
                                                            var timeStart   =0;
                                                            try{

                                                                  timeStart   = data.open_datetime.toString().split('T')[1].split('+')[0];
                                                              

                                                             }catch{ }


                                                            var today = new Date();
                                                           // var date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
                                                            var _dateCurrent = today.getDate()+"/"+(today.getMonth()+1)+"/"+today.getFullYear()+" "+today.getHours()+":"+today.getMinutes()+":"+today.getSeconds();
                                                             // var dd   = _day+" "+monthNamesThai[month]+" "+_year;

                                                              // console.log(timeStart+"_"+_dateCurrent)

                                                            //const laterDateTime = dateStart+" "+timeStart;
                                                            //const earlierDateTime= _dateCurrent;

                                                            const  earlierDateTime   = dateStart+" "+timeStart;
                                                            const  laterDateTime     = _dateCurrent;
                                                            //console.log(laterDateTime+"_test_"+earlierDateTime)
                                                            const ms = moment(laterDateTime, "DD/MM/YYYY HH:mm:ss").diff(moment(earlierDateTime, "DD/MM/YYYY HH:mm:ss"));
                                                            const d = moment.duration(ms);
                                                            const diff = `${Math.floor(d.asHours())}${moment.utc(ms).format(":mm:ss")}`;
                                                            //console.log(diff+"TEST ERROR API");
                                                            console.log(diff+"_"+dateStart+" "+timeStart+"xtime"+_dateCurrent);


                                                             this.setState({
                                                                      lbl_nstorey:"",
                                                                      lbl_name:"",
                                                                      bs_name:"",
                                                                      bs_nstorey:"",
                                                                      bs_employ:"",
                                                                      ststylelos:"none",
                                                                      bl_name:"",
                                                                      bl_nstorey:"",
                                                                      bl_employ:"",
                                                                      bbk:"",
                                                                      bs:"",
                                                                      army:data.army,
                                                                      vehicle:data.vehicle,
                                                                      weapon:data.weapon,
                                                                      communication:data.communication,
                                                                      moving_plan:data.moving_plan,
                                                                      action_plan:data.action_plan,
                                                                      open:dd,
                                                                      timeStart:timeStart,
                                                                      time_count:diff,
                                                                      close:data.close_datetime,
                                                                      bl_employ:'',
                                                                      name: data.name,
                                                                      lname: data.laname,
                                                                      p_type: data.p_type,
                                                                      p_form: data.p_form,
                                                                      topography: data.topography,
                                                                      population: data.population,
                                                                      dominance: data.dominance,
                                                                      religion:data.religion,
                                                                      way_land: data.way_land,
                                                                      way_water: data.way_water,
                                                                      plan_status: data.plan_status,
                                                                      Gotoprovince: data.province_code,
                                                                      province_code: data.province_code,
                                                                      Gotodistrict: data.district_code,
                                                                      district_code: data.district_code,
                                                                      Gotosubdistrict: data.sub_district_code,
                                                                      sub_district_code: data.sub_district_code,
                                                                      gotoname: data.name,
                                                                      w_id:data.w_id,
                                                                      lat:data.y,
                                                                      lon:data.x,
                                                                      maplng:data.y,
                                                                      maplat: data.x,
                                                                      dataAlert:'',
                                                                      selectWeapon:data.w_id,
                                                                      status:data.status,
                                                                      event_name:data.event_name
                                                                     });



                                                                     var sumAlert  = data.army+data.vehicle+data.weapon+
                                                                     data.communication+data.moving_plan+data.action_plan
                                                                     var dataAlert = (sumAlert/600)*100
                                                                     this.setState({dataAlert:dataAlert});

                                                                     //console.log(dataAlert+"dataAlertdataAlertdataAlertdataAlert"+sumAlert)


                                                    fetch('http://1.10.184.64:3000/poi_plan_report/index.php?plan_id='+xid)
                                                        .then(response => response.json())
                                                        .then(data => {
                                                                  this.map.loadImage(
                                                                    imggeolocation2,
                                                                 (error, image) => {
                                                                           this.map.addImage("imggeolocation2", image);
                                                                           var _data = [];
                                                                                 for(var i=0;i<data.length-1;i++)
                                                                                 {
                                                                                     _data.push
                                                                                     ({
                                                                                     'type':'Feature',
                                                                                     'geometry':{'type':'Point','coordinates':[data[i].y,data[i].x]},
                                                                                     'properties':{'title':'ff'}
                                                                                     })
                                                                                 }
                                                                                  this.map.addSource('points', {
                                                                                        'type': 'geojson',
                                                                                        'data': {
                                                                                        'type': 'FeatureCollection',
                                                                                                    'features':  JSON.parse(JSON.stringify(_data))
                                                                                        }
                                                                                    });

                                                                                    this.map.addLayer({
                                                                                            'id': "points",
                                                                                            'type': 'symbol',
                                                                                            'source': 'points',
                                                                                            'layout': {
                                                                                            'icon-image': 'imggeolocation2',
                                                                                            'icon-size': 0.1
                                                                                                }
                                                                                            });
                                                             });

                                               var sdata = data.sort((a, b) => (a.id < b.id ? 1 : -1));

                                                 if(JSON.stringify(data[0])=="{}")
                                              {
                                                data =[];
                                              }

                                                 this.setState({ poi_plan_report: data, loading: false });


                                        });
                                                  fetch('http://1.10.184.64:3000/bldg/index.php?id='+this.state.gotoname)
                                                    .then(response => response.json())
                                                    .then(data => {


                                                         var pro_c = "";
                                                         var sub_c = "";
                                                         var dis_c = "";
                                                         var bl_sub_dis =data[0].bl_sub_dis;
                                                         var bl_distric =data[0].bl_distric;

                                                          // console.log(item.length);
                                                        this.setState({Gotoprovince: pro_c});
                                                        fetch('http://1.10.184.64:3000/district/index.php?id='+pro_c)
                                                            .then(response => response.json())
                                                            .then(data => {

                                                                    var disitem  = data;
                                                                     for(var i =0;i<=disitem.length-1;i++){

                                                                              if(disitem[i].on_dis_tha==bl_distric)
                                                                               {
                                                                                   dis_c = disitem[i].dis_c;
                                                                                    this.setState({ Gotodistrict: dis_c,district: data})

                                                                                           fetch('http://1.10.184.64:3000/subdistrict/index.php?id='+dis_c)
                                                                                             .then(response => response.json())
                                                                                             .then(data => {

                                                                                                         for(var i =0;i<=data.length-1;i++){

                                                                                                                  if(data[i].on_sub_tha==bl_sub_dis)
                                                                                                                    {
                                                                                                                      sub_c = data[i].sub_c;
                                                                                                                      this.setState({ Gotosubdistrict: sub_c,subdistrict: data})
                                                                                                                         //console.log(this.state.Gotosubdistrict)
                                                                                                                     break;
                                                                                                                     }
                                                                                                         }
                                                                                             });
                                                                                   // console.log(this.state.Gotodistrict)
                                                                                    break;
                                                                               }

                                                                     }
                                                        });
                                                           lngLT = data[0].x;
                                                           latLT = data[0].y;

                                                           this.map.flyTo({ center: [data[0].x, data[0].y],zoom:15});
                                                         this.setState({

                                                         lbl_name:data[0].bl_name,
                                                         lbl_nstorey:data[0].bl_nstorey,
                                                         lbl_employ:data[0].bl_employ,
                                                         lat:data[0].x,
                                                         lon:data[0].y,
                                                         point:data[0].point,
                                                         mapzoom:16,

                                                          datapolygon:data[0].bld,
                                                          _x1:data[0].x1,
                                                          _y1:data[0].y1,
                                                          Gotoprovince: pro_c,
                                                          name: data[0].bl_name
                                                           });
                                                  var polygon =[];
                                                           
                                                 try{
                                                    polygon =JSON.parse(this.state.datapolygon);

                                                   } catch{}


                                                  try{

                                                   var mapLayer = this.map.getLayer('_plan');
                                                       if(typeof mapLayer !== 'undefined') {
                                                          this.map.removeLayer('_plan').removeSource('plan');
                                                       }
                                                  }
                                                  catch{}


                                                           this.map.addSource('plan', {

                                                                    'type': 'geojson',
                                                                    'data':
                                                                    {"features": [
                                                                    {"type": "Feature","properties":
                                                                    {"level": 1,"name": "Bird Exhibit","height": data[0].bl_nstorey+1,"base_height": 0,"color": "#CFBB10"},"geometry":
                                                                    {"coordinates": polygon,
                                                                    "type": "Polygon"},
                                                                    "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"},
                                                                     ],"type": "FeatureCollection"}
                                                                    });


                                                                    this.map.addLayer({
                                                                                'id': '_plan',
                                                                                'type': 'fill-extrusion',
                                                                                'source': 'plan',
                                                                                'paint': {
                                                                                // See the Mapbox Style Specification for details on data expressions.
                                                                                // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                                                                // Get the fill-extrusion-color from the source 'color' property.
                                                                                'fill-extrusion-color': ['get', 'color'],

                                                                                // Get fill-extrusion-height from the source 'height' property.
                                                                                'fill-extrusion-height': ['get', 'height'],

                                                                                // Get fill-extrusion-base from the source 'base_height' property.
                                                                                'fill-extrusion-base': ['get', 'base_height'],

                                                                                // Make extrusions slightly opaque for see through indoor walls.
                                                                                'fill-extrusion-opacity': 0.5
                                                                                    }
                                                                     });
                                                        this.map.setLayoutProperty('_plan', 'visibility', 'none');

                                                   var mapLayer = this.map.getLayer('layerLocation');
                                                   if(typeof mapLayer !== 'undefined') {
                                                      this.map.removeLayer('layerLocation').removeSource('Location');
                                                   }

                                                     var mpLayer = this.map.getLayer("layerLocation");
                                                        if (typeof mpLayer === 'undefined') {
                                                            // No Layer
                                                        } else {

                                                            this.map.removeLayer("layerLocation");
                                                             this.map.removeSource("Location");
                                                        }

                                                 this.map.addSource('Location', {
                                                    'type': 'geojson',
                                                    'data': {
                                                    'type': 'Feature',
                                                    'geometry': {
                                                    'type': 'Polygon',
                                                    'coordinates': polygon
                                                            }
                                                    }
                                                    });
                                                    this.map.addLayer({
                                                    'id': 'layerLocation',
                                                    'type': 'fill',
                                                    'source': 'Location',
                                                    'layout': {},
                                                    'paint': {
                                                    'fill-color': '#088',
                                                    'fill-opacity': 0.8
                                                    }
                                                    });


                                                          var mpLayer = this.map.getLayer("_pointslocation");
                                                                        if (typeof mpLayer === 'undefined') {
                                                                            // No Layer
                                                                        } else {

                                                                            this.map.removeLayer("_pointslocation");
                                                                             this.map.removeSource("pointlocation");
                                                                        }


                                                  this.map.loadImage(
                                                    imgmarkerlocation,
                                                    (error, image) => {
                                                   try{  this.map.addImage('imgmarkerlocation', image); }catch{}
                                                    this.map.addSource('pointlocation', {
                                                    'type': 'geojson',
                                                        'data': {
                                                        'type': 'FeatureCollection',
                                                        'features': [
                                                                {
                                                                'type': 'Feature',
                                                                'geometry': {
                                                                'type': 'Point',
                                                                'coordinates': [data[0].x,data[0].y]
                                                                }
                                                                }
                                                            ]
                                                        }
                                                    });

                                                    this.map.addLayer({
                                                            'id': '_pointslocation',
                                                            'type': 'symbol',
                                                            'source': 'pointlocation',
                                                            'layout': {
                                                            'icon-image': 'imgmarkerlocation',
                                                            'icon-size': 0.1
                                                                }
                                                            });
                                                        }
                                                    );



                                         fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&poi_type=404')
                                               .then(response => response.json())
                                                 .then(poi_plan => {

                                                  this.setState({
                                                      bl_name: poi_plan.name,
                                                      bl_nstorey: poi_plan.bl_nstorey,
                                                      bl_employ: poi_plan.bl_employ
                                                   });

                                                     var mapLayer = this.map.getLayer('_pointsbk');
                                                       if(typeof mapLayer !== 'undefined') {

                                                           this.map.removeLayer('_pointsbk').removeSource('pointbk');
                                                       }
                                                       this.map.loadImage(
                                                        imgmarkerbk,
                                                        (error, image) => {
                                                       try{ this.map.addImage('imgmarker', image); } catch { }

                                                        this.map.addSource('pointbk', {
                                                        'type': 'geojson',
                                                            'data': {
                                                            'type': 'FeatureCollection',
                                                            'features': [
                                                                    {
                                                                    'type': 'Feature',
                                                                    'geometry': {
                                                                    'type': 'Point',
                                                                    'coordinates': [poi_plan.x,poi_plan.y]
                                                                    }
                                                                    }
                                                                ]
                                                            }
                                                        });

                                                        this.map.addLayer({
                                                                'id': '_pointsbk',
                                                                'type': 'symbol',
                                                                'source': 'pointbk',
                                                                'layout': {
                                                                'icon-image': 'imgmarker',
                                                                'icon-size': 0.1
                                                                    }
                                                                });

                                                                 fetch('http://1.10.184.64:3000/bldg/convert.php?lat='+poi_plan.x+'&lon='+poi_plan.y+'')
                                                          .then(response => response.json())
                                                          .then(data => {
                                                           var urlb ='http://1.10.184.64:8080/geoserver/ctoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ctoc%3Abldg&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom,POINT('+data[0].ST_X+'%20'+data[0].ST_Y+'))';
                                                           //  this.setState({ arraydatapolygon: data, loading: false });
                                                                 fetch(urlb)
                                                                      .then(response => response.json())
                                                                      .then(data => {



                                                                                     if(typeof data.features[0] !== "undefined")
                                                                                        {

                                                                                       var _bl_name  =data.features[0].properties.bl_name;
                                                                                       var _bl_nstorey  =data.features[0].properties.bl_nstorey;
                                                                                       var _bl_employ  =data.features[0].properties.bl_employ;
                                                                                       this.setState({bbk:"", bl_name: _bl_name,bl_nstorey:_bl_nstorey,bl_employ :_bl_employ });


                                                                                         fetch('http://1.10.184.64:3000/bldg/objectid.php?id='+data.features[0].properties.objectid_1)
                                                                                                                            .then(response => response.json())
                                                                                                                            .then(data => {

                                                                                                                         var polygon =JSON.parse(data[0].bld);

                                                                                                                           var mapLayer = this.map.getLayer('layerbk');
                                                                                                                           if(typeof mapLayer !== 'undefined') {

                                                                                                                               this.map.removeLayer('layerbk').removeSource('sourcebk');
                                                                                                                           }

                                                                                                                                 this.map.addSource('sourcebk', {
                                                                                                                            'type': 'geojson',
                                                                                                                            'data': {
                                                                                                                            'type': 'Feature',
                                                                                                                            'geometry': {
                                                                                                                            'type': 'Polygon',
                                                                                                                            'coordinates': polygon
                                                                                                                                    }
                                                                                                                            }
                                                                                                                            });
                                                                                                                            this.map.addLayer({
                                                                                                                            'id': 'layerbk',
                                                                                                                            'type': 'fill',
                                                                                                                            'source': 'sourcebk',
                                                                                                                            'layout': {},
                                                                                                                            'paint': {
                                                                                                                            'fill-color': '#DDA0DD',
                                                                                                                            'fill-opacity': 0.5
                                                                                                                            }
                                                                                                                            });
                                                                                                                       });

                                                                                       fetch('http://1.10.184.64:3000/bldg/selectID.php?id='+data.features[0].properties.objectid_1)
                                                                                                             .then(response => response.json())
                                                                                                             .then(data => {
                                                                                                                  var polygon =JSON.parse(data[0].bld);

                                                                                                                       var mapLayer = this.map.getLayer('_planbk');
                                                                                                                           if(typeof mapLayer !== 'undefined') {
                                                                                                                              this.map.removeLayer('_planbk').removeSource('planbk');
                                                                                                                           }
                                                                                                                    this.map.addSource('planbk', {

                                                                                                                                'type': 'geojson',
                                                                                                                                'data':
                                                                                                                                {"features": [
                                                                                                                                {"type": "Feature","properties":
                                                                                                                                {"level": 1,"name": "Bird Exhibit","height": data[0].bl_nstorey+1,"base_height": 0,"color": "#8710CF"},"geometry":
                                                                                                                                {"coordinates": polygon,
                                                                                                                                "type": "Polygon"},
                                                                                                                                "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"},
                                                                                                                                 ],"type": "FeatureCollection"}
                                                                                                                                });

                                                                                                                                this.map.addLayer({
                                                                                                                                            'id': '_planbk',
                                                                                                                                            'type': 'fill-extrusion',
                                                                                                                                            'source': 'planbk',
                                                                                                                                            'paint': {
                                                                                                                                            // See the Mapbox Style Specification for details on data expressions.
                                                                                                                                            // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                                                                                                                            // Get the fill-extrusion-color from the source 'color' property.
                                                                                                                                            'fill-extrusion-color': ['get', 'color'],

                                                                                                                                            // Get fill-extrusion-height from the source 'height' property.
                                                                                                                                            'fill-extrusion-height': ['get', 'height'],

                                                                                                                                            // Get fill-extrusion-base from the source 'base_height' property.
                                                                                                                                            'fill-extrusion-base': ['get', 'base_height'],

                                                                                                                                            // Make extrusions slightly opaque for see through indoor walls.
                                                                                                                                             'fill-extrusion-opacity': 0.5
                                                                                                                                                }
                                                                                                                                 });
                                                                                                                                 this.map.setLayoutProperty('_planbk', 'visibility', 'none');



                                                                                               });

                                                                                       }
                                                                                       else
                                                                                       {
                                                                                                            this.setState({dataError:true})
                                                                                       }

                                                                      });
                                                              });

                                                            }
                                                        );



                                                                 })

                                                               fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&poi_type=412')
                                                              .then(response => response.json())
                                                              .then(poi_sni => {

                                                                 this.setState({
                                                                 _latSP:poi_sni.x,
                                                                 _lngSP:poi_sni.y,
                                                                 bs:"",
                                                                 bs_name:poi_sni.name,
                                                                 bs_nstorey:poi_sni.bl_nstorey,
                                                                 bs_employ:poi_sni.bl_employ
                                                                 });

                                              if(poi_sni.x>0 && poi_sni.y>0){
                                                 var mapLayer = this.map.getLayer('pointssniper');
                                                   if(typeof mapLayer !== 'undefined') {

                                                       this.map.removeLayer('pointssniper').removeSource('pointsniper');
                                                   }
                                                   this.map.loadImage(imgmarkersniper,
                                                    (error, image) => {
                                                   try{ this.map.addImage('imgmarkersniper', image); }catch{ }
                                                    this.map.addSource('pointsniper', {
                                                    'type': 'geojson',
                                                        'data': {
                                                        'type': 'FeatureCollection',
                                                        'features': [
                                                                {
                                                                'type': 'Feature',
                                                                'geometry': {
                                                                'type': 'Point',
                                                                 'coordinates': [poi_sni.x,poi_sni.y]
                                                                }
                                                                }
                                                            ]
                                                        }
                                                    });

                                                    this.map.addLayer({
                                                            'id': 'pointssniper',
                                                            'type': 'symbol',
                                                            'source': 'pointsniper',
                                                            'layout': {
                                                            'icon-image': 'imgmarkersniper',
                                                            'icon-size': 0.1
                                                                }
                                                            });
                                                        }
                                                    );

                                                     if(this.state.lat!=0&&this.state.lon!=0)
                                                     {

                                                                     lngLT = this.state.lat;
                                                                     latLT = this.state.lon;

                                                                     this.map.addSource('route', {
                                                                        'type': 'geojson',
                                                                        'data': {
                                                                        'type': 'Feature',
                                                                        'properties': {},
                                                                        'geometry': {
                                                                        'type': 'LineString',
                                                                        'coordinates': [
                                                                        [lngLT,latLT],
                                                                        [poi_sni.x,poi_sni.y],
                                                                        ]
                                                                        }
                                                                        }
                                                                });

                                                                this.map.addLayer({
                                                                            'id': '_route',
                                                                            'type': 'line',
                                                                            'source': 'route',
                                                                            'layout': {
                                                                            'line-join': 'round',
                                                                            'line-cap': 'round'
                                                                            },
                                                                            'paint': {
                                                                            'line-color': '#888',
                                                                            'line-width': 8
                                                                            }
                                                                });

                                                                   if(lngLT!=="" && poi_sni.x !="" ){

                                                                    let pt1 = point([lngLT,latLT], { 'name': 'Kano State' })
                                                                    let pt2 = point([poi_sni.x,poi_sni.y], { 'name': 'Lagos State' })

                                                                    var  result = distance(pt1, pt2, { units: 'metres' })
                                                                    var calresult = parseFloat(result).toFixed(2);
                                                                    this.setState({vdistance:calresult});

                                                                    }

                                                    }
                                                    fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&&poi_type=414')
                                             .then(response => response.json())
                                             .then(data => {

                                                     var y =data.x;
                                                     var x=data.y;



                                             fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&poi_type=412')
                                              .then(response => response.json())
                                              .then(poi_sni => {


                                                     lngLT = x;
                                                     latLT = y;

                                                     var mapLayer = this.map.getLayer('_route');
                                                     if(typeof mapLayer !== 'undefined') {

                                                       this.map.removeLayer('_route').removeSource('route');
                                                  }
                                                     this.map.addSource('route', {
                                                        'type': 'geojson',
                                                        'data': {
                                                        'type': 'Feature',
                                                        'properties': {},
                                                        'geometry': {
                                                        'type': 'LineString',
                                                        'coordinates': [
                                                        [y,x],
                                                        [poi_sni.x,poi_sni.y],
                                                        ]
                                                        }
                                                        }
                                                });

                                                this.map.addLayer({
                                                            'id': '_route',
                                                            'type': 'line',
                                                            'source': 'route',
                                                            'layout': {
                                                            'line-join': 'round',
                                                            'line-cap': 'round'
                                                            },
                                                            'paint': {
                                                            'line-color': '#888',
                                                            'line-width': 8
                                                            }
                                                });

                                                   if(lngLT!=="" && poi_sni.x !="" ){

                                                    let pt1 = point([y,x], { 'name': 'Kano State' })
                                                    let pt2 = point([poi_sni.x,poi_sni.y], { 'name': 'Lagos State' })

                                                    var  result = distance(pt1, pt2, { units: 'metres' })
                                                    var calresult = parseFloat(result).toFixed(2);
                                                    this.setState({vdistance:calresult});

                                                    }
                                              });





                                                              this.setState({lat_terrorist:data.y,lon_terrorist:data.x});
                                                                                var mapLayer = this.map.getLayer('_pointterrorist');
                                                                                if (typeof mapLayer !== 'undefined') {

                                                                                    this.map.removeLayer('_pointterrorist').removeSource('pointterrorist');
                                                                                }

                                                                                this.map.loadImage(
                                                                                    imgmarkerterrorist,
                                                                                    (error, image) => {
                                                                                        try { this.map.addImage('imgmarkerterrorist', image); } catch { }
                                                                                        this.map.addSource('pointterrorist', {
                                                                                            'type': 'geojson',
                                                                                            'data': {
                                                                                                'type': 'FeatureCollection',
                                                                                                'features': [
                                                                                                    {
                                                                                                        'type': 'Feature',
                                                                                                        'geometry': {
                                                                                                            'type': 'Point',
                                                                                                            'coordinates': [data.x, data.y]
                                                                                                        }
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        });
                                                                                        this.map.addLayer({
                                                                                            'id': '_pointterrorist',
                                                                                            'type': 'symbol',
                                                                                            'source': 'pointterrorist',
                                                                                            'layout': {
                                                                                                'icon-image': 'imgmarkerterrorist',
                                                                                                'icon-size': 0.1
                                                                                            }
                                                                                        });
                                                                                    }
                                                                                );


                                             })

                                                }

                                                       fetch('http://1.10.184.64:3000/bldg/convert.php?lat='+poi_sni.x+'&lon='+poi_sni.y+'')
                                                          .then(response => response.json())
                                                          .then(data => {



                                                              var urlb= 'http://1.10.184.64:8080/geoserver/ctoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ctoc%3Abldg&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom,POINT('+data[0].ST_X+'%20'+data[0].ST_Y+'))';
                                                              console.log(urlb)
                                                              //  this.setState({ arraydatapolygon: data, loading: false });
                                                                 fetch(urlb)
                                                                      .then(response => response.json())
                                                                      .then(data => {


                                                                                                            fetch('http://1.10.184.64:3000/bldg/selectID.php?id='+data.features[0].properties.objectid_1)
                                                                                                             .then(response => response.json())
                                                                                                             .then(data => {
                                                                                                                  var polygon =JSON.parse(data[0].bld);

                                                                                                                       var mapLayer = this.map.getLayer('_plansni');
                                                                                                                           if(typeof mapLayer !== 'undefined') {
                                                                                                                              this.map.removeLayer('_plansni').removeSource('plansni');
                                                                                                                           }
                                                                                                                    this.map.addSource('plansni', {

                                                                                                                                'type': 'geojson',
                                                                                                                                'data':
                                                                                                                                {"features": [
                                                                                                                                {"type": "Feature","properties":
                                                                                                                                {"level": 1,"name": "Bird Exhibit","height": data[0].bl_nstorey+1,"base_height": 0,"color": "#CF7E10 "},"geometry":
                                                                                                                                {"coordinates": polygon,
                                                                                                                                "type": "Polygon"},
                                                                                                                                "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"},
                                                                                                                                 ],"type": "FeatureCollection"}
                                                                                                                                });

                                                                                                                                this.map.addLayer({
                                                                                                                                            'id': '_plansni',
                                                                                                                                            'type': 'fill-extrusion',
                                                                                                                                            'source': 'plansni',
                                                                                                                                            'paint': {
                                                                                                                                            // See the Mapbox Style Specification for details on data expressions.
                                                                                                                                            // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                                                                                                                            // Get the fill-extrusion-color from the source 'color' property.
                                                                                                                                            'fill-extrusion-color': ['get', 'color'],

                                                                                                                                            // Get fill-extrusion-height from the source 'height' property.
                                                                                                                                            'fill-extrusion-height': ['get', 'height'],

                                                                                                                                            // Get fill-extrusion-base from the source 'base_height' property.
                                                                                                                                            'fill-extrusion-base': ['get', 'base_height'],

                                                                                                                                            // Make extrusions slightly opaque for see through indoor walls.
                                                                                                                                                'fill-extrusion-opacity': 0.5
                                                                                                                                                }
                                                                                                                                 });

                                                                                                                          this.map.setLayoutProperty('_plansni', 'visibility', 'none');

                                                                                               });

                                                                       if(typeof data.features[0] !== "undefined")
                                                                                        {
                                                                                       var _bs_name  =data.features[0].properties.bl_name;
                                                                                            var _bs_nstorey  =data.features[0].properties.bl_nstorey;
                                                                                               var _bs_employ =data.features[0].properties.bl_employ;
                                                                                            this.setState({bs:"", bs_name:_bs_name,bs_nstorey:_bs_nstorey,bs_employ:_bs_employ});

                                                                                            fetch('http://1.10.184.64:3000/bldg/objectid.php?id='+data.features[0].properties.objectid_1)
                                                                                                                            .then(response => response.json())
                                                                                                                            .then(data => {

                                                                                                                             var polygon =JSON.parse(data[0].bld);

                                                                                                                           var mapLayer = this.map.getLayer('layersniper');
                                                                                                                           if(typeof mapLayer !== 'undefined') {

                                                                                                                               this.map.removeLayer('layersniper').removeSource('sourcesniper');
                                                                                                                           }

                                                                                                                                 this.map.addSource('sourcesniper', {
                                                                                                                            'type': 'geojson',
                                                                                                                            'data': {
                                                                                                                            'type': 'Feature',
                                                                                                                            'geometry': {
                                                                                                                            'type': 'Polygon',
                                                                                                                            'coordinates': polygon
                                                                                                                                    }
                                                                                                                            }
                                                                                                                            });
                                                                                                                            this.map.addLayer({
                                                                                                                            'id': 'layersniper',
                                                                                                                            'type': 'fill',
                                                                                                                            'source': 'sourcesniper',
                                                                                                                            'layout': {},
                                                                                                                            'paint': {
                                                                                                                            'fill-color': '#FF8C00',
                                                                                                                            'fill-opacity': 0.5
                                                                                                                            }
                                                                                                                            });

                                                                                                                       });
                                                                                       }
                                                                                       else
                                                                                       {
                                                                                                            this.setState({dataError:true})
                                                                                       }
                                                                      });
                                                              });



                                                                 })

                                                         });

                                                               fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&poi_type=413')
                                                              .then(response => response.json())
                                                              .then(poi_dis => {

                                               if(poi_dis.x!=0 &&poi_dis.y!=0){




                                                     var mapLayer = this.map.getLayer('_pointdistance');
                                                   if(typeof mapLayer !== 'undefined') {

                                                       this.map.removeLayer('_pointdistance').removeSource('pointdistance');
                                                   }

                                                   this.map.loadImage(
                                                    imgmarkerdistance,
                                                    (error, image) => {
                                                    try { this.map.addImage('imgmarkerdistance', image); }catch{}
                                                    this.map.addSource('pointdistance', {
                                                    'type': 'geojson',
                                                        'data': {
                                                        'type': 'FeatureCollection',
                                                        'features': [
                                                                {
                                                                'type': 'Feature',
                                                                'geometry': {
                                                                'type': 'Point',
                                                                'coordinates': [poi_dis.x,poi_dis.y]
                                                                }
                                                                }
                                                            ]
                                                        }
                                                    });
                                                    this.map.addLayer({
                                                            'id': '_pointdistance',
                                                            'type': 'symbol',
                                                            'source': 'pointdistance',
                                                            'layout': {
                                                            'icon-image': 'imgmarkerdistance',
                                                            'icon-size': 0.1
                                                                }
                                                            });
                                                        }
                                                    );




                                                         const config = {
                                                              headers: {
                                                                'Content-Type': 'application/x-www-form-urlencoded'
                                                              }
                                                        }
                                                            const qs = require('querystring')
                                                          fetch('http://1.10.184.64:3000/bldg/point1000m.php?lat='+lngLT+"&&lon="+latLT+"&&m="+this.state.weaponmin)
                                                          .then(response => response.json())
                                                          .then(data => {
                                                                    this.setState({pl:JSON.stringify(data[0].point)})
                                                                     const body =
                                                                    {
                                                                      x : _latitude,
                                                                      y:  _longitude,
                                                                      pl: JSON.stringify(data[0].point),

                                                                    }
                                                                      axios.post('http://1.10.184.64:3000/st/index.php',qs.stringify(body), config)
                                                                    .then((result) => {
                                                                            var data = JSON.stringify(result);
                                                                           if(data.includes("EMPTY")===true){

                                                                             // this.map.flyTo({ center: [lngLT, latLT],zoom:15});
                                                                           }else
                                                                           {
                                                                            // console.log(_longitude+"xxxxxxxxxxxxxdddddddddddd")
                                                                              //this.map.flyTo({ center: [latLT,latLT],zoom:15});
                                                                           }
                                                                    })
                                                          })





                                             var directions = [];
                                             var directionsData = [];


                                   fetch('http://1.10.184.64:3000/route_plan/select.php?plan_id='+xid)
                                                     .then(response => response.json())
                                                     .then(data => {

                                                      if(typeof data.routes !== "undefined")
                                                                     {
                                                                         var distance=  data.routes[0].distance;
                                                                          var duration=  data.routes[0].duration;
                                                                          this.setState({apiduration:duration,apidistance:distance})
                                                                         for (var ii=0;ii<data.routes[0].legs.[0].steps.length;ii++)
                                                                        {
                                                                        var rows =data.routes[0].legs.[0].steps[ii].maneuver.location;
                                                                           directions.push(rows);
                                                                            var rowsData =data.routes[0].legs.[0].steps[ii].maneuver;


                                                                           directionsData.push(typeof rowsData.modifier==='undefined'?"": rowsData.modifier+" ประเภท:"+rowsData.type+" คำแนะนำ:"+rowsData.instruction);
                                                                           for (var iii=0;iii<data.routes[0].legs.[0].steps[ii].intersections.length;iii++)
                                                                           {
                                                                             var rows2 =data.routes[0].legs.[0].steps.[ii].intersections[iii].location;
                                                                            // directions.push(rows2);
                                                                           }
                                                                            for (var iiii=0;iiii<data.routes[0].legs.[0].steps[ii].geometry.coordinates.length;iiii++)
                                                                           {
                                                                             var rowsX =data.routes[0].legs.[0].steps.[ii].geometry.coordinates[iiii];
                                                                             directions.push(rowsX);

                                                                           }

                                                                          }



                                                                        }
                                                                                       else
                                                                                       {
                                                                                                       //     this.setState({dataError:true})
                                                                                       }

                                                                       this.setState({rdirections:directions});

                                                                       this.setState({datainfo:directionsData});

                     var geom = data.geom.toString().replace('MULTILINESTRING','').replaceAll(',','],[').replace('((','[[').replace('))',']]').replaceAll(' ',',')//.replaceAll(' ','],[').replace('((','[[').replace('))',']]');
                            // console.log("ge"+geom+"ge")
                                                         var _geom =  JSON.parse(geom);

                                                         var mpLayer = this.map.getLayer("_routedis");
                                                        if (typeof mpLayer === 'undefined') {
                                                            // No Layer
                                                        } else {

                                                            this.map.removeLayer("_routedis");
                                                             this.map.removeSource("routedis");
                                                        }


                                                                        this.map.addSource('routedis', {
                                                                                'type': 'geojson',
                                                                                'data': {
                                                                                'type': 'Feature',
                                                                                'properties': {},
                                                                                'geometry': {
                                                                                'type': 'LineString',
                                                                                'coordinates': _geom
                                                                                }
                                                                                }
                                                                        });
                                                                        this.map.addLayer({
                                                                                    'id': '_routedis',
                                                                                    'type': 'line',
                                                                                    'source': 'routedis',
                                                                                    'layout': {
                                                                                    'line-join': 'round',
                                                                                    'line-cap': 'round'
                                                                                    },
                                                                                    'paint': {
                                                                                    'line-color': 'blue',
                                                                                    'line-width': 8
                                                                                    }
                                                                        });
                                                                         this.ClearLayer();


                                                              });






                                                 this.ClearLayer();

                                                actionDistance="false";
                                               this.setState({colorDIS:"#663333",disabledDIS:false})
                                                                                       }
                                                                 })


                                                 var mapLayer = this.map.getLayer('_geolocation');
                                                   if(typeof mapLayer !== 'undefined') {

                                                       this.map.removeLayer('_geolocation').removeSource('geolocation');
                                                   }
                                                   this.map.loadImage(
                                                    imggeolocation,
                                                    (error, image) => {
                                                    try { this.map.addImage('imggeolocation', image); }catch{}
                                                    try{


                                                        this.map.addSource('geolocation', {
                                                        'type': 'geojson',
                                                            'data': {
                                                            'type': 'FeatureCollection',
                                                            'features': [
                                                                    {
                                                                    'type': 'Feature',
                                                                    'geometry': {
                                                                    'type': 'Point',
                                                                    'coordinates': [_longitude,_latitude]
                                                                    }
                                                                    }
                                                                ]
                                                            }
                                                        });

                                                    } catch{ }
                                                    this.map.addLayer({
                                                            'id': '_geolocation',
                                                            'type': 'symbol',
                                                            'source': 'geolocation',
                                                            'layout': {
                                                            'icon-image': 'imggeolocation',
                                                            'icon-size': 0.1
                                                                }
                                                            });
                                                        }
                                                    );



                                        this.map.on('click', function (e) {
                                                         var lngLat =e.lngLat.wrap();
                                                                _longitude=lngLat.lng;
                                                                _latitude=lngLat.lat;

                                        });

                                              })
                       }
               })



  }
       ClearLayer =() =>{





                          this.map.moveLayer('layersniper', 0);


                              this.map.moveLayer('sourcesniper', 0);
                                 this.map.moveLayer('layerbk', 0);
                              this.map.moveLayer('sourcebk', 0);
                          this.map.moveLayer('maine500', 0);
                            this.map.moveLayer('maine1000', 0);
                            this.map.moveLayer('maine500', 0);
                            this.map.moveLayer('maine1000', 0);
                            this.map.moveLayer('_maine500', 0);
                            this.map.moveLayer('_maine1000', 0);



                               this.map.moveLayer('l_showblg', 0);
                            this.map.moveLayer('s_showblg', 0);


                              this.map.moveLayer('Location', 0);
                            this.map.moveLayer('layerLocation', 0);

                                this.map.moveLayer('routedis', 0);
                            this.map.moveLayer('_routedis', 0);
                            this.map.moveLayer('route', 0);
                            this.map.moveLayer('_route', 0);

                            this.map.moveLayer('_pointslocation', 0);
                            this.map.moveLayer('pointslocation', 0);
                            this.map.moveLayer('_pointsbk', 0);
                            this.map.moveLayer('pointsbk', 0);
                            this.map.moveLayer('pointssniper', 0);
                            this.map.moveLayer('pointdistance', 0);
                             this.map.moveLayer('_pointdistance', 0);
                            this.map.moveLayer('pointssniper', 0);
                            this.map.moveLayer('_pointssniper', 0);
                               this.map.moveLayer('_plan', 0);
                            this.map.moveLayer('plan', 0);



       }
      componentDidMount() {

            // Any information the map needs can be passed down as a prop from the parent  MMap
            var { map } = this.props;
             const {dd,oo} = this.state;
            // Store the map as a property on the class so we can call mapbox methods later
            mapboxgl.accessToken = 'pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w';
            this.map = new mapboxgl.Map({
                style: {"version": 8,
                                      "name": "OSM",
                                      "metadata": {
                                      },
                                      "sources": {
                                        "osm": {
                                          "type": "raster",
                                          "tiles": [
                                            "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                          ]
                                        }
                                      },
                                      "layers": [
                                        {
                                          "id": "osm",
                                          "type": "raster",
                                          "source": "osm"
                                        }
                                      ],
                                      "id": "klokantech-basic"
                                    },
                center: [100.523237700882,13.7065817585414],
                animate: false,
                zoom: 4,

                container: 'map',

            });
                            this.map.on('load', () => {


                            var layers =  this.map.getStyle().layers;

                                var labelLayerId;
                                for (var i = 0; i < layers.length; i++) {
                                if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                                labelLayerId = layers[i].id;
                                break;
                                }
                                }

                 });


                           /*  this.interval = setInterval(() => {
                                const { timeTillDate, timeFormat } = this.props;
                                const then = moment(timeTillDate, timeFormat);
                                const now = moment();
                                const countdown = moment(then - now);
                                const days = countdown.format('D');
                                const hours = countdown.format('HH');
                                const minutes = countdown.format('mm');
                                const seconds = countdown.format('ss');

                                this.setState({ days, hours, minutes, seconds });
                            }, 1000);*/
        }
    checkstart = () =>
    {
   console.log("selecwwwt")

       // this.setState({ alertopen: true });
        this.timer = setInterval(() => {

            var select = '';
            if (this.state.CUSTOMER_ID == 0) {
                select = '/api/joincount/pagination';
            } else {
                select = '/api/joincountcus/pagination/'+this.state.CUSTOMER_ID;
            }

            console.log(select +"select")
            fetch(select)
                .then(response => response.json())
                .then(datapage => {

                   // console.log("TESG_T" + datapage)
                   // if (cookie.load('datapage') < datapage) {
                     //   this.setState({alertopen: true })
                   // }
                  //  console.log("_cookie" + cookie.load('datapage') + "datapage:" + datapage)
                    //var countdata = countdata + 1;


                 //   cookie.save('datapage', datapage, { path: '/' })
                });
           // console.log("cookie" + cookie.load('datapage') + "datapage:")
           /// this.setState({ test: 'eeeeeeeeeeeeeeee', alertopen: true })

        }, 60000);
    }

  opendoc =(id,news_group1,news_group2,news_group3,news_group4,period,name_job,event)=>
   {
     this.props.history.push("/app/detailnews/Index?id="+id);
    /* console.log("opendoc"+id+"_s"+period);
     this.setState({
     open1:true,
     news_group1:news_group1,
     news_group2:news_group2,
     news_group3:news_group3,
     news_group4:news_group4,
     Period:period,
     name_job:name_job
     })*/
   }
   handleEvent =()=>{
     this.setState({dgraph:100})
    }
    onMouseLeaveHander = () =>
    {
     this.setState({dgraph:0})
    }

  render() {
         const {data, order, orderBy, selected, rowsPerPage, page,
    _data1,_data2,_data3,_data4,_data5,_data6,
    lbl_nstorey, lbl_name, bs_name,bs_nstorey,bs_employ,
     ststylelos,bl_name,  bl_nstorey, bl_employ, bbk,lbl_employ,
     bs,ARdata,AMdata,hideMassage,hideMin,dataAlert,time_count,
     days, hours, minutes, seconds,status,news

    } = this.state;
        let _poi_plan_report = this.state.poi_plan_report;
        let poi_plan_reportItems = _poi_plan_report.map((_poi_plan_report) =>

           <div   key={_poi_plan_report.id} >

                <div className="row ml-3" style={{color:'#fff'}}>
                         <div className="col-md-12 col-12  p-0 m-0">
                                   <div className="row" >

                                    <div>
                                    <Avatar
                                               alt="http://via.placeholder.com/150x150"
                                                      src="http://via.placeholder.com/150x150"
                                              className="user-avatar"
                                            />
                                    </div>
                                  <div  style={{color:'#fff'}}
                                        onClick={(e)=> this._goTo(e,_poi_plan_report.x,_poi_plan_report.y)}>

                                       {_poi_plan_report.fname}
                                       <spen className="ml-1">
                                       <i class="zmdi zmdi-pin zmdi-hc-2x ml-2" ></i>
                                       </spen>
                                  </div>
                            </div>
                        </div>

                        <div className="col-md-8 col-12  p-0 m-0 ml-1 ">
                              <div className="row" >


                             </div>
                              <div className="row" style={{color:'#fff'}}>

                                         {_poi_plan_report.text_rpt}
                             </div>
                              <div className="row" style={{color:'#fff'}}>

                                   {
                                    _poi_plan_report.dt_rpt==null?'':
                                    _poi_plan_report.dt_rpt.substring(8,10)+'-'+
                                    _poi_plan_report.dt_rpt.substring(5,7)+'-'+
                                    _poi_plan_report.dt_rpt.substring(0,4)+' '+
                                     _poi_plan_report.dt_rpt.substring(11,19)
                                    }
                             </div>

                              <div className="row" style={{color:'#fff'}}>
                                  {_poi_plan_report.x} {_poi_plan_report.y}
                             </div>
                        </div>
                </div>





                  <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
           </div>

        );



    return (
    <div className="" >
    <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Kanit' />
    <div  style={{ fontFamily: 'Kanit' }}>
    <ProfileHeader/>

    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">


              </div>
            </div>
         </div>
         </div>

      <div className="p-2 pt-3" >
      <ProfileHeader/>
        <div className="jr-profile-content ">
           <div className="bg-geekblue  border border-primary p-4" style={{ position: 'absolute', zIndex: this.state.dgraph,right:10,top:50 }}>
                           <div >
                              <div>
                                    <Bar

                                                data={this.state.graph}
                                                height={300}
                                                width={400}
                                                options={

                                                {
                                                 maintainAspectRatio: false,
                                                 legend: {
                                                         labels: {
                                                              fontColor: 'orange'
                                                             }
                                                          },
                                                    title:{
                                                      display:true,
                                                      fontColor: '#fff',
                                                      text:'ด้านกำลังพล',
                                                      fontSize:20
                                                    },
                                                    legend:{
                                                      display:false,
                                                      position:'right'
                                                    },
                                                 scales: {
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero:true,
                                                            fontColor: '#fff'
                                                        },
                                                    }],
                                                 xAxes: [{
                                                        ticks: {
                                                            fontColor: '#fff'
                                                        },
                                                    }]
                                                    }
                                                }}
                                    />
                              </div>
                           </div>
           </div>
            <div className="">
                <div className="" Style={{marginTop:"-50px"}}  >
                    <div className="jr-tabs-content jr-task-list">
                            <div className=" bg-geekblue  border border-primary"  >


                            <div className="row ">
                                                   <div className="col-xl-5 col-lg-8 col-md-12 col-12">
                                                           <div className="ml-3 mt-3 mb-2" style={{borderRadius:'5px'}}>
                                                              <div className="text-white">
                                                             <NotificationsIcon/>      สถานการณ์ระดับ
                                                                   {status==""?" 1 :สถานการณ์ปกติ":""}
                                                                   {status=="0"?" 1 :สถานการณ์ปกติ":""}
                                                                   {status=="1"?" 2 :สถานการณ์ฉุกเฉิน":""}
                                                                   {status=="2"?" 3 :สถานการณ์ชั้นตอบโต้":""}
                                                                   {status=="3"?" 4 :สถานการณ์ชั้นป้องกันประเทศ":""}
                                                              </div>
                                                                 <div className="row col-7  ml-0 pl-0 mt-1">


                                                                </div>
                                                           </div>
                                                   </div>

                                                   <div className="col-xl-5 col-lg-2 col-md-12 col-12">

                                                   </div>
                                                   <div className="col-xl-2 col-lg-2 col-md-12 col-12">

                                                      <div className="m-4" onMouseOver={this.handleEvent}  onMouseLeave={this.onMouseLeaveHander}>

                                                          <div className="row" >
                                                              <div className="col-2"  >

                                                               </div>

                                                              </div>
                                                      </div>
                                                   </div>

                                             </div>

                                        <div className="row ml-3" >

                                                          <div className=""  >
                                                                          {status==0||status==""?
                                                                               <div
                                                                                 style={{
                                                                                    boxShadow: "rgb(43 165 21 / 92%) 0px 1px 20px 5px, rgb(76 174 80 / 20%) 0px 8px 17px 0px",
                                                                                    borderRadius: "20px",
                                                                                    backgroundColor: "green",
                                                                                    height: '20px',width:'20px'
                                                                                  }}
                                                                                />
                                                                                :<div
                                                                                 style={{
                                                                                    boxShadow: "rgba(76, 174, 80, 0.19) 0px 6px 20px 0px, rgba(76, 174, 80, 0.2) 0px 8px 17px 0px",
                                                                                    borderRadius: "20px",
                                                                                    backgroundColor: "green",
                                                                                    height: '20px',width:'20px'
                                                                                  }}
                                                                                />}
                                                                     </div>
                                                                      <div className=" ml-3">
                                                                      {status==1?
                                                                            <div
                                                                             style={{

                                                                                boxShadow: "rgb(216 237 36 / 92%) 0px 1px 20px 5px, rgba(76, 174, 80, 0.2) 0px 8px 17px 0px",
                                                                                borderRadius: "20px",
                                                                                backgroundColor: "yellow",
                                                                                height: '20px',width:'20px'
                                                                              }}
                                                                            />:
                                                                            <div
                                                                             style={{

                                                                                boxShadow: "rgba(76, 174, 80, 0.19) 0px 6px 20px 0px, rgba(76, 174, 80, 0.2) 0px 8px 17px 0px",
                                                                                borderRadius: "20px",
                                                                                backgroundColor: "yellow",
                                                                                height: '20px',width:'20px'
                                                                              }}
                                                                            />
                                                                            }
                                                                     </div>
                                                                      <div className="ml-3">
                                                                             {status==2?
                                                                                    <div
                                                                                     style={{

                                                                                        boxShadow: "#ffb307 -1px 0px 20px 4px, rgb(215 117 33 / 35%) 0px 8px 17px 0px",
                                                                                        borderRadius: "20px",
                                                                                        backgroundColor: "orange",
                                                                                        height: '20px',width:'20px'
                                                                                      }}
                                                                                    />:
                                                                                    <div
                                                                                     style={{

                                                                                        boxShadow: "rgba(76, 174, 80, 0.19) 0px 6px 20px 0px, rgba(76, 174, 80, 0.2) 0px 8px 17px 0px",
                                                                                        borderRadius: "20px",
                                                                                        backgroundColor: "orange",
                                                                                        height: '20px',width:'20px'
                                                                                      }}
                                                                                    />
                                                                            }

                                                                     </div>

                                                                     <div className="ml-3">
                                                                             {status==3?
                                                                                    <div
                                                                                      style={{

                                                                                        boxShadow: "rgb(255 7 7) -1px 0px 20px 4px, rgb(215 117 33 / 35%) 0px 8px 17px 0px",
                                                                                        borderRadius: "20px",
                                                                                        backgroundColor: "red",
                                                                                        height: '20px',width:'20px'
                                                                                      }}
                                                                                    />:
                                                                                    <div
                                                                                      style={{

                                                                                        borderRadius: "20px",
                                                                                        backgroundColor: "red",
                                                                                        height: '20px',width:'20px'
                                                                                      }}
                                                                                    />
                                                                            }

                                                                     </div>
                             </div>
                                <div className="row  mt-1 pl-3">
                                                   <div className="col-xl-6 col-lg-6 col-md-12 col-12 pr-1">
                                                      <div className="text-white mt-2"><BarChartIcon/> สรุปสถานการณ์ข่าวประจำวัน</div>
                                                   </div>
                                </div>




                            <div className="row  mt-1 pl-3 pr-3 pb-0">
                                 <div className="col-12">

                                 <Widget styleName="bg-primary text-white m-0 ">
                                       <div className="table-responsive-material  border border-primary">
                        <Table className="" size="small" >
                          <DataTableHead

                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={data.length}
                          />
                          <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                              const isSelected = this.isSelected(n.id);
                              return (
                                <TableRow
                                    onClick={()=>this.opendoc(n.id,n.news_group1,n.news_group2,n.news_group3,n.news_group4,n.period,n.name_job)}
                                  >
                                  <TableCell style={{color:'white',fontFamily: 'Kanit'}}  >
                                        {
                                           n.period==null?'':
                                           n.period.substring(8,10)+n.period.split('T')[1].split('.')[0].replaceAll(':','').substring(0,4)+' '
                                        }
                                        { n.period==null?'':n.period.substring(5,7)=="01"?"ม.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="02"?"ก.พ.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="03"?"มี.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="04"?"เม.ย.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="05"?"พ.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="06"?"มิ.ย.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="07"?"ก.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="08"?"ส.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="09"?"ก.ย.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="10"?"ต.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="11"?"พ.ย.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="12"?"ธ.ค.":""}
                                        {
                                            n.period==null?'':" "+String((parseInt(n.period.substring(0,4))+543)).substring(2,4)+""
                                        }
                                  </TableCell>
                                  <TableCell style={{color:'white',fontFamily: 'Kanit'}} >{n.title}</TableCell>

                                  <TableCell style={{color:'white',fontFamily: 'Kanit'}} >
                                  {
                                     this.state.users.filter(x => x.id == n.name_job).length != 0 ?
                                     this.state.users.filter(x => x.id == n.name_job)[0].fname :""
                                  }
                                  {
                                     this.state.users.filter(x => x.id == n.name_job).length != 0 ?
                                     " "+this.state.users.filter(x => x.id == n.name_job)[0].lname :""
                                  }
                                  </TableCell>

                                </TableRow>
                              );
                            })}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                 style={{color:'white'}}
                                count={data.length}
                                 labelDisplayedRows={({ from, to, count }) =>  <div style={{ color:'white',fontFamily: 'Kanit' }}>{from}-{to} จาก {count}</div>}
                                labelRowsPerPage=<div style={{ color:'white',fontFamily: 'Kanit' }}>รายการ</div>
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                        </div>
                      </Widget>
                                                   </div>
                                             </div>
                                                        <div className="row  mt-1 pl-3 pr-3">
                                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-12 pr-3" >

                                                                                          <div className="row ">
                                                                                            <div className="mt-0 mb-3 ml-3 pt-4">
                                                                                              <div className="row">
                                                                                                 <div className="col-xl-12 col-lg-2 col-md-12 col-12 text-white">
                                                                                                    <EventIcon />  ชื่อเหตุการณ์ {this.state.event_name}
                                                                                                 </div>
                                                                                              </div>
                                                                                               <div className="row mt-2">
                                                                                                 <div className="col-xl-12 col-lg-2 col-md-12 col-12 text-white">
                                                                                                    <AccountBalanceIcon />     สถานที่ {this.state.gotoname}
                                                                                                 </div>
                                                                                              </div>


                                                                                            </div>
                                                                                                      <div className="col-xl-12 col-lg-12 col-md-6 col-12">
                                                                                                          <div className="">
                                                                                                          <Widget styleName="bg-primary text-white">
                                                                                                             <div className="row d-flex justify-content-center ">

                                                                                                                          <div className="pl-2" style={{width:'250px'}}>
                                                                                                                             <ReactSpeedometer width='210' height='130' value={this.state.total}
                                                                                                                             textColor="white"  maxValue={100} value={this.state.army_total.length==0?0:((this.state.army_total[0].action-this.state.army_total[0].noaction)/this.state.army_total[0].action*100).toFixed(0)} />
                                                                                                                             <div className='text-white d-flex justify-content-center'>กำลังพล</div>
                                                                                                                          </div>
                                                                                                                         <div className="p-2" style={{width:'250px'}}>

                                                                                                                           <ReactSpeedometer width='210' height='130'  value={this.state.weapon}
                                                                                                                           textColor="white" maxValue={100}/>
                                                                                                                          <spen className='text-white d-flex justify-content-center '>อาวุธ/ยุทโธปกรณ์</spen>
                                                                                                                          </div>
                                                                                                                          <div className="p-2" style={{width:'250px'}}>
                                                                                                                           <ReactSpeedometer width='210' height='130'  value={this.state.vehicle}
                                                                                                                             textColor="white" maxValue={100}/>
                                                                                                                          <spen className='text-white d-flex justify-content-center '>ยานพาหนะ</spen>
                                                                                                                          </div>
                                                                                                                           <div className="p-2" style={{width:'250px'}}>
                                                                                                                           <ReactSpeedometer width='210' height='130' value={this.state.communication}
                                                                                                                             textColor="white" maxValue={100}/>
                                                                                                                          <spen className='text-white d-flex justify-content-center '>การติดต่อสื่อสาร</spen>
                                                                                                                          </div>

                                                                                                                             <div className="p-2" style={{width:'250px'}}  >
                                                                                                                           <ReactSpeedometer width='210' height='130'  value={this.state.moving_plan}
                                                                                                                              textColor="white" maxValue={100}/>
                                                                                                                          <spen className='text-white d-flex justify-content-center '>แผนการเคลื่อนย้าย</spen>
                                                                                                                          </div>

                                                                                                                             <div className="p-2" style={{width:'250px'}}>
                                                                                                                           <ReactSpeedometer width='210' height='130' value={this.state.action_plan}
                                                                                                                             textColor="white" maxValue={100}/>
                                                                                                                          <spen className='text-white d-flex justify-content-center '>แผนปฏิบัติการ</spen>
                                                                                                                          </div>

                                                                                                             </div>
                                                                                                             </Widget>
                                                                                                          </div>
                                                                                                      </div>
                                                                                         </div>



                                                                            </div>
                                                                            <div className="col-xl-6 col-lg-6 col-md-3 col-12 ">
                                                                                            <div className="m-4">
                                                                                              <div className="row">
                                                                                                 <div className="col-xl-4 col-lg-2 col-md-12 col-12 text-white">
                                                                                                   <EventAvailableIcon/>       สถานการณ์เริ่มต้น
                                                                                                 </div>
                                                                                                 <div className="col-xl-7 col-lg-2 col-md-12 col-12 bg-white text-red">
                                                                                                            {this.state.open}  {this.state.timeStart==''?'':"เวลา "+this.state.timeStart}
                                                                                                 </div>
                                                                                              </div>
                                                                                               <div className="row mt-1">
                                                                                                 <div className="col-xl-4 col-lg-2 col-md-4 col-12 text-white">
                                                                                                  <AccessAlarmIcon/>  เวลาสถานการณ์
                                                                                                 </div>
                                                                                                 <div className="col-xl-7 col-lg-2 col-md-8 col-12 bg-white text-red">
                                                                                                               {time_count==''?'':"เวลา "+time_count}
                                                                                                 </div>
                                                                                              </div>
                                                                                          </div>
                                                                                            <div style={{ position: 'absolute', zIndex: '3',right:0,display:hideMin}} className="pr-3" >
                                                                                                  <div className="bg-primary text-white p-3 m-0 ml-5 "
                                                                                                       onClick={()=>{this.setState({ hideMin: hideMin==""?"none":"",hideMassage: hideMassage==""?"none":"" })}} >
                                                                                                      <div >
                                                                                                        <DehazeIcon   />
                                                                                                      </div>
                                                                                                  </div>
                                                                                            </div>
                                                                                                 <div  className="text-white mr-3 " style={{ position: 'absolute', zIndex: '3',right:0,display:hideMassage }} >

                                                                                                            <Widget styleName="bg-primary text-white p-3" >
                                                                                                                    <div className="" >
                                                                                                                               <div style={{marginLeft:300}}
                                                                                                                                   onClick={()=>{this.setState({ hideMin: hideMin==""?"none":"",hideMassage: hideMassage==""?"none":"" })}} >
                                                                                                                                    <DehazeIcon   />
                                                                                                                              </div>

                                                                                                                                 <div><HomeWorkIcon /> : {lbl_name} </div>
                                                                                                                                    <div className="mb-1">ชั้น   : {lbl_nstorey}
                                                                                                                                         <spen className="mb-1">ความจุ  : {lbl_employ}
                                                                                                                                         </spen>
                                                                                                                                    </div>

                                                                                                                                        <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                                                                                                                                          <div >
                                                                                                                                                    <ImportantDevicesIcon /> : {bl_name}
                                                                                                                                          </div>
                                                                                                                                           <div className="mb-1">ชั้น   : {bl_nstorey}
                                                                                                                                                    <spen className="mb-1">ความจุ  : {bl_employ}
                                                                                                                                                    </spen>
                                                                                                                                           </div>
                                                                                                                                           <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                                                                                                                                                <div>
                                                                                                                                                    <MyLocationIcon /> : {bs_name}
                                                                                                                                                </div>
                                                                                                                                           <div className="mb-1">ชั้น   : {bs_nstorey}
                                                                                                                                                <spen className="mb-1">ความจุ  : {bs_employ}
                                                                                                                                                </spen>
                                                                                                                                           </div>
                                                                                                                                    </div>
                                                                                                                                  <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                                                                                                                               <div>{poi_plan_reportItems}</div>
                                                                                                            </Widget>

                                                                                                     </div>

                                                                                                    <div id='map' className="vh-100" style={{ position: 'relative', zIndex: '1' }}  />


                                                                            </div>

                                                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12  m-0 p-0 pl-3 pr-3 text-white">
                                                                                     <ReportProblemIcon/>          รายงานเหตุการณ์
                                                                                            </div>
                                                                                         </div>
                                                                                         <div className="row m-1">
                                                                                                      <div className="col-12">

                                                                                                        <Widget styleName="bg-primary text-white m-0">
                                                                                                           <div className="table-responsive-material ">

                                                                                                                     <Table className="" size="small"  style={{fontFamily: 'Kanit'}}>
                                                                                                                                                  <DataTableHead2

                                                                                                                                                    order={order}
                                                                                                                                                    orderBy={orderBy}
                                                                                                                                                    onSelectAllClick={this.handleSelectAllClick}
                                                                                                                                                    onRequestSort={this.handleRequestSort}
                                                                                                                                                    rowCount={ARdata.length}
                                                                                                                                                  />
                                                                                                                                                  <TableBody>
                                                                                                                                                    {ARdata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                                                                                                                                      const isSelected = this.isSelected(n.id);
                                                                                                                                                      return (
                                                                                                                                                        <TableRow  >
                                                                                                                                                              <TableCell style={{color:'white',fontFamily: 'Kanit'}} >
                                                                                                                                                                 {
                                                                                                                                                                    n.open_datetime==null?'':
                                                                                                                                                                    n.open_datetime.substring(8,10)+n.open_datetime.split('T')[1].split('.')[0].replaceAll(':','').substring(0,4)
                                                                                                                                                                    +'-'+n.open_datetime.substring(5,7)
                                                                                                                                                                    +'-'+n.open_datetime.substring(0,4)
                                                                                                                                                                  }

                                                                                                                                                              </TableCell>
                                                                                                                                                              <TableCell style={{color:'white',fontFamily: 'Kanit'}} >
                                                                                                                                                              {n.name}
                                                                                                                                                              </TableCell>
                                                                                                                                                              <TableCell style={{color:'white',fontFamily: 'Kanit'}} >
                                                                                                                                                                {
                                                                                                                                                                 this.state.users.filter(x => x.id == n.c_by).length != 0 ?
                                                                                                                                                                 this.state.users.filter(x => x.id == n.c_by)[0].fname :""
                                                                                                                                                                 }
                                                                                                                                                              </TableCell>
                                                                                                                                                              <TableCell style={{color:'white',fontFamily: 'Kanit'}} ></TableCell>
                                                                                                                                                            </TableRow>
                                                                                                                                                      );
                                                                                                                                                    })}
                                                                                                                                                  </TableBody>
                                                                                                                                                     <TableFooter>
                                                                                                                                                                <TableRow>
                                                                                                                                                                  <TablePagination
                                                                                                                                                                    style={{color:'white'}}
                                                                                                                                                                    count={ARdata.length}
                                                                                                                                                                    labelDisplayedRows={({ from, to, count }) =>  <div style={{ color:'white',fontFamily: 'Kanit' }}>{from}-{to} จาก {count}</div>}
                                                                                                                                                                    labelRowsPerPage=<div style={{ color:'white',fontFamily: 'Kanit' }}>รายการ</div>
                                                                                                                                                                    rowsPerPage={rowsPerPage}


                                                                                                                                                                    page={page}
                                                                                                                                                                    onChangePage={this.handleChangePage}
                                                                                                                                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                                                                                                                                  />
                                                                                                                                                                </TableRow>
                                                                                                                                                     </TableFooter>
                                                                                                                    </Table>
                                                                                                            </div>
                                                                                                         </Widget>
                                                                                                      </div>


                                                                          </div>
                                                                          <div className="row">
                                                                                             <div className="col-xl-12 col-lg-12 col-md-12 col-12  ml-3 text-white">
                                                                                      <ContactSupportIcon/>       การสั่งการ
                                                                                             </div>
                                                                                         </div>
                                                                                          <div className="row m-1">
                                                                                                      <div className="col-12">

                                                                                                        <Widget styleName="bg-primary text-white ">
                                                                                                           <div className="table-responsive-material ">

                                                                                                            <Table className="" size="small"  >
                                                                                                                                                  <DataTableHead3

                                                                                                                                                    order={order}
                                                                                                                                                    orderBy={orderBy}
                                                                                                                                                    onSelectAllClick={this.handleSelectAllClick}
                                                                                                                                                    onRequestSort={this.handleRequestSort}
                                                                                                                                                    rowCount={data.length}
                                                                                                                                                  />
                                                                                                                                                  <TableBody>
                                                                                                                                                    {AMdata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                                                                                                                                      const isSelected = this.isSelected(n.id);
                                                                                                                                                      return (
                                                                                                                                                        <TableRow hover >
                                                                                                                                                              <TableCell style={{color:'white'}} >132000 พ.ย.63</TableCell>
                                                                                                                                                              <TableCell style={{color:'white'}} >ใส่สรุปข่าวสาร....</TableCell>
                                                                                                                                                              <TableCell style={{color:'white'}} >ส่วนข่าว</TableCell>
                                                                                                                                                              <TableCell style={{color:'white'}} >-ส่วนแผน <br/>-ส่วนประสานงาน</TableCell>
                                                                                                                                                            </TableRow>
                                                                                                                                                      );
                                                                                                                                                    })}
                                                                                                                                                  </TableBody>
                                                                                                                                                     <TableFooter>
                                                                                                                                                                <TableRow>
                                                                                                                                                                  <TablePagination
                                                                                                                                                                     style={{color:'white'}}
                                                                                                                                                                    count={data.length}
                                                                                                                                                                     labelDisplayedRows={({ from, to, count }) =>  <div style={{ color:'white',fontFamily: 'Kanit' }}>{from}-{to} จาก {count}</div>}
                                                                                                                                                                    labelRowsPerPage=<div style={{ color:'white',fontFamily: 'Kanit' }}>รายการ</div>
                                                                                                                                                                    rowsPerPage={rowsPerPage}
                                                                                                                                                                    page={page}
                                                                                                                                                                    onChangePage={this.handleChangePage}
                                                                                                                                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                                                                                                                                  />
                                                                                                                                                                </TableRow>
                                                                                                                                                     </TableFooter>
                                                                                                            </Table>
                                                                                                            </div>
                                                                                                         </Widget>
                                                                                                      </div>
                                                                                         </div>
                            </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
          <Dialog fullWidth={true} maxWidth={'xs'} open={this.state.open1} onClose={this.handleRequestClose}>
                        <div style={{width:'400px',height:'800px'}}>
                            <Button onClick={()=>{this.setState({open1:false})}} className="bg-danger text-white">
                                     <CancelIcon />
                            </Button>
                            <div  className="pl-5 mr-3 mt-2 " >
                             <div className="row">
                             <div className="col-2  p-1" style={{ border: '1px solid'}}>
                                 <img src={require("assets/images/logoCTOC.png")}  width="50" height="30" alt="CTOC" title="CTOC"/>
                             </div>
                              <div className="col-10" style={{ border: '1px solid'}}>
                                 <h6>
                                 <div  className="row m-1 d-flex align-items-center justify-content-center ">ศูนย์ปฏิบัติการต่อต้านการก่เหตุการณ์ก่อการร้ายสากล</div>
                                 <div  className="row m-1 d-flex align-items-center justify-content-center ">สถานการณ์ข่าวประจำวัน</div>
                                 <div  className="row m-1 d-flex align-items-center justify-content-center ">ฉบับ 84 วันที่ 12 กุมภาพันธ์ พ.ศ.2564</div>
                                 </h6>
                             </div>
                             </div>


                            </div>
                             <div className="d-flex align-items-center justify-content-center m-3">
                                  <h6>
                                        สรุปสถานณ์ข่าวประจำวัน ในห้วงเวลา
                                        {
                                            this.state.Period==""?'':
                                            this.state.Period.substring(8,10)+this.state.Period.split('T')[1].split('.')[0].replaceAll(':','').substring(0,4)+' '
                                        }
                                        { this.state.Period.substring(5,7)=="01"?"ม.ค.":""}
                                        { this.state.Period.substring(5,7)=="02"?"ก.พ.":""}
                                        { this.state.Period.substring(5,7)=="03"?"มี.ค.":""}
                                        { this.state.Period.substring(5,7)=="04"?"เม.ย.":""}
                                        { this.state.Period.substring(5,7)=="05"?"พ.ค.":""}
                                        { this.state.Period.substring(5,7)=="06"?"มิ.ย.":""}
                                        { this.state.Period.substring(5,7)=="07"?"ก.ค.":""}
                                        { this.state.Period.substring(5,7)=="08"?"ส.ค.":""}
                                        { this.state.Period.substring(5,7)=="09"?"ก.ย.":""}
                                        { this.state.Period.substring(5,7)=="10"?"ต.ค.":""}
                                        { this.state.Period.substring(5,7)=="11"?"พ.ย.":""}
                                        { this.state.Period.substring(5,7)=="12"?"ธ.ค.":""}
                                        {
                                            " "+String((parseInt(this.state.Period.substring(0,4))+543)).substring(2,4)
                                        }
                                          ดังนี้
                                  </h6>
                             </div>

                                     <div className="ml-3">
                                             <h6>
                                                <b>
                                                    1.เหตุการณ์ที่อยู่ในความสนใจ
                                                </b>
                                             </h6>
                                     </div>
                                     <div className="ml-3" >
                                          <h6>
                                            {this.state.news_group1==""?"ไม่ปรากฎเหตุการณืที่สำคัญ":this.state.news_group1}
                                          </h6>
                                     </div>
                                     <div className="ml-3">
                                            <h6>
                                                <b>
                                                    2.เหตุการ์ก่อความไม่สงบภานในประเทศ
                                                </b>
                                             </h6>

                                     </div>
                                     <div className="ml-3">
                                          <h6>
                                             {this.state.news_group2==""?"ไม่ปรากฎเหตุการณืที่สำคัญ":this.state.news_group2}
                                           </h6>
                                     </div>
                                      <div className="ml-3">
                                            <h6>
                                                <b>
                                                    3.เหตุการณ์ก่อการร้ายสากล
                                                </b>
                                             </h6>

                                      </div>
                                      <div className="ml-3">
                                         <h6>
                                         {this.state.news_group3==""?"ไม่ปรากฎเหตุการณืที่สำคัญ":this.state.news_group3}
                                         </h6>
                                      </div>
                                      <div className="ml-3">
                                             <h6>
                                                <b>
                                                    4.อื่นๆ
                                                </b>
                                             </h6>
                                      </div>
                                      <div className="ml-3">
                                        <h6>
                                          {this.state.news_group4==""?"ไม่ปรากฎเหตุการณืที่สำคัญ":this.state.news_group4}
                                        </h6>
                                      </div>
                                       <div
                                           style={{
                                                 position: 'absolute',
                                                 bottom:100,
                                                 right:30
                                           }}
                                           >
                                            <h6>
                                                {
                                                    this.state.users.filter(x => x.id == this.state.name_job).length != 0 ?
                                                    this.state.users.filter(x => x.id == this.state.name_job)[0].fname :""
                                                }
                                            </h6>

                                        </div>
                                         <div
                                           style={{
                                                 position: 'absolute',
                                                 bottom:80,
                                                 right:20
                                           }}
                                           >
                                            <h6>
                                              (
                                                {
                                                    this.state.users.filter(x => x.id == this.state.name_job).length != 0 ?
                                                    this.state.users.filter(x => x.id == this.state.name_job)[0].fname :""
                                                }
                                              )
                                            </h6>

                                        </div>
                                        <div
                                           style={{
                                                 position: 'absolute',
                                                 bottom:60,
                                                 right:20
                                           }}
                                           >
                                            <h6>
                                                น.เวร ห้องติดตามสถานการณ์
                                            </h6>
                                        </div>
                                      <div
                                        style={{
                                                 position: 'absolute',
                                                 bottom:50,

                                           }}
                                        >
                                       ------------------------------------------------------------------
                                      </div>
                                       <div
                                        style={{
                                                 position: 'absolute',
                                                 bottom:30,
                                                 left:5

                                           }}
                                        >
                                       <h6> ห้องติเวรติดตามสถานการณ์ (JOS) </h6>
                                      </div>
                                      <div
                                        style={{
                                                 position: 'absolute',
                                                 bottom:10,
                                                 left:5

                                           }}
                                        >
                                       <h6> โทร 0 2565 8045 </h6>
                                      </div>


                        </div>
          </Dialog>

          <Dialog fullWidth={true}
                        maxWidth={'xs'} open={false} onClose={this.handleRequestClose}>
                        <DialogContent className="bg-geekblue text-white"  >
                           <div style={{ justifyContent: "center",alignItems: "center",display: "flex"}}>
                              <div style={{ width:'50%',justifyContent: "center",alignItems: "center",display: "flex"}}>
                                    <Bar

                                                data={this.state.graph}
                                                height={300}
                                                width={100}
                                                options={

                                                {
                                                 maintainAspectRatio: false,
                                                 legend: {
                                                         labels: {
                                                              fontColor: 'orange'
                                                             }
                                                          },
                                                    title:{
                                                      display:true,
                                                      fontColor: '#fff',
                                                      text:'ด้านกำลังพล',
                                                      fontSize:20
                                                    },
                                                    legend:{
                                                      display:false,
                                                      position:'right'
                                                    },
                                                 scales: {
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero:true,
                                                            fontColor: '#fff'
                                                        },
                                                    }],
                                                 xAxes: [{
                                                        ticks: {
                                                            fontColor: '#fff'
                                                        },
                                                    }]
                                                    }
                                                }}
                                    />
                              </div>
                           </div>
          </DialogContent>

          </Dialog>
          </div>
       </div>
    );
  }
}

export default index;