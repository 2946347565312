import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';


const Form = ({match}) => (
  <div className="">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/actionMap`}/>
      <Route path={`${match.url}/n_book_army_form`} component={asyncComponent(() => import('./routes/n_book_army_form/index'))}/>
      <Route path={`${match.url}/n_book_army_index`} component={asyncComponent(() => import('./routes/n_book_army_index/index'))}/>
       <Route path={`${match.url}/n_d_army_list_form`} component={asyncComponent(() => import('./routes/n_d_army_list_form/index'))}/>
      <Route path={`${match.url}/n_d_army_list_index`} component={asyncComponent(() => import('./routes/n_d_army_list_index/index'))}/>
      <Route path={`${match.url}/n_f_army_list_index`} component={asyncComponent(() => import('./routes/n_f_army_list_index/index'))}/>
      <Route path={`${match.url}/n_f_army_list_form`} component={asyncComponent(() => import('./routes/n_f_army_list_form/index'))}/>
      <Route path={`${match.url}/n_s_army_form`} component={asyncComponent(() => import('./routes/n_s_army_form/index'))}/>
      <Route path={`${match.url}/n_s_army_index`} component={asyncComponent(() => import('./routes/n_s_army_index/index'))}/>
	  <Route path={`${match.url}/fcarry_army`} component={asyncComponent(() => import('./routes/carry_army/form'))}/>
	  <Route path={`${match.url}/carry_army`} component={asyncComponent(() => import('./routes/carry_army/index'))}/>
	  <Route path={`${match.url}/fsend_army`} component={asyncComponent(() => import('./routes/send_army/form'))}/>
	  <Route path={`${match.url}/send_army`} component={asyncComponent(() => import('./routes/send_army/index'))}/>
	  <Route path={`${match.url}/fservice_army`} component={asyncComponent(() => import('./routes/service_army/form'))}/>
	  <Route path={`${match.url}/service_army`} component={asyncComponent(() => import('./routes/service_army/index'))}/>
	  <Route path={`${match.url}/f_army_list`} component={asyncComponent(() => import('./routes/f_army_list/index'))}/>
	  <Route path={`${match.url}/d_army_list`} component={asyncComponent(() => import('./routes/d_army_list/index'))}/>
	  <Route path={`${match.url}/fd_army_list`} component={asyncComponent(() => import('./routes/d_army_list/form'))}/>
	  <Route path={`${match.url}/s_army_list`} component={asyncComponent(() => import('./routes/s_army_list/index'))}/>
	  <Route path={`${match.url}/s_army`} component={asyncComponent(() => import('./routes/s_army/index'))}/>
	  <Route path={`${match.url}/s_army_sub`} component={asyncComponent(() => import('./routes/s_army_sub/index'))}/>
	  <Route path={`${match.url}/fbook_army`} component={asyncComponent(() => import('./routes/book_army/form'))}/>
      <Route path={`${match.url}/book_army`} component={asyncComponent(() => import('./routes/book_army/index'))}/>
      <Route path={`${match.url}/s_main`} component={asyncComponent(() => import('./routes/s_main/main'))}/>
      <Route path={`${match.url}/actionMap`} component={asyncComponent(() => import('./routes/actionMap/index'))}/>
      <Route path={`${match.url}/updatemap`} component={asyncComponent(() => import('./routes/updatemap/index'))}/>
      <Route path={`${match.url}/r_map`} component={asyncComponent(() => import('./routes/reportmap/index'))}/>
	   <Route path={`${match.url}/rp`} component={asyncComponent(() => import('./routes/report/index'))}/>
      <Route path={`${match.url}/actionConfig`} component={asyncComponent(() => import('./routes/actionConfig/index'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Form;
