import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';


const Form = ({match}) => (
  <div className="">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/index`}/>
      <Route path={`${match.url}/index`} component={asyncComponent(() => import('./routes/guard'))}/>
      <Route path={`${match.url}/list_guard`} component={asyncComponent(() => import('./routes/list_guard/list_guard'))}/>

    </Switch>
  </div>
);

export default Form;
