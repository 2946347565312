import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER,
  MINI_DRAWER,
  VERTICAL_NAVIGATION
} from 'constants/ActionTypes'
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {changeNavigationStyle, setDrawerType, setHorizontalMenuPosition} from 'actions/index';

import {Button, ButtonGroup} from 'reactstrap';


class Customizer extends React.Component {
   ma = () => {
  this.handleViewportChange({
                  width: "100%",
                  height: "450px"
                })
                }
  setFixedDrawer = () => {
    this.props.setDrawerType(FIXED_DRAWER);
  };
  setCollapsedDrawer = () => {
    this.props.setDrawerType(COLLAPSED_DRAWER);
  };
  setMiniDrawer = () => {
    this.props.setDrawerType(MINI_DRAWER);
  };

  render() {
    const {drawerType, navigationStyle, horizontalNavPosition} = this.props;

    return (
      <div className="side-nav-option">


        {navigationStyle === HORIZONTAL_NAVIGATION ? <ButtonGroup>
            <Button color="primary"
                    className={`jr-btn  ${horizontalNavPosition === INSIDE_THE_HEADER && 'active' } `}
                    onClick={() => {
                      this.props.setHorizontalMenuPosition(INSIDE_THE_HEADER)
                    }}>Inside</Button>
            <Button color="primary"
                    className={`jr-btn ${horizontalNavPosition === ABOVE_THE_HEADER && 'active'} `}
                    onClick={() => {
                      this.props.setHorizontalMenuPosition(ABOVE_THE_HEADER)
                    }}>Top</Button>
            <Button color="primary"
                    className={`jr-btn ${horizontalNavPosition === BELOW_THE_HEADER && 'active' } `}
                    onClick={() => {
                      this.props.setHorizontalMenuPosition(BELOW_THE_HEADER)
                    }}>Below</Button>
          </ButtonGroup>
          :
          <ButtonGroup>
            <Button color="primary"
                    className='jr-btn  active  btn btn-primary'
                    onClick={this.setFixedDrawer.bind(this) }><LockIcon/> </Button>
            <Button color="primary"
                    className='jr-btn  active  btn btn-primary'
                    onClick={this.setCollapsedDrawer.bind(this)}><LockOpenIcon/> </Button>

          </ButtonGroup>
        }


      </div>);
  }
}

const mapStateToProps = ({settings}) => {
  const {drawerType, navigationStyle, horizontalNavPosition} = settings;
  return {drawerType, navigationStyle, horizontalNavPosition}
};

export default withRouter(connect(mapStateToProps, {
  changeNavigationStyle,
  setDrawerType,
  setHorizontalMenuPosition
})(Customizer));

