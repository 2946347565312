import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter,useHistory } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import keycode from 'keycode';
import MapIcon from '@material-ui/icons/Map';
import Table from '@material-ui/core/Table';
import Avatar from '@material-ui/core/Avatar';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import axios from "axios";
import ProfileHeader from "./ProfileHeader/index";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GroupIcon from '@material-ui/icons/Group';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import GetAppIcon from '@material-ui/icons/GetApp';
import CallIcon from '@material-ui/icons/Call';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { TextField, InputAdornment } from "@material-ui/core";
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import ContainerHeader from 'components/ContainerHeader/index';
import PoolIcon from '@material-ui/icons/Pool';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import WorkIcon from '@material-ui/icons/Work';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
let counter = 0;


function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}


const columnData = [

    {id: '1', align: true, disablePadding: false, label: 'ชื่อ/สถานที่'},

    {id: '2', align: true, disablePadding: false, label: 'ประเภท'},
    { id: '3', align: true, disablePadding: false, label: 'รูปแบบ'},
    { id: '4', align: true, disablePadding: false, label: 'สถานะ'},
    { id: '5', align: true, disablePadding: false, label: 'Action'}


];



class DataTableHead extends React.Component {



  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };


  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead   className="bg-primary" >
        <TableRow>
          <TableCell padding="checkbox">
          </TableCell>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id} className="bg-primary"
                style={{color:'#fff'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );


  }
}


let DataTableToolbar = props => {

  const {numSelected} = props;

  return (

    <div className="table-header bg-geekblue border border-primary">
      <div className="title">

        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
              ) : (
                      <div className="" style={{ padding: '0' }}>
                                     <form action="" className="app-wrapper">
                                                <div className="row">
                                                  <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                      <label form="firstName" style={{color:'white'}}>สถานะภารกิจ</label>

<div className="bg-primary">
                        <TextField
                    select
                    required
                    id="user_no"
                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                            }
                        }}
                      style={{margin:0}}
                         InputProps={{
                          startAdornment: (
                            <InputAdornment >
                              <WorkIcon position="start" />
                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                 , color:"#fff"
                            }
                        }}

                >


                 <MenuItem value={1}>ทั้งหมด</MenuItem>
                 <MenuItem value={2}>เสร็จสิ้น</MenuItem>
                 <MenuItem value={3}>ดำเนินการ</MenuItem>
                 <MenuItem value={4}>ยังไม่ได้ดำเนินการ</MenuItem>
                 <MenuItem value={6}>สือออนไลน์</MenuItem>
                  <MenuItem value={6}>รูปแแบบอื่นๆ</MenuItem>
                </TextField>
  </div>


                                                    </div>
                                                  </div>

                                                  <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                      <label htmlFor="lastName" style={{color:'white'}}>ประเภทการก่อการร้าย</label>

  <div className="bg-primary" >
              <TextField
                    select
                    required
                    id="user_no"
                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px",
                                color:"#fff"
                            }
                        }}
                      style={{margin:0}}
                         InputProps={{
                          startAdornment: (
                            <InputAdornment >
                              <NewReleasesIcon position="start" />
                            </InputAdornment>
                          ),
                          style: {
                                height: "40px",
                                  color:"#fff"
                            }
                        }}
                >


                 <MenuItem value={1}>ชาตินิยม</MenuItem>
                 <MenuItem value={2}>ชาตินิยม</MenuItem>
                 <MenuItem value={3}>สนับสนุนจากรัฐ</MenuItem>
                 <MenuItem value={4}>ฝ่ายขาวจิด</MenuItem>
                    <MenuItem value={5}>อนาธิปไตย</MenuItem>
                       <MenuItem value={6}>ประเภทอื่นๆ</MenuItem>

                </TextField>
                </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="row">
                                                  <div className="col-md-6 col-12">
                                                    <div className="form-group">
                 <label htmlFor="email" style={{color:'white'}}>รูปแบบการก่อการร้าย</label>
                   <div className="bg-primary">
                <TextField
                    select
                    required
                    id="user_no"
                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px",
                                 color:"#fff"
                            }
                        }}
                      style={{margin:0}}
                         InputProps={{
                          startAdornment: (
                            <InputAdornment >
                              <NewReleasesIcon position="start" />
                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                , color:"#fff"
                            }
                        }}

                >
                 <MenuItem value={1}>นักรบกลุ่มใหญ่</MenuItem>
                 <MenuItem value={2}>นักรบขนาดเล็ก</MenuItem>
                 <MenuItem value={3}>นักรบขนาดเล็ก</MenuItem>
                 <MenuItem value={4}>กลุ่มเหตุคนเดียว</MenuItem>
                 <MenuItem value={5}>วินาศกรรม</MenuItem>
                 <MenuItem value={6}>ลักพาตัวประกัน</MenuItem>
                 <MenuItem value={6}>สือออนไลน์</MenuItem>
                 <MenuItem value={6}>รูปแแบบอื่นๆ</MenuItem>
                         </TextField>
                </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="row mt-2">
                                                  <div className="col-12">
                                                    <div className="form-group mb-0">
                                     <Button  variant="contained" className="bg-blue text-white  float-left"
                                            type="button" >
                                            ค้นหา
                                          </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {

  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
      super(props, context);
      this.state = {
      Employees: [],
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: [].sort((a, b) => (a.calories < b.calories ? -1 : 1)),
      page: 0,
      rowsPerPage: 5,
      id:''

      };
      fetch('http://1.10.184.64:3000/actionplan/report.php')
          .then(response => response.json())
          .then(data => {

              data.sort((a, b) => (a.id < b.id ? 1 : -1))
              this.setState({ data: data, loading: false });
          });



  }
  deleterow = (event, id) => {
  console.log(id);
   this.setState({open: true,id:id})

   };

  deleterow2 = () => {

   // event.preventDefault();
    this.setState({ data: []});
    axios.get('http://1.10.184.64:3000/actionplan/delete.php?id='+this.state.id)
       .then((result) => {

            fetch('http://1.10.184.64:3000/actionplan/report.php')
          .then(response => response.json())
          .then(data => {
           data.sort((a, b) => (a.id < b.id ? 1 : -1))
              this.setState({ data: data});
          });
       })
       .catch((err) => {
        console.log(err)
     })

        //this.setState({ data: null});
         this.setState({open: false});
     };
  render() {
    const {data, order, orderBy, selected, rowsPerPage, page} = this.state;

    return (
     <div className="p-2 pt-3" >
    <ProfileHeader/>
     <div className="jr-profile-content ">

        <DataTableToolbar numSelected={selected.length}/>
        <div className="flex-auto mb-2">
          <div className="table-responsive-material bg-geekblue border border-primary">
            <Table className="" size="small" >
              <DataTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      onKeyDown={event => this.handleKeyDown(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                        <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            <div className="user-profile d-flex flex-row align-items-center">
                                    <Avatar
                                       alt="http://via.placeholder.com/150x150"
                                              src="http://via.placeholder.com/150x150"
                                      className="user-avatar"
                                    />
                                    <div className="user-detail" align="left" style={{color:'white'}}>
                                      {n.name}
                                    </div>
                            </div>
                          </TableCell>



                           <TableCell align="left" className="ml-10" align="left" style={{color:'white'}}>

                             {n.p_type=="0"?"":""}
                             {n.p_type=="1"?"ชาตินิยม":""}
                             {n.p_type=="2"?"ทางศาสนา":""}
                             {n.p_type=="3"?"สนับสนุนจากรัฐ":""}
                             {n.p_type=="4"?"ฝ่ายซ้ายจัด":""}
                             {n.p_type=="5"?"ฝ่ายขวาจัด":""}
                             {n.p_type=="6"?"อนาธิปไตย":""}
                             {n.p_type=="7"?"การก่อการร้ายอื่น ๆ":""}
                           </TableCell>
                           <TableCell align="left " align="left" style={{color:'white'}}>
                            {n.p_form=="0"?"":""}
                            {n.p_form=="1"?"กำลังนักรบกลุ่มใหญ่":""}
                            {n.p_form=="2"?"กำลังนักรบขนาดเล็ก":""}
                            {n.p_form=="3"?"ก่อเหตุเพียงคนเดียว":""}
                            {n.p_form=="4"?"วินาศกรรมวางระเบิด":""}
                            {n.p_form=="5"?"ลักพาตัวประกัน":""}
                           </TableCell>
                            <TableCell align="left " align="left" style={{color:'white'}}>
                            {n.plan_status=="1"?"รายงาน":"แผนงาน"}
                            </TableCell>
                           <TableCell align="center">
                            <div className="user-profile d-flex flex-row align-items-center">
                                     <div className="user-detail ml-2" >
                                        <NavLink
                                              to={{
                                            pathname:'/app/reportmap',
                                            search:'?id='+n.id
                                            }}
                                                 style={{color:"red"}}  >
                                            < VolumeUpIcon />
                                        </NavLink>

                                     </div>
                                     <div className="user-detail ml-2" >
                                        <NavLink
                                              to={{
                                            pathname:'/app/conclude/Crypto',
                                            search:'?id='+n.id
                                            }}
                                                 style={{color:"#ffc107"}}  >
                                            <MapIcon />
                                        </NavLink>
                                     </div>

                            </div>
                          </TableCell>

                            <div>


              </div>
        </TableRow>
                  );
                })}
              </TableBody>
                          <Dialog open={this.state.open} onClose={this.handleRequestClose}
                          fullWidth={true}
                          maxWidth = {'sm'}
                          >
                          <DialogTitle>
                            {"ยืนยันการลบ"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              ยืนยันดำเนินการ
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button  className="bg-primary text-white"

                            onClick={event => this.deleterow2()}
                            >
                              ตกลง
                          </Button>

                            <Button  className="bg-primary text-white" onClick={() => this.setState({open: false})}  color="secondary">
                              ยกเลิก
                            </Button>
                          </DialogActions>
                        </Dialog>
              <TableFooter>
                <TableRow>
                  <TablePagination  style={{color:'white'}}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                      labelDisplayedRows={({ from, to, count }) => ` ${from}-${to} จาก ${count}`}
                                                                         labelRowsPerPage='รายการ'
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>

       </div>
      </div>
    );
  }
}

export default index;