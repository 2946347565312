import React from 'react';
import axios from "axios";
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'util/IntlMessages';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';

import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import About from "components/profile/About/index";
import Biography from "components/profile/Biography/index";
import Events from "components/profile/Events/index";
import Contact from "components/profile/Contact/index";
import { NavLink, withRouter,useHistory } from 'react-router-dom';
import Friends from "components/profile/Friends/index";
import Photos from "components/profile/Photos/index";
import Auxiliary from "../../../util/Auxiliary";

import ProfileHeader from "./ProfileHeader/index2";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GroupIcon from '@material-ui/icons/Group';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import GetAppIcon from '@material-ui/icons/GetApp';
import CallIcon from '@material-ui/icons/Call';
import AccountCircle from '@material-ui/icons/AccountCircle';

const SelectValue = [
      {
        value: '1',
        label: 'Select1',
      },
      {
        value: '2',
        label: 'Select2',
      }

    ];




class index extends React.Component {



  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };



     _user_pw = event => {
        this.setState({
          user_pw: event.target.value
          });
      };

      _fname = event => {
        this.setState({
          fname: event.target.value
          });
      };

       _lname = event => {
        this.setState({
          lname: event.target.value
          });
      };

      _user_no = event => {
        console.log(event.target.value)
        this.setState({
          user_no: event.target.value
          });
      };



       _postition = event => {
        this.setState({
          postition: event.target.value
          });
      };

         _dept = event => {
        this.setState({
          dept: event.target.value
          });
      };

       _mobile = event => {
        this.setState({
          mobile: event.target.value
          });
      };

         _email = event => {
        this.setState({
          email: event.target.value
          });
      };

       _status = event => {
        this.setState({
          status: event.target.value
          });
      };

         _img = event => {
        this.setState({
          img: event.target.value
          });
      };

       _user_id = event => {
        this.setState({
          user_id: event.target.value
          });
      };

      _user_type = event =>{
             this.setState({
            user_type: event.target.value
        });
    }

     state = {
          open: false,
        open2: false,


          };

constructor(props, context) {
      super(props, context);
      this.state = {
         dunit:[],
         dept:0,
        vertical: 'top',
        horizontal: 'center',
        listdept:[]


      };

    var location = window.location.href;
    const str = '' + location;
    const xid = str.split("=")[1];

         if (xid != null) {

         fetch('http://1.10.184.64:3000/users/select.php?id='+xid)
            .then(response => response.json())
            .then(data => {

              this.setState({

                id: data.id,
                user_id: data.user_id,
                user_pw: data.user_pw,
                fname: data.fname,
                lname: data.lname,
                user_no: data.user_no,
                user_type: data.user_type,
                postition: data.postition,
                dept: data.dept,

                mobile: data.mobile,
                email: data.email,
                status: data.status,
                img: data.img,
                mdunit:[]

              });
            });
         };

                 fetch('http://1.10.184.64:3000/users/dunit.php')
                                     .then(response => response.json())
                                     .then(data => {
                                     data.push({id:0,name:'เลือกรายการ'})
                                           this.setState({mdunit:data});
                                     });

                 fetch('http://1.10.184.64:3000/users/dept.php')
                                     .then(response => response.json())
                                     .then(data => {
                                     data.push({id:0,name:'เลือกรายการ'})
                                           this.setState({listdept:data});
                                     });

  }

 handleRequestClose = ()=>{
   this.setState({open2: false});
    this.setState({open: false});
 }
  handleSubmit = event => {

    event.preventDefault();
    const requestBody = {
      id : this.state.id,
      user_pw: this.state.user_pw,
      fname: this.state.fname,
      lname: this.state.lname,
      user_no: this.state.user_no,
      user_type: this.state.user_type,
      postition: this.state.postition,
      dept: this.state.dept,
      mobile: this.state.mobile,
      email: this.state.email,
      status: this.state.status,
      user_id: this.state.user_id,
      img: this.state.img
      }
      if(this.state.id==null)
      {
      const config = {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  }
          }
       const qs = require('querystring')
        axios.post('http://1.10.184.64:3000/users/insert.php',qs.stringify(requestBody), config)
        .then((result) => {
          // Do somthing
          const name = ""+qs.stringify(result);
          const splitName = name.split("failed");
          const length = splitName.length;
          console.log(length);
        if(length==1){
        this.setState({open2: false});
         this.setState({open: true});
        }
        if(length!=1){
          this.setState({open: false});
         this.setState({open2: true});
        }



        })
        .catch((err) => {
         this.setState({open: false});
         this.setState({open2: true});
          console.log(err)
        })
        }


       if(this.state.id>=0 )
      {
      console.log(this.state.id+"xxx");
        const config = {
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                          }
                  }
               const qs = require('querystring')
                axios.post('http://1.10.184.64:3000/users/update.php',qs.stringify(requestBody), config)
                .then((result) => {
                  // Do somthing
                  console.log(result)
                        this.setState({open: true});
                })
                .catch((err) => {
                        this.setState({open2: true});
                  // Do somthing
                  console.log(err)
                })
      }

    }


   select_dept = (event)=>
   {

               var mdunit = this.state.mdunit.filter(x=>x.p_id==event.target.value);
                console.log(event.target.value+"select_dept"+JSON.stringify(this.state.mdunit))
               this.setState({dunit :mdunit})
   }
  render() {

                let dunits = this.state.dunit;
                    let dunitsItems = dunits.map((dunit) =>
                       <MenuItem style={{fontFamily: 'Kanit'}}  selected="{this.state.dunit}"    key={dunit.id} value={dunit.id}>
                       <span className="ml-2"> {dunit.name} </span></MenuItem>
                );

                let __depts = this.state.listdept;
                    let _deptItems = __depts.map((dept) =>
                       <MenuItem   style={{fontFamily: 'Kanit'}}   key={dept.id} value={dept.id}>
                       <span className="ml-2"> {dept.name} </span></MenuItem>
                );

    return (
     <div>
     <div class="page-heading d-sm-flex justify-content-sm-between align-items-sm mb-0 p-2  bg-geekblue ">
          <h5 class="title p-1 mb-sm-0"></h5>
              <nav class="mb-0" aria-label="breadcrumb">
                      <ol class="breadcrumb">
                                    <div class="breadcrumb-item">
                                        <div   style={{color: 'gray', textDecoration: 'none'}}  >
                                         การจัดการระบบ
                                        </div>
                                         <div className="pl-1 pr-1"> > </div>
                                         <NavLink   style={{color: 'primary', textDecoration: 'none'}} to={{ pathname:'/app/tableusers/data' }} >
                                          ผู้ใช้งาน
                                        </NavLink>
                                        <div className="pl-1 pr-1"> > </div>
                                        <div   style={{color: 'white', textDecoration: 'none'}}  >
                                         {this.state.id!=null?"แก้ไข":"เพิ่ม"}
                                        </div>
                                     </div>
                      </ol>
              </nav>
     </div>
    <div className="app-wrapper  ">
       <Auxiliary>
        <ProfileHeader />
        <div className="jr-profile-content ">
          <div className="row">
          <div className="jr-card jr-card-widget jr-card-full jr-card-tabs-right jr-card-profile card bg-geekblue border border-primary" >
           <div className="jr-tabs-content jr-task-list">
           <form className="row" noValidate autoComplete="off" onSubmit={this.handleSubmit}>
           <div className="col-md-3 col-12 ">
             <label className="mb-0 mt-3" style={{color:"white"}}>ชื่อ</label>
             <div className="bg-primary">
                <TextField
                    style={{color:"white"}}
                    required
                    id="fname"
                    onChange={this._fname}
                    value={this.state.fname}
                    inputClass=""
                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {

                                height: "40px"

                            }
                        }}
                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment >
                              <AssignmentIndIcon height="40px" />
                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                 , color:"#fff"
                            }
                        }}
                />
                </div>
            </div>
            <div className="col-md-3 col-12">
             <label className="mb-0 mt-3" style={{color:"white"}}>นามสกุล</label>
             <div className="bg-primary">
                <TextField

                    required
                    onChange={this._lname}
                      value={this.state.lname}
                    id="lname"

                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                            }
                        }}
                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                              <AssignmentIndIcon height="40px" />
                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                 , color:"#fff"
                            }
                        }}

                />
                 </div>
            </div>
            <div className="col-md-6 col-12">
                <label className="mb-1 mt-3"  style={{color:"white"}}>ผู้ใช้งาน</label>
                  <div className="bg-primary">
                <TextField
                    required
                    onChange={this._user_id}
                    value={this.state.user_id}

                    margin="normal"
                    fullWidth
                     variant="outlined"

                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                              <AccountCircle height="40px" />
                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                 , color:"#fff"
                            }
                        }}
                />
            </div>
             </div>
            <div className="col-md-6 col-12">
              <label className="mb-0 mt-3"  style={{color:"white"}}>รหัสประจำตัว</label>
                <div className="bg-primary">
                <TextField

                    required
                    id="user_no"
                    onChange={this._user_no}
                        value={this.state.user_no}

                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                            }
                        }}
                      style={{margin:0}}

                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className="mr-1" >
                              <CreditCardIcon height="40px" />
                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                 , color:"#fff"
                            }
                        }}
                />

            </div>
  </div>


              <div className="col-md-6 col-12">

              <label className="mb-0 mt-3"  style={{color:"white"}}>รหัสผ่าน</label>
  <div className="bg-primary">

                <TextField

                    required
                    id="user_pw"
                    type="password"
                    onChange={this._user_pw}
                    value={this.state.user_pw+''.trim()}

                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"

                      style={{margin:0}}

                         InputProps={{
                          startAdornment: (
                            <InputAdornment className="mr-1">
                              <Visibility position="end" />
                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                 , color:"#fff"
                                 ,fontFamily: 'Kanit'
                            }
                        }}
                />
    </div>
            </div>
            <div className="col-md-6 col-12">
             <label className="mb-0 mt-3"  style={{color:"white"}}>ประเภทผู้ใช้งาน </label>
            <div className="bg-primary">
                    <TextField
                    select
                    required
                    id="user_no"
                    onChange={this._user_type}
                    value= {this.state.user_type=="1"?"1":
                            this.state.user_type=="2"?"2":
                             this.state.user_type=="3"?"3":
                             this.state.user_type=="4"?"4":
                             this.state.user_type=="5"?"5":""}


                    margin="normal"
                    fullWidth
                    variant="outlined"

                      style={{margin:0}}
                         InputProps={{
                          startAdornment: (
                            <InputAdornment className="mr-2">
                              <AccessibilityIcon position="start" />
                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                 , color:"#fff"
                                    ,fontFamily: 'Kanit'
                            }
                        }}

                >

                 <MenuItem style={{fontFamily: 'Kanit'}} value={0}> --เลือก-- </MenuItem>
                 <MenuItem style={{fontFamily: 'Kanit'}} value={1}>ผู้จัดการระบบ</MenuItem>
                 <MenuItem style={{fontFamily: 'Kanit'}} value={2}>ผู้บริหาร/หัวหน้า</MenuItem>
                 <MenuItem style={{fontFamily: 'Kanit'}} value={3}>ส่วนงานข้อมูล</MenuItem>
                 <MenuItem style={{fontFamily: 'Kanit'}} value={4}>จัดการแผนที่</MenuItem>
                </TextField>
            </div>
     </div>

              <div className="col-md-6 col-12">
                      <label className="mb-0 mt-3"  style={{color:"white"}}>ยืนยันรหัสผ่าน</label>
                        <div className="bg-primary">
                                    <TextField

                                       type="password"
                                        required
                                        id="user_pw"

                                        password
                                        defaultValue=""
                                        margin="normal"
                                        fullWidth
                                        variant="outlined"

                                          style={{margin:0}}

                                                    InputProps={{
                                          startAdornment: (
                                            <InputAdornment className="mr-1" >
                                              <Visibility position="end" />
                                            </InputAdornment>
                                          ),
                                          style: {
                                                height: "40px"
                                                 , color:"#fff"
                                                ,fontFamily: 'Kanit'
                                            }
                                        }}
                                    />
            </div>
              </div>
            <div className="col-md-6 col-12">
             <label className="mb-0 mt-3"  style={{color:"white"}}>ส่วนงาน</label>
               <div className="bg-primary">

                                             <TextField
                                                select
                                                required
                                                onChange={this.select_dept}
                                                defaultValue=""
                                                margin="normal"
                                                fullWidth
                                                variant="outlined"

                                                  style={{margin:0}}

                                                   InputProps={{
                                                                      startAdornment: (
                                                                        <InputAdornment >
                                                                          <SupervisedUserCircleIcon />
                                                                        </InputAdornment>
                                                                      ),
                                                                      style: {
                                                                            height: "40px"
                                                                             , color:"#fff"
                                                                             ,fontFamily: 'Kanit'
                                                                        }
                                                                    }}
                                            >
                                             {_deptItems}
                                            </TextField>
               </div>
            </div>

                <div className="col-md-6 col-12">
                     <label className="mb-0 mt-3"  style={{color:"white"}}>หน่วยงาน</label>
               <div className="bg-primary">
                                             <TextField
                                                select
                                                required
                                               id="dept"
                                                   value={this.state.dept}
                                                      onChange={this._dept}

                                                defaultValue=""
                                                margin="normal"
                                                fullWidth
                                                variant="outlined"

                                                  style={{margin:0}}

                                                   InputProps={{
                                                                      startAdornment: (
                                                                        <InputAdornment >
                                                                          <SupervisedUserCircleIcon />
                                                                        </InputAdornment>
                                                                      ),
                                                                      style: {
                                                                            height: "40px"
                                                                             , color:"#fff"
                                                                             ,fontFamily: 'Kanit'
                                                                        }
                                                                    }}
                                            >
                                             {dunitsItems}
                                            </TextField>
               </div>


  </div>




            <div className="col-md-6 col-12">

                       <label className="mb-0 mt-3"  style={{color:"white"}}>อีเมล์</label>
                         <div className="bg-primary">
                                    <TextField

                                        required
                                        id="email"
                                         value={this.state.email}
                                         onChange={this._email}

                                        defaultValue=""
                                        margin="normal"
                                        fullWidth
                                        variant="outlined"

                                          style={{margin:0}}

                                                                        InputProps={{
                                                              startAdornment: (
                                                                <InputAdornment className="mr-1">
                                                                  <AlternateEmailIcon />
                                                                </InputAdornment>
                                                              ),
                                                              style: {
                                                                    height: "40px"
                                                                     , color:"#fff"
                                                                     ,fontFamily: 'Kanit'

                                                                }
                                                            }}


                                    />
                                </div>

               </div>
              <div className="col-md-6 col-12">
               <label className="mb-0 mt-3"  style={{color:"white"}}>ตำแหน่งหน้าที่</label>
               <div className="bg-primary">
                <TextField
                    required

                    id="postition"
                      value={this.state.postition}
                      onChange={this._postition}

                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"

                      style={{margin:0}}

                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment >
                                              <GetAppIcon />
                                            </InputAdornment>
                                          ),
                                          style: {
                                                height: "40px"
                                                 , color:"#fff"
                                                 ,fontFamily: 'Kanit'
                                            }
                                        }}
                />
            </div>
            </div>

              <div className="col-md-6 col-12">

<label className="mb-0 mt-3"  style={{color:"white"}}>เบอร์โทรศัพท์มือถือ</label>
  <div className="bg-primary">
                <TextField
                    required

                    id="mobile"
                     value={this.state.mobile}
                    onChange={this._mobile}

                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"

                      style={{margin:0}}
                              InputProps={{
                                          startAdornment: (
                                            <InputAdornment className="mr-1">
                                              <CallIcon />
                                            </InputAdornment>
                                          ),
                                          style: {
                                                height: "40px"
                                                 , color:"#fff"
                                                 ,fontFamily: 'Kanit'
                                            }
                                        }}
                />
      </div>
            </div>
                <div className="col-md-6 col-12">
                     <label className="mb-0 mt-3"  style={{color:"white"}}>สถานะใช้งาน</label>
  <div className="bg-primary">
             <TextField
                    select
                    required
                    id="user_no"
                    onChange={this._status}
                    value={this.state.status==1?1:
                            this.state.status==2?2:""}

                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"

                      style={{margin:0}}

                       InputProps={{
                                          startAdornment: (
                                            <InputAdornment >
                                              <SupervisedUserCircleIcon />
                                            </InputAdornment>
                                          ),
                                          style: {
                                                height: "40px"
                                                 , color:"#fff"
                                                 ,fontFamily: 'Kanit'
                                            }
                                        }}
                >
                 <MenuItem  style={{fontFamily: 'Kanit'}}  value={0}><span className="ml-2"> --เลือก--</span> </MenuItem>
                 <MenuItem  style={{fontFamily: 'Kanit'}}  value={1}><span className="ml-2"> ใช้งาน</span></MenuItem>
                 <MenuItem  style={{fontFamily: 'Kanit'}}  value={2}><span className="ml-2"> ยกเลิก</span></MenuItem>
                </TextField>
            </div>
            </div>
             <div className="col-md-6 col-12">

                 </div>
           <div className="col-md-6 col-12">

            </div>
            <div className="col-md-6 col-12">

            </div>
             <div className="col-md-6 col-12">

            </div>
            <div className="m-4" >
                <Button type="submit"  variant="contained" className="bg-blue text-white" style={{fontFamily: 'Kanit'}}
                onClick={this.handleClickOpen}>
                    บันทึก
                </Button>
            </div>


          </form>
          </div>
            </div>
          </div>
        </div>
        <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={this.state.open}
         autoHideDuration={6e3}
        message="บันทึกสำเร็จ"
         action={[
            <Button key="undo" color="secondary" size="small" onClick={this.handleRequestClose}>
              UNDO
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}

      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={this.state.open2}
          autoHideDuration={6e3}
        message="บันทึกไม่สำเร็จ"
        action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
      />


      </Auxiliary>
</div>
</div>
    );
  }
}


export default index;