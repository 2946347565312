import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
 import Button from '@material-ui/core/Button';
import { TextField, InputAdornment } from "@material-ui/core";
 import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import {DatePicker} from 'material-ui-pickers';

let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}

const columnData = [
  {id: 'name', align: true, disablePadding: false, label: 'รหัส'},
  {id: 'calories', align: true, disablePadding: false, label: 'ชื่อผู้ใช้งาน'},
  {id: 'fat', align: true, disablePadding: false, label: 'วัน-เวลา เข้าใช้'},
  {id: 'carbs', align: true, disablePadding: false, label: 'IP Address'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white',fontFamily: 'Kanit'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      selectedDate: moment(),
      selectedDateEnd: moment(),
      orderBy: '',
      selected: [],
      data: [].sort((a, b) => (a.calories < b.calories ? -1 : 1)),
      page: 0,
      rowsPerPage: 5,
      DMYYYY: '',
      DMYYYYEnd: '',
      name:'',
      listuser:[]
    };

    fetch('http://1.10.184.64:3000/users/index.php')
          .then(response => response.json())
          .then(data => {
              this.setState({ listuser: data, loading: false });
          });

       fetch('http://1.10.184.64:3000/log/index.php')
          .then(response => response.json())
          .then(data => {
          var _data  = data.sort((a, b) => (a.c_dt > b.c_dt ? 1 : -1))
              this.setState({ data: _data, loading: false });
          });

  }
    handleDateChange = (date) => {
    var D      = date.format('D');
    var M      = date.format('M');
    var YYYY   = date.format('YYYY');
    var dmyyyy = YYYY+"-"+M+"-"+D;

    this.setState({selectedDate: date,DMYYYY: dmyyyy});
  };
    handleDateChangeEnd = (date) => {
    var D      = date.format('D');
    var M      = date.format('M');
    var YYYY   = date.format('YYYY');
    var dmyyyy = YYYY+"-"+M+"-"+D;

    this.setState({selectedDateEnd: date,DMYYYYEnd: dmyyyy});
  };
  name =(event)=>{ this.setState({name:event.target.value})}
   goTo =(event)=>
   {

        this.setState({ data: [],name: '', loading: false });

         if(this.state.name!=''){
        // this.setState({ data: [],name: '', loading: false });
                var value = this.state.listuser.filter(x=>x.fname.includes(this.state.name))

                fetch('http://1.10.184.64:3000/log/user_id.php?id='+value[0].id)
              .then(response => response.json())
              .then(data => {
                        //  var _data  = this.state.listuser.filter(x=>x.dt_log>=this.state.DMYYYY)
                  // console.log(JSON.stringify(_data));
                  this.setState({ data: data,name: '', loading: false });
              });


          }else{

              fetch('http://1.10.184.64:3000/log/dt_log.php?id='+this.state.DMYYYY+'&id2='+this.state.DMYYYYEnd)
              .then(response => response.json())
              .then(data => {

                  this.setState({ data: data,DMYYYY: '' ,loading: false });
              });

          }
   }
  render() {
    const {data,listuser, order, orderBy, selected, rowsPerPage, page,selectedDate,selectedDateEnd,name} = this.state;

    return (
    <div>
    <div class="page-heading d-sm-flex justify-content-sm-between align-items-sm mb-0 p-2  bg-geekblue ">
          <h5 class="title p-1 mb-sm-0"></h5>
              <nav class="mb-0" aria-label="breadcrumb">
                      <ol class="breadcrumb">
                                    <div class="breadcrumb-item">
                                        <div   style={{color: 'gray', textDecoration: 'none'}}  >
                                         การจัดการระบบ
                                        </div>
                                        <div className="pl-1 pr-1"> > </div>
                                        <div   style={{color: 'white', textDecoration: 'none'}}  >
                                         สถิติการเข้าใช้ระบบ
                                        </div>
                                     </div>
                      </ol>
              </nav>
     </div>
    <div className="p-2 pt-3" >
    <ProfileHeader/>


    <div className="jr-profile-content ">
        <div className="">

            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                       <label className="ml-4 mt-4 text-white"><h3></h3></label>
                            <div className="row ml-3 mr-3 mb-3" style={{color:'#fff'}}>

                                   <div className="col-md-6 col-12 mb-3">
                                   <label className="">ชื่อผู้ใช้งาน</label>
                                       <div className="">

                                            <TextField
                                            onChange={this.name}
                                            value={name}
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"

                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"
                                                            ,fontFamily: 'Kanit'

                                                    }
                                                }}
                                        />
                                       </div>
                                 </div>
                                <div className="col-md-6 col-12">
                                   <label className="">IP Address</label>


                                       <div className="bg-primary">

                                       <TextField
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"

                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"
                                                             ,fontFamily: 'Kanit'

                                                    }
                                                }}
                                        />

                                       </div>
                                 </div>
                            </div>
                            <div className="row m-3" style={{color:'#fff'}}>
                              <div className="col-md-6 col-12">
                                <label>วันทีเริ่ม</label>


                                         <div key="basic_day" className="picker">
                                                    <DatePicker
                                                      fullWidth
                                                       format="DD/MM/yyyy"
                                                          InputProps={{

                                                           style: {
                                                                    height: "40px"
                                                                    ,width: "100%"
                                                                     ,backgroundColor:"#3f51b5"
                                                                        ,color:"#fff"
                                                                         ,fontFamily: 'Kanit'

                                                                }
                                                            }}

                                                               variant="outlined"
                                                              value={selectedDate}
                                                              onChange={this.handleDateChange}
                                                              animateYearScrolling={false}
                                                              leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                                                              rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                                                    />
                                         </div>
                              </div>
                              <div className="col-md-6 col-12">
                              <label>วันทีสิ้นสุด</label>
                                    <div key="basic_day" className="picker">
                                                    <DatePicker
                                                    format="DD/MM/yyyy"
                                                      fullWidth
                                                          InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"
                                                             ,fontFamily: 'Kanit'

                                                    }
                                                }}

                                                       variant="outlined"
                                                      value={selectedDateEnd}
                                                      onChange={this.handleDateChangeEnd}
                                                      animateYearScrolling={false}
                                                      leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                                                      rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                                          />



                                       </div>
                                 </div>
                            </div>
                 <div className="row m-3" style={{color:'#fff'}}>
                    <Button  onClick={this.goTo}  className="bg-blue text-white mt-0 ml-3">
                            <SearchIcon/>
                          <span className="ml-1">  ค้นหา  </span>
                    </Button>
                 </div>
                 </div>


        <div className="flex-auto">
          <div className="table-responsive-material bg-geekblue border border-primary">
            <Table className="" size="small" >
              <DataTableHead

                order={order}
                orderBy={orderBy}

              />
              <TableBody>
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover

                    >

                      <TableCell style={{color:'white' ,fontFamily: 'Kanit'}}  >{n.id}</TableCell>
                      <TableCell style={{color:'white' ,fontFamily: 'Kanit'}} >{n.name}</TableCell>
                      <TableCell style={{color:'white' ,fontFamily: 'Kanit'}} >
                      {n.dt_log==null?'':n.dt_log.substring(8,10)+' '}


                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="01"?" ม.ค.":""}
                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="02"?" ก.พ.":""}
                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="03"?" มี.ค.":""}
                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="04"?" เม.ย.":""}
                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="05"?" พ.ค.":""}
                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="06"?" มิ.ย.":""}
                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="07"?" ก.ค.":""}
                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="08"?" ส.ค.":""}
                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="09"?" ก.ย.":""}
                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="10"?" ต.ค.":""}
                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="11"?" พ.ย.":""}
                                        { n.dt_log==null?'':n.dt_log.substring(5,7)=="12"?" ธ.ค.":""}
                                        {
                                            n.dt_log==null?'':" "+String((parseInt(n.dt_log.substring(0,4))+543)).substring(2,4)
                                        }
                                        {" "+n.dt_log.split("T")[1].split(".")[0]}

                      </TableCell>

                      <TableCell style={{color:'white' ,fontFamily: 'Kanit'}} >{n.ip_address}</TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                     style={{color:'white' ,fontFamily: 'Kanit'}}
                    count={data.length}
                         labelDisplayedRows={({ from, to, count }) =>  <div style={{ color:'white',fontFamily: 'Kanit' }}>{from}-{to} จาก {count}</div>}
                    labelRowsPerPage=<div style={{ color:'white',fontFamily: 'Kanit' }}>รายการ</div>
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>
   </div>
 </div>
 </div>
 </div>
      </div>
      </div>
    );
  }
}

export default index;