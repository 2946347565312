import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import axios from "axios";
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {NavLink, withRouter,Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import EditIcon from '@material-ui/icons/Edit';
import { TextField, InputAdornment } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import AddBoxIcon from '@material-ui/icons/AddBox';
import cookie from 'react-cookies';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import ReactFontLoader from 'react-font-loader'

let counter = 0;
var url = 'http://1.10.184.64:3000';
function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}

const columnData = [
  {id: '1', align: true, disablePadding: false, label: 'เวลา'},
  {id: '2', align: true, disablePadding: false, label: 'หัวข้อข่าวสำคัญ'},
  {id: '7', align: true, disablePadding: false, label: 'เวรติดตามสถานการณ์'},
  {id: '8', align: true, disablePadding: false, label: 'ดำเนินการ'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
               style={{ color:'white',fontFamily: 'Kanit' }}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 5,
      name:'',
      type:'',
      path:'',
      news_group1:'',
      news_group2:'',
      news_group3:'',
      news_group4:'',
      Period:'',
      title:'',
      name_job:'',
      users:[],
      openmsg:false,
      sEdit:'',
      id:'',
      delid:'',
      statue:''

    };

     fetch(url+'/news/select.php')
           .then(response => response.json())
           .then(_data => {

               var data  = _data.filter(x=>x.flat=='0').sort((a, b) => (a.id > b.id ? -1 : 1))
               this.setState({ data: data });
          });

     fetch(url+'/users/index.php')
          .then(response => response.json())
          .then(data => {
              this.setState({ users: data, loading: false });
          });



  }
  _del =()=>{

             const qs = require('querystring')
               const config =
               {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
               }
            const requestBody =
            {
                id: this.state.delid,
            }
           axios.post(url+'/news/delete.php', qs.stringify(requestBody), config)
                .then((result) =>
                {
                    fetch(url+'/news/select.php')
                        .then(response => response.json())
                        .then(_data => {

                           var data  = _data.filter(x=>x.flat=='0').sort((a, b) => (a.id > b.id ? -1 : 1))
                           this.setState({data: data,open1:false});
                      });
                });
  }
   update =()=>
    {

       const qs = require('querystring')
       const config =
       {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
       }
        var id_ =parseInt(cookie.load('userId'));
          const requestBody =
            {
                id: this.state.id,
                Period: this.state.Period.replace('T',' '),
                title: this.state.title,
                news_group1:this.state.news_group1,
                news_group2:this.state.news_group2,
                news_group3:this.state.news_group3,
                news_group4:this.state.news_group4,
                name_job:this.state.name_job,

            }
             axios.post(url+'/news/update.php', qs.stringify(requestBody), config)
                .then((result) =>
                {
                    fetch(url+'/news/select.php')
                        .then(response => response.json())
                        .then(_data => {

                         var data  = _data.sort((a, b) => (a.id > b.id ? -1 : 1))
                           this.setState({ data: data,
                          openmsg:true,
                          sEdit:'',
                          news_group1:'',
                          news_group2:'',
                          news_group3:'',
                          news_group4:'',
                          Period:'',
                          title:'',
                          name_job:'',
                            });
                      });
                });
    }
    submit =()=>
    {

       const qs = require('querystring')
       const config =
       {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
       }
        var id_ =parseInt(cookie.load('userId'));
          const requestBody =
            {

                Period: this.state.Period.replace('T',' '),
                title: this.state.title,
                news_group1:this.state.news_group1,
                news_group2:this.state.news_group2,
                news_group3:this.state.news_group3,
                news_group4:this.state.news_group4,
                name_job:this.state.name_job,

            }
             axios.post(url+'/news/insert.php', qs.stringify(requestBody), config)
                .then((result) =>
                {
                    fetch(url+'/news/select.php')
                        .then(response => response.json())
                        .then(_data => {
                         var data  = _data.sort((a, b) => (a.id > b.id ? -1 : 1))

                             this.setState({ data: data,openmsg:true });
                      });
                });
    }
  _name =(event) =>
   {
      this.setState({name:event.target.value })
    }
     _group1 = (event)=>
    {

                   this.setState({news_group1:event.target.value})
    }
    _group2 = (event)=>
    {

                   this.setState({news_group2:event.target.value})
    }
    _group3 = (event)=>
    {

                   this.setState({news_group3:event.target.value})
    }
    _group4 = (event)=>
    {

                   this.setState({news_group4:event.target.value})
    }
    _Period =(event)=>
    {

                    this.setState({Period: event.target.value});
    }
    _title =(event)=>
    {
                   this.setState({title:event.target.value})
    }
    _name_job =(event)=>
    {
                   this.setState({name_job:event.target.value})
    }
    _statue =(event)=>
   {
       var status =event.target.value;
        if(status==0){
           fetch(url+'/news/select.php')
           .then(response => response.json())
           .then(_data => {

               var data  = _data.filter(x=>x.flat=='0').sort((a, b) => (a.id > b.id ? -1 : 1))
               this.setState({ data: data });
          });

          }else{

              fetch(url+'/news/select.php')
               .then(response => response.json())
               .then(_data => {

                   var data  = _data.filter(x=>x.flat=='1').sort((a, b) => (a.id > b.id ? -1 : 1))
                   this.setState({ data: data });
              });

          }
          this.setState({statue:event.target.value})
   }

  render() {
            const {data, order, orderBy, selected, rowsPerPage, page,statue} = this.state;

            let __users = this.state.users;
                    let usersItems = __users.map((users) =>
                       <MenuItem    style={{fontFamily: 'Kanit' }}  key={users.id} value={users.id}>
                       <span className="ml-2"> {users.fname} </span></MenuItem>
            );


    return (

    <div >
    <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Kanit' />
    <div  style={{ fontFamily: 'Kanit' }}>


    <div class="page-heading d-sm-flex justify-content-sm-between align-items-sm mb-0 p-2  bg-geekblue ">
          <h5 class="title p-1 mb-sm-0"></h5>
              <nav class="mb-0" aria-label="breadcrumb">
                      <ol class="breadcrumb">
                                    <div class="breadcrumb-item">
                                        <div   style={{color: 'gray', textDecoration: 'none'}}  >
                                         สถานการณ์ปกติ
                                        </div>
                                        <div className="pl-1 pr-1"> > </div>
                                        <div   style={{color: 'white', textDecoration: 'none'}}  >
                                         ด้านการข่าว
                                        </div>


                                     </div>
                      </ol>
              </nav>
    </div>
    <div className="p-2 pt-3" >
    <ProfileHeader/>
    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                       <label className="ml-4 mt-4 text-white"><h3></h3></label>
                            <div className="row ml-3 mr-3 mb-3" style={{color:'#fff'}}>

                            </div>
                   <div className="row m-3" style={{color:'#fff'}}>

                        <NavLink    to={{ pathname:'/app/createnewslast'}} >
                                    <Button   className="bg-blue text-white mt-0 ml-3" >
                                          <AddBoxIcon/>
                                          <span className="ml-1"  style={{fontFamily: 'Kanit' }} >
                                                เพิ่ม
                                          </span>
                                    </Button>
                        </NavLink>
                    </div>

                    <div className="row ml-3 mr-3 mb-3" style={{color:'#fff'}}>
                                   <div className="col-md-4 col-12 mb-3">
                                   <label className="">สถานะ</label>
                                       <div className="">
                                            <TextField
                                              select
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                              onChange={this._statue}
                                              value={statue}
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    ,backgroundColor:"#3f51b5"
                                                    }
                                                }}
                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"

                                                    }
                                                }}
                                        >
                                                     <MenuItem  style={{fontFamily: 'Kanit' }}  value={0}>ใช้งาน</MenuItem>
                                                     <MenuItem  style={{fontFamily: 'Kanit' }}  value={1}>ไม่ใช้งาน</MenuItem>
                                              </TextField>
                                       </div>
                                 </div>
                         </div>
                    </div>

        <div className="flex-auto">
          <div className="table-responsive-material bg-geekblue border border-primary">
            <Table className="" size="small" >
              <DataTableHead

                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow   hover >
                      <TableCell style={{color:'white',fontFamily: 'Kanit'}} >
                                        {
                                           n.period==null?'':
                                           n.period.substring(8,10)+n.period.split('T')[1].split('.')[0].replaceAll(':','').substring(0,4)+' '
                                        }
                                        { n.period==null?'':n.period.substring(5,7)=="01"?"ม.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="02"?"ก.พ.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="03"?"มี.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="04"?"เม.ย.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="05"?"พ.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="06"?"มิ.ย.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="07"?"ก.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="08"?"ส.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="09"?"ก.ย.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="10"?"ต.ค.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="11"?"พ.ย.":""}
                                        { n.period==null?'':n.period.substring(5,7)=="12"?"ธ.ค.":""}
                                        {
                                            n.period==null?'':" "+String((parseInt(n.period.substring(0,4))+543)).substring(2,4)
                                        }
                      </TableCell>
                      <TableCell   style={{color:'white',fontFamily: 'Kanit'}} >{n.title}</TableCell>

                      <TableCell style={{color:'white',fontFamily: 'Kanit'}} >
                      {
                         this.state.users.filter(x => x.id == n.name_job).length != 0 ?
                         this.state.users.filter(x => x.id == n.name_job)[0].fname :""
                      }
                      {
                         this.state.users.filter(x => x.id == n.name_job).length != 0 ?
                         " "+this.state.users.filter(x => x.id == n.name_job)[0].lname :""
                      }
                      </TableCell>
                      <TableCell style={{color:'white',fontFamily: 'Kanit',width:'200px'}} >
                           <div className="row">
                                <NavLink
                                            to={{pathname:'/app/createnewslast',search:'?id='+n.id}}  >
                                                 <Button   className="bg-blue text-white mt-0 ml-3">
                                                    <EditIcon />
                                                 </Button>
                                </NavLink>
                                <Button   className="bg-blue text-white mt-0 ml-3" onClick={()=>this.setState({delid:n.id,open1:true})} >
                                    <DeleteIcon/>
                                </Button>
                           </div>
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                     style={{color:'white'}}
                    count={data.length}
                     labelDisplayedRows={({ from, to, count }) =>  <div style={{ color:'white',fontFamily: 'Kanit' }}>{from}-{to} จาก {count}</div>}
                    labelRowsPerPage=<div style={{ color:'white',fontFamily: 'Kanit' }}>รายการ</div>
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>
   </div>
 </div>
 </div>
 </div>
      <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.openmsg}
            autoHideDuration={1000}
              onClose={() => this.setState({openmsg: false})}
            message="บันทึกสำเร็จ"
             action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />
           <Dialog fullWidth={true}
                        maxWidth={'xs'} open={this.state.open1} onClose={this.handleRequestClose}>
                        <DialogContent className="bg-geekblue text-white"  >
                            <InfoIcon className="text-primary " />   ยืนยันการลบ.
                        <hr />
                        </DialogContent>
                        <DialogActions className="bg-geekblue">
                            <Button onClick={this._del} className="bg-success text-white">
                                    <CheckCircleIcon /> ใช่
                            </Button>

                            <Button onClick={()=>{this.setState({open1:false})}} className="bg-danger text-white">
                                     <CancelIcon /> ยกเลิก
                            </Button>
                        </DialogActions>
          </Dialog>
      </div>
      </div>
      </div>

    );
  }
}

export default index;