
import CloseIcon from '@material-ui/icons/Close';
    import React from 'react';
    import PropTypes from 'prop-types';
    import {withStyles} from '@material-ui/core/styles';
    import classNames from 'classnames';
    import Drawer from '@material-ui/core/Drawer';
    import AppBar from '@material-ui/core/AppBar';
    import Avatar from '@material-ui/core/Avatar';
    import CustomScrollbars from 'util/CustomScrollbars';
    import Autocomplete from '@material-ui/lab/Autocomplete';
    import RadioGroup from '@material-ui/core/RadioGroup';
    import LayersIcon from '@material-ui/icons/Layers';
    import DeckGL from '@deck.gl/react';
	import { render } from "react-dom";
    import CKEditor from '@ckeditor/ckeditor5-react';
    import Toolbar from '@material-ui/core/Toolbar';
    import MenuItem from '@material-ui/core/MenuItem';
    import Dialog from '@material-ui/core/Dialog';
    import DialogActions from '@material-ui/core/DialogActions';
    import DialogContent from '@material-ui/core/DialogContent';
    import DialogContentText from '@material-ui/core/DialogContentText';
    import DialogTitle from '@material-ui/core/DialogTitle';
    import List from '@material-ui/core/List';
    import IntlMessages from 'util/IntlMessages';
    import Typography from '@material-ui/core/Typography';
    import Divider from '@material-ui/core/Divider';
    import SquareFootRoundedIcon from '@material-ui/icons/SquareFootRounded';
    import Switch from '@material-ui/core/Switch';
    import IconButton from '@material-ui/core/IconButton';
    import LanguageIcon from '@material-ui/icons/Language';
    import axios from "axios";
    import MenuIcon from '@material-ui/icons/Menu';

    import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
    import ChevronRightIcon from '@material-ui/icons/ChevronRight';
    import Checkbox from '@material-ui/core/Checkbox';
    import Button from '@material-ui/core/Button';
    import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
    import AccessibilityIcon from '@material-ui/icons/Accessibility';
    import Snackbar from '@material-ui/core/Snackbar';
    import ReactMapboxGl, { Layer,Feature,Source } from 'react-mapbox-gl';
    import ReactStreetview from 'react-streetview';
    import MapGL,{ NavigationControl ,ScaleControl, FullscreenControl,AttributionControl,GeolocateControl  }   from '@urbica/react-map-gl';
    import Draw from '@urbica/react-map-gl-draw';
    import HomeWorkIcon from '@material-ui/icons/HomeWork';
    import { TextField, InputAdornment } from "@material-ui/core";
    import MyLocationIcon from '@material-ui/icons/MyLocation';
    import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
    import HealingRoundedIcon from '@material-ui/icons/HealingRounded';
    import RoomIcon from '@material-ui/icons/Room';
    import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
    import Radio from '@material-ui/core/Radio';

    import FormControl from '@material-ui/core/FormControl';
    import FormLabel from '@material-ui/core/FormLabel';
    import FormControlLabel from '@material-ui/core/FormControlLabel';
    import FormGroup from '@material-ui/core/FormGroup';
    import FormHelperText from '@material-ui/core/FormHelperText';
    import MapMesure from 'mapboxgl-measure-tool'
    import { connect } from 'react-redux';
    import mapboxgl,{MarkerViewOptions ,LatLng} from 'mapbox-gl';
    import imgmarkerbk from "./map-markerbk.png";
    import imgmarkersniper from "./map-markersniper.png";
    import imgmarkerlocation from "./map-markerlocation.png";
    import imgmarkerdistance from "./map-markerdistance.png";
  import img_users from "./users.png";
    import { compose, withProps } from "recompose";
    import bkmarkerIcon from "./bk_pin.svg";
    import smarkerIcon from "./Sniper.svg";
    import locationIcon from "./location.svg";
    import stlocationIcon from "./stNav.svg";

    import nmarkerIcon from "./Nav.svg";
    import Visibility from "@material-ui/icons/Visibility";
    import VisibilityOff from "@material-ui/icons/VisibilityOff";
    import CreditCardIcon from '@material-ui/icons/CreditCard';
    import GroupIcon from '@material-ui/icons/Group';
    import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
    import SaveIcon from '@material-ui/icons/Save';
    import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
    import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
    import GetAppIcon from '@material-ui/icons/GetApp';
    import CallIcon from '@material-ui/icons/Call';
    import AccountCircle from '@material-ui/icons/AccountCircle';
    import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
    import PoolIcon from '@material-ui/icons/Pool';
    import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
    import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
    import InfoIcon from '@material-ui/icons/Info';
    import LocationCityIcon from '@material-ui/icons/LocationCity';
    import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import Tabs from '@material-ui/core/Tabs';
    import Tab from '@material-ui/core/Tab';
    import ImageIcon from '@material-ui/icons/Image';
    import imggeolocation from "./geolocation.png";
    import imggeolocation2 from "./geolocation2.png";
    import { makeStyles } from "@material-ui/core/styles";
    import cookie from 'react-cookies'

    import {GoogleMap, OverlayView, StreetViewPanorama, withGoogleMap,withScriptjs,Marker} from "react-google-maps";

            const { point } = require('@turf/helpers')
            const distance = require('@turf/distance').default
            const drawerWidth = 330;

            const useStyles = makeStyles(theme => ({
  inputRoot: {
    color: "purple",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "green"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple"
    }
  }
}));

            const getItems = count =>
              Array.from({ length: count }, (v, k) => k).map(k => ({
                id: `item-${k}`,
                content: `item ${k}`
              }));

            // a little function to help us with reordering the result
            const reorder = (list, startIndex, endIndex) => {
                  const result = Array.from(list);
                  const [removed] = result.splice(startIndex, 1);
                  result.splice(endIndex, 0, removed);
                  return result;
            };


            const grid = 8;

            const getItemStyle = (isDragging, draggableStyle) => ({
              userSelect: "none",
              padding: grid * 2,
              margin: `0 0 ${grid}px 0`,
              background: isDragging ? "lightgreen" : "grey",
              ...draggableStyle
            });

            const getListStyle = isDraggingOver => ({
              background: isDraggingOver ? "lightblue" : "lightgrey",
              padding: grid,
              width: 250
            });


         let polygonPaint = ReactMapboxGl.FillPaint = {
            'fill-color': "red",
            'fill-opacity': 0.3
        }

        let _point500m = ReactMapboxGl.FillPaint = {
            'fill-color': "red",
            'fill-opacity': 0.3
        }
        let _point100m = ReactMapboxGl.FillPaint = {
            'fill-color': "#FFFF00",
            'fill-opacity': 0.1
        }


        const styles = theme => ({


          root: {
            width: '100%',
            height: '100%',
            zIndex: 1,
            overflow: 'hidden',
          },
          appFrame: {
            position: 'relative',
            display: 'flex',
            width: '100%',
            height: '100%',
          },
          appBar: {
            position: 'absolute',
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          },
          appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
          menuButton: {
            marginLeft: 12,
            marginRight: 20,
          },
          hide: {
            display: 'none',
          },
          drawerPaper: {
            position: 'relative',
            height: '100%',
            width: drawerWidth,
          },
          drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
          },
          content: {
            width: '100%',
            marginLeft: -drawerWidth,
            flexGrow: 1,
            padding: theme.spacing.unit * 3,
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginTop: 56,
            [theme.breakpoints.up('sm')]: {
              content: {
                height: 'calc(100% - 64px)',
                marginTop: 64,
              },
            },
          },
          contentShift: {
            marginLeft: 0,
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        });



         var  latData ="";
         var  lngData ="";
         var _latitude="";
         var _longitude="";
            var  _xid_= 0;
         var  xx= 0;
         var  yy= 0;
         var startview ="";
         var  _min="";
         var  _max="";
         var  find ="";
         var   actionBK ="false";
         var   lngBK =0;
         var   latBK =0;
         var   actionSniper ="false";
         var   lngSP =0;
         var   latSP =0;
         var   actionlocation ="false";
         var   lngLT =0;
         var   latLT =0;
         var   actionDistance ="false";
         var   lngDis =0;
         var   latDis =0;
         var   actionStreetview ="false";
         var   lngSV =0;
         var   latSV =0;
        function getPixelPositionOffset(width, height) {
          return {x: -(width / 2), y: -(height / 2)};
        }

        const STYLES = {
          overlayView: {
            background: `red`,
            color: `white`,
            padding: 5,
            borderRadius: `50%`,
          },
        };


        const coordinates = {lat: 13.700174, lng: 100.4088213};

        const StreetViewPanoramaExampleGoogleMap = compose(
       withProps
              ({
                googleMapURL:
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyCeuN-U26oP2_XLQ6RuWUjHIoeNiAYoLWA",
                loadingElement: <div style={{ height: '100%' }} />,
                containerElement: <div style={{ height: '100%' }} />,
                mapElement: <div style={{ height: '100%' }} />
              })
              ,withScriptjs,withGoogleMap)(props => (

       <GoogleMap defaultZoom={8} defaultCenter={{ lat: 13.700174, lng: 100.4088213 }}>
                  <StreetViewPanorama
                    defaultPosition={{ lat: 13.700174, lng: 100.4088213}}
                    visible  >
                  </StreetViewPanorama>
       </GoogleMap>
    ));
     /*formpopup*/
      function TabContainer(props) {
              return (
                <div style={{padding: 0}}>
                  {props.children}
                </div>
              );
            }
    class index extends React.Component {


          state = {
            open: false,
            openForm: false,
            zopen:0


          };

          handleDrawerOpen = () => {
            this.setState({open: true});

          };

          handleDrawerClose = () => {
            this.setState({open: false});

          };


    constructor(props, context)
            {
                    super(props, context);
                    this.state = {
                    idplen:0,
                    text_rpt:'',
                    longitude:'',
                    latitude:'',
                    selectedFile:'',
                      dd:-118.4107187,
                      oo:33.9415889,
                      items: getItems(10),
                      _SVy:0,
                      _SVx:0,
                      isToggle: null,
                      y1:'0',
                      x1:'0',
                      weapon:[],
                      _y1:'0',
                      _3dcolor:"#ffc107",
                      _x1:'0',
                      _lngSP:0,
                      _latSP:0,
                      _lngDis:0,
                      _latDis:0,
                      datapolygon:"[[[]]]",
                      dataComptele: false,
                      lbl_nstorey:"",
                      lbl_name:"",
                      arraydatapolygon:[],
                      arraypolygon1000m:[],
                      point500m:"[[[]]]",
                      point1000m:"[[[]]]",
                      nonelayer:"none",
                      nonedis:"none",
                      films:['The Shawshank Redemption'],
                      name:'',
                      pl:'',
                      gotoname:'',
                      openmsg: false,
                      openmsg2: false,
                      dataError: false,
                      duperror: false,
                      vertical: 'top',
                      horizontal: 'center',
                      point:null,
                      selectWeapon:'0',
                      weaponmin:'500',
                      weaponmax:'1000',
                      searchMail: '',
                      noContentFoundMessage: 'No mail found in selected folder',
                      alertMessage: '',
                      showMessage: false,
                      drawerStateMin: false,
                      drawerState: false,
                      optionName: 'None',
                      anchorEl: null,
                      lat:0,
                      data:null,
                      lon:0,
                      maplng: 101.490104,
                      maplat: 13.03887,
                      mapzoom: 5,
                      Updatemapzoom: 0,
                      loader: false,
                      currentMail: null,
                      user: {
                        name: 'Robert Johnson',
                        email: 'robert@example.com',
                        avatar: 'https://via.placeholder.com/150x150'
                      },
                      selectedMails: 0,
                      selectedFolder: 0,
                      composeMail: false,
                      labelMenuState: false,
                      folderMenuState: false,
                      optionMenuState: false,
                      bs_name:"",
                      bs_nstorey:"",
                       bs_employ:"",
                        _xid:"",
                        close_icon:"none",
                      bl_name:"",
                      bl_nstorey:"",
                      bl_employ:"",
                      bbk:"",
                      bs:"",
                      lbl_employ:"",
                      markerlng:"",
                      markerlat:"",
                      stmarkerlng:"",
                      stmarkerlat:"",
                      smarkerlng:"",
                      smarkerlat:"",
                      hidmarker1:"",
                      hidsmarker2:"",
                      vdistance: "",
                      directions:[],
                      nmarkerlng:"",
                      nmarkerlat:"",
                      listblg:[],
                      datainfo:[],
                      ststyle:"none",
                      nm:"",
                      apidistance:"",
                      apiduration:"",
                      colorBK:"#990099",
                      colorSNI:"#FF4500",
                      disabledBK:false,
                      colorSC:"bg-blue text-white mt-0 ml-4",
                      disabledSC:false,
                      disabledSNI:false,
                      colorDIS:"#663333",
                      disabledDIS:false,
                      ststylelos:"none",
                       /*FormPopup*/
                      province: [],
                      provinceGS: [],
                      district:[],
                      subdistrict:[],
                      province_code: '',
                      district_code: '',
                      sub_district_code: '',
                      _editor: ClassicEditor,
                      _content: props.content,
                        tabvalue: 0,
                         Gotoprovince: '',
                           Gotodistrict: '',
                            Gotosubdistrict: '',
                            images: [],
                            poi_plan_report:[],
                       /*FormPopup*/
                       /*bk*/
                       _lngBK:0,
                       _latBK:0,
                       plan_status:1,
                      typemap:{        "version": 8,
                                                  "name": "OSM",
                                                  "metadata": {
                                                  },
                                                  "sources": {
                                                    "osm": {
                                                      "type": "raster",
                                                      "tiles": [
                                                        "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                      ]
                                                    }
                                                  },
                                                  "layers": [
                                                    {
                                                      "id": "osm",
                                                      "type": "raster",
                                                      "source": "osm"
                                                    }
                                                  ],
                                                  "id": "klokantech-basic"
                                                }

                      ,omap:{"version": 8,
                                                  "name": "OSM",
                                                  "metadata": {
                                                  },
                                                  "sources": {
                                                    "osm": {
                                                      "type": "raster",
                                                      "tiles": [
                                                        "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                      ]
                                                    }
                                                  },
                                                  "layers": [
                                                    {
                                                      "id": "osm",
                                                      "type": "raster",
                                                      "source": "osm"
                                                    }
                                                  ],
                                                  "id": "klokantech-basic"
                                                },




                                   }



                                   this._upload_bldg = this._upload_bldg.bind(this);
                                   this._upload_enter = this._upload_enter.bind(this);
                                    this._upload_exit = this._upload_exit.bind(this);



                                   fetch('http://1.10.184.64:3000/weapon/index.php')
                                        .then(response => response.json())
                                        .then(data => {
                                                 this.setState({ weapon: data, loading: false });
                                        });

                                   fetch('http://1.10.184.64:3000/province/index.php')
                                        .then(response => response.json())
                                        .then(data => {
                                                 this.setState({ province: data, loading: false });
                                        });


                                    fetch('http://1.10.184.64:3000/actionplan/newselect.php')
                                        .then(response => response.json())
                                        .then(data => {
                                           var location = window.location.href;
                                            const str = '' + location;

                                            const _xid = str.split("=")[1];
                                            var   xid =data;
                                           if(typeof _xid!=="undefined"){ xid =str.split("=")[1] }

                                      fetch('http://1.10.184.64:3000/actionplan/select.php?id='+xid)
                                          .then(response => response.json())
                                          .then(data => {


                                      fetch('http://1.10.184.64:3000/bldg/autocomplete.php?id='+data.name)
                                             .then(response => response.json())
                                             .then(data => {
                                              this.setState({ listblg: data });
                                            })
                                             this.setState({
                                                        name: data.name,
                                                        lname: data.laname,
                                                        p_type: data.p_type,
                                                         p_form: data.p_form,
                                                         topography: data.topography,
                                                         population: data.population,
                                                         dominance: data.dominance,
                                                         religion:data.religion,
                                                         way_land: data.way_land,
                                                         way_water: data.way_water,
                                                         plan_status: data.plan_status,
                                                         Gotoprovince: data.province_code,
                                                         province_code: data.province_code,
                                                         Gotodistrict: data.district_code,
                                                         district_code: data.district_code,
                                                         Gotosubdistrict: data.sub_district_code,
                                                          sub_district_code: data.sub_district_code,
                                                         gotoname: data.name,
                                                         w_id:data.w_id,
                                                         lat:data.y,
                                                         lon:data.x,
                                                          maplng:data.y,
                                                          maplat: data.x,
                                                         selectWeapon:data.w_id

                                                     });



                                  fetch('http://1.10.184.64:3000/bldg/index.php?id='+this.state.gotoname)
                                    .then(response => response.json())
                                    .then(data => {
                                         var item  = this.state.province;
                                         var pro_c = "";
                                         var sub_c = "";
                                         var dis_c = "";
                                         var bl_sub_dis =data[0].bl_sub_dis;
                                         var bl_distric =data[0].bl_distric;
                                         for(var i =0;i<=item.length-1;i++){

                                                  if(item[i].on_pro_tha==data[0].bl_changwa)
                                                    { pro_c = item[i].pro_c; break; }
                                         }
                                          // console.log(item.length);
                                        this.setState({Gotoprovince: pro_c});
                                        fetch('http://1.10.184.64:3000/district/index.php?id='+pro_c)
                                            .then(response => response.json())
                                            .then(data => {

                                                    var disitem  = data;
                                                     for(var i =0;i<=disitem.length-1;i++){

                                                              if(disitem[i].on_dis_tha==bl_distric)
                                                               {
                                                                   dis_c = disitem[i].dis_c;
                                                                    this.setState({ Gotodistrict: dis_c,district: data})

                                                                           fetch('http://1.10.184.64:3000/subdistrict/index.php?id='+dis_c)
                                                                             .then(response => response.json())
                                                                             .then(data => {

                                                                                         for(var i =0;i<=data.length-1;i++){
                                                                                         console.log(bl_sub_dis+"xxxx"+data[i].on_sub_tha)
                                                                                                  if(data[i].on_sub_tha==bl_sub_dis)
                                                                                                    {
                                                                                                      sub_c = data[i].sub_c;
                                                                                                      this.setState({ Gotosubdistrict: sub_c,subdistrict: data})
                                                                                                         //console.log(this.state.Gotosubdistrict)
                                                                                                     break;
                                                                                                     }
                                                                                         }
                                                                             });
                                                                   // console.log(this.state.Gotodistrict)
                                                                    break;
                                                               }

                                                     }
                                        });



                                         console.log("fly"+data[0].x)
                                         find ="true";

                                           lngLT = data[0].x;
                                           latLT = data[0].y;

                                           this.map.flyTo({ center: [data[0].x, data[0].y],zoom:15});
                                         this.setState({
                                         lbl_name:data[0].bl_name,
                                         lbl_nstorey:data[0].bl_nstorey,
                                             lbl_employ:data[0].bl_employ,
                                         lat:data[0].x,
                                         lon:data[0].y,
                                         point:data[0].point,
                                         mapzoom:16,
                                          maplng:data[0].x,
                                          maplat: data[0].y,
                                          datapolygon:data[0].bld,
                                          _x1:data[0].x1,
                                          _y1:data[0].y1,
                                          Gotoprovince: pro_c,
                                          name: data[0].bl_name
                                           });

                                   var polygon =JSON.parse(this.state.datapolygon);

                                  try{

                                   var mapLayer = this.map.getLayer('_plan');
                                       if(typeof mapLayer !== 'undefined') {
                                          this.map.removeLayer('_plan').removeSource('plan');
                                       }
                                  }
                                  catch{}


                                           this.map.addSource('plan', {

                                                    'type': 'geojson',
                                                    'data':
                                                    {"features": [
                                                    {"type": "Feature","properties":
                                                    {"level": 1,"name": "Bird Exhibit","height": data[0].bl_nstorey+1,"base_height": 0,"color": "#CFBB10"},"geometry":
                                                    {"coordinates": polygon,
                                                    "type": "Polygon"},
                                                    "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"},
                                                     ],"type": "FeatureCollection"}
                                                    });


                                                    this.map.addLayer({
                                                                'id': '_plan',
                                                                'type': 'fill-extrusion',
                                                                'source': 'plan',
                                                                'paint': {
                                                                // See the Mapbox Style Specification for details on data expressions.
                                                                // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                                                // Get the fill-extrusion-color from the source 'color' property.
                                                                'fill-extrusion-color': ['get', 'color'],

                                                                // Get fill-extrusion-height from the source 'height' property.
                                                                'fill-extrusion-height': ['get', 'height'],

                                                                // Get fill-extrusion-base from the source 'base_height' property.
                                                                'fill-extrusion-base': ['get', 'base_height'],

                                                                // Make extrusions slightly opaque for see through indoor walls.
                                                                'fill-extrusion-opacity': 0.5
                                                                    }
                                                     });
                                        this.map.setLayoutProperty('_plan', 'visibility', 'none');

                                   var mapLayer = this.map.getLayer('layerLocation');
                                   if(typeof mapLayer !== 'undefined') {
                                      this.map.removeLayer('layerLocation').removeSource('Location');
                                   }

                                     var mpLayer = this.map.getLayer("layerLocation");
                                        if (typeof mpLayer === 'undefined') {
                                            // No Layer
                                        } else {

                                            this.map.removeLayer("layerLocation");
                                             this.map.removeSource("Location");
                                        }

                                 this.map.addSource('Location', {
                                    'type': 'geojson',
                                    'data': {
                                    'type': 'Feature',
                                    'geometry': {
                                    'type': 'Polygon',
                                    'coordinates': polygon
                                            }
                                    }
                                    });
                                    this.map.addLayer({
                                    'id': 'layerLocation',
                                    'type': 'fill',
                                    'source': 'Location',
                                    'layout': {},
                                    'paint': {
                                    'fill-color': '#088',
                                    'fill-opacity': 0.8
                                    }
                                    });


                                          var mpLayer = this.map.getLayer("_pointslocation");
                                                        if (typeof mpLayer === 'undefined') {
                                                            // No Layer
                                                        } else {

                                                            this.map.removeLayer("_pointslocation");
                                                             this.map.removeSource("pointlocation");
                                                        }


                                  this.map.loadImage(
                                    imgmarkerlocation,
                                    (error, image) => {
                                   try{  this.map.addImage('imgmarkerlocation', image); }catch{}
                                    this.map.addSource('pointlocation', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [data[0].x,data[0].y]
                                                }
                                                }
                                            ]
                                        }
                                    });

                                    this.map.addLayer({
                                            'id': '_pointslocation',
                                            'type': 'symbol',
                                            'source': 'pointlocation',
                                            'layout': {
                                            'icon-image': 'imgmarkerlocation',
                                            'icon-size': 0.1
                                                }
                                            });
                                        }
                                    );


                         fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&poi_type=404')
                               .then(response => response.json())
                                 .then(poi_plan => {


                                  this.setState({
                                      bl_name: poi_plan.name,
                                      bl_nstorey: poi_plan.bl_nstorey,
                                      bl_employ: poi_plan.bl_employ
                                   });

                                     var mapLayer = this.map.getLayer('_pointsbk');
                                       if(typeof mapLayer !== 'undefined') {

                                           this.map.removeLayer('_pointsbk').removeSource('pointbk');
                                       }
                                       this.map.loadImage(
                                        imgmarkerbk,
                                        (error, image) => {
                                       try{ this.map.addImage('imgmarker', image); } catch { }

                                        this.map.addSource('pointbk', {
                                        'type': 'geojson',
                                            'data': {
                                            'type': 'FeatureCollection',
                                            'features': [
                                                    {
                                                    'type': 'Feature',
                                                    'geometry': {
                                                    'type': 'Point',
                                                    'coordinates': [poi_plan.x,poi_plan.y]
                                                    }
                                                    }
                                                ]
                                            }
                                        });

                                        this.map.addLayer({
                                                'id': '_pointsbk',
                                                'type': 'symbol',
                                                'source': 'pointbk',
                                                'layout': {
                                                'icon-image': 'imgmarker',
                                                'icon-size': 0.1
                                                    }
                                                });

                                            }
                                        );

                                          fetch('http://1.10.184.64:3000/bldg/convert.php?lat='+poi_plan.x+'&lon='+poi_plan.y+'')
                                          .then(response => response.json())
                                          .then(data => {

                                           //  this.setState({ arraydatapolygon: data, loading: false });
                                                 fetch('http://1.10.184.64:8080/geoserver/ctoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ctoc%3Abldg&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom,POINT('+data[0].ST_X+'%20'+data[0].ST_Y+'))')
                                                      .then(response => response.json())
                                                      .then(data => {



                                                                     if(typeof data.features[0] !== "undefined")
                                                                        {

                                                                       var _bl_name  =data.features[0].properties.bl_name;
                                                                       var _bl_nstorey  =data.features[0].properties.bl_nstorey;
                                                                       var _bl_employ  =data.features[0].properties.bl_employ;
                                                                       this.setState({bbk:"", bl_name: _bl_name,bl_nstorey:_bl_nstorey,bl_employ :_bl_employ });


                                                                         fetch('http://1.10.184.64:3000/bldg/objectid.php?id='+data.features[0].properties.objectid_1)
                                                                                                            .then(response => response.json())
                                                                                                            .then(data => {

                                                                                                         var polygon =JSON.parse(data[0].bld);

                                                                                                           var mapLayer = this.map.getLayer('layerbk');
                                                                                                           if(typeof mapLayer !== 'undefined') {

                                                                                                               this.map.removeLayer('layerbk').removeSource('sourcebk');
                                                                                                           }

                                                                                                                 this.map.addSource('sourcebk', {
                                                                                                            'type': 'geojson',
                                                                                                            'data': {
                                                                                                            'type': 'Feature',
                                                                                                            'geometry': {
                                                                                                            'type': 'Polygon',
                                                                                                            'coordinates': polygon
                                                                                                                    }
                                                                                                            }
                                                                                                            });
                                                                                                            this.map.addLayer({
                                                                                                            'id': 'layerbk',
                                                                                                            'type': 'fill',
                                                                                                            'source': 'sourcebk',
                                                                                                            'layout': {},
                                                                                                            'paint': {
                                                                                                            'fill-color': '#DDA0DD',
                                                                                                            'fill-opacity': 0.5
                                                                                                            }
                                                                                                            });
                                                                                                       });

                                                                       fetch('http://1.10.184.64:3000/bldg/selectID.php?id='+data.features[0].properties.objectid_1)
                                                                                             .then(response => response.json())
                                                                                             .then(data => {
                                                                                                  var polygon =JSON.parse(data[0].bld);

                                                                                                       var mapLayer = this.map.getLayer('_planbk');
                                                                                                           if(typeof mapLayer !== 'undefined') {
                                                                                                              this.map.removeLayer('_planbk').removeSource('planbk');
                                                                                                           }
                                                                                                    this.map.addSource('planbk', {

                                                                                                                'type': 'geojson',
                                                                                                                'data':
                                                                                                                {"features": [
                                                                                                                {"type": "Feature","properties":
                                                                                                                {"level": 1,"name": "Bird Exhibit","height": data[0].bl_nstorey+1,"base_height": 0,"color": "#8710CF"},"geometry":
                                                                                                                {"coordinates": polygon,
                                                                                                                "type": "Polygon"},
                                                                                                                "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"},
                                                                                                                 ],"type": "FeatureCollection"}
                                                                                                                });

                                                                                                                this.map.addLayer({
                                                                                                                            'id': '_planbk',
                                                                                                                            'type': 'fill-extrusion',
                                                                                                                            'source': 'planbk',
                                                                                                                            'paint': {
                                                                                                                            // See the Mapbox Style Specification for details on data expressions.
                                                                                                                            // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                                                                                                            // Get the fill-extrusion-color from the source 'color' property.
                                                                                                                            'fill-extrusion-color': ['get', 'color'],

                                                                                                                            // Get fill-extrusion-height from the source 'height' property.
                                                                                                                            'fill-extrusion-height': ['get', 'height'],

                                                                                                                            // Get fill-extrusion-base from the source 'base_height' property.
                                                                                                                            'fill-extrusion-base': ['get', 'base_height'],

                                                                                                                            // Make extrusions slightly opaque for see through indoor walls.
                                                                                                                             'fill-extrusion-opacity': 0.5
                                                                                                                                }
                                                                                                                 });
                                                                                                                 this.map.setLayoutProperty('_planbk', 'visibility', 'none');



                                                                               });

                                                                       }
                                                                       else
                                                                       {
                                                                                            this.setState({dataError:true})
                                                                       }

                                                      });
                                              });
                                               });




                                               fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&poi_type=412')
                                              .then(response => response.json())
                                              .then(poi_sni => {


                                                 this.setState({
                                                 _latSP:poi_sni.x,
                                                 _lngSP:poi_sni.y,
                                                 bs:"",
                                                 bs_name:poi_sni.name,
                                                 bs_nstorey:poi_sni.bl_nstorey,
                                                 bs_employ:poi_sni.bl_employ
                                                 });

                              if(poi_sni.x>0 && poi_sni.y>0){
                                 var mapLayer = this.map.getLayer('pointssniper');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('pointssniper').removeSource('pointsniper');
                                   }

                                   this.map.loadImage(imgmarkersniper,
                                    (error, image) => {
                                   try{ this.map.addImage('imgmarkersniper', image); }catch{ }
                                    this.map.addSource('pointsniper', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [poi_sni.x,poi_sni.y]
                                                }
                                                }
                                            ]
                                        }
                                    });

                                    this.map.addLayer({
                                            'id': 'pointssniper',
                                            'type': 'symbol',
                                            'source': 'pointsniper',
                                            'layout': {
                                            'icon-image': 'imgmarkersniper',
                                            'icon-size': 0.1
                                                }
                                            });
                                        }
                                    );



                                    // lngLT = this.state.lat;
                                    // latLT = this.state.lon;

                                     // var mapLayer = this.map.getLayer('_route');
                                     // if(typeof mapLayer !== 'undefined') {

                                  //     this.map.removeLayer('_route').removeSource('route');
                                 //  }
                                     this.map.addSource('route', {
                                        'type': 'geojson',
                                        'data': {
                                        'type': 'Feature',
                                        'properties': {},
                                        'geometry': {
                                        'type': 'LineString',
                                        'coordinates': [
                                        [lngLT,latLT],
                                        [poi_sni.x,poi_sni.y],
                                        ]
                                        }
                                        }
                                });

                                this.map.addLayer({
                                            'id': '_route',
                                            'type': 'line',
                                            'source': 'route',
                                            'layout': {
                                            'line-join': 'round',
                                            'line-cap': 'round'
                                            },
                                            'paint': {
                                            'line-color': '#888',
                                            'line-width': 8
                                            }
                                });

                                   if(lngLT!=="" && poi_sni.x !="" ){

                                    let pt1 = point([lngLT,latLT], { 'name': 'Kano State' })
                                    let pt2 = point([poi_sni.x,poi_sni.y], { 'name': 'Lagos State' })

                                    var  result = distance(pt1, pt2, { units: 'metres' })
                                    var calresult = parseFloat(result).toFixed(2);
                                    this.setState({vdistance:calresult});

                                    }
                                    var url = 'http://1.10.184.64:3000/bldg/convert.php?lat='+poi_sni.x+'&lon='+poi_sni.y+'';
                                    fetch(url)
                                          .then(response => response.json())
                                          .then(data => {



                                          var url = 'http://1.10.184.64:8080/geoserver/ctoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ctoc%3Abldg&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom,POINT('+data[0].ST_X+'%20'+data[0].ST_Y+'))';

                                       fetch(url)
                                                      .then(response => response.json())
                                                      .then(data => {
                                                       console.log(data.features.length+"sssssssss"+JSON.stringify(data))

                                                         fetch('http://1.10.184.64:3000/bldg/selectID.php?id='+data.features[0].properties.objectid_1)
                                                                                             .then(response => response.json())
                                                                                             .then(data => {
                                                                                                  var polygon =JSON.parse(data[0].bld);

                                                                                                       var mapLayer = this.map.getLayer('_plansni');
                                                                                                           if(typeof mapLayer !== 'undefined') {
                                                                                                              this.map.removeLayer('_plansni').removeSource('plansni');
                                                                                                           }
                                                                                                    this.map.addSource('plansni', {

                                                                                                                'type': 'geojson',
                                                                                                                'data':
                                                                                                                {"features": [
                                                                                                                {"type": "Feature","properties":
                                                                                                                {"level": 1,"name": "Bird Exhibit","height": data[0].bl_nstorey+1,"base_height": 0,"color": "#CF7E10 "},"geometry":
                                                                                                                {"coordinates": polygon,
                                                                                                                "type": "Polygon"},
                                                                                                                "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"},
                                                                                                                 ],"type": "FeatureCollection"}
                                                                                                                });

                                                                                                                this.map.addLayer({
                                                                                                                            'id': '_plansni',
                                                                                                                            'type': 'fill-extrusion',
                                                                                                                            'source': 'plansni',
                                                                                                                            'paint': {
                                                                                                                            // See the Mapbox Style Specification for details on data expressions.
                                                                                                                            // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                                                                                                            // Get the fill-extrusion-color from the source 'color' property.
                                                                                                                            'fill-extrusion-color': ['get', 'color'],

                                                                                                                            // Get fill-extrusion-height from the source 'height' property.
                                                                                                                            'fill-extrusion-height': ['get', 'height'],

                                                                                                                            // Get fill-extrusion-base from the source 'base_height' property.
                                                                                                                            'fill-extrusion-base': ['get', 'base_height'],

                                                                                                                            // Make extrusions slightly opaque for see through indoor walls.
                                                                                                                                'fill-extrusion-opacity': 0.5
                                                                                                                                }
                                                                                                                 });

                                                                                                          this.map.setLayoutProperty('_plansni', 'visibility', 'none');

                                                                               });
                                                     if(data.features.length==0){

                                                     }
                                                     else
                                                     {
                                            fetch('http://1.10.184.64:3000/bldg/objectid.php?id='+data.features[0].properties.objectid_1)
                                    .then(response => response.json())
                                    .then(data => {
								 var polygon =JSON.parse(data[0].bld);

								   var mapLayer = this.map.getLayer('layersniper');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('layersniper').removeSource('sourcesniper');
                                   }
								    this.map.addSource('sourcesniper', {
                                    'type': 'geojson',
                                    'data': {
                                    'type': 'Feature',
                                    'geometry': {
                                    'type': 'Polygon',
                                    'coordinates': polygon
                                            }
                                    }
                                    });
                                    this.map.addLayer({
                                    'id': 'layersniper',
                                    'type': 'fill',
                                    'source': 'sourcesniper',
                                    'layout': {},
                                    'paint': {
                                    'fill-color': '#FF8C00',
                                    'fill-opacity': 0.5
                                    }
                                    });

                                   });

                                               }


                                                fetch('http://1.10.184.64:3000/bldg/objectid.php?id='+data.features[0].properties.objectid_1)
                                    .then(response => response.json())
                                    .then(data => {
								 var polygon =JSON.parse(data[0].bld);

								   var mapLayer = this.map.getLayer('layersniper');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('layersniper').removeSource('sourcesniper');
                                   }

										 this.map.addSource('sourcesniper', {
                                    'type': 'geojson',
                                    'data': {
                                    'type': 'Feature',
                                    'geometry': {
                                    'type': 'Polygon',
                                    'coordinates': polygon
                                            }
                                    }
                                    });
                                    this.map.addLayer({
                                    'id': 'layersniper',
                                    'type': 'fill',
                                    'source': 'sourcesniper',
                                    'layout': {},
                                    'paint': {
                                    'fill-color': '#FF8C00',
                                    'fill-opacity': 0.5
                                    }
                                    });
                                   });
                                            });
   });




                                }
                                       fetch('http://1.10.184.64:3000/bldg/convert.php?lat='+data.x+'&lon='+data.y+'')
                                          .then(response => response.json())
                                          .then(data => {

                                           //  this.setState({ arraydatapolygon: data, loading: false });
                                                 fetch('http://1.10.184.64:8080/geoserver/ctoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ctoc%3Abldg&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom,POINT('+data.y+'%20'+data.x+'))')
                                                      .then(response => response.json())
                                                      .then(data => {





                                                       if(typeof data.features[0] !== "undefined")
                                                                        {
                                                                       var _bs_name  =data.features[0].properties.bl_name;
                                                                            var _bs_nstorey  =data.features[0].properties.bl_nstorey;
                                                                               var _bs_employ =data.features[0].properties.bl_employ;
                                                                            this.setState({bs:"", bs_name:_bs_name,bs_nstorey:_bs_nstorey,bs_employ:_bs_employ});



                                                                       }
                                                                       else
                                                                       {
                                                                             //               this.setState({dataError:true})
                                                                       }
                                                      });
                                              });



                                                 })
                                                  });




                                               fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&poi_type=413')
                                              .then(response => response.json())
                                              .then(poi_dis => {

                               if(poi_dis.x!=0 &&poi_dis.y!=0){




                                     var mapLayer = this.map.getLayer('_pointdistance');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_pointdistance').removeSource('pointdistance');
                                   }

                                   this.map.loadImage(
                                    imgmarkerdistance,
                                    (error, image) => {
                                    try { this.map.addImage('imgmarkerdistance', image); }catch{}
                                    this.map.addSource('pointdistance', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [poi_dis.x,poi_dis.y]
                                                }
                                                }
                                            ]
                                        }
                                    });
                                    this.map.addLayer({
                                            'id': '_pointdistance',
                                            'type': 'symbol',
                                            'source': 'pointdistance',
                                            'layout': {
                                            'icon-image': 'imgmarkerdistance',
                                            'icon-size': 0.1
                                                }
                                            });
                                        }
                                    );




                                         const config = {
                                              headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded'
                                              }
                                        }
                                            const qs = require('querystring')
                                          fetch('http://1.10.184.64:3000/bldg/point1000m.php?lat='+lngLT+"&&lon="+latLT+"&&m="+this.state.weaponmin)
                                          .then(response => response.json())
                                          .then(data => {
                                                    this.setState({pl:JSON.stringify(data[0].point)})
                                                     const body =
                                                    {
                                                      x : _latitude,
                                                      y:  _longitude,
                                                      pl: JSON.stringify(data[0].point),

                                                    }
                                                      axios.post('http://1.10.184.64:3000/st/index.php',qs.stringify(body), config)
                                                    .then((result) => {
                                                            var data = JSON.stringify(result);
                                                           if(data.includes("EMPTY")===true){

                                                             // this.map.flyTo({ center: [lngLT, latLT],zoom:15});
                                                           }else
                                                           {
                                                            // console.log(_longitude+"xxxxxxxxxxxxxdddddddddddd")
                                                              //this.map.flyTo({ center: [latLT,latLT],zoom:15});
                                                           }
                                                    })
                                          })





                             var directions = [];
                             var directionsData = [];

                    fetch('http://1.10.184.64:3000/route_plan/select.php?plan_id='+xid)
                                     .then(response => response.json())
                                     .then(data => {

                                      if(typeof data.routes !== "undefined")
                                                     {
                                                         var distance=  data.routes[0].distance;
                                                          var duration=  data.routes[0].duration;
                                                          this.setState({apiduration:duration,apidistance:distance})
                                                         for (var ii=0;ii<data.routes[0].legs.[0].steps.length;ii++)
                                                        {
                                                        var rows =data.routes[0].legs.[0].steps[ii].maneuver.location;
                                                           directions.push(rows);
                                                            var rowsData =data.routes[0].legs.[0].steps[ii].maneuver;


                                                           directionsData.push(typeof rowsData.modifier==='undefined'?"": rowsData.modifier+" ประเภท:"+rowsData.type+" คำแนะนำ:"+rowsData.instruction);
                                                           for (var iii=0;iii<data.routes[0].legs.[0].steps[ii].intersections.length;iii++)
                                                           {
                                                             var rows2 =data.routes[0].legs.[0].steps.[ii].intersections[iii].location;
                                                            // directions.push(rows2);
                                                           }
                                                            for (var iiii=0;iiii<data.routes[0].legs.[0].steps[ii].geometry.coordinates.length;iiii++)
                                                           {
                                                             var rowsX =data.routes[0].legs.[0].steps.[ii].geometry.coordinates[iiii];
                                                             directions.push(rowsX);

                                                           }

                                                          }



                                                        }
                                                                       else
                                                                       {
                                                                                      //      this.setState({dataError:true})
                                                                       }

                                                       this.setState({rdirections:directions});

                                                    this.setState({datainfo:directionsData});




                                          var geom ='';
                                          var _geom =[];
                                          try {

                                         geom =data.geom.replace('MULTILINESTRING','')
                                        .replaceAll(',','],[')
                                        .replace('((','[[')
                                        .replace('))',']]')
                                        .replaceAll(' ',',');




                                          }catch{ }

                                                      _geom =  JSON.parse(geom);
                                         var mpLayer = this.map.getLayer("_routedis");
                                        if (typeof mpLayer === 'undefined') {
                                            // No Layer
                                        } else {

                                            this.map.removeLayer("_routedis");
                                             this.map.removeSource("routedis");
                                        }


                                                        this.map.addSource('routedis', {
                                                                'type': 'geojson',
                                                                'data': {
                                                                'type': 'Feature',
                                                                'properties': {},
                                                                'geometry': {
                                                                'type': 'LineString',
                                                                'coordinates': _geom
                                                                }
                                                                }
                                                        });
                                                        this.map.addLayer({
                                                                    'id': '_routedis',
                                                                    'type': 'line',
                                                                    'source': 'routedis',
                                                                    'layout': {
                                                                    'line-join': 'round',
                                                                    'line-cap': 'round'
                                                                    },
                                                                    'paint': {
                                                                    'line-color': 'blue',
                                                                    'line-width': 8
                                                                    }
                                                        });
                                                         this.ClearLayer();


                                              });






                                 this.ClearLayer();

                                actionDistance="false";
                               this.setState({colorDIS:"#663333",disabledDIS:false})
                                                                       }
                                                 })


                                 var mapLayer = this.map.getLayer('_geolocation');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_geolocation').removeSource('geolocation');
                                   }
                                   this.map.loadImage(
                                    imggeolocation,
                                    (error, image) => {
                                    try { this.map.addImage('imggeolocation', image); }catch{}
                                    try{


                                        this.map.addSource('geolocation', {
                                        'type': 'geojson',
                                            'data': {
                                            'type': 'FeatureCollection',
                                            'features': [
                                                    {
                                                    'type': 'Feature',
                                                    'geometry': {
                                                    'type': 'Point',
                                                    'coordinates': [_longitude,_latitude]
                                                    }
                                                    }
                                                ]
                                            }
                                        });

                                    } catch{ }
                                    this.map.addLayer({
                                            'id': '_geolocation',
                                            'type': 'symbol',
                                            'source': 'geolocation',
                                            'layout': {
                                            'icon-image': 'imggeolocation',
                                            'icon-size': 0.1
                                                }
                                            });
                                        }
                                    );



                        this.map.on('click', function (e) {
                                         var lngLat =e.lngLat.wrap();
                                                _longitude=lngLat.lng;
                                                _latitude=lngLat.lat;

                        });
                               });


                                fetch('http://1.10.184.64:3000/poi_plan_report/index.php?plan_id='+xid)
                                        .then(response => response.json())
                                        .then(data => {
                                                  this.map.loadImage(
                                                       imggeolocation2,
                                                 (error, image) => {
                                                   this.map.addImage("imggeolocation2", image);
                                                   var _data = [];
                                                 for(var i=0;i<data.length-1;i++)
                                                 {
                                                     _data.push
                                                     ({
                                                     'type':'Feature',
                                                     'geometry':{'type':'Point','coordinates':[data[i].y,data[i].x]},
                                                     'properties':{'title':'ff'}
                                                     })
                                                 }
                                                  this.map.addSource('points', {
                                                        'type': 'geojson',
                                                        'data': {
                                                        'type': 'FeatureCollection',
                                                                    'features':  JSON.parse(JSON.stringify(_data))
                                                        }
                                                    });

                                                    this.map.addLayer({
                                                            'id': "points",
                                                            'type': 'symbol',
                                                            'source': 'points',
                                                            'layout': {
                                                            'icon-image': 'imggeolocation2',
                                                            'icon-size': 0.1
                                                                }
                                                            });
                                             });

                                                 var sdata = data.sort((a, b) => (a.id < b.id ? 1 : -1));
                                                 this.setState({open:true,poi_plan_report: data, loading: false });



                                        });
                              })


            }


                       GetCenterMap = (map,event)=> {

                                    var  latData =map.getCenter().lat;
                                    var  lngData =map.getCenter().lng;

                       }
                       GetMap = (map,event)=> {
                              if(startview!="")
                              xx =93.7521429641697;
                              yy =0.7143114151588321;
                                 {
                                     var  latData =map.getCenter().lat;
                                     var  lngData =map.getCenter().lng;
                                 }
                       }
                      find_name = (event, values) =>{

                                      this.setState({gotoname:values.bl_name});
                        }


             findblg = (event) =>{
                        fetch('http://1.10.184.64:3000/bldg/autocomplete.php?id='+event.target.value)
                                     .then(response => response.json())
                                     .then(data => {
                                      this.setState({ listblg: data, loading: false });
                                    })
                               }





           //this.setState({bbk:"1",bs:"",nm:"" });


           _Close =() =>{

                             this.setState({ststyle:"none"});
                }




        _bls = (map, event) =>{

           this.setState({bbk:"",bs:"1",nm:"",ststyle:"none"});
        }
         _Nav = () =>{

           this.setState({bbk:"",bs:"",nm:"1",ststyle:"none"});
        }






         streetviewStart =()=>
         {
          this.map.setStyle({"version": 8,
                                      "name": "OSM",
                                      "metadata": {
                                      },
                                      "sources": {
                                        "osm": {
                                          "type": "raster",
                                          "tiles": [
                                            "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                          ]
                                        }
                                      },
                                      "layers": [
                                        {
                                          "id": "osm",
                                          "type": "raster",
                                          "source": "osm"
                                        }
                                      ],
                                      "id": "klokantech-basic"
                                    });
          //  this.setState({streetviewStart:'1'})
         }

          onDragUpdate = () => {
            /*...*/
          }



        onDragStart(result) {


              startview ="1";
            // this.setState({streetviewStart:'1'});
           // the only one that is required
         };

          onDragEnd(result) {
              startview ="";
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            const items = reorder(
              this.state.items,
              result.source.index,
              result.destination.index
            );

            this.setState({
              items
            });
          }
          _nonelayer =()=>{
            if(this.state.nonelayer=="block"){

              this.setState({
              nonelayer:"none"
            });

            }

              if(this.state.nonelayer=="none"){

            this.setState({
              nonelayer:"block"
            });
            }

          }

           _nonedis =()=>{
            if(this.state.nonedis=="block"){

              this.setState({
              nonedis:"none"
            });

            }

              if(this.state.nonedis=="none"){

            this.setState({
              nonedis:"block"
            });
            }

          }
          _goToWeapon =(event)=>
          {
            var value= ""+event.target.value;


            this.setState({selectWeapon:value});
          }
          noselect =()=>
          {
              this.setState({weaponmin:'500',weaponmax:'1000'})
          }



        componentDidMount() {
			   if ("geolocation" in navigator) {

    } else {

    }

	 navigator.geolocation.getCurrentPosition((position) => {

});


            // Any information the map needs can be passed down as a prop from the parent  MMap
            var { map } = this.props;
             const {dd,oo} = this.state;
            // Store the map as a property on the class so we can call mapbox methods later
            mapboxgl.accessToken = 'pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w';
            this.map = new mapboxgl.Map({
                style: {"version": 8,
                                      "name": "OSM",
                                      "metadata": {
                                      },
                                      "sources": {
                                        "osm": {
                                          "type": "raster",
                                          "tiles": [
                                            "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                          ]
                                        }
                                      },
                                      "layers": [
                                        {
                                          "id": "osm",
                                          "type": "raster",
                                          "source": "osm"
                                        }
                                      ],
                                      "id": "klokantech-basic"
                                    },
                center: [100.523237700882,13.7065817585414],
                animate: false,
                zoom: 4,

                container: 'map',

            });



                            this.map.on('load', () => {
                            this.initMap();

                            var layers =  this.map.getStyle().layers;

                                var labelLayerId;
                                for (var i = 0; i < layers.length; i++) {
                                if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                                labelLayerId = layers[i].id;
                                break;
                                }
                                }

                                this.map.addLayer(
                                {
                                'id': '3d-buildings',
                                'source': 'composite',
                                'source-layer': 'building',
                                'filter': ['==', 'extrude', 'true'],
                                'type': 'fill-extrusion',
                                'minzoom': 15,
                                'paint': {
                                'fill-extrusion-color': '#aaa',

                                // use an 'interpolate' expression to add a smooth transition effect to the
                                // buildings as the user zooms in
                                'fill-extrusion-height': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                15,
                                0,
                                15.05,
                                ['get', 'height']
                                ],
                                'fill-extrusion-base': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                15,
                                0,
                                15.05,
                                ['get', 'min_height']
                                ],
                                'fill-extrusion-opacity': 0.6
                                }
                                },
                                labelLayerId
                                );

                            this.map.addSource('floorplan', {
                    // GeoJSON Data source used in vector tiles, documented at
                    // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
                    'type': 'geojson',
                    'data':
                    'https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson'
                    });

                    this.map.addLayer({
                                'id': 'room-extrusion',
                                'type': 'fill-extrusion',
                                'source': 'floorplan',
                                'paint': {
                                // See the Mapbox Style Specification for details on data expressions.
                                // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                // Get the fill-extrusion-color from the source 'color' property.
                                'fill-extrusion-color': ['get', 'color'],

                                // Get fill-extrusion-height from the source 'height' property.
                                'fill-extrusion-height': ['get', 'height'],

                                // Get fill-extrusion-base from the source 'base_height' property.
                                'fill-extrusion-base': ['get', 'base_height'],

                                // Make extrusions slightly opaque for see through indoor walls.
                                'fill-extrusion-opacity': 0.5
                                    }
                    });







                 });


                   this.map.on('load', function() {


                })

          navigator.geolocation.getCurrentPosition(function(position) {

                _latitude = position.coords.latitude;
                _longitude = position.coords.longitude;
            });



        }
      handleRequestClose =()=>
             {
             this.setState({open2:false,open:false,openmsg2:false,duperror:false,
              dataError:false,dataComptele:false,msgsni:false,openmsg:false
             })
             }

       _goTo = (event,x,y) => {

        if(event.type=="click"){
                                         this.map.flyTo({ center: [y, x],zoom:15});



                                             var mapLayer = this.map.getLayer('_geolocation2');
                                               if(typeof mapLayer !== 'undefined') {

                                                   this.map.removeLayer('_geolocation2').removeSource('geolocation2');
                                               }
                                               this.map.loadImage(
                                                imggeolocation2,
                                                (error, image) => {
                                                try { this.map.addImage('imggeolocation2', image); }catch{}
                                                this.map.addSource('geolocation2', {
                                                'type': 'geojson',
                                                    'data': {
                                                    'type': 'FeatureCollection',
                                                    'features': [
                                                            {
                                                            'type': 'Feature',
                                                            'geometry': {
                                                            'type': 'Point',
                                                            'coordinates': [y,x]
                                                            }
                                                            }
                                                        ]
                                                    }
                                                });
                                                this.map.addLayer({
                                                        'id': '_geolocation2',
                                                        'type': 'symbol',
                                                        'source': 'geolocation2',
                                                        'layout': {
                                                        'icon-image': 'imggeolocation2',
                                                        'icon-size': 0.1
                                                            }
                                                        });
                                                    }
                                                );

                               }
                 }



       ClearLayer =() =>{

                              this.map.moveLayer('maine500', 0);
                            this.map.moveLayer('maine1000', 0);
                            this.map.moveLayer('maine500', 0);
                            this.map.moveLayer('maine1000', 0);
                            this.map.moveLayer('_maine500', 0);
                            this.map.moveLayer('_maine1000', 0);
                            this.map.moveLayer('l_showblg', 0);
                            this.map.moveLayer('s_showblg', 0);
                            this.map.moveLayer('Location', 0);
                            this.map.moveLayer('layerLocation', 0);
                            this.map.moveLayer('_plan', 0);
                            this.map.moveLayer('plan', 0);
                            this.map.moveLayer('_plansni', 0);
                            this.map.moveLayer('plansni', 0);
                            this.map.moveLayer('layersniper', 0);
                            this.map.moveLayer('sourcesniper', 0);
                            this.map.moveLayer('layerbk', 0);
                            this.map.moveLayer('sourcebk', 0);
                            this.map.moveLayer('_pointsbk', 0);
                            this.map.moveLayer('pointsbk', 0);
                            this.map.moveLayer('pointdistance', 0);
                            this.map.moveLayer('_pointdistance', 0);
                            this.map.moveLayer('pointssniper', 0);
                            this.map.moveLayer('_pointssniper', 0);
                            this.map.moveLayer('route', 0);
                            this.map.moveLayer('_route', 0);
                            this.map.moveLayer('_pointslocation', 0);
                            this.map.moveLayer('pointslocation', 0);
                            this.map.moveLayer('routedis', 0);
                            this.map.moveLayer('_routedis', 0);
                            this.map.moveLayer('_planbk', 0);
                            this.map.moveLayer('planbk', 0);
       }

       CreatePoint = ()=> {

                      var mapLayer = this.map.getLayer('_geolocation');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_geolocation').removeSource('geolocation');
                                   }
                                   this.map.loadImage(
                                    imggeolocation,
                                    (error, image) => {
                                    try { this.map.addImage('imggeolocation', image); }catch{}
                                    this.map.addSource('geolocation', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [_longitude,_latitude]
                                                }
                                                }
                                            ]
                                        }
                                    });
                                    this.map.addLayer({
                                            'id': '_geolocation',
                                            'type': 'symbol',
                                            'source': 'geolocation',
                                            'layout': {
                                            'icon-image': 'imggeolocation',
                                            'icon-size': 0.1
                                                }
                                            });
                                        }
                                    );
                                    this.setState({latitude:_latitude,longitude:_longitude})
           }
     _bld = (event) => {
             var lngLat = event.lngLat;

                                          fetch('http://1.10.184.64:3000/bldg/point500m.php?lat='+this.state.lat+"&&lon="+this.state.lon+"&&m="+this.state.weaponmin)
                                          .then(response => response.json())
                                          .then(data => {

                                            this.setState({ point500m: '['+data[0].point+']', loading: false,mapzoom:15 });

                                                var polygon =JSON.parse(this.state.point500m);
                                         var mapLayer = this.map.getLayer('_maine500');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_maine500').removeSource('maine500');
                                   }
                                                this.map.addSource('maine500', {
                                                        'type': 'geojson',
                                                        'data': {
                                                        'type': 'Feature',
                                                        'geometry': {
                                                        'type': 'Polygon',
                                                        'coordinates': polygon
                                                }
                                                }
                                                });
                                                this.map.addLayer({
                                                        'id': '_maine500',
                                                        'type': 'fill',
                                                        'source': 'maine500',
                                                        'layout': {},
                                                        'paint': {
                                                         'fill-color': "red",
                                                        'fill-opacity': 0.3
                                                }
                                                });



                                        });

                                     fetch('http://1.10.184.64:3000/bldg/point1000m.php?lat='+this.state.lat+"&&lon="+this.state.lon+"&&m="+this.state.weaponmax)
                                          .then(response => response.json())
                                          .then(data => {

                                            this.setState({ point1000m: '['+data[0].point+']', loading: false,mapzoom:15 });

                                               var polygon =JSON.parse(this.state.point1000m);
        var mapLayer = this.map.getLayer('_maine1000');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_maine1000').removeSource('maine1000');
                                   }
                                                this.map.addSource('maine1000', {
                                                        'type': 'geojson',
                                                        'data': {
                                                        'type': 'Feature',
                                                        'geometry': {
                                                        'type': 'Polygon',
                                                        'coordinates': polygon
                                                }
                                                }
                                                });
                                                this.map.addLayer({
                                                        'id': '_maine1000',
                                                        'type': 'fill',
                                                        'source': 'maine1000',
                                                        'layout': {},
                                                        'paint': {
                                                         'fill-color': "#FFFF00",
                                                        'fill-opacity': 0.1
                                                }
                                                });
                                          });


                                  //  if( typeof lngLat !== "undefined" ) {

                                   //}
                                   var url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&cql_filter=DWithin(geom,POINT("+this.state._x1+" "+this.state._y1+"),"+this.state.weaponmax+",meters)&layers=ctoc:bldg";


                                  this.map.addLayer({
                                            id: 'raster-layer',
                                            type: 'raster',
                                            source: {
                                              type: 'raster',
                                              tiles: [url],
                                            },
                                            minzoom: 0,
                                            maxzoom: 22
                                          });
                                      this.setState({x1:this.state._x1,y1:this.state._y1});





                            this.ClearLayer();


            }

             mapc =()=>{


       }
        bmapc1 = () =>{
           this.map.setLayoutProperty('basic-map', 'visibility', 'none');
                 this.map.setLayoutProperty('dark-map', 'visibility', 'none');
                   this.map.setLayoutProperty('oms-map', 'visibility', 'none');
                    this.map.setLayoutProperty('satellite-map', 'visibility', 'none');
         var mapLayer = this.map.getLayer('oms-map');

                                           if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('dark-map').removeSource('dark-tiles');
                                   }
                                    var mapLayer = this.map.getLayer('dark-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('dark-map').removeSource('dark-tiles');
                                   }
                                   var mapLayer = this.map.getLayer('basic-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('basic-map').removeSource('basic-tiles');
                                   }
                                     var mapLayer = this.map.getLayer('satellite-map');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('satellite-map').removeSource('mapbox-satellite');
                                   }

           //this.setState({typemap:});
        }
        bmapc2 = () =>{
            this.map.setLayoutProperty('basic-map', 'visibility', 'none');
                 this.map.setLayoutProperty('dark-map', 'visibility', 'none');
                   this.map.setLayoutProperty('oms-map', 'visibility', 'none');
                    this.map.setLayoutProperty('satellite-map', 'visibility', 'none');
    var mapLayer = this.map.getLayer('dark-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('dark-map').removeSource('dark-tiles');
                                   }


                        this.map.addSource("dark-tiles", {
            "type": "raster",
            "tiles": ["https://api.mapbox.com/styles/v1/mapbox/dark-v8/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w"],
            "tileSize": 256
            })

                      this.map.addLayer({
                      "type": "raster",
                      "id": 'dark-map',
                      "source": "dark-tiles",
                      "layout": {
                          "visibility": "visible"
                        },
                       });

 this.map.moveLayer('dark-map', 0);
                        this.ClearLayer();


          //this.map.on('styledata')
           //this.setState({typemap:"mapbox://styles/mapbox/dark-v8"});
        }
         bmapc3 = () =>{

              this.map.setLayoutProperty('basic-map', 'visibility', 'none');
                 this.map.setLayoutProperty('dark-map', 'visibility', 'none');
                   this.map.setLayoutProperty('oms-map', 'visibility', 'none');
                    this.map.setLayoutProperty('satellite-map', 'visibility', 'none');
          var mapLayer = this.map.getLayer('basic-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('basic-map').removeSource('basic-tiles');
                                   }


                        this.map.addSource("basic-tiles", {
            "type": "raster",
            "tiles": ["https://api.mapbox.com/styles/v1/mapbox/streets-v8/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w"],
            "tileSize": 256
            })

                      this.map.addLayer({
                      "type": "raster",
                      "id": 'basic-map',
                      "source": "basic-tiles",
                      "layout": {
                          "visibility": "visible"
                        },
                       });
                            this.map.moveLayer('basic-map', 0);
                         this.ClearLayer();
           //this.setState({typemap:"mapbox://styles/mapbox/streets-v8"});
        }
         bmapc4 = () =>{
                 this.map.setLayoutProperty('basic-map', 'visibility', 'none');
                 this.map.setLayoutProperty('dark-map', 'visibility', 'none');
                   this.map.setLayoutProperty('oms-map', 'visibility', 'none');
                    this.map.setLayoutProperty('satellite-map', 'visibility', 'none');

         var mapLayer = this.map.getLayer('satellite-map');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('satellite-map').removeSource('mapbox-satellite');
                                   }


                      this.map.addSource("mapbox-satellite", { "type": "raster", "url": "mapbox://mapbox.satellite", "tileSize": 256 });
                      this.map.addLayer({
                      "type": "raster",
                      "id": 'satellite-map',
                      "source": "mapbox-satellite",
                      "layout": {
                          "visibility": "visible"
                        },
                       });
                        this.map.moveLayer('satellite-map', 0);
                      this.ClearLayer();

           //this.setState({typemap:"mapbox://styles/mapbox/satellite-v9"});
        }
          _3d = ()=>{

                if(this.state.open3d=="1")
                {
                  this.ClearLayer();
                    this.map.setLayoutProperty('_planbk', 'visibility', 'none');
                    this.map.setLayoutProperty('_plansni', 'visibility', 'none');
                    this.map.setLayoutProperty('_plan', 'visibility', 'none');
                    this.setState({open3d:"",_3dcolor:"#ffc107"})
                }
                else
                {
                    this.ClearLayer();
                    this.map.setLayoutProperty('_planbk', 'visibility', 'visible');
                    this.map.setLayoutProperty('_plansni', 'visibility', 'visible');
                    this.map.setLayoutProperty('_plan', 'visibility', 'visible');
                    this.setState({open3d:"1",_3dcolor:"#7E4C09"})

                }

         }
         _3d_ = ()=>{
         this.map.flyTo({ center: [-87.61694, 41.86625],zoom:15,speed: 100});


                            this.map.addSource('floorplan2', {
                    // GeoJSON Data source used in vector tiles, documented at
                    // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
                    'type': 'geojson',
                    'data':
                    'https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson'
                    });

                    this.map.addLayer({
                                'id': 'room-extrusion2',
                                'type': 'fill-extrusion',
                                'source': 'floorplan2',
                                'paint': {
                                // See the Mapbox Style Specification for details on data expressions.
                                // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                // Get the fill-extrusion-color from the source 'color' property.
                                'fill-extrusion-color': ['get', 'color'],

                                // Get fill-extrusion-height from the source 'height' property.
                                'fill-extrusion-height': ['get', 'height'],

                                // Get fill-extrusion-base from the source 'base_height' property.
                                'fill-extrusion-base': ['get', 'base_height'],

                                // Make extrusions slightly opaque for see through indoor walls.
                                'fill-extrusion-opacity': 0.5
                                    }
                    });
         }
          _3d_ = ()=>{

            mapboxgl.accessToken = 'pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w';
            this.map = new mapboxgl.Map({
                style: 'mapbox://styles/mapbox/light-v10',
                center: [-74.0066, 40.7135],
                zoom: 15.5,
                pitch: 45,
                bearing: -17.6,
                container: 'map',
                antialias: true
            });

                            this.map.on('load', () => {


                               var layers =  this.map.getStyle().layers;
                                var labelLayerId;
                                for (var i = 0; i < layers.length; i++) {
                                if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                                labelLayerId = layers[i].id;
                                break;
                                }
                                }

                                this.map.addLayer(
                                {
                                'id': '3d-buildings',
                                'source': 'composite',
                                'source-layer': 'building',
                                'filter': ['==', 'extrude', 'true'],
                                'type': 'fill-extrusion',
                                'minzoom': 15,
                                'paint': {
                                'fill-extrusion-color': '#aaa',

                                // use an 'interpolate' expression to add a smooth transition effect to the
                                // buildings as the user zooms in
                                'fill-extrusion-height': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                15,
                                0,
                                15.05,
                                ['get', 'height']
                                ],
                                'fill-extrusion-base': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                15,
                                0,
                                15.05,
                                ['get', 'min_height']
                                ],
                                'fill-extrusion-opacity': 0.6
                                }
                                },
                                labelLayerId
                                );

                            this.map.addSource('floorplan', {
                    // GeoJSON Data source used in vector tiles, documented at
                    // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
                    'type': 'geojson',
                    'data':
                    'https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson'
                    });

                    this.map.addLayer({
                                'id': 'room-extrusion',
                                'type': 'fill-extrusion',
                                'source': 'floorplan',
                                'paint': {
                                // See the Mapbox Style Specification for details on data expressions.
                                // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                // Get the fill-extrusion-color from the source 'color' property.
                                'fill-extrusion-color': ['get', 'color'],

                                // Get fill-extrusion-height from the source 'height' property.
                                'fill-extrusion-height': ['get', 'height'],

                                // Get fill-extrusion-base from the source 'base_height' property.
                                'fill-extrusion-base': ['get', 'base_height'],

                                // Make extrusions slightly opaque for see through indoor walls.
                                'fill-extrusion-opacity': 0.5
                                    }
                    });
                 });
        }
           _blbk = () =>{

               if(latLT!=0&&latLT!=0){
                     actionBK ="true";
                         this.map.on('click', function (e) {
                                         var lngLat =e.lngLat.wrap();
                                                lngBK=lngLat.lng;
                                                latBK=lngLat.lat;

                        });

                    this.setState({colorBK:"#400140",disabledBK:true})
                        }

        }

           _Sniper = () =>{
            if(latLT!=0&&latLT!=0){
                     actionSniper ="true";
                         this.map.on('click', function (e) {
                                         var lngLat =e.lngLat.wrap();
                                                lngSP=lngLat.lng;
                                                latSP=lngLat.lat;

                        });
                           this.setState({colorSNI:"bg-danger text-white",disabledSNI:false})
                        }
        }

         _Distance = () =>{
           if(latLT!=0&&latLT!=0){
                     actionDistance ="true";
                         this.map.on('click', function (e) {
                                         var lngLat =e.lngLat.wrap();
                                                lngDis=lngLat.lng;
                                                latDis=lngLat.lat;

                        });
                          this.setState({colorDIS:"#1c0707",disabledDIS:false})
                        }
        }

        _StreetOpen = () =>{
             //if(lngLT!="0" && latLT!="0"){

                    this.setState({ststyle:""});
                     actionStreetview ="true";
                         this.map.on('click', function (e) {
                                         var lngLat =e.lngLat.wrap();
                                                lngSV=lngLat.lng;
                                                latSV=lngLat.lat;

                        });
                // }
        }
         _cal = () =>{

            if(latLT!=="" && latSP !="" ){

            let pt1 = point([latLT, lngLT], { 'name': 'Kano State' })
            let pt2 = point([latSP,lngSP], { 'name': 'Lagos State' })

            var  result = distance(pt1, pt2, { units: 'metres' })
            var calresult = parseFloat(result).toFixed(2);
            this.setState({vdistance:calresult});

            }
       }



        initMap() {
            // Initialize you're map here
            // i.e. if you want to load an overlays right away, etc.
        }

        // Wrap mapbox flyTo for easy accessibility
        // Grap map from the store which holds the position that our map should be at
        setMapPosition() {
            var { map } = this.props;
            this.map.flyTo({
                center: map.center,
                zoom: map.zoom,
                pitch: map.pitch || 0,
                bearing: map.bearing || 0
            });
        }
         FormClickOpen = () => {
        this.setState({openForm: true});
      };

      FormRequestClose = () => {
        this.setState({openForm: false});
      };
       /*Formpopup*/
         maphandleChange  = (event, value) =>
         {
             this.setState({tabvalue:value})
         };
        _goToProvince = (event) => {
                      var value= ""+event.target.value;
                       this.setState({Gotoprovince:value})
                        if(value!=""){
                             this.setState({province_code: value });
                              }

                                fetch('http://1.10.184.64:3000/district/index.php?id='+value)
                                    .then(response => response.json())
                                    .then(data => {
                                    this.setState({ district: data, loading: false });
                         });

              };
        _goToDistrict = (event) => {

                           var value= ""+event.target.value;
                           this.setState({Gotodistrict:value})
                               if(value!=""){

                                 this.setState({district_code: value });
                                 }
                       fetch('http://1.10.184.64:3000/subdistrict/index.php?id='+value)
                         .then(response => response.json())
                         .then(data => {
                             this.setState({ subdistrict: data, loading: false });
                         });
              };
        _goToSubDistrict = (event) => {
                    var value= ""+event.target.value;
                   this.setState({Gotosubdistrict:value})
              };

              _name = event => {this.setState({name: event.target.value }); };
              _lname = event => {this.setState({lname: event.target.value }); };
              _p_type = event => {this.setState({p_type: event.target.value }); };
              _p_form = event => {this.setState({p_form: event.target.value }); };
              _topography = event => {this.setState({topography: event.target.value }); };
              _population = event => {this.setState({population: event.target.value }); };
              _dominance = event => {this.setState({dominance: event.target.value }); };
              _religion = event => {this.setState({religion: event.target.value }); };
              _way_land = event => {this.setState({way_land: event.target.value }); };
              _way_water = event => {this.setState({way_water: event.target.value }); };
             /// _img_build = event => {this.setState({img_build: event.target.value }); };
            //  _img_in = event => {this.setState({img_in: event.target.value }); };
            //  _img_out = event => {this.setState({img_out: event.target.value }); };
              _plan_status = event => {this.setState({plan_status: event.target.value }); };
                _img_buildx = (e) => {

                        if (e.target.files && e.target.files[0]) {
                          let img = e.target.files[0];
                          this.setState({
                            image: URL.createObjectURL(img)
                          });

                        }
                };

            _img_buildxx = (e) => {

                // '/files' is your node.js route that triggers our middleware
                axios.post('http://1.10.184.64:3000/upload/upload_file.php', e.target.files).then((response) => {

                });
             }
                 _upload_bldg(event) {
                    this.setState({
                        selectedFile_bldg: event.target.files[0],
                      })
                }

                upload_bldg() {

                        const data = new FormData()
                        data.append('file', this.state.selectedFile_bldg)

                        let url = "http://1.10.184.64:3000/uploads/bldg.php?id="+this.state.name;
                        axios.post(url, data, { // receive two parameter endpoint url ,form data
                        })
                        .then(res => { // then print response status

                        })

                       this.setState({img_build: 'uploads/file/bldg/'+this.state.name+''});



              }
               _upload_enter(event) {
                    this.setState({
                        selectedFile_enter: event.target.files[0],
                      })
                }

              upload_enter() {

                        const data = new FormData()
                        data.append('file', this.state.selectedFile_enter)

                        let url = "http://1.10.184.64:3000/uploads/enter.php?id="+this.state.name;
                        axios.post(url, data, { // receive two parameter endpoint url ,form data
                        })
                        .then(res => { // then print response status

                        })
                         this.setState({img_in: 'uploads/file/enter/'+this.state.name+''});


              }
                _upload_exit(event) {
                    this.setState({
                        selectedFile_exit: event.target.files[0],
                      })
                }
              upload_exit() {

                        const data = new FormData()
                        data.append('file', this.state.selectedFile_exit)

                        let url = "http://1.10.184.64:3000/uploads/exit.php?id="+this.state.name;
                        axios.post(url, data, { // receive two parameter endpoint url ,form data
                        })
                        .then(res => { // then print response status

                        })
                        this.setState({img_out: 'uploads/file/exit/'+this.state.name+''});
              }
              _close_icon = () =>{
         this.setState({close_icon:"none"})
        }

     Save = event => {
                          this.upload_bldg()
                          this.upload_enter()
                          this.upload_exit()

                            event.preventDefault();
                            var location = window.location.href;
                                const str = '' + location;
                                const xid = str.split("=")[1];
                            const requestBody =
                            {
                              id : this.state._xid,
                              geom: this.state.lon+","+this.state.lat,
                              name: this.state.name,
                              lname: this.state.lname,
                              p_type: this.state.p_type,
                              p_form: this.state.p_form,
                              topography: this.state.topography,
                              population: this.state.population,
                              dominance: this.state.dominance,
                              religion: this.state.religion,
                              way_land: this.state.way_land,
                              way_water: this.state.way_water,
                              way_water: this.state.way_water,
                              img_build: this.state.img_build,
                              img_in: this.state.img_in,
                              img_out: this.state.img_out,
                              province_code: this.state.province_code,
                              district_code: this.state.district_code,
                              sub_district_code: this.state.sub_district_code,
                              plan_status: this.state.plan_status,
                              w_id:this.state.w_id
                            }


              if(this.state.id==null)
              {
              const config = {
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                          }
                  }

                  if(this.state.lon==0&&this.state.name==null){

                  this.setState({open2: true});
                  }
               var check ="";
                   // if(this.state.lon!=0&&this.state.name!=null){
                const qs = require('querystring')



                axios.post('http://1.10.184.64:3000/actionplan/updateall.php',qs.stringify(requestBody), config)
                .then((result) => {

                  const name = ""+qs.stringify(result);
                  const splitName = name.split("failed");
                  const length = splitName.length;




                                if(length!=1){
                                 this.setState({duperror: true});
                                }
                                })

                                this.FormRequestClose()



                            this.setState({dataComptele:true});



                            //}
                  }
    }
    _SaveBK = event =>
    {
                         event.preventDefault();
                            const requestBody =
                            {
                              id : this.state.id,
                              geom: this.state._lngBK+" "+this.state._latBK,
                              name: this.state.bl_name,
                              poi_type:'404',
                              plan_id: this.state.idplen,
                              bl_nstorey: this.state.bl_nstorey,
                              bl_employ: this.state.bl_employ

                            }
                          const config = {
                                              headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded'
                                              }
                                        }

                   const qs = require('querystring')
                   axios.post('http://1.10.184.64:3000/poi_plan/update.php',qs.stringify(requestBody), config)
                   .then((result) => {

                    })

    }
    _SaveSNI = event =>
    {
                         event.preventDefault();
                            const requestBody =
                            {
                              id : this.state.id,
                              geom: lngSP+" "+latSP,
                              name: this.state.bs_name,
                              poi_type:'405',
                              plan_id: this.state.idplen,
                              bl_nstorey: this.state.bs_nstorey,
                              bl_employ: this.state.bs_employ

                            }
                          const config = {
                                              headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded'
                                              }
                                        }

                           const qs = require('querystring')
                           axios.post('http://1.10.184.64:3000/poi_plan/update.php',qs.stringify(requestBody), config)
                           .then((result) => {

                            })

    }
      _SaveNVG = event =>
    {
                         event.preventDefault();
                            const requestBody =
                            {
                              id : this.state.id,
                              geom: lngDis+" "+latDis,
                              name: this.state.bs_name,
                              poi_type:'412',
                              plan_id: this.state.idplen,
                              bl_nstorey: this.state.bs_nstorey,
                              bl_employ: this.state.bs_employ

                            }
                          const config = {
                                              headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded'
                                              }
                                        }

                           const qs = require('querystring')
                           axios.post('http://1.10.184.64:3000/poi_plan/update.php',qs.stringify(requestBody), config)
                           .then((result) => {

                            })

    }
        _weapon = event =>
        {                      event.preventDefault();
                                const requestBody =
                                {
                                  id : this.state.idplen,
                                  w_id: this.state.selectWeapon,
                                }
                              const config = {
                                                  headers: {
                                                    'Content-Type': 'application/x-www-form-urlencoded'
                                                  }
                                            }
                               const qs = require('querystring')
                               axios.post('http://1.10.184.64:3000/actionplan/update.php',qs.stringify(requestBody), config)
                               .then((result) => {

                                })
        }

         _text_rpt = (event, values) =>{  this.setState({text_rpt:event.target.value}); }
         _save_rpt =()=>
         {
                  this.setState({latitude:_latitude,longitude:_longitude})

                           const config = {
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                                    }
                            }
                            const qs = require('querystring')


                               var location = window.location.href;
                                const str = '' + location;
                                const xid = str.split("=")[1];
                                var   userid =cookie.load('userId');

                          const   Body =
                            {
                              id : this.state.id,
                              geom: _latitude+","+_longitude,
                              user_id: userid,
                              dt_rpt:'',
                              text_rpt: this.state.text_rpt,
                              plan_id: this.state._xid

                            }
                           axios.post('http://1.10.184.64:3000/poi_plan_report/insert.php',qs.stringify(Body), config)
                           .then((result) => {
                               this.setState({dataComptele:true});

                                     fetch('http://1.10.184.64:3000/poi_plan_report/index.php?plan_id='+this.state._xid)
                                        .then(response => response.json())
                                        .then(data => {

                                            var sdata = data.sort((a, b) => (a.id < b.id ? 1 : -1));
                                                 this.setState({ poi_plan_report: sdata, loading: false });


                                        });
                            });
         }
               _los =()=>{
                 //  console.log("http://1.10.184.64:3000/map/los.php?lat1="+this.state.maplat+"&lng1="+this.state.maplng+"&lat2="+this.state._lngSP+"&lng2="+this.state._latSP+"");
                      this.setState({_srclos:"http://1.10.184.64:3000/map/los.php?lat1="+this.state.maplat+"&lng1="+this.state.maplng+"&lat2="+this.state._lngSP+"&lng2="+this.state._latSP+""})
                      this.setState({ststylelos:""})
                 }
          _Closelos =() =>{
                      this.setState({ststylelos:"none"});
                }
        render() {

         const {classes, theme} = this.props;

       const {
       text_rpt,poi_plan_report,close_icon,
       colorDIS,disabledDIS,
       _SVy,_SVx,ststyle,listblg,stmarkerlng,stmarkerlat,
       films,typemap,nmarkerlng,selectWeapon,
       nmarkerlat,rdirections,
       lbl_name,lbl_nstorey,vdistance,
       bs_nstorey,markerlng,markerlat,
       smarkerlng,smarkerlat,bl_name,
       bs_name,bl_nstorey,bl_employ,_3dcolor,
       mapzoom,maplng,maplat,datapolygon,nonelayer,nonedis,
       arraydatapolygon,arraypolygon1000m,weaponmin,weaponmax,
       point500m,point1000m,lon,lat,x1,y1,weapon,datainfo,apiduration,apidistance,
       /*Formpopup*/
       province,
       district,
       subdistrict,
       tabvalue,
       ststylelos,
       Gotoprovince,
       colorSNI,
       bs_employ,
       Gotodistrict,
       Gotosubdistrict,
       lbl_employ,
       name
       /*Formpopup*/
       } = this.state;



       let _poi_plan_report = this.state.poi_plan_report;
        let poi_plan_reportItems = poi_plan_report.map((_poi_plan_report) =>

           <div   key={_poi_plan_report.id} >


                <div className="row ml-3" style={{color:'#fff'}}>
                         <div className="col-md-4 col-12  p-0 m-0">
                                   <div className="row" >
                                    <div>
                                    <Avatar
                                               alt="http://via.placeholder.com/150x150"
                                                      src="http://via.placeholder.com/150x150"
                                              className="user-avatar"
                                            />
                                    </div>
                                        <div  style={{color:'#fff'}} onClick={(e)=> this._goTo(e,_poi_plan_report.x,_poi_plan_report.y)}>

                                       {_poi_plan_report.fname}  <spen className="ml-1">
                                       <i class="zmdi zmdi-pin zmdi-hc-2x ml-2" ></i>
                                       </spen>
                             </div>
                                   </div>


                        </div>

                        <div className="col-md-8 col-12  p-0 m-0 ml-1 ">
                              <div className="row" >


                             </div>
                              <div className="row" style={{color:'#fff'}}>

                                         {_poi_plan_report.text_rpt}
                             </div>
                              <div className="row" style={{color:'#fff'}}>

                                   {
                                    _poi_plan_report.dt_rpt==null?'':
                                    _poi_plan_report.dt_rpt.substring(8,10)+'-'+
                                    _poi_plan_report.dt_rpt.substring(5,7)+'-'+
                                    _poi_plan_report.dt_rpt.substring(0,4)+' '+
                                     _poi_plan_report.dt_rpt.substring(11,19)
                                    }
                             </div>



                        </div>
                </div>





                  <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
           </div>

        );




            return (




          <div style={{backgroundColor: 'rgb(181 63 63 / 0%)'}} className={classes.root}>




            <div className={classes.appFrame}  >

              <AppBar
               className={classNames('rgb(181 63 63 / 0%)', classes.appBar, this.state.open && classes.appBarShift)}
                  style={{backgroundColor: 'rgb(181 63 63 / 0%)',
                    boxShadow:'0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%)'
                  }}

              >

                <Toolbar disableGutters={!this.state.open}  style={{backgroundColor: 'rgb(181 63 63 / 0%)'}}>
                <div className='m-2  p-0 ' style={{backgroundColor: '#3f51b5', top: 70, position: 'fixed' }}>
                  <IconButton

                    aria-label="open drawer"
                    onClick={this.handleDrawerOpen}
                    style={{backgroundColor: '#3f51b5',margin:0}}
                    className={classNames(classes.menuButton, this.state.open && classes.hide)}
                    >

                    <MenuIcon className="text-white"/>
                  </IconButton>

              </div>


                </Toolbar>

                <div
                style={{

                position: 'fixed',
                top: 80,
                right: 10,
                }} >
                     <div  onClick={this._nonelayer} className="bg-blue text-white p-2"   >
                         <LayersIcon />
                    </div>


                </div>



                 <div
                style={{

                position: 'fixed',
                top: 130,
                right: 10,

                 display: nonelayer,
                backgroundColor:'#3f51b5',
                borderRadius: '5px'

                }} >

                 <RadioGroup  >
                <ul className="nav-menu">
                       <li className="menu no-arrow ml-1" >
                         <FormControlLabel
                            onClick={this.bmapc1}
                            value="Streetmap"
                            control={<Radio/>}
                            label="แผนที่ OSM"/>
                      </li>
               </ul>
               <ul className="nav-menu">
                       <li className="menu no-arrow ml-1" >
                         <FormControlLabel
                             onClick={this.bmapc2}
                              value="Dark"
                              control={<Radio/>}
                              label="แผนที่แบบมืด"
                         />
                      </li>
               </ul>

                <ul className="nav-menu">
                       <li className="menu no-arrow ml-1" >
                         <FormControlLabel
                         onClick={this.bmapc3}
                          value="Streets"
                          control={<Radio/>}
                          label="แผนที่ Mapbox"
                        />
                      </li>
               </ul>
               <ul className="nav-menu">
                       <li className="menu no-arrow ml-1">
                        <FormControlLabel
                         onClick={this.bmapc4}
                          value="Satellite"
                          control={<Radio/>}
                          label="ภาพถ่ายดาวเทียม"
                        />
                      </li>
               </ul>
                </RadioGroup >
                </div>

                <div
                style={{
                 display:close_icon,
                //width:'20%',
              ///  marginLeft:'calc(100% - 700px)',
                backgroundColor:"#000",
                position: 'fixed',
                top: 80,
                right: 80,
                }} >

                 <div className="m-2"     >

                  {window.innerWidth<800?
                   <div    style={{color:'#fff',width:200 }}   >
                     <div style={{marginLeft:180}} onClick={this._close_icon} >X</div>

                             <div>   <HomeWorkIcon />: {lbl_name}   </div>
                        <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                          <div>   <ImportantDevicesIcon /> :{bl_name}   </div>
                          <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                          <div>  <MyLocationIcon /> :{bs_name} </div>
                   </div>:
                   <div    style={{color:'#fff'}} >
                       <HomeWorkIcon />: {lbl_name} | <ImportantDevicesIcon /> :{bl_name} | <MyLocationIcon /> :{bs_name}
                   </div>
                   }
                  </div>
                </div>

              </AppBar>

              <Drawer
                variant="persistent"
                classes={{
                  paper: classes.drawerPaper,
                }}
                open={this.state.open}
              >
                 <CustomScrollbars className="module-side-scroll scrollbar"
                              style={{backgroundColor: '#030852',height: this.props.width >= 1200 ? 'calc(100vh - 70px)' : 'calc(100vh - 70px)'}}>

                <div className={classes.drawerInner} style={{color:'#fff' ,white:'300px'}} >
                  <div className={classes.drawerHeader} style={{color:'#fff'}} >
                    <IconButton onClick={this.handleDrawerClose} style={{color:'#fff'}} >
                      {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                  </div>
                  <Divider/>


              <div className="ml-3 mt-0">
                <div><HomeWorkIcon /> : {lbl_name} </div>
                <div className="mb-1">ชั้น   : {lbl_nstorey}  <spen className="mb-1">ความจุ  : {lbl_employ}   </spen></div>

                        <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                          <div >   <ImportantDevicesIcon /> :{bl_name}   </div>
                           <div className="mb-1">ชั้น   : {bl_nstorey}  <spen className="mb-1">ความจุ  : {bl_employ}   </spen></div>

                          <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                          <div>  <MyLocationIcon /> :{bs_name} </div>
                           <div className="mb-1">ชั้น   : {bs_nstorey}   <spen className="mb-1">ความจุ  : {bs_employ}   </spen></div>
                            <div className="mb-1">
                            <Button   onClick={this._3d}
                      style={{backgroundColor:_3dcolor}}
                     className="text-white"   >
                       3D
                    </Button>
                    <Button   onClick={this._los}
                             className="text-white ml-2" style={{backgroundColor:colorSNI}} >
                                LOS
                        </Button>
                            </div>
               </div>
               <div className="m-4">
                 {poi_plan_reportItems}
               </div>
           <br/>

              <hr class="MuiDivider-root"/>


             <Divider/>
                    </div>
                     </CustomScrollbars>
                        </Drawer>

              <main className={classNames(classes.content, this.state.open && classes.contentShift)}
                          style={{ padding: '0px',marginTop:-10}}>
                <div state={{backgroundColor:'red'}}>
                <div id='map' style={{height:'100%',width:'100%',top: 0,left:0,right:0,position:'fixed'}}
                ///className={"MuiPaper-root  MuiAppBar-positionFixed"}
               >

             </div>


                <div
                style={{
                display:ststyle,
                position: 'fixed',
                bottom: 30,
                right: 10,
                height:'320px',
                width:'320px',
               border: '3px solid white',
               backgroundColor:'white'
                }} >

                    <div className="bg-white" onClick={this._Close}>X</div>
                       {/* <MyMapComponent
                              isMarkerShown
                              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCeuN-U26oP2_XLQ6RuWUjHIoeNiAYoLWA"
                              loadingElement={<div style={{ height: `100%` }} />}
                              containerElement={<div style={{ height: `200px` }} />}
                              mapElement={<div style={{ height: `100%` }} />}

                            >

                        </MyMapComponent>*/}
                    </div>
                </div>

                <div
                                        style={{
                                        display:ststylelos,
                                        position: 'fixed',
                                        bottom: 30,
                                        right: 10,
                                        height:'200px',
                                        width:'45%',
                                         backgroundColor:'#030852',
                                        }} >
                                        <div className="bg-geekblue m-1" >
                                               <button  onClick={this._Closelos}
                                               className="MuiButtonBase-root MuiButton-text text-white bg-red p-1 m-0" >
                                                    <spen className="m-0 p-0">X</spen>
                                                </button>
                                                    </div>
                                                          <iframe frameBorder="0"
                                                              style={{ width:'100%'}}
                                                              src={this.state._srclos}
                                                            />
                            </div>
            </main>

            <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.openmsg}
            autoHideDuration={1000}
              onClose={() => this.setState({openmsg: false})}
            message="รอข้อมูล"
              action={[

            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />
          	   <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.dataComptele}
            autoHideDuration={1000}
              onClose={() => this.setState({dataComptele: false})}
            message="บันทึกสำเร็จ"
              action={[

            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />

           <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.dataError}
            autoHideDuration={1000}
              onClose={() => this.setState({dataError: false})}
            message="ไม่พบข้อมูล"
              action={[

            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.duperror}
            autoHideDuration={1000}
              onClose={() => this.setState({duperror: false})}
            message="มีข้อมูลสถานที่นี้แล้วในระบบ"
              action={[

            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.openmsg2}
                  onClose={() => this.setState({openmsg2: false})}
            autoHideDuration={1000}
            message="แสดงข้อมูล"
              action={[

            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />
           {/*Formpopup*/}

          </div>
          </div>

            );
        }
    }
    index.propTypes = {
      classes: PropTypes.object.isRequired,
      theme: PropTypes.object.isRequired,
    };
    export default withStyles(styles, {withTheme: true})(index);