import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import axios from "axios";
import { NavLink, withRouter,useHistory } from 'react-router-dom';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { TextField, InputAdornment } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import cookie from 'react-cookies';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}
  var url = 'http://1.10.184.64:3000';
 var urlupload = 'http://1.10.184.64:3000';
const columnData = [
  {id: 'name', align: true, disablePadding: false, label: 'ชื่อ'},
  {id: 'path', align: true, disablePadding: false, label: 'ไฟล์แนบ'},
  {id: 'createby', align: true, disablePadding: false, label: 'ผูใช้สร้าง'},
  {id: 'dept', align: true, disablePadding: false, label: 'ส่วน'},
  {id: 'dunit', align: true, disablePadding: false, label: 'หน่ยวงาน'},
  {id: 'createdate', align: true, disablePadding: false, label: 'วันที่สร้าง'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 5,
      id:'',
      name:'',
      type:0,
      path:'',
      dept:'',
      dunit:'',
      dunits:[],
      depts:[],
      vdunits:[],
      users:[],
      lastname:'',
      a_id:0,
      year:2564,
      month:1,
      c_by:'',
      c_dt:'',
      path:'',
      agency:[],
      openmsg:false,
      open1:false
    };



     this._upload = this._upload.bind(this);

      var location = window.location.href;
          const str = '' + location;
          const id = str.split("=")[1];

           fetch(url+'/army_file/find.php?id='+id)
                .then(response => response.json())
                .then(data => {

                      this.setState({
                      id:data[0].id,
                      a_id:data[0].a_id,
                      year:data[0].year,
                      month:data[0].month,
                      type:data[0].type,
                      path:data[0].path,
                      });

                 });

     fetch(url+'/basemaster/select.php')
            .then(response => response.json())
           .then(data => {
             var data = data.filter(x=>x.type==2)
             this.setState({ data: data });
          });


          fetch('http://1.10.184.64:3000/agency/select.php')
                                     .then(response => response.json())
                                     .then(_data => {
                                       var data ={id:0,code:'',name:"เลือก"}
                                       _data.push(data)
                                       var data  = _data.sort((a, b) => (a.id > b.id ? 1 : -1))
                                      this.setState({agency:data});
                        });

  }
    submit =()=>
    {
      if(this.state.id=="")
      {
        console.log(this.state.path+"xxx");
            if(this.state.path=="")
            {

                this.setState({open1:true})
            }
            else
            {
            console.log(this.state.type+"_"+this.state.a_id);
              if(this.state.type==2 && this.state.a_id==0)
              {
                      this.setState({open1:true})
              }else
                    {


                      this.upload()
                       const qs = require('querystring')
                       const config =
                       {
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                }
                       }
                        var id_ =parseInt(cookie.load('userId'));




                          const requestBody =
                            {
                                a_id: this.state.a_id,
                                year: this.state.year,
                                month: this.state.month,
                                type:this.state.type,
                                c_by:id_,
                                c_dt: this.state.c_dt,
                                path: this.state.path+"."+this.state.lastname,
                            }
                             axios.post(url+'/army_file/insert.php', qs.stringify(requestBody), config)
                                .then((result) =>
                                {

                                      this.props.history.push("/app/perpared/mfile/Index");
                                });


                    }
            }
        }
        else
        {
               this.upload()
               console.log(this.state.name+"_D"+this.state.name)

               const qs = require('querystring')
               const config =
               {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
               }
                var id_ =parseInt(cookie.load('userId'));
                  const requestBody =
                    {
                        id:    this.state.id,
                        a_id:  this.state.a_id,
                        year:  this.state.year,
                        month: this.state.month,
                        type:  this.state.type,
                        path:  this.state.name==''?this.state.path: this.state.path+"."+this.state.lastname,
                    }
                     axios.post(url+'/army_file/update.php', qs.stringify(requestBody), config)
                        .then((result) =>
                        {
                             this.setState({openmsg:true})
                        });

        }

    }
  _name =(event) =>
   {
      this.setState({name:event.target.value })
   }
    _dunit =(event) =>
   {
      this.setState({dunit:event.target.value })
   }
   _upload(event) {

      var subpath =event.target.value.split('\\').length;
      var name =event.target.value.split('\\')[subpath-1].split('.')[0];

        this.setState({
            name:name,
            selectedFile: event.target.files[0],
            path:'uploads/file/mfile/'+name,
            lastname:event.target.value.split('.')[1]
        })

    }

    upload()
    {

        const data = new FormData()
        data.append('file', this.state.selectedFile)
        let url = urlupload+"/uploads/mfile.php?id="+this.state.name;
        axios.post(url, data,
             {
                 //this.setState({ path:'uploads/file/master/'+this.state.name+''});
             })
            .then(res =>
            {

            })
                //this.setState({ path: 'uploads/file/master/' + this.state.name + '' });

    }


    select_dept = (event)=>
    {
                   var vdunits = this.state.dunits.filter(x=>x.p_id==event.target.value);
                   this.setState({vdunits :vdunits,dept:event.target.value})
    }

  render() {
            const {data, order, orderBy, selected, rowsPerPage, page} = this.state;



              let agencys = this.state.agency;
                    let agencyItems = agencys.map((agency) =>
                       <MenuItem selected="{this.state.dunit}"
                         key={agency.id} value={agency.id}>
                       <span className="ml-2"> {agency.name} </span></MenuItem>
                );
    return (
    <div>
    <div class="page-heading d-sm-flex justify-content-sm-between align-items-sm mb-0 p-2  bg-geekblue ">
          <h5 class="title p-1 mb-sm-0"></h5>
              <nav class="mb-0" aria-label="breadcrumb">
                      <ol class="breadcrumb">
                                    <div class="breadcrumb-item">
                                        <div   style={{color: 'gray', textDecoration: 'none'}}  >
                                         การเตรียมพร้อมรองรับสถานการณ์
                                        </div>
                                        <div className="pl-1 pr-1"> > </div>
                                        <div   style={{color: '', textDecoration: 'none'}}  >
                                         การเตรียมพร้อม
                                        </div>
                                        <div className="pl-1 pr-1"> > </div>
                                        <div   style={{color: '', textDecoration: 'none'}}  >
                                         จัดการไฟล์แนบ
                                        </div>
                                         <div className="pl-1 pr-1"> > </div>
                                        <div   style={{color: 'white', textDecoration: 'none'}}  >
                                          {this.state.id==''?"เพิ่ม":"แก้ไข"}
                                        </div>
                                     </div>
                      </ol>
              </nav>
    </div>
    <div className="p-2 pt-3" >
    <ProfileHeader/>

    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                       <label className="ml-4 mt-4 text-white"><h3></h3></label>
                            <div className="row ml-3 mr-3 mb-3" style={{color:'#fff'}}>

                                 <div className="col-md-6 col-12 mb-3">
                                   <label className="">ประเภท</label><spen className="text-red"> *</spen>
                                        <div className="bg-primary">
                                            <TextField
                                                                        select
                                                                        required
                                                                        id="user_no"
                                                                        value={this.state.type}
                                                                        onChange={(event)=> this.setState({type: event.target.value})}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                            InputProps={{
                                                                                style: {
                                                                                    height: "40px"
                                                                                }
                                                                            }}
                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">

                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {

                                                                                    height: "40px"
                                                                                     ,width: "100%"
                                                                                     ,color:"#fff"

                                                                                }
                                                                            }}

                                                                    >
                                                     <MenuItem value={0}>เลือก</MenuItem>
                                                     <MenuItem value={1}>คำสั่งเวรประจำเดือน</MenuItem>
                                                     <MenuItem value={2}>กำลังพลประจำเดือน</MenuItem>
                                            </TextField>
                                       </div>
                                 </div>

                                {this.state.type==2?
                                <div className="col-md-6 col-12 mb-3">
                                   <label className="">หน่ยวงาน</label><spen className="text-red"> *</spen>
                                        <div className="bg-primary">
                                            <TextField
                                                                        select
                                                                        required
                                                                        id="user_no"
                                                                        value={this.state.a_id}
                                                                        onChange={(event)=> this.setState({a_id: event.target.value})}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                            InputProps={{
                                                                                style: {
                                                                                    height: "40px"
                                                                                }
                                                                            }}
                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">

                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {

                                                                                    height: "40px"
                                                                                     ,width: "100%"
                                                                                     ,color:"#fff"

                                                                                }
                                                                            }}

                                                                    >
                                                                  {agencyItems}
                                            </TextField>
                                       </div>
                                </div>
                                :
                                <div className="col-md-6 col-12 mb-3"></div>}
                                <div className="col-md-6 col-12">
                                   <label className="">ปี</label><spen className="text-red"> *</spen>
                                       <div className="bg-primary">
                                            <TextField
                                                                        select
                                                                        required
                                                                        id="user_no"
                                                                        value={this.state.year}
                                                                        onChange={(event)=> this.setState({year: event.target.value})}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                            InputProps={{
                                                                                style: {
                                                                                    height: "40px"
                                                                                }
                                                                            }}
                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">

                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {

                                                                                    height: "40px"
                                                                                     ,width: "100%"
                                                                                     ,color:"#fff"

                                                                                }
                                                                            }}
                                                                    >

                                                     <MenuItem value={2564}>2564</MenuItem>
                                                     <MenuItem value={2565}>2565</MenuItem>
                                                     <MenuItem value={2566}>2565</MenuItem>
                                                     <MenuItem value={2567}>2567</MenuItem>
                                                     <MenuItem value={2568}>2568</MenuItem>
                                                     <MenuItem value={2569}>2569</MenuItem>
                                                     <MenuItem value={2570}>2570</MenuItem>
                                                     <MenuItem value={2571}>2571</MenuItem>
                                                     <MenuItem value={2572}>2572</MenuItem>
                                                     <MenuItem value={2573}>2573</MenuItem>
                                                     <MenuItem value={2574}>2574</MenuItem>
                                                     <MenuItem value={2575}>2575</MenuItem>
                                            </TextField>
                                       </div>
                                 </div>
                                 <div className="col-md-6 col-12">
                                   <label className="">เดือน</label><spen className="text-red"> *</spen>
                                       <div className="bg-primary">
                                            <TextField
                                                                        select
                                                                        required
                                                                        id="user_no"
                                                                        value={this.state.month}
                                                                        onChange={(event)=> this.setState({month: event.target.value})}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                            InputProps={{
                                                                                style: {
                                                                                    height: "40px"
                                                                                }
                                                                            }}
                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">

                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {

                                                                                    height: "40px"
                                                                                     ,width: "100%"
                                                                                     ,color:"#fff"

                                                                                }
                                                                            }}

                                                                    >
                                                     <MenuItem value={1}>ม.ค.</MenuItem>
                                                     <MenuItem value={2}>ก.พ.</MenuItem>
                                                     <MenuItem value={3}>มี.ค.</MenuItem>
                                                     <MenuItem value={4}>เม.ย.</MenuItem>
                                                     <MenuItem value={5}>พ.ค.</MenuItem>
                                                     <MenuItem value={6}>มิ.ย.</MenuItem>
                                                     <MenuItem value={7}>ก.ค.</MenuItem>
                                                     <MenuItem value={8}>ส.ค.</MenuItem>
                                                     <MenuItem value={9}>ก.ย.</MenuItem>
                                                     <MenuItem value={10}>ต.ค.</MenuItem>
                                                     <MenuItem value={11}>พ.ย.</MenuItem>
                                                     <MenuItem value={12}>ธ.ค.</MenuItem>
                                            </TextField>
                                       </div>
                                 </div>

                                 <div className="col-md-6 col-12 mt-3">
                                   <label>ไฟล์แนบที่เกี่ยวข้อง</label> <spen className="text-red"> *</spen>
                                       <div >
                                            <TextField
                                                            type="file"
                                                            accept="image/*"
                                                            id="raised-button-file"
                                                            multiple
                                                            onChange={this._upload}
                                                            required
                                                            id="fname"
                                                            defaultValue=""
                                                            margin="normal"
                                                            fullWidth
                                                            variant="outlined"
                                                            InputProps={{
                                                                style: {
                                                                    height: "40px"
                                                                }
                                                            }}
                                                            style={{ margin: 0 }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start" >
                                                                    </InputAdornment>
                                                                ),
                                                                style: {
                                                                    height: "40px"
                                                                    , color: "#fff"
                                                                }
                                                            }}
                                                        />
                                       </div>
                                 </div>
                            </div>

                   <div className="row m-3" style={{color:'#fff'}}>
                     <Button   className="bg-blue text-white mt-0 ml-3" onClick={()=>this.submit()}>
                          <SaveIcon/>
                          <span className="ml-1">
                                บันทึก
                          </span>
                    </Button>
                 </div>
                 </div>


   </div>
 </div>
 </div>
 </div>
      </div>
      <Dialog fullWidth={true}
                        maxWidth={'xs'} open={this.state.open1} onClose={() => this.setState({open1: false})}>
                        <DialogContent className="bg-geekblue text-white"  >
                            ข้อมูลไม่ครบ.
                        <hr />
                        </DialogContent>
                        <DialogActions className="bg-geekblue">
                            <Button onClick={() => this.setState({open1: false})} className="bg-success text-white">
                                    <CheckCircleIcon /> ตกลง
                            </Button>
                        </DialogActions>
      </Dialog>
      <Snackbar
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        open={this.state.openmsg}
                        autoHideDuration={1000}
                          onClose={() => this.setState({openmsg: false})}
                        message="บันทึกสำเร็จ"
                         action={[
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={() => this.setState({openmsg: false})}
                        >
                          <CloseIcon/>
                        </IconButton>,
                      ]}
                      />
      </div>
    );
  }
}

export default index;