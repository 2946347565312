import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import axios from "axios";
import { NavLink, withRouter,useHistory } from 'react-router-dom';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { TextField, InputAdornment } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import cookie from 'react-cookies';
let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}
 var url = 'http://1.10.184.64:3000';
 var urlupload = 'http://1.10.184.64:3000';
const columnData = [
  {id: 'name', align: true, disablePadding: false, label: 'ชื่อ'},
  {id: 'path', align: true, disablePadding: false, label: 'ไฟล์แนบ'},
  {id: 'createby', align: true, disablePadding: false, label: 'ผูใช้สร้าง'},
  {id: 'dept', align: true, disablePadding: false, label: 'ส่วน'},
  {id: 'dunit', align: true, disablePadding: false, label: 'หน่ยวงาน'},
  {id: 'createdate', align: true, disablePadding: false, label: 'วันที่สร้าง'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 5,
      id:'',
      name:'',
      type:'',
      path:'',
      dept:'',
      dunit:'',
      dunits:[],
      depts:[],
      vdunits:[],
      users:[],
      lastname:''
    };
     this._upload = this._upload.bind(this);

      var location = window.location.href;
          const str = '' + location;
          const id = str.split("=")[1];

           fetch(url+'/basemaster/find.php?id='+id)
                .then(response => response.json())
                .then(data => {
                       //console.log(data[0].period + "dataid");
                      this.setState({
                      id:data[0].id,
                      name:data[0].name,
                      type:data[0].type,
                      path:data[0].path,
                      dept:data[0].dept,
                      dunit:data[0].dunit
                      });
                        var dept = data[0].dept;
                        fetch(url+'/users/dunit.php')
                                     .then(response => response.json())
                                     .then(data => {
                                     data.push({id:0,name:'เลือกรายการ'})
                                     var vdunits = data.filter(x=>x.p_id==dept);
                                      this.setState({vdunits:vdunits});
                            });
                 });

     fetch(url+'/basemaster/select.php')
            .then(response => response.json())
           .then(data => {
             var data = data.filter(x=>x.type==8&&x.flat=="0")
             this.setState({ data: data });
          });
      fetch(url+'/users/dunit.php')
                                     .then(response => response.json())
                                     .then(data => {
                                     data.push({id:0,name:'เลือกรายการ'})
                                      this.setState({dunits:data});
          });

      fetch(url+'/users/dept.php')
                                     .then(response => response.json())
                                     .then(data => {
                                     data.push({id:0,name:'เลือกรายการ'})
                                           this.setState({depts:data});
          });
      fetch(url+'/users/index.php')
                                     .then(response => response.json())
                                     .then(data => {
                                     data.push({id:0,name:'เลือกรายการ'})
                                           this.setState({users:data});
        });
  }
    submit =()=>
    {
      if(this.state.id==""){
               this.upload()
               const qs = require('querystring')
               const config =
               {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
               }
                var id_ =parseInt(cookie.load('userId'));
                  const requestBody =
                    {
                        id: '',
                        name: this.state.name,
                        path: this.state.path+"."+this.state.lastname,
                        dunit:this.state.dunit,
                        dept:this.state.dept,
                        type: 8,
                        c_by: id_
                    }
                     axios.post(url+'/basemaster/insert.php', qs.stringify(requestBody), config)
                        .then((result) =>
                        {
                              this.props.history.push("/app/eventnews/Index");
                        });
        }
        else
        {
               this.upload()
               const qs = require('querystring')
               const config =
               {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
               }
                var id_ =parseInt(cookie.load('userId'));
                  const requestBody =
                    {
                        id: this.state.id,
                        name: this.state.name,
                        dunit:this.state.dunit,
                        dept:this.state.dept,
                        type: 8,
                        m_by: id_
                    }
                     axios.post(url+'/basemaster/update.php', qs.stringify(requestBody), config)
                        .then((result) =>
                        {
                              this.props.history.push("/app/eventnews/Index");
                        });
        }

    }
  _name =(event) =>
   {
      this.setState({name:event.target.value })
   }
    _dunit =(event) =>
   {
      this.setState({dunit:event.target.value })
   }
   _upload(event) {

      var name = new Date().toLocaleString().replace(',','').replaceAll(':','').replaceAll(' ','')
        var ln =event.target.value.split('.').length-1;
        this.setState({
            selectedFile: event.target.files[0],
            path:'uploads/file/manage/'+this.state.name,
            lastname:event.target.value.split('.')[ln]
        })
    }

    upload()
    {

        const data = new FormData()
        data.append('file', this.state.selectedFile)
        let _url = url+"/uploads/manage.php?id="+this.state.name;
        axios.post(_url, data,
             {
                 //this.setState({ path:'uploads/file/master/'+this.state.name+''});
             })
            .then(res =>
            {

            })
                //this.setState({ path: 'uploads/file/master/' + this.state.name + '' });

    }


    select_dept = (event)=>
    {
                   var vdunits = this.state.dunits.filter(x=>x.p_id==event.target.value);
                   this.setState({vdunits :vdunits,dept:event.target.value})
    }

  render() {
            const {data, order, orderBy, selected, rowsPerPage, page} = this.state;

            let __depts = this.state.depts;
                    let deptItems = __depts.map((dept) =>
                       <MenuItem    key={dept.id} value={dept.id}>
                       <span className="ml-2"> {dept.name} </span></MenuItem>
            );
            let dunits = this.state.vdunits;
                    let dunitsItems = dunits.map((dunit) =>
                       <MenuItem selected="{this.state.dunit}"
                         key={dunit.id} value={dunit.id}>
                       <span className="ml-2"> {dunit.name} </span></MenuItem>
                );
    return (
    <div>
    <div class="page-heading d-sm-flex justify-content-sm-between align-items-sm mb-0 p-2  bg-geekblue ">
          <h5 class="title p-1 mb-sm-0"></h5>
              <nav class="mb-0" aria-label="breadcrumb">
                      <ol class="breadcrumb">
                                    <div class="breadcrumb-item">
                                        <div   style={{color: 'gray', textDecoration: 'none'}}  >
                                         การบริหารเหตุการณ์
                                        </div>
                                        <div className="pl-1 pr-1"> > </div>
                                         <NavLink   style={{color: 'primary', textDecoration: 'none'}} to={{ pathname:'/app/eventnews' }} >
                                          ส่วนข่าว
                                        </NavLink>
                                         <div className="pl-1 pr-1"> > </div>
                                        <div   style={{color: 'white', textDecoration: 'none'}}  >
                                         {this.state.id==''?"เพิ่ม":"แก้ไข"}
                                        </div>
                                     </div>
                      </ol>
              </nav>
    </div>
    <div className="p-2 pt-3" >
    <ProfileHeader/>

    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                       <label className="ml-4 mt-4 text-white"><h3></h3></label>
                            <div className="row ml-3 mr-3 mb-3" style={{color:'#fff'}}>
                                   <div className="col-md-6 col-12 mb-3">
                                   <label className="">ชื่อ</label>
                                       <div className="">
                                            <TextField
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                            onChange={this._name}
                                            value={this.state.name}
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    ,backgroundColor:"#3f51b5"
                                                    }
                                                }}
                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"

                                                    }
                                                }}
                                        />
                                       </div>
                                 </div>
                                <div className="col-md-6 col-12">
                                   <label className="">ส่วน</label>
                                       <div className="bg-primary">
                                            <TextField
                                                                        select
                                                                        required
                                                                        id="user_no"
                                                                        value={this.state.dept}
                                                                        onChange={this.select_dept}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                            InputProps={{
                                                                                style: {
                                                                                    height: "40px"
                                                                                }
                                                                            }}
                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">

                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {

                                                                                    height: "40px"
                                                                                     ,width: "100%"
                                                                                     ,color:"#fff"

                                                                                }
                                                                            }}
                                                                    >

                                                                  {deptItems}
                                            </TextField>
                                       </div>
                                 </div>
                                 <div className="col-md-6 col-12">
                                   <label className="">หน่ยวงาน</label>
                                       <div className="bg-primary">
                                            <TextField
                                                                        select
                                                                        required
                                                                        id="user_no"
                                                                        value={this.state.dunit}
                                                                        onChange={this._dunit}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                            InputProps={{
                                                                                style: {
                                                                                    height: "40px"
                                                                                }
                                                                            }}
                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">

                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {

                                                                                    height: "40px"
                                                                                     ,width: "100%"
                                                                                     ,color:"#fff"

                                                                                }
                                                                            }}

                                                                    >
                                                                  {dunitsItems}
                                            </TextField>
                                       </div>
                                 </div>
                                 <div className="col-md-6 col-12">
                                   <label>ไฟล์แนบที่เกี่ยวข้อง</label>
                                       <div >


                                            <TextField
                                                            type="file"
                                                            accept="image/*"
                                                            id="raised-button-file"
                                                            multiple
                                                            onChange={this._upload}
                                                            required
                                                            id="fname"
                                                            defaultValue=""
                                                            margin="normal"
                                                            fullWidth
                                                            variant="outlined"
                                                            InputProps={{
                                                                style: {
                                                                    height: "40px"
                                                                }
                                                            }}
                                                            style={{ margin: 0 }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start" >
                                                                    </InputAdornment>
                                                                ),
                                                                style: {
                                                                    height: "40px"
                                                                    , color: "#fff"
                                                                }
                                                            }}
                                                        />
                                       </div>
                                 </div>
                            </div>

                   <div className="row m-3" style={{color:'#fff'}}>
                     <Button   className="bg-blue text-white mt-0 ml-3" onClick={()=>this.submit()}>
                          <SaveIcon/>
                          <span className="ml-1">
                                บันทึก
                          </span>
                    </Button>
                 </div>
                 </div>


   </div>
 </div>
 </div>
 </div>
      </div>
      </div>
    );
  }
}

export default index;