  import CloseIcon from '@material-ui/icons/Close';
    import React from 'react';
    import Table from '@material-ui/core/Table';
    import TableBody from '@material-ui/core/TableBody';
    import TableCell from '@material-ui/core/TableCell';
    import TableFooter from '@material-ui/core/TableFooter';
    import TableHead from '@material-ui/core/TableHead';
    import PropTypes from 'prop-types';
    import EditIcon from '@material-ui/icons/Edit';
    import {withStyles} from '@material-ui/core/styles';
    import SearchIcon from '@material-ui/icons/Search';
    import TableRow from '@material-ui/core/TableRow';
    import classNames from 'classnames';
    import TablePagination from '@material-ui/core/TablePagination';
    import Tooltip from '@material-ui/core/Tooltip';
    import Drawer from '@material-ui/core/Drawer';
    import AppBar from '@material-ui/core/AppBar';
    import Avatar from '@material-ui/core/Avatar';
    import CustomScrollbars from 'util/CustomScrollbars';
    import Autocomplete from '@material-ui/lab/Autocomplete';
    import RadioGroup from '@material-ui/core/RadioGroup';
    import LayersIcon from '@material-ui/icons/Layers';
    import DeckGL from '@deck.gl/react';
	import { render } from "react-dom";
	import DeleteIcon from '@material-ui/icons/Delete';
    import CKEditor from '@ckeditor/ckeditor5-react';
    import Toolbar from '@material-ui/core/Toolbar';
    import MenuItem from '@material-ui/core/MenuItem';
    import Dialog from '@material-ui/core/Dialog';
    import DialogActions from '@material-ui/core/DialogActions';
    import DialogContent from '@material-ui/core/DialogContent';
    import DialogContentText from '@material-ui/core/DialogContentText';
    import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
    import DialogTitle from '@material-ui/core/DialogTitle';
    import List from '@material-ui/core/List';
    import IntlMessages from 'util/IntlMessages';
    import Typography from '@material-ui/core/Typography';
    import Divider from '@material-ui/core/Divider';
    import SquareFootRoundedIcon from '@material-ui/icons/SquareFootRounded';
    import Switch from '@material-ui/core/Switch';
    import IconButton from '@material-ui/core/IconButton';
    import LanguageIcon from '@material-ui/icons/Language';
    import axios from "axios";
    import MenuIcon from '@material-ui/icons/Menu';
    import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
    import ChevronRightIcon from '@material-ui/icons/ChevronRight';
    import Checkbox from '@material-ui/core/Checkbox';
    import Button from '@material-ui/core/Button';
    import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
    import AccessibilityIcon from '@material-ui/icons/Accessibility';
    import Snackbar from '@material-ui/core/Snackbar';
    import ReactMapboxGl, { Layer,Feature,Source } from 'react-mapbox-gl';
    import ReactStreetview from 'react-streetview';
    import MapGL,{ NavigationControl ,ScaleControl, FullscreenControl,AttributionControl,GeolocateControl  }   from '@urbica/react-map-gl';
    import Draw from '@urbica/react-map-gl-draw';
    import HomeWorkIcon from '@material-ui/icons/HomeWork';
    import { TextField, InputAdornment } from "@material-ui/core";
    import MyLocationIcon from '@material-ui/icons/MyLocation';
    import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
    import HealingRoundedIcon from '@material-ui/icons/HealingRounded';
    import RoomIcon from '@material-ui/icons/Room';
    import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
    import Radio from '@material-ui/core/Radio';
    import FormControl from '@material-ui/core/FormControl';
    import FormLabel from '@material-ui/core/FormLabel';
    import FormControlLabel from '@material-ui/core/FormControlLabel';
    import FormGroup from '@material-ui/core/FormGroup';
    import FormHelperText from '@material-ui/core/FormHelperText';
    import MapMesure from 'mapboxgl-measure-tool'
    import { connect } from 'react-redux';
    import mapboxgl,{MarkerViewOptions ,LatLng} from 'mapbox-gl';
    import TableSortLabel from '@material-ui/core/TableSortLabel';
    import { compose, withProps } from "recompose";

    import Visibility from "@material-ui/icons/Visibility";
    import VisibilityOff from "@material-ui/icons/VisibilityOff";
    import CreditCardIcon from '@material-ui/icons/CreditCard';
    import GroupIcon from '@material-ui/icons/Group';
    import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
    import SaveIcon from '@material-ui/icons/Save';
    import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
    import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
    import GetAppIcon from '@material-ui/icons/GetApp';
    import CallIcon from '@material-ui/icons/Call';
    import AccountCircle from '@material-ui/icons/AccountCircle';
    import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
    import PoolIcon from '@material-ui/icons/Pool';
    import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
    import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
    import InfoIcon from '@material-ui/icons/Info';
    import LocationCityIcon from '@material-ui/icons/LocationCity';
    import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import Tabs from '@material-ui/core/Tabs';
    import Tab from '@material-ui/core/Tab';
    import ImageIcon from '@material-ui/icons/Image';

    import { makeStyles } from "@material-ui/core/styles";
    import cookie from 'react-cookies'

let counter = 0;
const drawerWidth = 330;



function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}
 var url = 'http://1.10.184.64:3000';
 var urlupload = 'http://1.10.184.64:3000';
const columnData = [
    {id: 'name', align: true, disablePadding: false, label: 'ชื่ออาคาร'},
      {id: 'createby', align: true, disablePadding: false, label: 'ดำเนินการ'},

];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white',fontFamily: 'Kanit'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const styles = theme => ({


    root: {
        width: '100%',
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
    },
    appFrame: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    appBar: {
        position: 'absolute',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        height: '100%',
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        width: '100%',
        marginLeft: -drawerWidth,
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginTop: 56,
        [theme.breakpoints.up('sm')]: {
            content: {
                height: 'calc(100% - 64px)',
                marginTop: 64,
            },
        },
    },
    contentShift: {
        marginLeft: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
});


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };


  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
        nonelayer: "none",
                          order: 'asc',
                          orderBy: '',
                          selected: [],
                          data: [],
                          page: 0,
                          rowsPerPage: 5,
                          name:'',
                          type:'',
                          path:'',
                          open:false,
                          listblg:[],
                          disabledAdd: true,
                          id:'',
                          bl_name:'',
                          bl_employ:'',
                          bl_nstorey:'',
                          openmsg:false,
                          fdisplay:'none',
                          idisplay:'',
                          colorSC: "bg-blue text-white mt-0 ml-4",
                          s_nstorey:'',
                          s_employ:'',
                       typemap:{        "version": 8,
                                                  "name": "OSM",
                                                  "metadata": {
                                                  },
                                                  "sources": {
                                                    "osm": {
                                                      "type": "raster",
                                                      "tiles": [
                                                        "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                      ]
                                                    }
                                                  },
                                                  "layers": [
                                                    {
                                                      "id": "osm",
                                                      "type": "raster",
                                                      "source": "osm"
                                                    }
                                                  ],
                                                  "id": "klokantech-basic"
                                                }

                                                ,omap:{"version": 8,
                                                  "name": "OSM",
                                                  "metadata": {
                                                  },
                                                  "sources": {
                                                    "osm": {
                                                      "type": "raster",
                                                      "tiles": [
                                                        "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                      ]
                                                    }
                                                  },
                                                  "layers": [
                                                    {
                                                      "id": "osm",
                                                      "type": "raster",
                                                      "source": "osm"
                                                    }
                                                  ],
                                                  "id": "klokantech-basic"
                                                }


    };



  }


  componentDidMount() {
        // Any information the map needs can be passed down as a prop from the parent  MMap
        var { map } = this.props;
        const { dd, oo } = this.state;
        // Store the map as a property on the class so we can call mapbox methods later
        mapboxgl.accessToken = 'pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w';
        this.map = new mapboxgl.Map({
            style: {
                "version": 8,
                "name": "OSM",
                "metadata": {
                },
                "sources": {
                    "osm": {
                        "type": "raster",
                        "tiles": [
                            "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                        ]
                    }
                },
                "layers": [
                    {
                        "id": "osm",
                        "type": "raster",
                        "source": "osm"
                    }
                ],
                "id": "klokantech-basic"
            },
            center: [100.523237700882, 13.7065817585414],
            animate: false,
            zoom: 4,

            container: 'map',

        });



        this.map.on('load', () => {


            var layers = this.map.getStyle().layers;

            var labelLayerId;
            for (var i = 0; i < layers.length; i++) {
                if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                    labelLayerId = layers[i].id;
                    break;
                }
            }

        });


        this.map.on('load', function () {


        })



    }
      handleDrawerOpen = () => {
        this.setState({ open: true });

    };

    handleDrawerClose = () => {
        this.setState({ open: false });

    };


    ClearLayer =() =>{

                              this.map.moveLayer('maine500', 0);
                            this.map.moveLayer('maine1000', 0);
                            this.map.moveLayer('maine500', 0);
                            this.map.moveLayer('maine1000', 0);
                            this.map.moveLayer('_maine500', 0);
                            this.map.moveLayer('_maine1000', 0);
                            this.map.moveLayer('l_showblg', 0);
                            this.map.moveLayer('s_showblg', 0);
                            this.map.moveLayer('Location', 0);
                            this.map.moveLayer('layerLocation', 0);
                            this.map.moveLayer('_plan', 0);
                            this.map.moveLayer('plan', 0);
                            this.map.moveLayer('_plansni', 0);
                            this.map.moveLayer('plansni', 0);
                            this.map.moveLayer('layersniper', 0);
                            this.map.moveLayer('sourcesniper', 0);
                            this.map.moveLayer('layerbk', 0);
                            this.map.moveLayer('sourcebk', 0);
                            this.map.moveLayer('_pointsbk', 0);
                            this.map.moveLayer('pointsbk', 0);
                            this.map.moveLayer('pointdistance', 0);
                            this.map.moveLayer('_pointdistance', 0);
                            this.map.moveLayer('pointssniper', 0);
                            this.map.moveLayer('_pointssniper', 0);
                            this.map.moveLayer('route', 0);
                            this.map.moveLayer('_route', 0);
                            this.map.moveLayer('_pointslocation', 0);
                            this.map.moveLayer('pointslocation', 0);
                            this.map.moveLayer('routedis', 0);
                            this.map.moveLayer('_routedis', 0);
                            this.map.moveLayer('_planbk', 0);
                            this.map.moveLayer('planbk', 0);

       }



        bmapc1 = () =>{

         var mapLayer = this.map.getLayer('oms-map');

                                           if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('dark-map').removeSource('dark-tiles');
                                   }
                                    var mapLayer = this.map.getLayer('dark-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('dark-map').removeSource('dark-tiles');
                                   }
                                   var mapLayer = this.map.getLayer('basic-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('basic-map').removeSource('basic-tiles');
                                   }
                                     var mapLayer = this.map.getLayer('satellite-map');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('satellite-map').removeSource('mapbox-satellite');
                                   }

           //this.setState({typemap:});
        }
        bmapc2 = () =>{
    var mapLayer = this.map.getLayer('dark-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('dark-map').removeSource('dark-tiles');
                                   }


                        this.map.addSource("dark-tiles", {
            "type": "raster",
            "tiles": ["https://api.mapbox.com/styles/v1/mapbox/dark-v8/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w"],
            "tileSize": 256
            })

                      this.map.addLayer({
                      "type": "raster",
                      "id": 'dark-map',
                      "source": "dark-tiles",
                      "layout": {
                          "visibility": "visible"
                        },
                       });

 this.map.moveLayer('dark-map', 0);
                        this.ClearLayer();


          //this.map.on('styledata')
           //this.setState({typemap:"mapbox://styles/mapbox/dark-v8"});
        }
         bmapc3 = () =>{


          var mapLayer = this.map.getLayer('basic-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('basic-map').removeSource('basic-tiles');
                                   }


                        this.map.addSource("basic-tiles", {
            "type": "raster",
            "tiles": ["https://api.mapbox.com/styles/v1/mapbox/streets-v8/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w"],
            "tileSize": 256
            })

                      this.map.addLayer({
                      "type": "raster",
                      "id": 'basic-map',
                      "source": "basic-tiles",
                      "layout": {
                          "visibility": "visible"
                        },
                       });
                            this.map.moveLayer('basic-map', 0);
                         this.ClearLayer();
           //this.setState({typemap:"mapbox://styles/mapbox/streets-v8"});
        }
         bmapc4 = () =>{


         var mapLayer = this.map.getLayer('satellite-map');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('satellite-map').removeSource('mapbox-satellite');
                                   }


                      this.map.addSource("mapbox-satellite", { "type": "raster", "url": "mapbox://mapbox.satellite", "tileSize": 256 });
                      this.map.addLayer({
                      "type": "raster",
                      "id": 'satellite-map',
                      "source": "mapbox-satellite",
                      "layout": {
                          "visibility": "visible"
                        },
                       });
                        this.map.moveLayer('satellite-map', 0);
                      this.ClearLayer();

           //this.setState({typemap:"mapbox://styles/mapbox/satellite-v9"});
        }

       find_name = (event, values) => {
                 //   this.setState({ gotoname: values.bl_name });
       }
        findblg = (event) => {


               var v =event.target.value;

                fetch('http://1.10.184.64:3000/bldg/table_autocomplete.php?id=' + v)
                    .then(response => response.json())
                    .then(data => {

                        this.setState({ data: data, loading: false });
                                  })
        }
    goto_nstorey =(event)=>{

                 var v =event.target.value;

                fetch('http://1.10.184.64:3000/bldg/nstorey_autocomplete.php?id=' + v)
                    .then(response => response.json())
                    .then(data => {

                        this.setState({ data: data, loading: false });
                                  })


     }
     goto_employ =(event)=>{

             var v =event.target.value;

                fetch('http://1.10.184.64:3000/bldg/employ_autocomplete.php?id=' + v)
                    .then(response => response.json())
                    .then(data => {

                        this.setState({ data: data, loading: false });
                                  })


     }

         _goTo = (id) => {

         fetch('http://1.10.184.64:3000/bldg/indexid.php?id=' + id)
                .then(response => response.json())
                .then(data => {
                        var id =data[0].id;
                        var bl_name =data[0].bl_name;
                        var bl_employ =data[0].bl_employ;
                        var bl_nstorey =data[0].bl_nstorey;
                        var x =data[0].x;
                        var y =data[0].y;



                          this.map.flyTo({ center: [x, y], zoom: 18 });
                            var polygon = JSON.parse(data[0].bld);
                            var mapLayer = this.map.getLayer('_plan');
                            if (typeof mapLayer !== 'undefined') {
                                this.map.removeLayer('_plan').removeSource('plan');
                            }

                            this.map.addSource('plan', {

                                'type': 'geojson',
                                'data':
                                {
                                    "features": [
                                        {
                                            "type": "Feature", "properties":
                                                { "level": 1, "name": "Bird Exhibit", "height": data[0].bl_nstorey + 1, "base_height": 0, "color": "#CFBB10" }, "geometry":
                                            {
                                                "coordinates": polygon,
                                                "type": "Polygon"
                                            },
                                            "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"
                                        },
                                    ], "type": "FeatureCollection"
                                }
                            });

                            this.map.addLayer({
                                'id': '_plan',
                                'type': 'fill-extrusion',
                                'source': 'plan',
                                'paint': {
                                    // See the Mapbox Style Specification for details on data expressions.
                                    // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                    // Get the fill-extrusion-color from the source 'color' property.
                                    'fill-extrusion-color': ['get', 'color'],

                                    // Get fill-extrusion-height from the source 'height' property.
                                    'fill-extrusion-height': ['get', 'height'],

                                    // Get fill-extrusion-base from the source 'base_height' property.
                                    'fill-extrusion-base': ['get', 'base_height'],

                                    // Make extrusions slightly opaque for see through indoor walls.
                                    'fill-extrusion-opacity': 0.5
                                }
                            });

                            this.setState({id:id,bl_nstorey:bl_nstorey,bl_employ:bl_employ,bl_name:bl_name,idisplay:'none',fdisplay:''})

                });

    }
       _nonelayer =()=>{
            if(this.state.nonelayer=="block"){

              this.setState({
              nonelayer:"none"
            });

            }

              if(this.state.nonelayer=="none"){

            this.setState({
              nonelayer:"block"
            });
            }

          }
    submit =()=>
    {
                                        const requestBody = {
                                            id: this.state.id,
                                            bl_name: this.state.bl_name,
                                            bl_employ: this.state.bl_employ,
                                            bl_nstorey: this.state.bl_nstorey
                                        }
                                        const config = {
                                            headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded'
                                            }
                                        }
                                        const qs = require('querystring')
                                        axios.post('http://1.10.184.64:3000/bldg/bldg_update.php', qs.stringify(requestBody), config)
                                            .then((result) => {
                                                 this.setState({openmsg:true,data:[],idisplay:'',fdisplay:'none'})

                                            });
    }



  render() {
          const {classes, theme} = this.props;

         const {nonelayer,data, order, orderBy, selected, rowsPerPage, page,statue,listblg,colorSC,disabledAdd,gotoname} = this.state;


   return (
      <div style={{backgroundColor: 'rgb(181 63 63 / 0%)'}} className={classes.root}>
          <div className={classes.appFrame}  >

              <AppBar
               className={classNames('rgb(181 63 63 / 0%)', classes.appBar, this.state.open && classes.appBarShift)}
                  style={{backgroundColor: 'rgb(181 63 63 / 0%)',
                    boxShadow:'0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%)'
                  }}

              >

                <Toolbar disableGutters={!this.state.open}  style={{backgroundColor: 'rgb(181 63 63 / 0%)'}}>
                <div className='m-2  p-0 ' style={{backgroundColor: '#3f51b5', top: 70, position: 'fixed' }}>
                  <IconButton

                    aria-label="open drawer"
                    onClick={this.handleDrawerOpen}
                    style={{backgroundColor: '#3f51b5',margin:0}}
                    className={classNames(classes.menuButton, this.state.open && classes.hide)}>

                    <MenuIcon className="text-white"/>
                  </IconButton>

              </div>


                </Toolbar>

                <div
                style={{

                position: 'fixed',
                top: 80,
                right: 10,
                }} >
                     <div  onClick={this._nonelayer} className="bg-blue text-white p-2"   >
                         <LayersIcon />
                    </div>
                </div>
                <div
                    style={{

                    position: 'fixed',
                    top: 130,
                    right: 10,

                     display: nonelayer,
                    backgroundColor:'#3f51b5',
                    borderRadius: '5px'

                    }}
                >

               <RadioGroup  >
                <ul className="nav-menu">
                       <li className="menu no-arrow ml-1" >
                         <FormControlLabel
                            onClick={this.bmapc1}
                            value="Streetmap"
                            control={<Radio/>}
                            label="แผนที่ OSM"/>
                      </li>
               </ul>
               <ul className="nav-menu">
                       <li className="menu no-arrow ml-1" >
                         <FormControlLabel
                             onClick={this.bmapc2}
                              value="Dark"
                              control={<Radio/>}
                              label="แผนที่แบบมืด"
                         />
                      </li>
               </ul>

                <ul className="nav-menu">
                       <li className="menu no-arrow ml-1" >
                         <FormControlLabel
                         onClick={this.bmapc3}
                          value="Streets"
                          control={<Radio/>}
                          label="แผนที่ Mapbox"
                        />
                      </li>
               </ul>
               <ul className="nav-menu">
                       <li className="menu no-arrow ml-1">
                        <FormControlLabel
                         onClick={this.bmapc4}
                          value="Satellite"
                          control={<Radio/>}
                          label="ภาพถ่ายดาวเทียม"
                        />
                      </li>
               </ul>
                </RadioGroup >
                </div>
              </AppBar>

              <Drawer
                variant="persistent"
                classes={{
                  paper: classes.drawerPaper,
                }}
                open={this.state.open}
              >
                <CustomScrollbars className="module-side-scroll scrollbar"
                              style={{backgroundColor: '#030852',height: this.props.width >= 1200 ?
                                'calc(100vh - 70px)' : 'calc(100vh - 70px)'}}>

                <div className={classes.drawerInner} style={{color:'#fff' ,white:'300px'}} >
                  <div className={classes.drawerHeader} style={{color:'#fff'}} >
                    <IconButton onClick={this.handleDrawerClose} style={{color:'#fff'}} >
                      {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                  </div>
                  <Divider/>
                         <div className="m-4" style={{display:this.state.fdisplay}}>

                                        <Row className="ml-2" style={{ color: '#fff' }}>
                                            ชื่ออาคาร
                                        </Row>
                                        <Row style={{ color: '#fff' }} >
                                            <Col className="align-middle">
                                                <div className="">

                                                    <TextField
                                                            id="fname"
                                                            value={this.state.bl_name}
                                                            onChange={(event)=>{this.setState({bl_name:event.target.value})}}

                                                            margin="normal"
                                                            fullWidth
                                                            variant="outlined"
                                                            InputProps={{
                                                                style: {
                                                                    height: "40px"
                                                                    , backgroundColor: "#3f51b5"
                                                                }
                                                            }}
                                                            style={{ margin: 0 }}
                                                            InputProps={{

                                                                style: {
                                                                    height: "40px"
                                                                    , width: "250px"
                                                                    , backgroundColor: "#3f51b5"
                                                                    , color: "#fff"

                                                                }
                                                            }}
                                                        />

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="ml-2 mt-3" style={{ color: '#fff' }}>
                                            ความสูง
                                        </Row>
                                        <Row style={{ color: '#fff' }} >
                                            <Col className="align-middle">
                                                <div className="">

                                                    <TextField
                                                            id="fname"
                                                            value={this.state.bl_nstorey}
                                                            onChange={(event)=>{this.setState({bl_nstorey:event.target.value})}}

                                                            margin="normal"
                                                            fullWidth
                                                            variant="outlined"

                                                            style={{ margin: 0 }}
                                                            InputProps={{

                                                                style: {
                                                                    height: "40px"
                                                                    , width: "250px"
                                                                    , backgroundColor: "#3f51b5"
                                                                    , color: "#fff"
                                                                    ,fontFamily: 'Kanit'

                                                                }
                                                            }}
                                                        />

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="ml-2 mt-3" style={{ color: '#fff' }}>
                                            ความจุ
                                        </Row>
                                        <Row style={{ color: '#fff' }} >
                                            <Col className="align-middle">
                                                <div className="">

                                                    <TextField
                                                            id="fname"
                                                            value={this.state.bl_employ}
                                                            onChange={(event)=>{this.setState({bl_employ:event.target.value})}}

                                                            margin="normal"
                                                            fullWidth
                                                            variant="outlined"

                                                            style={{ margin: 0 }}
                                                            InputProps={{

                                                                style: {
                                                                    height: "40px"
                                                                    , width: "250px"
                                                                    , backgroundColor: "#3f51b5"
                                                                    , color: "#fff"
                                                                        ,fontFamily: 'Kanit'

                                                                }
                                                            }}
                                                        />

                                                </div>
                                            </Col>
                                        </Row>

                                           <div className="mt-3">
                                           <Button onClick={this.submit}
                                                            className="bg-blue text-white mt-0"   >
                                                            <SaveIcon />
                                                            <spen className="ml-1">
                                                                บันทึก
                                                            </spen>
                                                 </Button>
                                                 <Button  onClick={()=> this.setState({idisplay:'',fdisplay:'none'})}
                                                              className="bg-blue text-white mt-0 ml-4"   >
                                                             <CloseIcon />
                                                            <spen className="ml-1" style={{fontFamily: 'Kanit'}}>
                                                                ยกเลิก
                                                            </spen>
                                           </Button>
                                           </div>

                          </div>

                          <div className="m-3" style={{display:this.state.idisplay}}>
                                <Row className="ml-2" style={{ color: '#fff' }}>
                                    ค้นหาสถานที่
                                </Row>
                                <Row style={{ color: '#fff' }} >

                                    <Col className="align-middle">
                                        <div className="">
                                            <TextField
                                                    id="fname"
                                                    value={gotoname}
                                                    onChange={this.findblg}

                                                    margin="normal"
                                                    fullWidth
                                                    variant="outlined"
                                                    InputProps={{
                                                        style: {
                                                            height: "40px"
                                                            , backgroundColor: "#3f51b5"
                                                        }
                                                    }}
                                                    style={{ margin: 0 }}
                                                    InputProps={{

                                                        style: {
                                                            height: "40px"
                                                            , width: "250px"
                                                            , backgroundColor: "#3f51b5"
                                                            , color: "#fff"
                                                            , fontFamily: 'Kanit'

                                                        }
                                                    }}
                                                />

                                        </div>
                                    </Col>
                                </Row>
                                <Row className="ml-2  mt-3" style={{ color: '#fff' }}>
                                    ค้นหาความสูง
                                </Row>
                                <Row style={{ color: '#fff' }} >

                                    <Col className="align-middle">
                                        <div className="">
                                            <TextField
                                                    id="fname"

                                                    onChange={this.goto_nstorey}

                                                    margin="normal"
                                                    fullWidth
                                                    variant="outlined"

                                                    style={{ margin: 0 }}
                                                    InputProps={{

                                                        style: {
                                                            height: "40px"
                                                            , width: "250px"
                                                            , backgroundColor: "#3f51b5"
                                                            , color: "#fff"
                                                            , fontFamily: 'Kanit'

                                                        }
                                                    }}
                                                />

                                        </div>
                                    </Col>
                                </Row>
                                <Row className="ml-2 mt-3" style={{ color: '#fff' }}>
                                    ค้นหาความจุ
                                </Row>
                                <Row style={{ color: '#fff' }} >

                                    <Col className="align-middle">
                                        <div className="">
                                            <TextField
                                                    id="fname"

                                                    onChange={this.goto_employ}

                                                    margin="normal"
                                                    fullWidth
                                                    variant="outlined"

                                                    style={{ margin: 0 }}
                                                    InputProps={{

                                                        style: {
                                                            height: "40px"
                                                            , width: "250px"
                                                            , backgroundColor: "#3f51b5"
                                                            , color: "#fff"
                                                            , fontFamily: 'Kanit'

                                                        }
                                                    }}
                                                />

                                        </div>
                                    </Col>
                                </Row>


                             <Table className="mt-5" size="small" >
                                  <DataTableHead

                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                  />
                                  <TableBody>
                                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                      return (
                                        <TableRow   hover >
                                          <TableCell style={{color:'white', fontFamily: 'Kanit'}} >
                                                {n.bl_name}
                                          </TableCell>

                                          <TableCell style={{color:'white', fontFamily: 'Kanit'}} >
                                                 <Button onClick={()=>this._goTo(n.id)} variant="contained"
                                                            className={colorSC}   >
                                                            <EditIcon />

                                                 </Button>
                                          </TableCell>


                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                  <TableFooter>
                                    <TableRow>
                                      <TablePagination
                                         style={{color:'white'}}
                                        count={data.length}
                                          labelDisplayedRows={({ from, to, count }) =>  <div style={{ color:'white',fontFamily: 'Kanit' }}>{from}-{to} จาก {count}</div>}
                                          labelRowsPerPage=<div style={{ color:'white',fontFamily: 'Kanit' }}>รายการ</div>
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                      />
                                    </TableRow>
                                  </TableFooter>
                                </Table>
                         </div>
                         <hr class="MuiDivider-root"/>
                  <Divider/>
                    </div>
                     </CustomScrollbars>
                        </Drawer>
                          <main className={classNames(classes.content, this.state.open && classes.contentShift)}
                          style={{ padding: '0px',marginTop:-10}}>
                                 <div state={{backgroundColor:'red'}}>
                                    <div id='map' style={{height:'100%',width:'100%',top: 0,left:0,right:0,position:'fixed'}} />
                                </div>
                           </main>
          </div>
          <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={this.state.openmsg}
                        autoHideDuration={1000}
                        onClose={() => this.setState({ openmsg: false })}
                        message="รอข้อมูล"
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={() => this.setState({ openmsg: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
      </div>

            );
        }
    }
    index.propTypes = {
      classes: PropTypes.object.isRequired,
      theme: PropTypes.object.isRequired,
    };
    export default withStyles(styles, {withTheme: true})(index);