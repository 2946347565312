import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import {connect} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import cookie from 'react-cookies'
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { NavLink, withRouter,useHistory } from 'react-router-dom';

class UserInfo extends React.Component {



  state = {
    anchorEl: null,
    open: false,
    userId:''
  };

  componentDidMount() {

   this.state = { userId: cookie.load('userId') }
    var id_ =parseInt(cookie.load('userId'));
    console.log(id_+"xx"+cookie.load('userId')+"ddddd")

    if(id_!=""){
    const requestBody = {
              id: id_,
            }

            const config = {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }

              const axios = require('axios')
              const qs = require('querystring')
              axios.post('http://1.10.184.64:3000/users/getid.php', qs.stringify(requestBody), config)
                .then((result) => {


                         console.log(result.data+"result.data")
                         if(result.data==0)
                         {
                             this.props.userSignOut();
                         }
                          if (typeof result.data === 'undefined')
                          {
                                this.props.userSignOut();

                          } else {
                        var ddd = result.data;
                      
                          if( result.data.trim().length>100 ) {
                         cookie.save('userId', '', { path: '/' })
                          this.props.userSignOut();
                            }
                            else
                            {
                           this.setState({name:result.data});
                           }
                        }
                })
                .catch((err) => {
                    console.log("uuuu");
                  // Do somthing

                })
   }
  }
  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  render() {



    return (
      <div className="user-profile d-flex flex-row align-items-center " >
         <Avatar
        alt='...'
        src={"https://via.placeholder.com/150x150"}
        className="user-avatar "
      />

        <div className="user-detail">
          <h6 className="user-name" style={{color:"#fff"}} onClick={this.handleClick}>{this.state.name} <i
            className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
          </h6>
        </div>
        <Menu className="user-info"
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              PaperProps={{
                style: {
                  minWidth: 50,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}
        >
        <NavLink className="prepend-icon"  to={{pathname:'/app/changepassword'}}  >
          <span className="jr-link dropdown-item text-muted" >
               <VpnKeyIcon/>
               เปลี่ยนรหัสผ่าน
          </span>
        </NavLink>
         <span className="jr-link dropdown-item text-muted" onClick={() => {

          this.props.userSignOut()
        }}>

              <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
              ออกจากระบบ

        </span>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale} = settings;
  return {locale}
};
export default connect(mapStateToProps, {userSignOut})(UserInfo);


