import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
 import Button from '@material-ui/core/Button';
import { TextField, InputAdornment } from "@material-ui/core";
 import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}

const columnData = [
  {id: 'name', align: true, disablePadding: false, label: 'ชื่อ'},
  {id: 'calories', align: true, disablePadding: false, label: 'ไฟล์แนบ'},
  {id: 'fat', align: true, disablePadding: false, label: 'ผูใช้สร้าง'},
  {id: 'carbs', align: true, disablePadding: false, label: 'หน่ยวงาน'},
  {id: 'protein', align: true, disablePadding: false, label: 'วันที่สร้าง'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [
        createData('เอกสารประจำการ', '', 'พลทหาร วีระ','ยก', '20/10/2020'),
        createData('เอกสารหน่วย', '', 'พลทหาร ชิด','ยก','20/10/2020'),
        createData('เอกสารทั่วไป', '',  'พลทหาร ยอด','ยก','20/10/2020'),
      ].sort((a, b) => (a.calories < b.calories ? -1 : 1)),
      page: 0,
      rowsPerPage: 5,
    };
  }

  render() {
    const {data, order, orderBy, selected, rowsPerPage, page} = this.state;

    return (
    <div className="p-2 pt-3" >
    <ProfileHeader/>

    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                       <label className="ml-4 mt-4 text-white"><h3>การกำลังพล</h3></label>
                            <Row style={{color:'#fff'}} >

                                 <Col xs={6}>
                                   <label className="ml-4 mt-4">ชื่อ</label>
                                       <div className="ml-4">

                                            <TextField
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    ,backgroundColor:"#3f51b5"
                                                    }
                                                }}
                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "450px"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"

                                                    }
                                                }}
                                        />
                                       </div>
                                 </Col>
                                 <Col xs={6}>
                                   <label className="ml-4 mt-4">หน่ยวงาน</label>
                                       <div className="ml-4 bg-primary">

                                            <TextField
                                                                        select
                                                                        required
                                                                        id="user_no"

                                                                        onChange={this._goToProvince}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                            InputProps={{
                                                                                style: {
                                                                                    height: "40px"
                                                                                }
                                                                            }}
                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">

                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {
                                                                                    height: "40px"
                                                                                     ,color:"#fff"

                                                                                }
                                                                            }}

                                                                    >

                                                                   <MenuItem value={0}>ยก</MenuItem>
                                                                    <MenuItem value={0}>สส</MenuItem>
                                                                   </TextField>
                                       </div>
                                 </Col>
                            </Row>
                            <Row style={{color:'#fff'}} className="mb-4" >

                                 <Col xs={6}>
                                   <label className="ml-4 mt-4">ไฟล์แนบ</label>
                                       <div className="ml-4">
                                            <input
                                                  accept="image/*"

                                                  style={{ display: 'none' }}
                                                  id="raised-button-file"
                                                  multiple
                                                  type="file"
                                                />
                                                <label htmlFor="raised-button-file">
                                                  <Button variant="raised" component="span" className="bg-primary">
                                                    Upload
                                                  </Button>
                                                </label>

                                                 <label  className="ml-2" >ชื่อไฟล์: </label>
                                                   <label className="ml-2">นามสกล:</label>
                                                  <label className="ml-2">ขนาด:</label>
                                                 <label className="ml-2">MB</label>
                                       </div>
                                 </Col>
                                 <Col xs={6}>

                                 </Col>
                            </Row>
                 </div>

        <div className="flex-auto">
          <div className="table-responsive-material bg-geekblue border border-primary">
            <Table className="" size="small" >
              <DataTableHead

                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover

                    >

                      <TableCell style={{color:'white'}} >{n.name}</TableCell>
                      <TableCell style={{color:'white'}} ><DescriptionIcon /></TableCell>
                      <TableCell style={{color:'white'}} >{n.fat}</TableCell>
                      <TableCell style={{color:'white'}} >{n.carbs}</TableCell>
                      <TableCell style={{color:'white'}} >{n.protein}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                     style={{color:'white'}}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>
   </div>
 </div>
 </div>
 </div>
      </div>
    );
  }
}

export default index;