import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import cookie from 'react-cookies'
import ReactFontLoader from 'react-font-loader'
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
} from 'actions/Auth';

class SignIn extends React.Component {



  constructor() {
    super();
    this.state = {
      email: 'demo@example.com',
      password: 'demo#123',
      open : false,
      ip:0,
      openmsg:false
    }
    fetch('https://geolocation-db.com/json/62d8d690-e7b8-11ed-8c81-cbb990727ab5')
        .then(res => res.json())
        .then(json => this.setState({ip: json.IPv4}))

  }

componentDidUpdate() {
if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
     this.props.history.push('/');
      }
     console.log(this.state.ip)
  }

  render() {

    const {
      email,
      password,

    } = this.state;
    const {showMessage, loader, alertMessage} = this.props;

    const loginx =()=> {


     const requestBody = {
              users: this.state.email, //, [CUSTOMER_CODE]
              password: this.state.password, //, [CUSTOMER_CODE]
              ip :this.state.ip
            }

            const config = {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }

              const axios = require('axios')
              const qs = require('querystring')
              axios.post('http://1.10.184.64:3000/users/getuser.php', qs.stringify(requestBody), config)
                .then((result) => {
                   //console.log("resultresult"+result.data.replaceAll("\n",""))
                  var r_login  = result.data;
                   // console.log("resultresultxx"+JSON.stringify(r_login))
                  var c_login ="";
                   if(r_login=="\n\n\nerror\n\n")
                   {
                          r_login="0";
                          c_login="0";
                          this.setState({openmsg: true});
                   }
                               if(r_login!="0"){


                                         cookie.save('userId', result.data, { path: '/',maxAge: 15 * 60 * 1000 })
                                         const   email= 'demo@example.com';
                                         const  password= 'demo#123';

                                         this.props.showAuthLoader();
                                         this.props.userSignIn({email, password});


                                }else{
                                     if(c_login=="") {  this.setState({open: true});}

                                }

                              // Do somthing

                                this.setState({
                                 check :result.data
                                })
                            })
                            .catch((err) => {
                                 this.setState({open: true});
                              // Do somthing



                            })




     }
const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
     this.setState({open: true});
  };

    return (

      <div className="app-login-container  justify-content-center  animated slideInUpTiny animation-duration-3">

    <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Kanit' />
        <div className="m-5" style={{height:20,fontFamily: 'Kanit' }}></div>

               <div className=" app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                  <div className="" style={{fontSize:25,color:"#3f51b5"}}> ระบบสารสนเทศสำหรับการบริหารเหตุการณ์วิกฤต </div>
               </div>
                <div className=" app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">

                <div className="app-login-main-content">

                  <div className="app-logo-content d-flex align-items-center justify-content-center">

                    <Link className="logo-lg" to="/" title="CTOC">
                      <img src={require("assets/images/logoCTOC.png")}  width="200" height="100" alt="CTOC" title="CTOC"/>
                    </Link>
                  </div>

                  <div className="app-login-content">
                    <div className="app-login-header mb-4">
                      <h1><IntlMessages id="เข้าสู่ระบบ"/></h1>
                    </div>

                    <div className="app-login-form">
                      <form>
                        <fieldset>
                          <TextField
                            label={<IntlMessages id="ผู้ใช้งาน"/>}
                            fullWidth
                            onChange={(event) => this.setState({email: event.target.value})}

                            margin="normal"
                            className="mt-1 my-sm-3"
                          />
                          <TextField
                            type="password"
                            label={<IntlMessages id="รหัสผ่าน"/>}
                            fullWidth
                            onChange={(event) => this.setState({password: event.target.value})}

                            margin="normal"
                            className="mt-1 my-sm-3"
                          />

                          <div className="mb-3 d-flex align-items-center justify-content-between">
                            <Button onClick={() => {
                               loginx()
                               }} variant="contained" color="primary">
                              <IntlMessages id="เข้าสู่ระบบ"/>
                            </Button>


                          </div>



                        </fieldset>
                      </form>
                    </div>
                  </div>

                </div>
                {
                  loader &&
                  <div className="loader-view">
                    <CircularProgress/>
                  </div>
                }

                <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={this.state.open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="รหัสผ่านไม่ถูกต้องหรือผู้ใช้งานไม่มีในระบบ"
              />

              </div>
              <div className="mt-3 app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                   <div className="row" style={{height:20}} > ส่วนติดตามสถานกาณ์ ศตก. </div>

              </div>
               <div className=" app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">

                   <div className="row" style={{height:20}} > 0 2565 8045 </div>
              </div>
              <Snackbar
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        open={this.state.openmsg}
                        autoHideDuration={1000}
                          onClose={() => this.setState({openmsg: false})}
                        message="มีบัญชีผู้ใช้เดียวกันใช้งานอยู่ ไม่สามารถเข้าสู่ระบบพร้อมกันได้"
                         action={[
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={() => this.setState({openmsg: false})}
                        >
                          <CloseIcon/>
                        </IconButton>,
                      ]}
              />
      </div>

    );
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader

})(SignIn);
