import React from 'react';
import axios from "axios";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import IntlMessages from 'util/IntlMessages';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import About from "components/profile/About/index";
import Biography from "components/profile/Biography/index";
import Events from "components/profile/Events/index";
import Contact from "components/profile/Contact/index";
import {Link, Router, Route, hashHistory} from "react-router";
import { NavLink, withRouter,useHistory } from 'react-router-dom';
import Friends from "components/profile/Friends/index";
import Photos from "components/profile/Photos/index";
import ProfileHeader from "../Profile/ProfileHeader/index";
import Auxiliary from "../../../../../util/Auxiliary";
import {friendList} from '../Profile/Profile/data'
import {photoList} from "../Profile/Wall/data";
import FullScreen from '../../dropzone/FullScreen/index';
import CKEditor from 'react-ckeditor-component';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';


import ContainerHeader from 'components/ContainerHeader/index';

const SelectValue = [
      {
        value: '1',
        label: 'Select1',
      },
      {
        value: '2',
        label: 'Select2',
      }

    ];





class TextFields extends React.Component {


  navigate=()=> {
       ///    window.location.href = "http://"+window.location.hostname+":"+window.location.port;
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };



     _name = event => {
        this.setState({
          name: event.target.value
          });
      };


     _topography(evt) {
     console.log(evt.editor.getData()+"xxxxxxxxxxxxxx")
        this.setState( {

           topography: evt.editor.getData()

        } );
    }

       _population = event => {
        this.setState({
          population: event.target.value
          });
      };

      _dominance = event => {
        this.setState({
          dominance: event.target.value
          });
      };



       _religion = event => {
        this.setState({
          religion: event.target.value
          });
      };

         _way_land = event => {
        this.setState({
          way_land: event.target.value
          });
      };

       _mobile = event => {
        this.setState({
          mobile: event.target.value
          });
      };

         _way_water = event => {
        this.setState({
          way_water: event.target.value
          });
      };

       _img_build = event => {
        this.setState({
          img_build: event.target.value
          });
      };

         _img_in = event => {
        this.setState({
          img_in: event.target.value
          });
      };

       _ing_out = event => {
        this.setState({
          ing_out: event.target.value
          });
      };

  _plan_status (event){
         this.setState({

        plan_status: event.target.value
    });
    }



constructor(props, context) {
      super(props, context);
      this.state = {
        open: false,
        open2: false,
        vertical: 'top',
        horizontal: 'center',
          content: '',



      };
  this.navigate = this.navigate.bind(this);



    var location = window.location.href;
    const str = '' + location;
    const xid = str.split("=")[1];

         if (xid != null) {

         fetch('http://1.10.184.64:3000/actionplan/select.php?id='+xid)
            .then(response => response.json())
            .then(data => {

              this.setState({

                id: data.id,
                name: data.name,
                topography: data.topography,
                population: data.population,
                dominance: data.dominance,
                religion: data.religion,
                way_land: data.way_land,
                way_water: data.way_water,
                img_build: data.img_build,
                img_in: data.img_in,
                ing_out: data.ing_out,
                plan_status: data.plan_status

              });
            });
         };
 this.updateContent = this.updateContent.bind(this);
  }
 navigate(){

    }


  Save = event => {

    event.preventDefault();
    const requestBody = {
      id : this.state.id,
      name: this.state.name,
      topography: this.state.topography,
      population: this.state.population,
      dominance: this.state.dominance,
      religion: this.state.religion,
      way_land: this.state.way_land,
      way_water: this.state.way_water,
      img_build: this.state.img_build,
      img_in: this.state.img_in,
      ing_out: this.state.ing_out,
      plan_status: this.state.plan_status
      }
      if(this.state.id==null)
      {
      const config = {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  }
          }
       const qs = require('querystring')
        axios.post('http://1.10.184.64:3000/actionplan/insert.php',qs.stringify(requestBody), config)
        .then((result) => {
          // Do somthing
          const name = ""+qs.stringify(result);
          const splitName = name.split("failed");
          const length = splitName.length;
          console.log(length +"x"+this.state.name);
        if(length==1 && this.state.name!==undefined ){

           this.setState({open: true});
            window.location.href = "http://"+window.location.hostname+":"+window.location.port;
        }
        else
        {
            this.setState({open2: true});
        }



        })
        .catch((err) => {
         this.setState({open2: true});
          console.log(err)
        })
        }
     

       if(this.state.id>=0 )
      {

        const config = {
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                          }
                  }
               const qs = require('querystring')
                axios.post('http://1.10.184.64:3000/users/update.php',qs.stringify(requestBody), config)
                .then((result) => {
                  // Do somthing
                  console.log(result)
                        this.setState({open: true});
                })
                .catch((err) => {
                        this.setState({open2: true});
                  // Do somthing
                  console.log(err)
                })
      }

    }



  onBlur(evt) {
    console.log('onBlur event called with event info: ', evt);
  }

  afterPaste(evt) {
    console.log('afterPaste event called with event info: ', evt);
  }
   CKEonChange(evt) {
    const newContent = evt.editor.getData();

    this.setState({
      topography: newContent
    })
  }

  handleRequestClose =()=>{
  this.setState({open:false,open2:false})
  }
  updateContent(newContent) {
    this.setState({
      content: newContent
    })
  }

  render() {

    return (
     <div>

    <div className="app-wrapper">
       <Auxiliary>

        <div className="jr-profile-content">
          <div className="row">
          <div className="">
           <div className="jr-tabs-content jr-task-list">
           <form className="row" noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <div className="col-md-12 col-12">
                <label className="mb-3 mt-3">สถานะภารกิจ</label>

            <FormGroup className="d-flex flex-row">

              <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedJ}
                            onChange={this.handleChange('checkedJ')}
                            value="checkedJ"
                  />
                }
                label="ทั้งหมด"
              />
              <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedK}
                            onChange={this.handleChange('checkedK')}
                            value="checkedK"
                  />
                }
                label="เสร็จสิ้น"
              />
              <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedL}
                            onChange={this.handleChange('checkedL')}
                            value="checkedL"
                  />
                }
                label="ดำเนินการ"
              />
                 <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedL}
                            onChange={this.handleChange('checkedL')}
                            value="checkedL"
                  />
                }
                label="ยังไม่ดำเนินการ"
              />

            </FormGroup>

            </div>
            <div className="col-md-6 col-12">
             <label className="mb-3 mt-3">ประเภทการก่อการร้าย</label>
                 <FormGroup className="d-flex flex-row">



              <div className="user-profile d-flex flex-row align-items-center">
             <div className="user-detail ml-2">
              <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedJ}
                            onChange={this.handleChange('checkedJ')}
                            value="checkedJ"
                  />
                }
                label="ชาตินิยม"
              />
              </div>
               <div className="user-detail ml-2">

              <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedK}
                            onChange={this.handleChange('checkedK')}
                            value="checkedK"
                  />
                }
                label="ทางศาสนา"
              />
               </div>

                 <div className="user-detail ml-2">
             <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedL}
                            onChange={this.handleChange('checkedL')}
                            value="checkedL"
                  />
                }
                label="สนับสนุนจากรัฐ"
              />

             </div>
           </div>

             <div className="user-profile d-flex flex-row align-items-center">
             <div className="user-detail ml-2">
              <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedJ}
                            onChange={this.handleChange('checkedJ')}
                            value="checkedJ"
                  />
                }
                label="ฝ่ายขวาจีล"
              />
              </div>
               <div className="user-detail ml-2">

              <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedK}
                            onChange={this.handleChange('checkedK')}
                            value="checkedK"
                  />
                }
                label="อนาธิปไตย"
              />
               </div>

                 <div className="user-detail ml-2">
             <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedL}
                            onChange={this.handleChange('checkedL')}
                            value="checkedL"
                  />
                }
                label="ประเภทใหญ่"
              />

             </div>
           </div>

            </FormGroup>
            </div>
         <div className="col-md-6 col-12">
             <label className="mb-3 mt-3">รูปแบบการก่อการร้าย</label>
         <FormGroup className="d-flex flex-row">
            <div className="user-profile d-flex flex-row align-items-center">
             <div className="user-detail ml-2">
              <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedJ}
                            onChange={this.handleChange('checkedJ')}
                            value="checkedJ"
                  />
                }
                label="นักรบกลุ่มใหญ่"
              />
              </div>
               <div className="user-detail ml-2">

              <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedK}
                            onChange={this.handleChange('checkedK')}
                            value="checkedK"
                  />
                }
                label="นักรบขนาดเล็ก"
              />
               </div>

                 <div className="user-detail ml-2">
             <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedL}
                            onChange={this.handleChange('checkedL')}
                            value="checkedL"
                  />
                }
                label="ก่อเหตุคนเดียว"
              />

             </div>
           </div>
           <div className="user-profile d-flex flex-row align-items-center">
             <div className="user-detail ml-2">
              <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedJ}
                            onChange={this.handleChange('checkedJ')}
                            value="checkedJ"
                  />
                }
                label="วินาศกรรม"
              />
              </div>
               <div className="user-detail ml-2">

              <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedK}
                            onChange={this.handleChange('checkedK')}
                            value="checkedK"
                  />
                }
                label="ลักพาตัวประกัน"
              />
               </div>

                 <div className="user-detail ml-2">
             <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedL}
                            onChange={this.handleChange('checkedL')}
                            value="checkedL"
                  />
                }
                label="สือออนไลน์"
              />

             </div>
           </div>
  <div className="m-2">
               <FormControlLabel
                control={
                  <Checkbox color="primary"
                            checked={this.state.checkedL}
                            onChange={this.handleChange('checkedL')}
                            value="checkedL"
                  />
                }
                label="รูปแแบอื่นๆ"
              />
  </div>
            </FormGroup>

            </div>

            <div className="m-4" onClick={this.Save} >

              <Button  variant="contained" className="bg-primary text-white user-detail flex-right"
                                            type="button">
                                            ค้นหา
                                          </Button>

            </div>


          </form>
          </div>
            </div>
          </div>
        </div>
        <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        message="บันทึกสำเร็จ"
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={this.state.open2}
        autoHideDuration={6000}
        message="บันทึกไม่สำเร็จ"
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
      />
      </Auxiliary>
     </div>
    </div>
    );
  }
}


export default TextFields;