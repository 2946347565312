import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { NavLink, withRouter,useHistory } from 'react-router-dom';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { TextField, InputAdornment } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import cookie from 'react-cookies';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}
var url ="http://1.10.184.64:3000/";
const columnData = [
  {id: 'name', align: true, disablePadding: false, label: 'ชื่อ'},
  {id: 'path', align: true, disablePadding: false, label: 'ไฟล์แนบ'},
  {id: 'createby', align: true, disablePadding: false, label: 'ผูใช้สร้าง'},
  {id: 'dept', align: true, disablePadding: false, label: 'ส่วน'},
  {id: 'dunit', align: true, disablePadding: false, label: 'หน่ยวงาน'},
  {id: 'createdate', align: true, disablePadding: false, label: 'วันที่สร้าง'},
   {id: 'datetime', align: true, disablePadding: false, label: 'ดำเนินการ'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white',fontFamily: 'Kanit'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 5,
      name:'',
      type:'',
      path:'',
      depts:[],
      dept:'',
      dunits:[],
      dunit:'',
      vdunits:[],
      users:[],
      lastname:''
    };
     this._upload = this._upload.bind(this);
     fetch('http://1.10.184.64:3000/basemaster/select.php')
            .then(response => response.json())
           .then(_data => {

             var data  = _data.filter(x=>x.flat=='0'&&x.type==5).sort((a, b) => (a.id > b.id ? -1 : 1))
               this.setState({ data: data });
          });
      fetch('http://1.10.184.64:3000/users/dunit.php')
                                     .then(response => response.json())
                                     .then(data => {
                                     data.push({id:0,name:'เลือกรายการ'})
                                           this.setState({dunits:data});
          });

      fetch('http://1.10.184.64:3000/users/dept.php')
                                     .then(response => response.json())
                                     .then(data => {
                                     data.push({id:0,name:'เลือกรายการ'})
                                           this.setState({depts:data});
          });
      fetch('http://1.10.184.64:3000/users/index.php')
                                     .then(response => response.json())
                                     .then(data => {
                                     data.push({id:0,name:'เลือกรายการ'})
                                           this.setState({users:data});
        });
  }
    submit =()=>
    {

        this.upload()
       const qs = require('querystring')
       const config =
       {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
       }
        var id_ =parseInt(cookie.load('userId'));
          const requestBody =
            {
                id: '',
                name: this.state.name,
                path: this.state.path+"."+this.state.lastname,
                dunit:this.state.dunit,
                dept:this.state.dept,
                type: 5,
                c_by: id_
            }
             axios.post('http://1.10.184.64:3000/basemaster/insert.php', qs.stringify(requestBody), config)
                .then((result) =>
                {
                    fetch('http://1.10.184.64:3000/basemaster/select.php')
                        .then(response => response.json())
                       .then(data => {
                         var data = data.filter(x=>x.flat=='0'&&x.type==5)
                         this.setState({ data: data });
                      });
                });
    }
  _name =(event) =>
   {
      this.setState({name:event.target.value })
   }
    _dunit =(event) =>
   {
      this.setState({dunit:event.target.value })
   }
   _upload(event) {

      var name = new Date().toLocaleString().replace(',','').replaceAll(':','').replaceAll(' ','')
        this.setState({
            selectedFile: event.target.files[0],
            path:'uploads/file/master/kp/'+this.state.name,
            lastname:event.target.value.split('.')[1]
        })
    }

    upload()
    {

        const data = new FormData()
        data.append('file', this.state.selectedFile)
        let url = "http://1.10.184.64:3000/uploads/kp.php?id="+this.state.name;
        axios.post(url, data,
             {
                 //this.setState({ path:'uploads/file/master/'+this.state.name+''});
             })
            .then(res =>
            {

            })
                //this.setState({ path: 'uploads/file/master/' + this.state.name + '' });

    }

    select_dept = (event)=>
    {
                   var vdunits = this.state.dunits.filter(x=>x.p_id==event.target.value);
                   this.setState({vdunits :vdunits,dept:event.target.value})
    }

    _del =()=>{

             const qs = require('querystring')
               const config =
               {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
               }
            const requestBody =
            {
                id: this.state.delid,
            }
           axios.post(url+'/basemaster/delete.php', qs.stringify(requestBody), config)
                .then((result) =>
                {
                    fetch(url+'/basemaster/select.php')
                        .then(response => response.json())
                        .then(_data => {

                           var data  = _data.filter(x=>x.flat=='0').sort((a, b) => (a.id > b.id ? -1 : 1))
                           this.setState({data: data,open1:false});
                      });
                });
  }

   _statue =(event)=>
   {
       var status =event.target.value;
        if(status==0){
           fetch(url+'/basemaster/select.php')
           .then(response => response.json())
           .then(_data => {

               var data  = _data.filter(x=>x.flat=='0'&&x.type==5).sort((a, b) => (a.id > b.id ? -1 : 1))
               this.setState({ data: data });
          });

          }else{

              fetch(url+'/basemaster/select.php')
               .then(response => response.json())
               .then(_data => {

                   var data  = _data.filter(x=>x.flat=='1'&&x.type==5).sort((a, b) => (a.id > b.id ? -1 : 1))
                   this.setState({ data: data });
              });

          }
          this.setState({statue:event.target.value})
   }
   handleDownload = (url, filename) => {
             axios({
                  url: url,
                  method: 'GET',
                  responseType: 'blob', // important
                }).then((response) => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', filename);
                  document.body.appendChild(link);
                  link.click();
                });

    }

  render() {
            const {data, order, orderBy, selected, rowsPerPage, page,statue} = this.state;

            let __depts = this.state.depts;
                    let deptItems = __depts.map((dept) =>
                       <MenuItem    key={dept.id} value={dept.id}>
                       <span className="ml-2"> {dept.name} </span></MenuItem>
            );
            let dunits = this.state.vdunits;
                    let dunitsItems = dunits.map((dunit) =>
                       <MenuItem selected="{this.state.dunit}"
                         key={dunit.id} value={dunit.id}>
                       <span className="ml-2"> {dunit.name} </span></MenuItem>
                );
    return (
    <div>
    <div class="page-heading d-sm-flex justify-content-sm-between align-items-sm mb-0 p-2  bg-geekblue ">
          <h5 class="title p-1 mb-sm-0"></h5>
              <nav class="mb-0" aria-label="breadcrumb">
                      <ol class="breadcrumb">
                                     <div class="breadcrumb-item">
                                        <div   style={{color: 'gray', textDecoration: 'none'}}  >
                                         สถานการณ์ปกติ
                                        </div>

                                        <div className="pl-1 pr-1"> > </div>
                                        <div   style={{color: 'white', textDecoration: 'none'}}  >
                                          ด้านส่งกำลังบำรุง
                                        </div>
                                     </div>
                      </ol>
              </nav>
    </div>
    <div className="p-2 pt-3" >
    <ProfileHeader/>

    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                       <label className="ml-4 mt-4 text-white"><h3></h3></label>
                               <div className="row m-3" style={{color:'#fff'}}>
                                     <NavLink to={{pathname:'/app/masterkb/fmasterkb'}}  >
                                                     <Button   className="bg-blue text-white mt-0 ml-3" >
                                                          <AddBoxIcon/>
                                                          <span className="ml-1" style={{fontFamily: 'Kanit' }}>
                                                                เพิ่ม
                                                          </span>
                                                     </Button>
                                     </NavLink>
                               </div>
                               <div className="row ml-3 mr-3 mb-3" style={{color:'#fff'}}>
                                   <div className="col-md-4 col-12 mb-3">
                                   <label className="">สถานะ</label>
                                       <div className="">
                                            <TextField
                                              select
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                              onChange={this._statue}
                                              value={statue}
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    ,backgroundColor:"#3f51b5"
                                                    }
                                                }}
                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"
                                                            ,fontFamily: 'Kanit'

                                                    }
                                                }}
                                        >
                                                     <MenuItem style={{fontFamily: 'Kanit' }} value={0}>ใช้งาน</MenuItem>
                                                     <MenuItem style={{fontFamily: 'Kanit' }} value={1}>ไม่ใช้งาน</MenuItem>
                                              </TextField>
                                       </div>
                                 </div>
                               </div>
                       </div>



        <div className="flex-auto">
          <div className="table-responsive-material bg-geekblue border border-primary">
            <Table className="" size="small" >
              <DataTableHead

                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow   hover >
                      <TableCell style={{ color:'white',fontFamily: 'Kanit' }} >{n.name}</TableCell>
                      <TableCell style={{ color:'white',fontFamily: 'Kanit' }} >
                            {n.path.trim()=='.'?'':
                          <Button   className="bg-blue text-white mt-0 ml-3"
                                onClick={() => {this.handleDownload(url+'/'+n.path, n.path.split('/')[n.path.split('/').length-1].trim() )}}>
                                <InsertDriveFileIcon />
                            </Button>
                       }
                      </TableCell>
                      <TableCell style={{ color:'white',fontFamily: 'Kanit' }}>
                       {
                         this.state.users.filter(x => x.id == n.c_by).length != 0 ?
                         this.state.users.filter(x => x.id == n.c_by)[0].fname :""
                       }
                      </TableCell>
                      <TableCell style={{ color:'white',fontFamily: 'Kanit' }}>
                      {
                         this.state.depts.filter(x => x.id == n.dept).length != 0 ?
                         this.state.depts.filter(x => x.id == n.dept)[0].name :""
                      }
                      </TableCell>
                      <TableCell style={{ color:'white',fontFamily: 'Kanit' }}>
                       {
                         this.state.dunits.filter(x => x.id == n.dunit).length != 0 ?
                         this.state.dunits.filter(x => x.id == n.dunit)[0].name :""
                       }
                      </TableCell>
                      <TableCell style={{ color:'white',fontFamily: 'Kanit' }} >

                                        {
                                           n.c_dt==null?'':
                                           n.c_dt.substring(8,10)+n.c_dt.split('T')[1].split('.')[0].replaceAll(':','').substring(0,4)+' '
                                        }
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="01"?"ม.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="02"?"ก.พ.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="03"?"มี.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="04"?"เม.ย.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="05"?"พ.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="06"?"มิ.ย.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="07"?"ก.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="08"?"ส.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="09"?"ก.ย.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="10"?"ต.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="11"?"พ.ย.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="12"?"ธ.ค.":""}
                                        {
                                            n.c_dt==null?'':" "+String((parseInt(n.c_dt.substring(0,4))+543)).substring(2,4)
                                        }

                      </TableCell>
                      <TableCell style={{color:'white',width:'200px'}} >
                           <div className="row">
                                <NavLink
                                            to={{pathname:'/app/masterkb/fmasterkb',search:'?id='+n.id}}  >
                                                 <Button   className="bg-blue text-white mt-0 ml-3">
                                                    <EditIcon />
                                                 </Button>
                                </NavLink>
                                <Button   className="bg-blue text-white mt-0 ml-3" onClick={()=>this.setState({delid:n.id,open1:true})} >
                                    <DeleteIcon/>
                                </Button>
                           </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                     style={{color:'white'}}
                    count={data.length}
                      labelDisplayedRows={({ from, to, count }) =>  <div style={{ color:'white',fontFamily: 'Kanit' }}>{from}-{to} จาก {count}</div>}
                    labelRowsPerPage=<div style={{ color:'white',fontFamily: 'Kanit' }}>รายการ</div>
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>
   </div>
 </div>
 </div>
 </div>
                <Dialog fullWidth={true}
                        maxWidth={'xs'} open={this.state.open1} onClose={this.handleRequestClose}>
                        <DialogContent className="bg-geekblue text-white"  >
                            <InfoIcon className="text-primary " />   ยืนยันการลบ.
                        <hr />
                        </DialogContent>
                        <DialogActions className="bg-geekblue">
                            <Button onClick={this._del} className="bg-success text-white">
                                    <CheckCircleIcon /> ใช่
                            </Button>

                            <Button onClick={()=>{this.setState({open1:false})}} className="bg-danger text-white">
                                     <CancelIcon /> ยกเลิก
                            </Button>
                        </DialogActions>
                </Dialog>
      </div>
      </div>
    );
  }
}

export default index;