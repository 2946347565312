import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import CloseIcon from '@material-ui/icons/Close';
import TableHead from '@material-ui/core/TableHead';
 import Button from '@material-ui/core/Button';
 import Snackbar from '@material-ui/core/Snackbar';
 import {DatePicker} from 'material-ui-pickers';
import { TextField, InputAdornment } from "@material-ui/core";
 import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import axios from "axios";
import SaveIcon from '@material-ui/icons/Save';
let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}

const columnData = [
  {id: 'name', align: true, disablePadding: false, label: 'ชื่อ'},
  {id: 'calories', align: true, disablePadding: false, label: 'ไฟล์แนบ'},
  {id: 'fat', align: true, disablePadding: false, label: 'ผูใช้สร้าง'},
  {id: 'carbs', align: true, disablePadding: false, label: 'หน่ยวงาน'},
  {id: 'protein', align: true, disablePadding: false, label: 'วันที่สร้าง'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

      constructor(props, context) {
        super(props, context);

        this.state = {
          order: 'asc',
          orderBy: '',
          selected: [],
          data: [
            createData('เอกสารประจำการ', '', 'พลทหาร วีระ','ยก', '20/10/2020'),
            createData('เอกสารหน่วย', '', 'พลทหาร ชิด','ยก','20/10/2020'),
            createData('เอกสารทั่วไป', '',  'พลทหาร ยอด','ยก','20/10/2020'),
          ].sort((a, b) => (a.calories < b.calories ? -1 : 1)),
          page: 0,
          rowsPerPage: 5,
          name:'',
          army:'',
          weapon:'',
          vehicle:'',
          communication:'',
          moving_plan:'',
          action_plan:'',
          open:'',
          close:'',
          dataComptele: false,
          DMYYYY:'',
          DMYYYYEnd:'',
          getdate:'',
          status:''

        };
            var location = window.location.href;
                                    const str = '' + location;
                                    const xid = str.split("=")[1];
         fetch('http://1.10.184.64:3000/actionplan/select.php?id='+xid)
                                              .then(response => response.json())
                                              .then(data => {

                                                  this.setState({
                                                  name :data.name,
                                                  army : data.army,
                                                  weapon: data.weapon,
                                                  vehicle: data.vehicle,
                                                  communication: data.communication,
                                                  moving_plan:data.moving_plan,
                                                  action_plan:data.action_plan,
                                                  status:data.status
                                                  })

                                              });

                    var today = new Date();
                    var date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();

                   this.setState({getdate:date})
      }

        save =(event, values)=>
      {

              this.setState({ dataComptele:true });
                    var location = window.location.href;
                                const str = '' + location;
                                const xid = str.split("=")[1];

              var today = new Date();
              var date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
                    const requestBody =
                    {
                       id: xid,
                       army: this.state.army,
                       weapon: this.state.weapon,
                       vehicle: this.state.vehicle,
                       communication: this.state.communication,
                       moving_plan: this.state.moving_plan,
                       action_plan: this.state.action_plan,
                       open_datetime: this.state.DMYYYY==''?date:this.state.DMYYYY,
                       close_datetime:  this.state.DMYYYYEnd==''?date:this.state.DMYYYYEnd,
                       time_count: 1,
                       status:this.state.status
                    }

                        const config = {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }

            const qs = require('querystring')
            axios.post('http://1.10.184.64:3000/actionplan/updateConfig.php', qs.stringify(requestBody), config)
                .then((result) => {
              //    this.setState({ dataComptele:false });

                })
           //this.setState({ weapon: event.target.value });
      }

         _weapon =(event, values)=>
      {
           this.setState({ weapon: event.target.value });
      }

        _vehicle =(event, values)=>
      {
         this.setState({ vehicle:  event.target.value });
      }

      _communication =(event, values)=>
      {
         this.setState({ communication:  event.target.value });
      }
      _moving_plan =(event, values)=>
      {
        this.setState({ moving_plan:  event.target.value });
      }

      _action_plan =(event, values)=>
      {
       this.setState({ action_plan: event.target.value  });
      }

      _army =(event, values)=>
      {

        this.setState({ army: event.target.value  });
      }
      _open =(event, values)=>
      {

      //  this.setState({ open: event.target.value  });
      }
        _close =(event, values)=>
      {

       // this.setState({ close: event.target.value  });
      }
       handleDateChange = (event) => {
      // console.log(event.target.value+"handleDateChange");
       //var D      = date.format('D');
       //var M      = date.format('M');
       //var YYYY   = date.format('YYYY');
       //var dmyyyy = YYYY+"-"+M+"-"+D;
       console.log(event.target.value+"recheck")
        this.setState({open: event.target.value,DMYYYY: event.target.value});
      };
        handleDateChangeEnd = (event) => {
      // console.log(event.target.value+"handleDateChange");
    //    var D      = date.format('D');
    //    var M      = date.format('M');
    //    var YYYY   = date.format('YYYY');
    //    var dmyyyy = YYYY+"-"+M+"-"+D;

      this.setState({close: event.target.value,DMYYYYEnd: event.target.value});
      };

   selectStatus =(event)=>
   {
    //console.log(event.target.value)
      this.setState({status:event.target.value})
   }

  render() {
    const {
    data, order, orderBy, selected, rowsPerPage, page,name,army,status,
    action_plan,moving_plan,communication,vehicle,weapon,close,open,getdate,
    } = this.state;

    return (
    <div className="p-2 pt-3" >
    <ProfileHeader/>

    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                       <label className="ml-4 mt-4 text-white"><h3>{name}</h3></label>
                            <div className="row ml-3 mr-3 mb-3" style={{color:'#fff'}}>

                                 <div className="col-md-3 col-12 mb-3">
                                   <label className="">กำลังพล</label>
                                       <div className="">

                                            <TextField
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                            onChange={this._army}
                                            value={army}
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    ,backgroundColor:"#3f51b5"
                                                    }
                                                }}
                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"

                                                    }
                                                }}
                                        />
                                       </div>
                                 </div>
                                 <div className="col-md-3 col-12">
                                   <label className="">อาวุธ/ยุทโธปกรณ์</label>
                                       <div className="bg-primary">

                                             <TextField
                                                onChange={this._weapon}
                                            value={weapon}
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    ,backgroundColor:"#3f51b5"
                                                    }
                                                }}
                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"

                                                    }
                                                }}
                                        />
                                       </div>
                                 </div>
                                 <div className="col-md-3 col-12">
                                   <label className="">ยานพาหนะ</label>
                                       <div className="bg-primary">

                                             <TextField
                                                  onChange={this._vehicle}
                                            value={vehicle}
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    ,backgroundColor:"#3f51b5"
                                                    }
                                                }}
                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"

                                                    }
                                                }}
                                        />
                                       </div>
                                 </div>
                                 <div className="col-md-3 col-12">
                                   <label className="">การติดต่อสื่อสาร</label>
                                       <div className="bg-primary">

                                             <TextField
                                             onChange={this._communication}
                                             value={communication}
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    ,backgroundColor:"#3f51b5"
                                                    }
                                                }}
                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"

                                                    }
                                                }}
                                        />
                                       </div>
                                 </div>
                                 <div className="col-md-3 col-12">
                                   <label className="">แผนการเคลื่อนย้าย</label>
                                       <div className="bg-primary">

                                             <TextField
                                             onChange={this._moving_plan}
                                             value={moving_plan}
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    ,backgroundColor:"#3f51b5"
                                                    }
                                                }}
                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"

                                                    }
                                                }}
                                        />
                                       </div>
                                 </div>
                                 <div className="col-md-3 col-12">
                                   <label className="">แผนปฏิบัติการ</label>
                                       <div className="bg-primary">

                                             <TextField
                                              onChange={this._action_plan}
                                              value={action_plan}
                                                    id="fname"
                                                    margin="normal"
                                                    fullWidth
                                                    variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                height: "40px"
                                                            ,backgroundColor:"#3f51b5"
                                                            }
                                                        }}
                                                  style={{margin:0}}
                                                  InputProps={{

                                                      style: {
                                                              height: "40px"
                                                             ,width: "100%"
                                                             ,backgroundColor:"#3f51b5"
                                                             ,color:"#fff"

                                                        }
                                                    }}
                                              />
                                       </div>
                                 </div>
                                 <div className="col-md-3 col-12">
                                   <label className="">เวลาที่เริ่ม</label>
                                    <div key="basic_day" className="picker">
                                                 <TextField
                                                    id="datetime-local"
                                                    type="datetime-local"
                                                    //defaultValue={this.state.getDate}
                                                    onChange={this.handleDateChange}
                                                    value={open==''? this.state.getDate:open}
                                                    InputProps={{
                                                                 style: {
                                                                        height: "40px"
                                                                         ,width: "100%"
                                                                         ,backgroundColor:"#3f51b5"
                                                                         ,color:"#fff"
                                                                     }
                                                         }}
                                                   //className={classes.textField}
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                  />
                                    </div>
                                 </div>
                                 <div className="col-md-3 col-12">
                                   <label className="">วันที่สิ้นสุด</label>
                                        <div key="basic_day" className="picker">
                                                    <TextField
                                                    id="datetime-local"
                                                    type="datetime-local"
                                                    //defaultValue={this.state.getDate}
                                                    value={close==''?this.state.getDate:close}
                                                    onChange={this.handleDateChangeEnd}
                                                    InputProps={{
                                                                 style: {
                                                                        height: "40px"
                                                                         ,width: "100%"
                                                                         ,backgroundColor:"#3f51b5"
                                                                         ,color:"#fff"
                                                                     }
                                                         }}
                                                   //className={classes.textField}
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                  />
                                    </div>
                                 </div>

                            </div>
                            <div className="row m-3" style={{color:'#fff'}}>
                                    <div className="col-md-3 col-12">
                                         <label className="">สถานการณ์ระดับ</label>
                                            <div key="basic_day" className="picker">
                                                    <TextField
                                                            select
                                                            required
                                                            margin="normal"
                                                            value={status}
                                                            onChange={this.selectStatus}
                                                            fullWidth
                                                            variant="outlined"
                                                                InputProps={{
                                                                    style: {
                                                                        height: "40px",
                                                                        backgroundColor:"#3f51b5",
                                                                         color:"#fff"
                                                                    }
                                                                }}
                                                              style={{margin:0}}
                                                                 InputProps={{
                                                                  startAdornment: (
                                                                    <InputAdornment >
                                                                    </InputAdornment>
                                                                  ),
                                                                  style: {
                                                                        height: "40px",
                                                                        color:"#fff",
                                                                        backgroundColor:"#3f51b5"
                                                                    }
                                                                }}

                                                        >
                                                         <MenuItem value={0}>สีเขียว</MenuItem>
                                                         <MenuItem value={1}>สีเหลือง</MenuItem>
                                                         <MenuItem value={2}>สีส้ม</MenuItem>
                                                         <MenuItem value={3}>สีแดง</MenuItem>

                                                   </TextField>
                                            </div>
                                   </div>
                                 </div>




                   <div className="row m-3" style={{color:'#fff'}}>
                     <Button   className="bg-blue text-white mt-0 ml-3" onClick={this.save}>
                     <SaveIcon/>
                          <span className="ml-1">  บันทึก  </span>
                    </Button>
                 </div>
                 </div>

             <div className="flex-auto">
            </div>
           </div>
          </div>
         </div>
        </div>

         <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={this.state.dataComptele}
                        autoHideDuration={1000}
                        onClose={() => this.setState({ dataComptele: false })}
                        message="บันทึกสำเร็จ"
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.handleRequestClose}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
      </div>
    );
  }
}

export default index;