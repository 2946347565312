
    import React from 'react';
    import cookie from 'react-cookies'
    import PropTypes from 'prop-types';
    import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
    import {withStyles} from '@material-ui/core/styles';
    import classNames from 'classnames';
    import Slider from '@material-ui/core/Slider';
    import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
    import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
    import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
    import LiveHelpIcon from '@material-ui/icons/LiveHelp';
    import imgst from "./mpa_st.png";
    import Paper from '@material-ui/core/Paper';
    import SearchIcon from '@material-ui/icons/Search';
    import DeleteIcon from '@material-ui/icons/Delete';
    import Tooltip from '@material-ui/core/Tooltip';
    import IconButton from '@material-ui/core/IconButton';
    import CloseIcon from '@material-ui/icons/Close';
    import Drawer from '@material-ui/core/Drawer';
    import LabelIcon from '@material-ui/icons/Label';
	import PlaceIcon from '@material-ui/icons/Place';
	import SportsHandballIcon from '@material-ui/icons/SportsHandball';
    import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
    import AppBar from '@material-ui/core/AppBar';
    import CustomScrollbars from 'util/CustomScrollbars';
    import Autocomplete from '@material-ui/lab/Autocomplete';
    import RadioGroup from '@material-ui/core/RadioGroup';
    import LayersIcon from '@material-ui/icons/Layers';
    import DeckGL from '@deck.gl/react';
    import CKEditor from '@ckeditor/ckeditor5-react';
    import Toolbar from '@material-ui/core/Toolbar';
    import MenuItem from '@material-ui/core/MenuItem';
    import Dialog from '@material-ui/core/Dialog';
    import DialogActions from '@material-ui/core/DialogActions';
    import DialogContent from '@material-ui/core/DialogContent';
    import DialogContentText from '@material-ui/core/DialogContentText';
    import DialogTitle from '@material-ui/core/DialogTitle';
    import List from '@material-ui/core/List';
    import IntlMessages from 'util/IntlMessages';
    import Typography from '@material-ui/core/Typography';
    import Divider from '@material-ui/core/Divider';
    import SquareFootRoundedIcon from '@material-ui/icons/SquareFootRounded';
    import Switch from '@material-ui/core/Switch';
    import LanguageIcon from '@material-ui/icons/Language';
    import axios from "axios";
    import imggeolocation2 from "./geolocation2.png";
    import MenuIcon from '@material-ui/icons/Menu';
    import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
    import ChevronRightIcon from '@material-ui/icons/ChevronRight';
    import Checkbox from '@material-ui/core/Checkbox';
    import Button from '@material-ui/core/Button';
    import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
    import AccessibilityIcon from '@material-ui/icons/Accessibility';
    import Snackbar from '@material-ui/core/Snackbar';
    import ReactMapboxGl, { Layer,Feature,Source } from 'react-mapbox-gl';
    import ReactStreetview from 'react-streetview';
    import MapGL,{ NavigationControl ,ScaleControl, FullscreenControl,AttributionControl,GeolocateControl  }   from '@urbica/react-map-gl';
    import Draw from '@urbica/react-map-gl-draw';
    import HomeWorkIcon from '@material-ui/icons/HomeWork';
    import { TextField, InputAdornment } from "@material-ui/core";
    import MyLocationIcon from '@material-ui/icons/MyLocation';
    import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
    import HealingRoundedIcon from '@material-ui/icons/HealingRounded';
    import RoomIcon from '@material-ui/icons/Room';
    import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
    import Radio from '@material-ui/core/Radio';
    import FullScreen from './dropzone/FullScreen/index';
    import FormControl from '@material-ui/core/FormControl';
    import FormLabel from '@material-ui/core/FormLabel';
    import FormControlLabel from '@material-ui/core/FormControlLabel';
    import FormGroup from '@material-ui/core/FormGroup';
    import FormHelperText from '@material-ui/core/FormHelperText';
    import MapMesure from 'mapboxgl-measure-tool'
    import { connect } from 'react-redux';
    import mapboxgl,{MarkerViewOptions ,LatLng} from 'mapbox-gl';
    import imgmarkerbk from "./map-markerbk.png";
    import imgmarkersniper from "./map-markersniper.png";
    import imgmarkerlocation from "./map-markerlocation.png";
    import imgmarkerdistance from "./map-markerdistance.png";
    import imgmarkerterrorist from "./map-marker_terrorist.png";
    import { compose, withProps } from "recompose";
    import bkmarkerIcon from "./bk_pin.svg";
    import smarkerIcon from "./Sniper.svg";
    import locationIcon from "./location.svg";
    import stlocationIcon from "./stNav.svg";
    import nmarkerIcon from "./Nav.svg";
    import Visibility from "@material-ui/icons/Visibility";
    import VisibilityOff from "@material-ui/icons/VisibilityOff";
    import CreditCardIcon from '@material-ui/icons/CreditCard';
    import GroupIcon from '@material-ui/icons/Group';
    import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
    import SaveIcon from '@material-ui/icons/Save';
    import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
    import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
    import GetAppIcon from '@material-ui/icons/GetApp';
    import CallIcon from '@material-ui/icons/Call';
    import AccountCircle from '@material-ui/icons/AccountCircle';
    import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
    import PoolIcon from '@material-ui/icons/Pool';
    import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
    import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
    import InfoIcon from '@material-ui/icons/Info';
    import LocationCityIcon from '@material-ui/icons/LocationCity';
    import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import Tabs from '@material-ui/core/Tabs';
    import Tab from '@material-ui/core/Tab';
     import TableHead from '@material-ui/core/TableHead';
    import TablePagination from '@material-ui/core/TablePagination';
    import TableRow from '@material-ui/core/TableRow';
    import TableSortLabel from '@material-ui/core/TableSortLabel';
    import Table from '@material-ui/core/Table';
    import Avatar from '@material-ui/core/Avatar';
    import TableBody from '@material-ui/core/TableBody';
    import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import ImageIcon from '@material-ui/icons/Image';
import { makeStyles } from "@material-ui/core/styles";
import {GoogleMap, OverlayView, StreetViewPanorama, withGoogleMap,withScriptjs,Marker} from "react-google-maps";

            const { point } = require('@turf/helpers')
            const distance = require('@turf/distance').default
            const drawerWidth = 330;

            const useStyles = makeStyles(theme => ({
  inputRoot: {
    color: "purple",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "green"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple"
    }
  }
}));



              let counter = 0;


            function createData(id,code,name, calories, fat, carbs, protein) {
              counter += 1;
              return {id: counter,code, name, calories, fat, carbs, protein};
            }


            const columnData = [
                {id: '1', align: true, disablePadding: false, label: 'ชื่อ/สถานที่'},
                {id: '2', align: true, disablePadding: false, label:  'ชั้น'},
                {id: '3', align: true, disablePadding: false, label:'ความจุ' }
            ];

            class DataTableHead extends React.Component {
              static propTypes = {
                numSelected: PropTypes.number.isRequired,
                onRequestSort: PropTypes.func.isRequired,
                onSelectAllClick: PropTypes.func.isRequired,
                order: PropTypes.string.isRequired,
                orderBy: PropTypes.string.isRequired,
                rowCount: PropTypes.number.isRequired,
              };

              createSortHandler = property => event => {
                this.props.onRequestSort(event, property);
              };

              render() {
                const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

                return (
                  <TableHead   className="bg-primary" >
                    <TableRow>

                      {columnData.map(column => {
                        return (
                          <TableCell
                            key={column.id} className="bg-primary"
                            style={{color:'#fff'}}
                            align={column.numeric}
                            padding={column.disablePadding ? 'none' : 'default'}
                          >
                            <Tooltip
                              title="Sort"
                              placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                              enterDelay={300}
                            >
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={order}
                                onClick={this.createSortHandler(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </Tooltip>
                          </TableCell>
                        );
                      }, this)}
                    </TableRow>
                  </TableHead>
                );


              }
            }
            const getItems = count =>
              Array.from({ length: count }, (v, k) => k).map(k => ({
                id: `item-${k}`,
                content: `item ${k}`
              }));

            // a little function to help us with reordering the result
            const reorder = (list, startIndex, endIndex) => {
                  const result = Array.from(list);
                  const [removed] = result.splice(startIndex, 1);
                  result.splice(endIndex, 0, removed);
                  return result;
            };

            const grid = 8;

            const getItemStyle = (isDragging, draggableStyle) => ({
              userSelect: "none",
              padding: grid * 2,
              margin: `0 0 ${grid}px 0`,
              background: isDragging ? "lightgreen" : "grey",
              ...draggableStyle
            });

            const getListStyle = isDraggingOver => ({
              background: isDraggingOver ? "lightblue" : "lightgrey",
              padding: grid,
              width: 250
            });


         let polygonPaint = ReactMapboxGl.FillPaint = {
            'fill-color': "red",
            'fill-opacity': 0.3
        }

        let _point500m = ReactMapboxGl.FillPaint = {
            'fill-color': "red",
            'fill-opacity': 0.3
        }
        let _point100m = ReactMapboxGl.FillPaint = {
            'fill-color': "#FFFF00",
            'fill-opacity': 0.1
        }




let DataTableToolbar = props => {

  const {numSelected} = props;

  return (

    <div className="table-header bg-geekblue border border-primary">
      <div className="title">

        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
              ) : (
                      <div className="" style={{ padding: '0' }}>

                       </div>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
        const styles = theme => ({


          root: {
            width: '100%',
            height: '100%',
            zIndex: 1,
            overflow: 'hidden',
          },
          appFrame: {
            position: 'relative',
            display: 'flex',
            width: '100%',
            height: '100%',
          },
          appBar: {
            position: 'absolute',
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          },
          appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
          menuButton: {
            marginLeft: 12,
            marginRight: 20,
          },
          hide: {
            display: 'none',
          },
          drawerPaper: {
            position: 'relative',
            height: '100%',
            width: drawerWidth,
          },
          drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
          },
          content: {
            width: '100%',
            marginLeft: -drawerWidth,
            flexGrow: 1,
            padding: theme.spacing.unit * 3,
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginTop: 56,
            [theme.breakpoints.up('sm')]: {
              content: {
                height: 'calc(100% - 64px)',
                marginTop: 64,
              },
            },
          },
          contentShift: {
            marginLeft: 0,
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        });



         var  latData ="";
         var  lngData ="";
         var _latitude="";
         var _longitude="";
         var  xx= 0;
         var  yy= 0;
         var startview ="";
         var  _min="";
         var  _max="";
         var  find ="";
         var   actionBK ="false";
         var   lngBK =0;
         var   latBK =0;
         var   actionSniper ="false";
         var   lngSP =0;
         var   latSP =0;
         var   actionlocation ="false";
         var   lngLT =0;
         var   latLT =0;
         var   actionDistance ="false";
         var   lngDis =0;
         var   latDis =0;
         var   actionStreetview ="false";
         var   lngSV =0;
         var   latSV =0;
         var    geojsondata =[];
        function getPixelPositionOffset(width, height) {
          return {x: -(width / 2), y: -(height / 2)};
        }

        const STYLES = {
          overlayView: {
            background: `red`,
            color: `white`,
            padding: 5,
            borderRadius: `50%`,
          },
        };


        const coordinates = {lat: 13.700174, lng: 100.4088213};

        const StreetViewPanoramaExampleGoogleMap = compose(
       withProps
              ({
                googleMapURL:
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyCeuN-U26oP2_XLQ6RuWUjHIoeNiAYoLWA",
                loadingElement: <div style={{ height: '100%' }} />,
                containerElement: <div style={{ height: '100%' }} />,
                mapElement: <div style={{ height: '100%' }} />
              })
              ,withScriptjs,withGoogleMap)(props => (

       <GoogleMap defaultZoom={8} defaultCenter={{ lat: 13.700174, lng: 100.4088213 }}>
                  <StreetViewPanorama
                    defaultPosition={{ lat: 13.700174, lng: 100.4088213}}
                    visible  >
                  </StreetViewPanorama>
       </GoogleMap>
    ));
     /*formpopup*/
      function TabContainer(props) {
              return (
                <div style={{padding: 0}}>
                  {props.children}
                </div>
              );
            }
    class index extends React.Component {


          state = {
            open: false,
            openForm: false,
              popupbk: false,
            zopen:0,
              _src:"http://1.10.184.64:3000/map/streetview.php",
            vslng:"",
            vslat:"",
            _srclos:""


          };


          handleDrawerOpen = () => {
            this.setState({open: true});

          };

          handleDrawerClose = () => {
            this.setState({open: false});

          };


    constructor(props, context)
            {
                    super(props, context);
                    this.state = {
                    polygonM:'',
                        polygonMBK:'',
                         polygonMaster:'',
                    idplen:0,

                     tabledata: [].sort((a, b) => (a.code < b.code ? -1 : 1)),
                        page: 0,
                     rowsPerPage: 5,
                    selectedFile:'',
                    selectedFile:'',
                      dd:-118.4107187,
                      oo:33.9415889,
                      items: getItems(10),
                      open:true,
                      _SVy:0,
                      _SVx:0,
                      isToggle: null,
                      y1:'0',
                      x1:'0',
                       ch_poi_info:true,
                      weapon:[],
                      _y1:'0',
                      _x1:'0',
                      _lngSP:0,
                      _latSP:0,
                      _lngDis:0,
                      _latDis:0,
                      load_data:false,
                      datapolygon:"[[[]]]",
                      dataComptele: false,
                      lbl_nstorey:"",
                      lbl_name:"",
                      roadincount : 0,
                      roadoutcount : 0,
                      roadmin:0,
                      roadmax:0,
                      poi_plan_report:[],
                      arraydatapolygon:[],
                      arraypolygon1000m:[],
                      point500m:"[[[]]]",
                      point1000m:"[[[]]]",
                      nonelayer:"none",
                      nonelayer2:"none",
                      nonedis:"none",
                      stcolor:"#28a745",
                       _3dcolor:"#ffc107",
                       text_rpt:'',
                       usersType:'',
                      films:['The Shawshank Redemption'],
                      name:'',
                      gotoname:'',
                      openmsg: false,
                      openmsg2: false,
                      dataError: false,
                      duperror: false,
                      vertical: 'top',
                      horizontal: 'center',
                      point:null,
                      selectWeapon:'0',
                      weaponmin:'500',
                      weaponmax:'1000',
                      searchMail: '',
                      noContentFoundMessage: 'No mail found in selected folder',
                      alertMessage: '',
                      showMessage: false,
                      drawerStateMin: false,
                      drawerState: false,
                      optionName: 'None',
                      anchorEl: null,
                      lat:0,
                      data:null,
                      lon:0,
                      maplng: 101.490104,
                      maplat: 13.03887,
                      mapzoom: 5,
                      Updatemapzoom: 0,
                      loader: false,
                      currentMail: null,
                      user: {
                        name: 'Robert Johnson',
                        email: 'robert@example.com',
                        avatar: 'https://via.placeholder.com/150x150'
                      },
                      selectedMails: 0,
                      selectedFolder: 0,
                      composeMail: false,
                      labelMenuState: false,
                      folderMenuState: false,
                      optionMenuState: false,
                      bs_name:"",
                      bs_nstorey:"",
                       bs_employ:"",
                       ly_height:"",
                      ly_overflowY:"",
                      bl_name:"",
                      bl_nstorey:"",
                      bl_employ:"",
                      bbk:"",
                      bs:"",
                      markerlng:"",
                      markerlat:"",
                      stmarkerlng:"",
                      stmarkerlat:"",
                      smarkerlng:"",
                      smarkerlat:"",
                      hidmarker1:"",
                      hidsmarker2:"",
                      vdistance: "",
                      directions:[],
                      nmarkerlng:"",
                      nmarkerlat:"",
                      listblg:[],
                      datainfo:[],
                       ststyle:"none",
                      ststylelos:"none",
                      nm:"",
                      apidistance:"",
                      apiduration:"",
                      colorBK:"#990099",
                      disabledBK:false,
                       _disabledBK:true,
                      colorSC:"bg-blue text-white mt-0 ml-4",
                      disabledSC:false,
                     colorSNI:"#FF4500",
                      disabledSNI:false,
                      _disabledSNI:true,
                      colorDIS:"#663333",
                      tablestatus:'',
                      disabledDIS:false,
                       /*FormPopup*/
                      province: [],
                      provinceGS: [],
                      district:[],
                      subdistrict:[],
                      province_code: '',
                      district_code: '',
                      sub_district_code: '',
                      _editor: ClassicEditor,
                      _content: props.content,
                        tabvalue: 0,
                         Gotoprovince: '',
                           Gotodistrict: '',
                            Gotosubdistrict: '',
                            images: [],
                            _geojson: [],
                       /*FormPopup*/
                       /*bk*/
                       _lngBK:0,
                       _latBK:0,
                       listroadin:[],
                       listroadout:[],
                        layer11:"none",
                       layer22:"none",
                       layer1:"none",
                       layer2:"none",
                       layer3:"none",
                       layer4:"none",
                       layer5:"none",

                       layer111:"none",
                       layer222:"none",
                       layer333:"none",

                       plan_status:0,
                        inmap:true,
                        outmap:true,
                        c_inpopup: 0,
                        c_outpopup: 0,
                        distext:true,
                        editorState: 'xxxxxxxxxxxxxxxxxxxx',
                        layerout:false,
                        layerin: false,
                        wname:'',
                        road_dwr:false,
                        waterway_dwr:false,
                        road_esri_dwr:false,
                        road_area_dwr:false,

                        contour20_dwr:false,
                        dem12_5_dwr:false,
                        dem30_hillshade_dwr:false,
                        NEXTMapOneTerrainHillshadeDSM_dwr:false,
                        dsm_dwr:false,


                        LDD_RASTER_WM_CACHE_dwr:false,
                        L7018L7018_dwr:false,
                        smpklandcheck_dwr:false,
                        bldg_dwr:false,

                        eventname:'',
                        typemap:{        "version": 8,
                                                  "name": "OSM",
                                                  "metadata": {
                                                  },
                                                  "sources": {
                                                    "osm": {
                                                      "type": "raster",
                                                      "tiles": [
                                                        "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                      ]
                                                    }
                                                  },
                                                  "layers": [
                                                    {
                                                      "id": "osm",
                                                      "type": "raster",
                                                      "source": "osm"
                                                    }
                                                  ],
                                                  "id": "klokantech-basic"
                                                }

                      ,omap:{"version": 8,
                                                  "name": "OSM",
                                                  "metadata": {
                                                  },
                                                  "sources": {
                                                    "osm": {
                                                      "type": "raster",
                                                      "tiles": [
                                                        "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                      ]
                                                    }
                                                  },
                                                  "layers": [
                                                    {
                                                      "id": "osm",
                                                      "type": "raster",
                                                      "source": "osm"
                                                    }
                                                  ],
                                                  "id": "klokantech-basic"
                                                },




                                   }
                                    this._upload_bldg = this._upload_bldg.bind(this);
                                    this._upload_enter = this._upload_enter.bind(this);
                                    this._upload_exit = this._upload_exit.bind(this);
                                    var x_geojson =[];
                                    var directions = [];
                                    var directionsData = [];
                                    var location = window.location.href;
                                    const str = '' + location;
                                    const xid = str.split("=")[1];

                      fetch('http://1.10.184.64:3000/poi_plan_report/index.php?plan_id='+xid)
                                        .then(response => response.json())
                                        .then(data => {
                                          console.log(data)
                                                  this.map.loadImage(
                                                       imggeolocation2,
                                                 (error, image) => {

                                                 try{


                                                   this.map.addImage("imggeolocation2", image);
                                                   var _data = [];
                                                 for(var i=0;i<data.length-1;i++)
                                                 {
                                                     _data.push
                                                     ({
                                                     'type':'Feature',
                                                     'geometry':{'type':'Point','coordinates':[data[i].y,data[i].x]},
                                                     'properties':{'title':'ff'}
                                                     })
                                                 }
                                                  this.map.addSource('points', {
                                                        'type': 'geojson',
                                                        'data': {
                                                        'type': 'FeatureCollection',
                                                                    'features':  JSON.parse(JSON.stringify(_data))
                                                        }
                                                    });

                                                    this.map.addLayer({
                                                            'id': "points",
                                                            'type': 'symbol',
                                                            'source': 'points',
                                                            'layout': {
                                                            'icon-image': 'imggeolocation2',
                                                            'icon-size': 0.1
                                                                }
                                                            });

                                                        this.map.on('click', function (e) {

                                                                         var lngLat =e.lngLat.wrap();
                                                                                _longitude=lngLat.lng;
                                                                                _latitude=lngLat.lat;




                                                        });
                                                        }catch{}
                                             });
                                               var sdata = data.sort((a, b) => (a.id < b.id ? 1 : -1));

                                              if(JSON.stringify(data[0])=="{}")
                                              {
                                                data =[];
                                              }

                                                 this.setState({ poi_plan_report: data, loading: false });
                                        });



                    fetch('http://1.10.184.64:3000/route_plan/select.php?plan_id='+xid)
                                     .then(response => response.json())
                                     .then(data => {

                                         if(typeof data.routes !== "undefined")
                                                     {

                                                         var distance=  data.routes[0].distance;
                                                          var duration=  data.routes[0].duration;
                                                          this.setState({apiduration:duration,apidistance:distance})
                                                         for (var ii=0;ii<data.routes[0].legs.[0].steps.length;ii++)
                                                        {
                                                        var rows =data.routes[0].legs.[0].steps[ii].maneuver.location;
                                                           directions.push(rows);
                                                            var rowsData =data.routes[0].legs.[0].steps[ii].maneuver;


                                                           directionsData.push(typeof rowsData.modifier==='undefined'?"": rowsData.modifier+" ประเภท:"+rowsData.type+" คำแนะนำ:"+rowsData.instruction);
                                                           for (var iii=0;iii<data.routes[0].legs.[0].steps[ii].intersections.length;iii++)
                                                           {
                                                             var rows2 =data.routes[0].legs.[0].steps.[ii].intersections[iii].location;
                                                            // directions.push(rows2);
                                                           }
                                                            for (var iiii=0;iiii<data.routes[0].legs.[0].steps[ii].geometry.coordinates.length;iiii++)
                                                           {
                                                             var rowsX =data.routes[0].legs.[0].steps.[ii].geometry.coordinates[iiii];
                                                             directions.push(rowsX);

                                                           }

                                                          }



                                                        }
                                                                       else
                                                                       {
                                                                                     //       this.setState({dataError:true})
                                                                       }

                                         this.setState({rdirections:directions});

                                         this.setState({datainfo:directionsData});

                                         var geom =data.geom.replace('MULTILINESTRING','').replaceAll(',','],[').replace('((','[[').replace('))',']]').replaceAll(' ',',')//.replaceAll(' ','],[').replace('((','[[').replace('))',']]');

                                         var _geom =  JSON.parse(geom);
                                         var mpLayer = this.map.getLayer("_routedis");
                                        if (typeof mpLayer === 'undefined') {
                                            // No Layer
                                        } else {

                                            this.map.removeLayer("_routedis");
                                             this.map.removeSource("routedis");
                                        }


                                                        this.map.addSource('routedis', {
                                                                'type': 'geojson',
                                                                'data': {
                                                                'type': 'Feature',
                                                                'properties': {},
                                                                'geometry': {
                                                                'type': 'LineString',
                                                                'coordinates': _geom
                                                                }
                                                                }
                                                        });
                                                        this.map.addLayer({
                                                                    'id': '_routedis',
                                                                    'type': 'line',
                                                                    'source': 'routedis',
                                                                    'layout': {
                                                                    'line-join': 'round',
                                                                    'line-cap': 'round'
                                                                    },
                                                                    'paint': {
                                                                    'line-color': 'blue',
                                                                    'line-width': 8
                                                                    }
                                                        });



                                              });






                                   this.setState({id:xid});
                                     if (xid != null) {
                            fetch('http://1.10.184.64:3000/weapon/index.php')
                                        .then(response => response.json())
                                        .then(data_weapon => {

                                                /// this.setState({ weapon: data, loading: false });
                                        var dataweapon =data_weapon;
                                      fetch('http://1.10.184.64:3000/actionplan/select.php?id='+xid)
                                          .then(response => response.json())
                                          .then(data => {



                                      //fetch('http://1.10.184.64:3000/bldg/autocomplete.php?id='+data.name)
                                         //    .then(response => response.json())
                                          //   .then(data => {
                                           //   this.setState({ listblg: data });
                                           // })



                                             this.setState({
                                                        eventname: data.event_name,
                                                        name: data.name,
                                                        lname: data.laname,
                                                        p_type: data.p_type,
                                                         p_form: data.p_form,
                                                         topography: data.topography,
                                                         population: data.population,
                                                         dominance: data.dominance,
                                                         religion:data.religion,
                                                         way_land: data.way_land,
                                                         way_water: data.way_water,
                                                         plan_status: data.plan_status,
                                                         Gotoprovince: data.province_code,
                                                         province_code: data.province_code,
                                                         Gotodistrict: data.district_code,
                                                         district_code: data.district_code,
                                                         Gotosubdistrict: data.sub_district_code,
                                                          sub_district_code: data.sub_district_code,
                                                         gotoname: data.name,
                                                         w_id:data.w_id,
                                                         lat:data.y,
                                                         lon:data.x,
                                                         selectWeapon:data.w_id==null?"0":data.w_id


                                                     });
                                                     var popup = new mapboxgl.Popup({ className:"poi_info"})
                                                                        .setLngLat([data.y, data.x])
                                                                        .setHTML('<div padding: 3px;"><div><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path></svg> พื้นที่เกิดเหตุ</div></div>')
                                                                         .addTo(this.map);


                              console.log(dataweapon)
                              console.log(data)
                                  var wm_shoot_min = dataweapon.filter(x=>x.id==data.w_id).length==0?0:dataweapon.filter(x=>x.id==data.w_id)[0].shoot_min



                                       var  shoot_max = wm_shoot_min*2;
                                       this.setState({weaponmin:wm_shoot_min,weaponmax:shoot_max})


                             var  y = data.y;
                             var  x = data.x;
                             var  w = dataweapon.filter(x=>x.id==data.w_id).length==0?"":dataweapon.filter(x=>x.id==data.w_id)[0].w_name ;
                            // var  win =  this.state.weapon.filter(x=>x.id==data.w_id)[0].w_name ;
                            // var  wout =  this.state.weapon.filter(x=>x.id==data.w_id)[0].w_name ;

                      this.setState({ wname: w });




         fetch('http://1.10.184.64:3000/bldg/point500m.php?lat=' + data.y + "&&lon=" + data.x + "&&m=" + wm_shoot_min)
            .then(response => response.json())
            .then(data => {
             try{

                this.setState({ disabledSBK: false, disabledSSNI: false, point500m: '[' + data[0].point + ']', loading: false, mapzoom: 15 });

                var polygon = JSON.parse(this.state.point500m);
                this.setState({ polygonM: data[0].point })
                var mapLayer = this.map.getLayer('_maine500');
                if (typeof mapLayer !== 'undefined') {

                    this.map.removeLayer('_maine500').removeSource('maine500');
                }
                this.map.addSource('maine500', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': polygon
                        }
                    }
                });
                this.map.addLayer({
                    'id': '_maine500',
                    'type': 'fill',
                    'source': 'maine500',
                    'layout': {},
                    'paint': {
                        'fill-color': "red",
                        'fill-opacity': 0.3
                    }
                });
                }catch
                {}
            });


             fetch('http://1.10.184.64:3000/bldg/point1000m.php?lat=' + data.y + "&&lon=" + data.x + "&&m=" + shoot_max)
            .then(response => response.json())
            .then(data => {

            try{
                this.setState({ point1000m: '[' + data[0].point + ']', loading: false, mapzoom: 15, polygonMBK: data[0].point });

                var polygon = JSON.parse(this.state.point1000m);
                var mapLayer = this.map.getLayer('_maine1000');
                if (typeof mapLayer !== 'undefined') {

                    this.map.removeLayer('_maine1000').removeSource('maine1000');
                }
                this.map.addSource('maine1000', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': polygon
                        }
                    }
                });
                this.map.addLayer({
                    'id': '_maine1000',
                    'type': 'fill',
                    'source': 'maine1000',
                    'layout': {},
                    'paint': {
                        'fill-color': "#FFFF00",
                        'fill-opacity': 0.1
                    }
                });

                }catch{  }
            });





          fetch('http://1.10.184.64:3000/roads/roadin.php?lat='+data.y+'&&lon='+data.x+'&&m='+wm_shoot_min+'')
                        .then(response => response.json())
                        .then(data => {
                             data = data.filter(x=>x.name!=null);

                                   var list =[];

                                   for(var i=0;i<data.length;i++)
                                   {
                                           var  value = list.filter(x => x.['name'] === data[i].name )

                                           if(value.length==0)
                                           {


                                             var  v = {name:data[i].name};
                                             list.push(v);

                                           }else{

                                           }

                                   }
                                    console.log("listroadin"+JSON.stringify(list))
                                     this.setState({listroadin:list})

                                    fetch('http://1.10.184.64:3000/roads/roadout.php?lat='+y+'&&lon='+x+'&&max='+shoot_max+'&&min=0')
                                        .then(response => response.json())
                                        .then(data => {

                                             data = data.filter(x=>x.name!=null);

                                                   var list =[];

                                                   for(var i=0;i<data.length;i++)
                                                   {
                                                           var  value = list.filter(x => x.['name'] === data[i].name )

                                                           if(value.length==0)
                                                           {

                                                             var  v = {name:data[i].name};
                                                             list.push(v);

                                                           }else{

                                                           }

                                                   }

                                                     this.setState({listroadout:list})
                                        });
                        });




                                  fetch('http://1.10.184.64:3000/bldg/index.php?id='+this.state.gotoname)
                                    .then(response => response.json())
                                    .then(data => {


                                         var item  = this.state.province;
                                         var pro_c = "";
                                         var sub_c = "";
                                         var dis_c = "";
                                         var bl_sub_dis =data[0].bl_sub_dis;
                                         var bl_distric =data[0].bl_distric;
                                         for(var i =0;i<=item.length-1;i++){

                                                  if(item[i].on_pro_tha==data[0].bl_changwa)
                                                    { pro_c = item[i].pro_c; break; }
                                         }

                                        this.setState({Gotoprovince: pro_c});
                                        fetch('http://1.10.184.64:3000/district/index.php?id='+pro_c)
                                            .then(response => response.json())
                                            .then(data => {

                                                    var disitem  = data;
                                                     for(var i =0;i<=disitem.length-1;i++){

                                                              if(disitem[i].on_dis_tha==bl_distric)
                                                               {
                                                                   dis_c = disitem[i].dis_c;
                                                                    this.setState({ Gotodistrict: dis_c,district: data})

                                                                           fetch('http://1.10.184.64:3000/subdistrict/index.php?id='+dis_c)
                                                                             .then(response => response.json())
                                                                             .then(data => {

                                                                                         for(var i =0;i<=data.length-1;i++){

                                                                                                  if(data[i].on_sub_tha==bl_sub_dis)
                                                                                                    {
                                                                                                      sub_c = data[i].sub_c;
                                                                                                      this.setState({ Gotosubdistrict: sub_c,subdistrict: data})
                                                                                                         //console.log(this.state.Gotosubdistrict)
                                                                                                     break;
                                                                                                     }
                                                                                         }
                                                                             });
                                                                   // console.log(this.state.Gotodistrict)
                                                                    break;
                                                               }

                                                     }
                                        });





                                         find ="true";

                                        // console.log([data[0].x+"ssssssssssssssssssssssssssssss"+data[0].y])
                                         this.map.flyTo({ center: [data[0].x, data[0].y],zoom:15});
                                           lngLT = data[0].x;
                                           latLT = data[0].y;
                                          var _geojson = this.state._geojson;
                                          _geojson.push({x:data[0].x, y:data[0].y})
                                          x_geojson.push({x:data[0].x, y:data[0].y})

                                         this.setState({

                                          maplat :data[0].x,
                                          maplng :data[0].y,
                                         lbl_name:data[0].bl_name,
                                         lbl_nstorey:data[0].bl_nstorey,
                                         lbl_employ:data[0].bl_employ,
                                         lat:data[0].x,
                                         lon:data[0].y,
                                         point:data[0].point,
                                         mapzoom:16,
                                          maplng:data[0].x,
                                          maplat: data[0].y,
                                          datapolygon:data[0].bld,
                                          _x1:data[0].x1,
                                          _y1:data[0].y1,
                                          Gotoprovince: pro_c,
                                          name: data[0].bl_name,
                                          _geojson: _geojson
                                           });

                                   var polygon =JSON.parse(this.state.datapolygon);
                                  try{

                                   var mapLayer = this.map.getLayer('_plan');
                                       if(typeof mapLayer !== 'undefined') {
                                          this.map.removeLayer('_plan').removeSource('plan');
                                       }
                                  }
                                  catch{}


                                           this.map.addSource('plan', {

                                                    'type': 'geojson',
                                                    'data':
                                                    {"features": [
                                                    {"type": "Feature","properties":
                                                    {"level": 1,"name": "Bird Exhibit","height": data[0].bl_nstorey+1,"base_height": 0,"color": "#CFBB10"},"geometry":
                                                    {"coordinates": polygon,
                                                    "type": "Polygon"},
                                                    "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"},
                                                     ],"type": "FeatureCollection"}
                                                    });


                                                    this.map.addLayer({
                                                                'id': '_plan',
                                                                'type': 'fill-extrusion',
                                                                'source': 'plan',
                                                                'paint': {
                                                                // See the Mapbox Style Specification for details on data expressions.
                                                                // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                                                // Get the fill-extrusion-color from the source 'color' property.
                                                                'fill-extrusion-color': ['get', 'color'],

                                                                // Get fill-extrusion-height from the source 'height' property.
                                                                'fill-extrusion-height': ['get', 'height'],

                                                                // Get fill-extrusion-base from the source 'base_height' property.
                                                                'fill-extrusion-base': ['get', 'base_height'],

                                                                // Make extrusions slightly opaque for see through indoor walls.
                                                                'fill-extrusion-opacity': 0.5
                                                                    }
                                                     });
                                        this.map.setLayoutProperty('_plan', 'visibility', 'none');

                                   var mapLayer = this.map.getLayer('layerLocation');
                                   if(typeof mapLayer !== 'undefined') {
                                      this.map.removeLayer('layerLocation').removeSource('Location');
                                   }

                                     var mpLayer = this.map.getLayer("layerLocation");
                                        if (typeof mpLayer === 'undefined') {
                                            // No Layer
                                        } else {

                                            this.map.removeLayer("layerLocation");
                                             this.map.removeSource("Location");
                                        }

                                 this.map.addSource('Location', {
                                    'type': 'geojson',
                                    'data': {
                                    'type': 'Feature',
                                    'geometry': {
                                    'type': 'Polygon',
                                    'coordinates': polygon
                                            }
                                    }
                                    });
                                    this.map.addLayer({
                                    'id': 'layerLocation',
                                    'type': 'fill',
                                    'source': 'Location',
                                    'layout': {},
                                    'paint': {
                                    'fill-color': '#088',
                                    'fill-opacity': 0.8
                                    }
                                    });

                                   var vdata = data;


                                   var mpLayer = this.map.getLayer("_pointslocation");
                                                        if (typeof mpLayer === 'undefined') {
                                                            // No Layer
                                                        } else {

                                                            this.map.removeLayer("_pointslocation");
                                                             this.map.removeSource("pointlocation");
                                                        }


                                  this.map.loadImage(
                                    imgmarkerlocation,
                                    (error, image) => {
                                   try{  this.map.addImage('imgmarkerlocation', image); }catch{}
                                    this.map.addSource('pointlocation', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [vdata[0].x,vdata[0].y]
                                               // 'coordinates': [0,0]
                                                }
                                                }
                                            ]
                                        }
                                    });

                                    this.map.addLayer({
                                            'id': '_pointslocation',
                                            'type': 'symbol',
                                            'source': 'pointlocation',
                                            'layout': {
                                            'icon-image': 'imgmarkerlocation',
                                            'icon-size': 0.1
                                                }
                                            });
                                        }
                                    );









                                               fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&poi_type=412')
                                              .then(response => response.json())
                                              .then(poi_sni => {




                                                 this.setState({
                                                 bs:"",
                                                 bs_name:poi_sni.name,
                                                 bs_nstorey:poi_sni.bl_nstorey,
                                                 bs_employ:poi_sni.bl_employ,
                                                 _latSP:poi_sni.y,
                                                _lngSP:poi_sni.x,
                                                 });




                              if(poi_sni.x>0 && poi_sni.y>0){

                                 var mapLayer = this.map.getLayer('pointssniper');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('pointssniper').removeSource('pointsniper');
                                   }
                                   this.map.loadImage(imgmarkersniper,
                                    (error, image) => {
                                   try{ this.map.addImage('imgmarkersniper', image); }catch{ }
                                    this.map.addSource('pointsniper', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [poi_sni.x,poi_sni.y]

                                                }
                                                }
                                            ]
                                        }
                                    });

                                    this.map.addLayer({
                                            'id': 'pointssniper',
                                            'type': 'symbol',
                                            'source': 'pointsniper',
                                            'layout': {
                                            'icon-image': 'imgmarkersniper',
                                            'icon-size': 0.1
                                                }
                                            });


                                        }
                                    );
                                          var popup = new mapboxgl.Popup({ className:"poi_info"})
                                                                        .setLngLat([poi_sni.x,poi_sni.y])
                                                                        .setHTML('<div padding: 3px;"><div> <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path></svg> พลซุ่มยิง</div></div>')
                                                                         .addTo(this.map);



                                                      var mpLayer = this.map.getLayer("_pointslocation");
                                                        if (typeof mpLayer === 'undefined') {
                                                            // No Layer
                                                        } else {

                                                            this.map.removeLayer("_pointslocation");
                                                             this.map.removeSource("pointlocation");
                                                        }


                                  this.map.loadImage(
                                    imgmarkerlocation,
                                    (error, image) => {
                                   try{  this.map.addImage('imgmarkerlocation', image); }catch{}
                                   try{
                                    this.map.addSource('pointlocation', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [vdata[0].x,vdata[0].y]
                                               // 'coordinates': [0,0]
                                                }
                                                }
                                            ]
                                        }
                                    });
                                    }catch{

                                    }

                                    this.map.addLayer({
                                            'id': '_pointslocation',
                                            'type': 'symbol',
                                            'source': 'pointlocation',
                                            'layout': {
                                            'icon-image': 'imgmarkerlocation',
                                            'icon-size': 0.1
                                                }
                                            });
                                        }
                                    );



                                    //-------sni-------------------
                                    var url = 'http://1.10.184.64:3000/bldg/convert.php?lat='+poi_sni.x+'&lon='+poi_sni.y+'';
                                    fetch(url)
                                          .then(response => response.json())
                                          .then(data => {

                                            var url = 'http://1.10.184.64:8080/geoserver/ctoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ctoc%3Abldg&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom,POINT('+data[0].ST_X+'%20'+data[0].ST_Y+'))';

                                               fetch(url)
                                                      .then(response => response.json())
                                                      .then(data => {


                                                      if(typeof data.features[0] !== "undefined")
                                                                        {
                                                                        var _bs_name  =data.features[0].properties.bl_name;
                                                                        var _bs_nstorey  =data.features[0].properties.bl_nstorey;
                                                                        var _bs_employ =data.features[0].properties.bl_employ;
                                                                        this.setState({bs:"", bs_name:_bs_name,bs_nstorey:_bs_nstorey,bs_employ:_bs_employ});

                              fetch('http://1.10.184.64:3000/bldg/objectid.php?id='+data.features[0].properties.objectid_1)
                                    .then(response => response.json())
                                    .then(data => {
								 var polygon =JSON.parse(data[0].bld);

								   var mapLayer = this.map.getLayer('layersniper');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('layersniper').removeSource('sourcesniper');
                                   }

										 this.map.addSource('sourcesniper', {
                                    'type': 'geojson',
                                    'data': {
                                    'type': 'Feature',
                                    'geometry': {
                                    'type': 'Polygon',
                                    'coordinates': polygon
                                            }
                                    }
                                    });
                                    this.map.addLayer({
                                    'id': 'layersniper',
                                    'type': 'fill',
                                    'source': 'sourcesniper',
                                    'layout': {},
                                    'paint': {
                                    'fill-color': '#FF8C00',
                                    'fill-opacity': 0.5
                                    }
                                    });
                                   });
                                                                                      fetch('http://1.10.184.64:3000/bldg/selectID.php?id='+data.features[0].properties.objectid_1)
                                                                                             .then(response => response.json())
                                                                                             .then(data => {
                                                                                                  var polygon =JSON.parse(data[0].bld);

                                                                                                       var mapLayer = this.map.getLayer('_plansni');
                                                                                                           if(typeof mapLayer !== 'undefined') {
                                                                                                              this.map.removeLayer('_plansni').removeSource('plansni');
                                                                                                           }
                                                                                                    this.map.addSource('plansni', {

                                                                                                                'type': 'geojson',
                                                                                                                'data':
                                                                                                                {"features": [
                                                                                                                {"type": "Feature","properties":
                                                                                                                {"level": 1,"name": "Bird Exhibit","height": data[0].bl_nstorey+1,"base_height": 0,"color": "#CF7E10 "},"geometry":
                                                                                                                {"coordinates": polygon,
                                                                                                                "type": "Polygon"},
                                                                                                                "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"},
                                                                                                                 ],"type": "FeatureCollection"}
                                                                                                                });

                                                                                                                this.map.addLayer({
                                                                                                                            'id': '_plansni',
                                                                                                                            'type': 'fill-extrusion',
                                                                                                                            'source': 'plansni',
                                                                                                                            'paint': {
                                                                                                                            // See the Mapbox Style Specification for details on data expressions.
                                                                                                                            // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                                                                                                            // Get the fill-extrusion-color from the source 'color' property.
                                                                                                                            'fill-extrusion-color': ['get', 'color'],

                                                                                                                            // Get fill-extrusion-height from the source 'height' property.
                                                                                                                            'fill-extrusion-height': ['get', 'height'],

                                                                                                                            // Get fill-extrusion-base from the source 'base_height' property.
                                                                                                                            'fill-extrusion-base': ['get', 'base_height'],

                                                                                                                            // Make extrusions slightly opaque for see through indoor walls.
                                                                                                                                'fill-extrusion-opacity': 0.5
                                                                                                                                }
                                                                                                                 });

                                                                                                          this.map.setLayoutProperty('_plansni', 'visibility', 'none');

                                                                               });




                                                                       }
                                                                       else
                                                                       {
                                                                             //this.setState({dataError:true})
                                                                       }



                                                      });
                                              });
                                    //-------sni---------------

                                    if(this.state.lat!=0&&this.state.lon!=0)
                                    {

                                                 lngLT = this.state.lat;
                                                 latLT = this.state.lon;

                                                 var mapLayer = this.map.getLayer('_route');
                                                 if(typeof mapLayer !== 'undefined') {

                                                   this.map.removeLayer('_route').removeSource('route');
                                              }
                                                 this.map.addSource('route', {
                                                    'type': 'geojson',
                                                    'data': {
                                                    'type': 'Feature',
                                                    'properties': {},
                                                    'geometry': {
                                                    'type': 'LineString',
                                                    'coordinates': [
                                                    [lngLT,latLT],
                                                    [poi_sni.x,poi_sni.y],
                                                    ]
                                                    }
                                                    }
                                            });

                                            this.map.addLayer({
                                                        'id': '_route',
                                                        'type': 'line',
                                                        'source': 'route',
                                                        'layout': {
                                                        'line-join': 'round',
                                                        'line-cap': 'round'
                                                        },
                                                        'paint': {
                                                        'line-color': '#888',
                                                        'line-width': 8
                                                        }
                                            });

                                               if(lngLT!=="" && poi_sni.x !="" ){

                                                let pt1 = point([lngLT,latLT], { 'name': 'Kano State' })
                                                let pt2 = point([poi_sni.x,poi_sni.y], { 'name': 'Lagos State' })

                                                var  result = distance(pt1, pt2, { units: 'metres' })
                                                var calresult = parseFloat(result).toFixed(2);
                                                this.setState({vdistance:calresult});

                                                }


                                   }

                                         fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&&poi_type=414')
                                             .then(response => response.json())
                                             .then(data => {

                                                     var y =data.x;
                                                     var x=data.y;
                                                    fetch('http://1.10.184.64:3000/bldg/point500m.php?lat='+y+"&&lon="+x+"&&m=" + wm_shoot_min)
                                                    .then(response => response.json())
                                                    .then(data => {

                                                        this.setState({ disabledSBK: false, disabledSSNI: false, point500m: '[' + data[0].point + ']', loading: false, mapzoom: 15 });

                                                        var polygon = JSON.parse(this.state.point500m);
                                                        this.setState({ polygonM: data[0].point })
                                                        var mapLayer = this.map.getLayer('_maine500');
                                                        if (typeof mapLayer !== 'undefined') {

                                                            this.map.removeLayer('_maine500').removeSource('maine500');
                                                        }
                                                        this.map.addSource('maine500', {
                                                            'type': 'geojson',
                                                            'data': {
                                                                'type': 'Feature',
                                                                'geometry': {
                                                                    'type': 'Polygon',
                                                                    'coordinates': polygon
                                                                }
                                                            }
                                                        });
                                                        this.map.addLayer({
                                                            'id': '_maine500',
                                                            'type': 'fill',
                                                            'source': 'maine500',
                                                            'layout': {},
                                                            'paint': {
                                                                'fill-color': "red",
                                                                'fill-opacity': 0.3
                                                            }
                                                        });
                                                    });



                                            fetch('http://1.10.184.64:3000/bldg/point1000m.php?lat=' + y + "&&lon=" + x + "&&m=" + shoot_max)
                                                .then(response => response.json())
                                                .then(data => {

                                                    this.setState({ point1000m: '[' + data[0].point + ']', loading: false, mapzoom: 15, polygonMBK: data[0].point });

                                                    var polygon = JSON.parse(this.state.point1000m);
                                                    var mapLayer = this.map.getLayer('_maine1000');
                                                    if (typeof mapLayer !== 'undefined') {

                                                        this.map.removeLayer('_maine1000').removeSource('maine1000');
                                                    }
                                                    this.map.addSource('maine1000', {
                                                        'type': 'geojson',
                                                        'data': {
                                                            'type': 'Feature',
                                                            'geometry': {
                                                                'type': 'Polygon',
                                                                'coordinates': polygon
                                                            }
                                                        }
                                                    });
                                                    this.map.addLayer({
                                                        'id': '_maine1000',
                                                        'type': 'fill',
                                                        'source': 'maine1000',
                                                        'layout': {},
                                                        'paint': {
                                                            'fill-color': "#FFFF00",
                                                            'fill-opacity': 0.1
                                                        }
                                                    });
                                                });


                                             fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&poi_type=412')
                                              .then(response => response.json())
                                              .then(poi_sni => {
                                              console.log("xxxxxxxx"+x)

                                                     lngLT = x;
                                                     latLT = y;

                                                     var mapLayer = this.map.getLayer('_route');
                                                     if(typeof mapLayer !== 'undefined') {

                                                       this.map.removeLayer('_route').removeSource('route');
                                                  }
                                                     this.map.addSource('route', {
                                                        'type': 'geojson',
                                                        'data': {
                                                        'type': 'Feature',
                                                        'properties': {},
                                                        'geometry': {
                                                        'type': 'LineString',
                                                        'coordinates': [
                                                        [y,x],
                                                        [poi_sni.x,poi_sni.y],
                                                        ]
                                                        }
                                                        }
                                                });

                                                this.map.addLayer({
                                                            'id': '_route',
                                                            'type': 'line',
                                                            'source': 'route',
                                                            'layout': {
                                                            'line-join': 'round',
                                                            'line-cap': 'round'
                                                            },
                                                            'paint': {
                                                            'line-color': '#888',
                                                            'line-width': 8
                                                            }
                                                });

                                                   if(lngLT!=="" && poi_sni.x !="" ){

                                                    let pt1 = point([y,x], { 'name': 'Kano State' })
                                                    let pt2 = point([poi_sni.x,poi_sni.y], { 'name': 'Lagos State' })

                                                    var  result = distance(pt1, pt2, { units: 'metres' })
                                                    var calresult = parseFloat(result).toFixed(2);
                                                    this.setState({vdistance:calresult});

                                                    }
                                              });





                                                              this.setState({lat_terrorist:data.y,lon_terrorist:data.x});
                                                                                var mapLayer = this.map.getLayer('_pointterrorist');
                                                                                if (typeof mapLayer !== 'undefined') {

                                                                                    this.map.removeLayer('_pointterrorist').removeSource('pointterrorist');
                                                                                }

                                                                                this.map.loadImage(
                                                                                    imgmarkerterrorist,
                                                                                    (error, image) => {
                                                                                        try { this.map.addImage('imgmarkerterrorist', image); } catch { }
                                                                                        this.map.addSource('pointterrorist', {
                                                                                            'type': 'geojson',
                                                                                            'data': {
                                                                                                'type': 'FeatureCollection',
                                                                                                'features': [
                                                                                                    {
                                                                                                        'type': 'Feature',
                                                                                                        'geometry': {
                                                                                                            'type': 'Point',
                                                                                                            'coordinates': [data.x, data.y]
                                                                                                        }
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        });
                                                                                        this.map.addLayer({
                                                                                            'id': '_pointterrorist',
                                                                                            'type': 'symbol',
                                                                                            'source': 'pointterrorist',
                                                                                            'layout': {
                                                                                                'icon-image': 'imgmarkerterrorist',
                                                                                                'icon-size': 0.1
                                                                                            }
                                                                                        });

                                                                                              var popup = new mapboxgl.Popup({ className:"poi_info"})
                                                                                                .setLngLat([data.x, data.y])
                                                                                                .setHTML('<div padding: 3px;"><div>'+
                                                                                                    '<svg version="1.0" xmlns="http://www.w3.org/2000/svg"'+
                                                                                                     'width="20pt" height="20pt" viewBox="0 0 100.000000 100.000000"'+
                                                                                                     'preserveAspectRatio="xMidYMid meet">'+
                                                                                                    '<g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"'+
                                                                                                    'fill="back" stroke="none">'+
                                                                                                    '<path d="M360 928 c-76 -29 -159 -113 -191 -193 -20 -51 -23 -77 -23 -175 1'+
                                                                                                    ' -148 26 -248 85 -330 9 -12 4 -26 -21 -63 -40 -60 -35 -68 73 -98 118 -34 316'+
                                                                                                    ' -34 434 0 108 30 113 38 73 98 -25 37 -30 51 -21 63 58 81 84 181 85 330 0 99'+
                                                                                                    ' -3 124 -23 175 -33 82 -115 164 -194 193 -78 30 -200 29 -277 0z m57 -373 c23'+
                                                                                                    ' -14 43 -30 43 -35 0 -18 -87 -60 -123 -60 -41 0 -77 28 -77 60 0 59 83 78 157'+
                                                                                                    ' 35z m303 5 c42 -42 9 -100 -57 -100 -36 0 -123 42 -123 60 0 5 19 20 43 34 52'+
                                                                                                    ' 32 109 34 137 6z m-188 -151 c50 -27 63 -130 22 -178 -35 -42 -73 -42 -108 0'+
                                                                                                    ' -30 35 -34 81 -12 133 21 51 57 67 98 45z"/>'+
                                                                                                    '</g>'+
                                                                                                    '</svg>'
                                                                                                +'ผู้ก่อการร้าย</div></div>')
                                                                                                 .addTo(this.map);


                                                                                    }
                                                                                );
                                                          var popup = new mapboxgl.Popup({ className:"poi_info"})
                                                                        .setLngLat([data.x, data.y])
                                                                        .setHTML('<div padding: 3px;"><div>ผู้ก่อการร้าย</div></div>')
                                                                         .addTo(this.map);


                                             })

                                }
                                       fetch('http://1.10.184.64:3000/bldg/convert.php?lat='+data.x+'&lon='+data.y+'')
                                          .then(response => response.json())
                                          .then(data => {
                                           //  this.setState({ arraydatapolygon: data, loading: false });
                                                 fetch('http://1.10.184.64:8080/geoserver/ctoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ctoc%3Abldg&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom,POINT('+data.y+'%20'+data.x+'))')
                                                      .then(response => response.json())
                                                      .then(data => {


                                                       if(typeof data.features[0] !== "undefined")
                                                                        {
                                                                            var _bs_name  =data.features[0].properties.bl_name;
                                                                            var _bs_nstorey  =data.features[0].properties.bl_nstorey;
                                                                            var _bs_employ =data.features[0].properties.bl_employ;
                                                                            this.setState({bs:"", bs_name:_bs_name,bs_nstorey:_bs_nstorey,bs_employ:_bs_employ});

                                                                       }
                                                                       else
                                                                       {
                                                                                            this.setState({dataError:true})
                                                                       }
                                                      });
                                              });



                                                 })
  });




                                               fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&poi_type=413')
                                              .then(response => response.json())
                                              .then(poi_dis => {

                               if(poi_dis.x!=0 &&poi_dis.y!=0){




                                     var mapLayer = this.map.getLayer('_pointdistance');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_pointdistance').removeSource('pointdistance');
                                   }

                                   this.map.loadImage(
                                    imgmarkerdistance,
                                    (error, image) => {
                                    try { this.map.addImage('imgmarkerdistance', image); }catch{}
                                    this.map.addSource('pointdistance', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [poi_dis.x,poi_dis.y]
                                                }
                                                }
                                            ]
                                        }
                                    });
                                    this.map.addLayer({
                                            'id': '_pointdistance',
                                            'type': 'symbol',
                                            'source': 'pointdistance',
                                            'layout': {
                                            'icon-image': 'imgmarkerdistance',
                                            'icon-size': 0.1
                                                }
                                            });
                                        }
                                    );

                                actionDistance="false";
                               this.setState({colorDIS:"#663333",disabledDIS:false})



       }




                                                 })



 /*--------------------------------------------------bk*/


                                   fetch('http://1.10.184.64:3000/poi_plan/select.php?plan_id='+xid+'&poi_type=404')
                               .then(response => response.json())
                                 .then(poi_plan => {



                                          fetch('http://1.10.184.64:3000/bldg/convert.php?lat='+poi_plan.x+'&lon='+poi_plan.y+'')
                                          .then(response => response.json())
                                          .then(data => {


                                          var vpoi_plan = poi_plan;

                                                 fetch('http://1.10.184.64:8080/geoserver/ctoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ctoc%3Abldg&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom,POINT('+data[0].ST_X+'%20'+data[0].ST_Y+'))')
                                                      .then(response => response.json())
                                                      .then(data => {

                                                           var vvpoi_plan = vpoi_plan;






                                                                     if(typeof data.features[0] !== "undefined")
                                                                        {

                                                                       var _bl_name  =data.features[0].properties.bl_name;
                                                                       var _bl_nstorey  =data.features[0].properties.bl_nstorey;
                                                                       var _bl_employ  =data.features[0].properties.bl_employ;
                                                                       this.setState({bbk:"", bl_name: _bl_name,bl_nstorey:_bl_nstorey,bl_employ :_bl_employ });

                                                                         fetch('http://1.10.184.64:3000/bldg/objectid.php?id='+data.features[0].properties.objectid_1)
                                                                                                            .then(response => response.json())
                                                                                                            .then(data => {


                                                      var mapLayer = this.map.getLayer('_pointsbk');
                                                                       if(typeof mapLayer !== 'undefined') {

                                                                           this.map.removeLayer('_pointsbk').removeSource('pointbk');
                                                                       }
                                                                       this.map.loadImage(
                                                                        imgmarkerbk,
                                                                        (error, image) => {
                                                                       try{ this.map.addImage('imgmarkerX', image); } catch { }

                                                                        this.map.addSource('pointbk', {
                                                                        'type': 'geojson',
                                                                            'data': {
                                                                            'type': 'FeatureCollection',
                                                                            'features': [
                                                                                    {
                                                                                    'type': 'Feature',
                                                                                    'geometry': {
                                                                                    'type': 'Point',
                                                                                    'coordinates': [vvpoi_plan.x,vvpoi_plan.y]

                                                                                    }
                                                                                    }
                                                                                ]
                                                                            }
                                                                        });

                                                                        this.map.addLayer({
                                                                                'id': '_pointsbk',
                                                                                'type': 'symbol',
                                                                                'source': 'pointbk',
                                                                                'layout': {
                                                                                'icon-image': 'imgmarkerX',
                                                                                'icon-size': 0.1
                                                                                    }
                                                                                });

                                                               var popup = new mapboxgl.Popup({ className:"poi_info"})
                                                                        .setLngLat([poi_plan.x,poi_plan.y])
                                                                        .setHTML('<div padding: 3px;"><div><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M23 11.01L18 11c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-9c0-.55-.45-.99-1-.99zM23 20h-5v-7h5v7zM20 2H2C.89 2 0 2.89 0 4v12c0 1.1.89 2 2 2h7v2H7v2h8v-2h-2v-2h2v-2H2V4h18v5h2V4c0-1.11-.9-2-2-2zm-8.03 7L11 6l-.97 3H7l2.47 1.76-.94 2.91 2.47-1.8 2.47 1.8-.94-2.91L15 9h-3.03z"></path></svg> บ.ก.เหตุการณ์</div></div>')
                                                                         .addTo(this.map);
                                                                 this.setState({_lngBK:poi_plan.x,_latBK:poi_plan.y})



                                                                            }
                                                                        );


                                                                                                         var polygon =JSON.parse(data[0].bld);

                                                                                                           var mapLayer = this.map.getLayer('layerbk');
                                                                                                           if(typeof mapLayer !== 'undefined') {

                                                                                                               this.map.removeLayer('layerbk').removeSource('sourcebk');
                                                                                                           }

                                                                                                                 this.map.addSource('sourcebk', {
                                                                                                            'type': 'geojson',
                                                                                                            'data': {
                                                                                                            'type': 'Feature',
                                                                                                            'geometry': {
                                                                                                            'type': 'Polygon',
                                                                                                            'coordinates': polygon
                                                                                                                    }
                                                                                                            }
                                                                                                            });
                                                                                                            this.map.addLayer({
                                                                                                            'id': 'layerbk',
                                                                                                            'type': 'fill',
                                                                                                            'source': 'sourcebk',
                                                                                                            'layout': {},
                                                                                                            'paint': {
                                                                                                            'fill-color': '#DDA0DD',
                                                                                                            'fill-opacity': 0.5
                                                                                                            }
                                                                                                            });
                                                                                                       });

                                                                       fetch('http://1.10.184.64:3000/bldg/selectID.php?id='+data.features[0].properties.objectid_1)
                                                                                             .then(response => response.json())
                                                                                             .then(data => {
                                                                                                  var polygon =JSON.parse(data[0].bld);

                                                                                                       var mapLayer = this.map.getLayer('_planbk');
                                                                                                           if(typeof mapLayer !== 'undefined') {
                                                                                                              this.map.removeLayer('_planbk').removeSource('planbk');
                                                                                                           }
                                                                                                    this.map.addSource('planbk', {

                                                                                                                'type': 'geojson',
                                                                                                                'data':
                                                                                                                {"features": [
                                                                                                                {"type": "Feature","properties":
                                                                                                                {"level": 1,"name": "Bird Exhibit","height": data[0].bl_nstorey+1,"base_height": 0,"color": "#8710CF"},"geometry":
                                                                                                                {"coordinates": polygon,
                                                                                                                "type": "Polygon"},
                                                                                                                "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"},
                                                                                                                 ],"type": "FeatureCollection"}
                                                                                                                });

                                                                                                                this.map.addLayer({
                                                                                                                            'id': '_planbk',
                                                                                                                            'type': 'fill-extrusion',
                                                                                                                            'source': 'planbk',
                                                                                                                            'paint': {
                                                                                                                            // See the Mapbox Style Specification for details on data expressions.
                                                                                                                            // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                                                                                                            // Get the fill-extrusion-color from the source 'color' property.
                                                                                                                            'fill-extrusion-color': ['get', 'color'],

                                                                                                                            // Get fill-extrusion-height from the source 'height' property.
                                                                                                                            'fill-extrusion-height': ['get', 'height'],

                                                                                                                            // Get fill-extrusion-base from the source 'base_height' property.
                                                                                                                            'fill-extrusion-base': ['get', 'base_height'],

                                                                                                                            // Make extrusions slightly opaque for see through indoor walls.
                                                                                                                             'fill-extrusion-opacity': 0.5
                                                                                                                                }
                                                                                                                 });
                                                                                                                 this.map.setLayoutProperty('_planbk', 'visibility', 'none');







                                                                               });

                                                                       }
                                                                       else
                                                                       {
                                                                                            this.setState({dataError:true})
                                                                       }

                                                      });



                                              });
                                             })



                                           })
                                        })

                                     }



            }


                       GetCenterMap = (map,event)=> {

                                    var  latData =map.getCenter().lat;
                                    var  lngData =map.getCenter().lng;

                       }
                       GetMap = (map,event)=> {
                              if(startview!="")
                              xx =93.7521429641697;
                              yy =0.7143114151588321;
                                 {
                                     var  latData =map.getCenter().lat;
                                     var  lngData =map.getCenter().lng;
                                 }
                       }
                      find_name = (event, values) =>{
                              //console.log("ss"+values.bl_name);
                                      this.setState({gotoname:values.bl_name});
                        }


             findblg = (event) =>{
                        fetch('http://1.10.184.64:3000/bldg/autocomplete.php?id='+event.target.value)
                                     .then(response => response.json())
                                     .then(data => {
                                      this.setState({ listblg: data, loading: false });
                                    })
                               }





           //this.setState({bbk:"1",bs:"",nm:"" });


           _Close =() =>{

                             this.setState({ststyle:"none"});
                }
                 _Closelos =() =>{

                             this.setState({ststylelos:"none"});
                }




        _bls = (map, event) =>{

           this.setState({bbk:"",bs:"1",nm:"",ststyle:"none"});
        }
         _Nav = () =>{

           this.setState({bbk:"",bs:"",nm:"1",ststyle:"none"});
        }






         streetviewStart =()=>
         {
          this.map.setStyle({"version": 8,
                                      "name": "OSM",
                                      "metadata": {
                                      },
                                      "sources": {
                                        "osm": {
                                          "type": "raster",
                                          "tiles": [
                                            "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                          ]
                                        }
                                      },
                                      "layers": [
                                        {
                                          "id": "osm",
                                          "type": "raster",
                                          "source": "osm"
                                        }
                                      ],
                                      "id": "klokantech-basic"
                                    });
          //  this.setState({streetviewStart:'1'})
         }

          onDragUpdate = () => {
            /*...*/
          }



        onDragStart(result) {


              startview ="1";
            // this.setState({streetviewStart:'1'});
           // the only one that is required
         };

          onDragEnd(result) {
              startview ="";
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            const items = reorder(
              this.state.items,
              result.source.index,
              result.destination.index
            );

            this.setState({
              items
            });
          }
          _nonelayer =()=>{
            if(this.state.nonelayer=="block"){

              this.setState({
              nonelayer:"none"
            });

            }

              if(this.state.nonelayer=="none"){

            this.setState({
              nonelayer:"block"
            });
            }

          }

          _nonelayer2 =()=>{
            if(this.state.nonelayer2=="block"){

              this.setState({
              nonelayer2:"none"
            });

            }

              if(this.state.nonelayer2=="none"){

            this.setState({
              nonelayer2:"block"
            });
            }

          }

           _nonedis =()=>{
            if(this.state.nonedis=="block"){

              this.setState({
              nonedis:"none"
            });

            }

              if(this.state.nonedis=="none"){

            this.setState({
              nonedis:"block"
            });
            }

          }
          _goToWeapon =(event)=>
          {
            var value= ""+event.target.value;


            this.setState({selectWeapon:value});
          }
          noselect =()=>
          {
              this.setState({weaponmin:'500',weaponmax:'1000'})
          }





        componentDidMount() {



            // Any information the map needs can be passed down as a prop from the parent  MMap
            var { map } = this.props;
             const {dd,oo} = this.state;

            // Store the map as a property on the class so we can call mapbox methods later
            mapboxgl.accessToken = 'pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w';
            this.map = new mapboxgl.Map({
                style: {"version": 8,
                                      "name": "OSM",
                                      "metadata": {
                                      },
                                      "sources": {
                                        "osm": {
                                          "type": "raster",
                                          "tiles": [
                                            "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                          ]
                                        }
                                      },
                                      "layers": [
                                        {
                                          "id": "osm",
                                          "type": "raster",
                                          "source": "osm"
                                        }
                                      ],
                                      "id": "klokantech-basic"
                                    },
                center: [100.523237700882,13.7065817585414],
                animate: false,
                zoom: 4,

                container: 'map',

            });



                            this.map.on('load', () => {
                            this.initMap();

                            var layers =  this.map.getStyle().layers;

                                var labelLayerId;
                                for (var i = 0; i < layers.length; i++) {
                                if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                                labelLayerId = layers[i].id;
                                break;
                                }
                                }









                 });


                   this.map.on('load', function() {


                })



        }


           _goTo = (event,x,y) => {

                         if(event.type=="click")
                        {
                                        try{

                                         this.map.flyTo({ center: [y, x],zoom:15});

                                         }catch{
                                         }

                        }
                 }



       ClearLayer =() =>{


                            this.map.moveLayer('maine500', 0);
                            this.map.moveLayer('maine1000', 0);
                            this.map.moveLayer('maine500', 0);
                            this.map.moveLayer('maine1000', 0);
                            this.map.moveLayer('_maine500', 0);
                            this.map.moveLayer('_maine1000', 0);
                            this.map.moveLayer('l_showblg', 0);
                            this.map.moveLayer('s_showblg', 0);
                            this.map.moveLayer('Location', 0);
                            this.map.moveLayer('layerLocation', 0);
                            this.map.moveLayer('_plan', 0);
                            this.map.moveLayer('plan', 0);
                             this.map.moveLayer('_plansni', 0);
                            this.map.moveLayer('plansni', 0);
                            this.map.moveLayer('layersniper', 0);
                            this.map.moveLayer('sourcesniper', 0);
                            this.map.moveLayer('layerbk', 0);
                            this.map.moveLayer('sourcebk', 0);

                            this.map.moveLayer('route', 0);
                            this.map.moveLayer('_route', 0);
                            this.map.moveLayer('_pointslocation', 0);
                            this.map.moveLayer('pointslocation', 0);
                               this.map.moveLayer('_pointsbk', 0);
                            this.map.moveLayer('pointsbk', 0);
                            this.map.moveLayer('pointdistance', 0);
                            this.map.moveLayer('_pointdistance', 0);
                            this.map.moveLayer('pointssniper', 0);
                            this.map.moveLayer('_pointssniper', 0);
                            this.map.moveLayer('routedis', 0);
                            this.map.moveLayer('_routedis', 0);
                            this.map.moveLayer('_planbk', 0);
                            this.map.moveLayer('planbk', 0);



       }

       CreatePoint = ()=> {

              if(actionBK==="true"){


                               var mapLayer = this.map.getLayer('_pointsbk');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_pointsbk').removeSource('pointbk');
                                   }
                                   this.map.loadImage(
                                    imgmarkerbk,
                                    (error, image) => {
                                   try{ this.map.addImage('imgmarker', image); } catch { }

                                    this.map.addSource('pointbk', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [lngBK,latBK]
                                                }
                                                }
                                            ]
                                        }
                                    });

                                    this.map.addLayer({
                                            'id': '_pointsbk',
                                            'type': 'symbol',
                                            'source': 'pointbk',
                                            'layout': {
                                            'icon-image': 'imgmarker',
                                            'icon-size': 0.1
                                                }
                                            });

                                        }
                                    );


                                     fetch('http://1.10.184.64:3000/bldg/convert.php?lat='+lngBK+'&lon='+latBK+'')
                                          .then(response => response.json())
                                          .then(data => {

                                           //  this.setState({ arraydatapolygon: data, loading: false });
                                                 fetch('http://1.10.184.64:8080/geoserver/ctoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ctoc%3Abldg&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom,POINT('+data[0].ST_X+'%20'+data[0].ST_Y+'))')
                                                      .then(response => response.json())
                                                      .then(data => {



                                                                     if(typeof data.features[0] !== "undefined")
                                                                        {

                                                                       var _bl_name  =data.features[0].properties.bl_name;
                                                                       var _bl_nstorey  =data.features[0].properties.bl_nstorey;
                                                                       var _bl_employ  =data.features[0].properties.bl_employ;
                                                                       this.setState({bbk:"", bl_name: _bl_name,bl_nstorey:_bl_nstorey,bl_employ :_bl_employ });
                                                                                  fetch('http://1.10.184.64:3000/bldg/objectid.php?id='+data.features[0].properties.objectid_1)
                                                                                                            .then(response => response.json())
                                                                                                            .then(data => {

                                                                                                         var polygon =JSON.parse(data[0].bld);

                                                                                                           var mapLayer = this.map.getLayer('layerbk');
                                                                                                           if(typeof mapLayer !== 'undefined') {

                                                                                                               this.map.removeLayer('layerbk').removeSource('sourcebk');
                                                                                                           }

                                                                                                                 this.map.addSource('sourcebk', {
                                                                                                            'type': 'geojson',
                                                                                                            'data': {
                                                                                                            'type': 'Feature',
                                                                                                            'geometry': {
                                                                                                            'type': 'Polygon',
                                                                                                            'coordinates': polygon
                                                                                                                    }
                                                                                                            }
                                                                                                            });
                                                                                                            this.map.addLayer({
                                                                                                            'id': 'layerbk',
                                                                                                            'type': 'fill',
                                                                                                            'source': 'sourcebk',
                                                                                                            'layout': {},
                                                                                                            'paint': {
                                                                                                            'fill-color': '#DDA0DD',
                                                                                                            'fill-opacity': 0.5
                                                                                                            }
                                                                                                            });
                                                                                                       });
                                                                       fetch('http://1.10.184.64:3000/bldg/selectID.php?id='+data.features[0].properties.objectid_1)
                                                                                             .then(response => response.json())
                                                                                             .then(data => {
                                                                                                  var polygon =JSON.parse(data[0].bld);

                                                                                                       var mapLayer = this.map.getLayer('_planbk');
                                                                                                           if(typeof mapLayer !== 'undefined') {
                                                                                                              this.map.removeLayer('_planbk').removeSource('planbk');
                                                                                                           }
                                                                                                    this.map.addSource('planbk', {

                                                                                                                'type': 'geojson',
                                                                                                                'data':
                                                                                                                {"features": [
                                                                                                                {"type": "Feature","properties":
                                                                                                                {"level": 1,"name": "Bird Exhibit","height": data[0].bl_nstorey+1,"base_height": 0,"color": "#8710CF"},"geometry":
                                                                                                                {"coordinates": polygon,
                                                                                                                "type": "Polygon"},
                                                                                                                "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"},
                                                                                                                 ],"type": "FeatureCollection"}
                                                                                                                });

                                                                                                                this.map.addLayer({
                                                                                                                            'id': '_planbk',
                                                                                                                            'type': 'fill-extrusion',
                                                                                                                            'source': 'planbk',
                                                                                                                            'paint': {
                                                                                                                            // See the Mapbox Style Specification for details on data expressions.
                                                                                                                            // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                                                                                                            // Get the fill-extrusion-color from the source 'color' property.
                                                                                                                            'fill-extrusion-color': ['get', 'color'],

                                                                                                                            // Get fill-extrusion-height from the source 'height' property.
                                                                                                                            'fill-extrusion-height': ['get', 'height'],

                                                                                                                            // Get fill-extrusion-base from the source 'base_height' property.
                                                                                                                            'fill-extrusion-base': ['get', 'base_height'],

                                                                                                                            // Make extrusions slightly opaque for see through indoor walls.
                                                                                                                             'fill-extrusion-opacity': 0.5
                                                                                                                                }
                                                                                                                 });
                                                                                                                 this.map.setLayoutProperty('_planbk', 'visibility', 'none');



                                                                               });

                                                                       }
                                                                       else
                                                                       {
                                                                                            this.setState({dataError:true})
                                                                       }

                                                      });
                                              });
                                actionBK="false";

                                 this.setState({colorBK:"#990099",disabledBK:false,_lngBK:lngBK,_latBK:latBK})

                                  this.ClearLayer()


                   }
                     if(actionStreetview==="true"){

                       var mapLayer = this.map.getLayer('layerpointStreetview');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('layerpointStreetview').removeSource('spointStreetview');
                                   }
                                   this.map.loadImage(
                                    imgst,
                                    (error, image) => {
                                   try{ this.map.addImage('imgmarkerSV', image); } catch { }

                                    this.map.addSource('spointStreetview', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [lngSV,latSV]
                                                }
                                                }
                                            ]
                                        }
                                    });

                                    this.map.addLayer({
                                            'id': 'layerpointStreetview',
                                            'type': 'symbol',
                                            'source': 'spointStreetview',
                                            'layout': {
                                            'icon-image': 'imgmarkerSV',
                                            'icon-size': 0.1
                                                }
                                            });

                                        }
                                    );
                                    //  ref={(f) => { this.ifr = f; }}
                                    var id_ =parseInt(cookie.load('name'));

                                    this.setState({_latSV:latSV,_lngSV:lngSV,ststyle:"",stcolor:"#28a745"})
                                     this.setState({_src: "http://1.10.184.64:3000/map/streetview.php?x="+latSV+"&y="+lngSV})

                                actionStreetview="false";
                        //  this.setState({});
                     }
                     if(actionSniper==="true"){
                                 var mapLayer = this.map.getLayer('pointssniper');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('pointssniper').removeSource('pointsniper');
                                   }
                                   this.map.loadImage(imgmarkersniper,
                                    (error, image) => {
                                   try{ this.map.addImage('imgmarkersniper', image); }catch{ }
                                    this.map.addSource('pointsniper', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [lngSP,latSP]
                                                }
                                                }
                                            ]
                                        }
                                    });

                                    this.map.addLayer({
                                            'id': 'pointssniper',
                                            'type': 'symbol',
                                            'source': 'pointsniper',
                                            'layout': {
                                            'icon-image': 'imgmarkersniper',
                                            'icon-size': 0.1
                                                }
                                            });
                                        }
                                    );


                                     if(lngLT!=0&&latLT!=0){
                                      var mapLayer = this.map.getLayer('_route');
                                      if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_route').removeSource('route');
                                   }
                                     this.map.addSource('route', {
                                        'type': 'geojson',
                                        'data': {
                                        'type': 'Feature',
                                        'properties': {},
                                        'geometry': {
                                        'type': 'LineString',
                                        'coordinates': [
                                        [lngLT,latLT],
                                        [lngSP,latSP],
                                        ]
                                        }
                                        }
                                });

                                this.map.addLayer({
                                            'id': '_route',
                                            'type': 'line',
                                            'source': 'route',
                                            'layout': {
                                            'line-join': 'round',
                                            'line-cap': 'round'
                                            },
                                            'paint': {
                                            'line-color': '#888',
                                            'line-width': 8
                                            }
                                });
                                }
                                       fetch('http://1.10.184.64:3000/bldg/convert.php?lat='+lngSP+'&lon='+latSP+'')
                                          .then(response => response.json())
                                          .then(data => {


                                               fetch('http://1.10.184.64:8080/geoserver/ctoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ctoc%3Abldg&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom,POINT('+data[0].ST_X+'%20'+data[0].ST_Y+'))')
                                                      .then(response => response.json())
                                                      .then(data => {

                                                      if(typeof data.features[0] !== "undefined")
                                                                        {
                                                                        var _bs_name  =data.features[0].properties.bl_name;
                                                                        var _bs_nstorey  =data.features[0].properties.bl_nstorey;
                                                                        var _bs_employ =data.features[0].properties.bl_employ;
                                                                        this.setState({bs:"", bs_name:_bs_name,bs_nstorey:_bs_nstorey,bs_employ:_bs_employ});

                                                                          fetch('http://1.10.184.64:3000/bldg/objectid.php?id='+data.features[0].properties.objectid_1)
                                    .then(response => response.json())
                                    .then(data => {
								 var polygon =JSON.parse(data[0].bld);

								   var mapLayer = this.map.getLayer('layersniper');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('layersniper').removeSource('sourcesniper');
                                   }

										 this.map.addSource('sourcesniper', {
                                    'type': 'geojson',
                                    'data': {
                                    'type': 'Feature',
                                    'geometry': {
                                    'type': 'Polygon',
                                    'coordinates': polygon
                                            }
                                    }
                                    });
                                    this.map.addLayer({
                                    'id': 'layersniper',
                                    'type': 'fill',
                                    'source': 'sourcesniper',
                                    'layout': {},
                                    'paint': {
                                    'fill-color': '#FF8C00',
                                    'fill-opacity': 0.5
                                    }
                                    });
                                   });
                                                                                      fetch('http://1.10.184.64:3000/bldg/selectID.php?id='+data.features[0].properties.objectid_1)
                                                                                             .then(response => response.json())
                                                                                             .then(data => {
                                                                                                  var polygon =JSON.parse(data[0].bld);

                                                                                                       var mapLayer = this.map.getLayer('_plansni');
                                                                                                           if(typeof mapLayer !== 'undefined') {
                                                                                                              this.map.removeLayer('_plansni').removeSource('plansni');
                                                                                                           }
                                                                                                    this.map.addSource('plansni', {

                                                                                                                'type': 'geojson',
                                                                                                                'data':
                                                                                                                {"features": [
                                                                                                                {"type": "Feature","properties":
                                                                                                                {"level": 1,"name": "Bird Exhibit","height": data[0].bl_nstorey+1,"base_height": 0,"color": "#CF7E10 "},"geometry":
                                                                                                                {"coordinates": polygon,
                                                                                                                "type": "Polygon"},
                                                                                                                "id": "06e8fa0b3f851e3ae0e1da5fc17e111e"},
                                                                                                                 ],"type": "FeatureCollection"}
                                                                                                                });

                                                                                                                this.map.addLayer({
                                                                                                                            'id': '_plansni',
                                                                                                                            'type': 'fill-extrusion',
                                                                                                                            'source': 'plansni',
                                                                                                                            'paint': {
                                                                                                                            // See the Mapbox Style Specification for details on data expressions.
                                                                                                                            // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                                                                                                            // Get the fill-extrusion-color from the source 'color' property.
                                                                                                                            'fill-extrusion-color': ['get', 'color'],

                                                                                                                            // Get fill-extrusion-height from the source 'height' property.
                                                                                                                            'fill-extrusion-height': ['get', 'height'],

                                                                                                                            // Get fill-extrusion-base from the source 'base_height' property.
                                                                                                                            'fill-extrusion-base': ['get', 'base_height'],

                                                                                                                            // Make extrusions slightly opaque for see through indoor walls.
                                                                                                                                'fill-extrusion-opacity': 0.5
                                                                                                                                }
                                                                                                                 });

                                                                                                          this.map.setLayoutProperty('_plansni', 'visibility', 'none');

                                                                               });




                                                                       }
                                                                       else
                                                                       {
                                                                             this.setState({dataError:true})
                                                                       }



                                                      });
                                              });
                                actionSniper="false";
                                 this.setState({colorSNI:"#FF4500",_lngSP:lngSP,_latSP:latSP});
                                this.ClearLayer();

                   }
                           var mapLayer = this.map.getLayer('_geolocation2');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_geolocation2').removeSource('geolocation2');
                                   }
                                   this.map.loadImage(
                                    imggeolocation2,
                                    (error, image) => {
                                    try { this.map.addImage('imggeolocation2', image); }catch{}
                                    this.map.addSource('geolocation2', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [_longitude,_latitude]
                                                }
                                                }
                                            ]
                                        }
                                    });

                                    this.map.addLayer({
                                            'id': '_geolocation2',
                                            'type': 'symbol',
                                            'source': 'geolocation2',
                                            'layout': {
                                            'icon-image': 'imggeolocation2',
                                            'icon-size': 0.1
                                                }
                                            });
                                        }
                                    );

                                       this.setState({distext:false});

                   if(actionDistance==="true")
                   {
                                    var mapLayer = this.map.getLayer('_pointdistance');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_pointdistance').removeSource('pointdistance');
                                   }
                                   this.map.loadImage(
                                    imgmarkerdistance,
                                    (error, image) => {
                                    try { this.map.addImage('imgmarkerdistance', image); }catch{}
                                    this.map.addSource('pointdistance', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [lngDis,latDis]
                                                }
                                                }
                                            ]
                                        }
                                    });
                                    this.map.addLayer({
                                            'id': '_pointdistance',
                                            'type': 'symbol',
                                            'source': 'pointdistance',
                                            'layout': {
                                            'icon-image': 'imgmarkerdistance',
                                            'icon-size': 0.1
                                                }
                                            });
                                        }
                                    );

                     if (lngDis !== "undefined") {


                     if(lngDis!=0&&latDis!=0){
                             var directions = [];
                             var directionsData = [];

                    fetch('https://api.mapbox.com/directions/v5/mapbox/driving/'+lngLT+'%2C'+latLT+'%3B'+lngDis+'%2C'+ latDis+'?alternatives=true&geometries=geojson&steps=true&access_token=pk.eyJ1IjoiZGV2cGFya2FzaHN1bWl0IiwiYSI6ImNqc242dmNvcTAwdno0YXFuMzQ4MGlzbWkifQ.nhpUN4VR3Lvd7_gjtsIesg')
                                     .then(response => response.json())
                                     .then(data => {

                                      if(typeof data.routes !== "undefined")
                                                     {
                                                         var distance=  data.routes[0].distance;
                                                          var duration=  data.routes[0].duration;
                                                          this.setState({apiduration:duration,apidistance:distance})
                                                         for (var ii=0;ii<data.routes[0].legs.[0].steps.length;ii++)
                                                        {
                                                        var rows =data.routes[0].legs.[0].steps[ii].maneuver.location;
                                                           directions.push(rows);
                                                            var rowsData =data.routes[0].legs.[0].steps[ii].maneuver;


                                                           directionsData.push(typeof rowsData.modifier==='undefined'?"": rowsData.modifier+" ประเภท:"+rowsData.type+" คำแนะนำ:"+rowsData.instruction);
                                                           for (var iii=0;iii<data.routes[0].legs.[0].steps[ii].intersections.length;iii++)
                                                           {
                                                             var rows2 =data.routes[0].legs.[0].steps.[ii].intersections[iii].location;
                                                            // directions.push(rows2);
                                                           }
                                                            for (var iiii=0;iiii<data.routes[0].legs.[0].steps[ii].geometry.coordinates.length;iiii++)
                                                           {
                                                             var rowsX =data.routes[0].legs.[0].steps.[ii].geometry.coordinates[iiii];
                                                             directions.push(rowsX);

                                                           }

                                                          }


                                                        }
                                                                       else
                                                                       {
                                                                                            this.setState({dataError:true})
                                                                       }

                                                       this.setState({rdirections:directions});

                                                    this.setState({datainfo:directionsData});


                                         var mpLayer = this.map.getLayer("_routedis");
                                        if (typeof mpLayer === 'undefined') {
                                            // No Layer
                                        } else {

                                            this.map.removeLayer("_routedis");
                                             this.map.removeSource("routedis");
                                        }


                                                        this.map.addSource('routedis', {
                                                                'type': 'geojson',
                                                                'data': {
                                                                'type': 'Feature',
                                                                'properties': {},
                                                                'geometry': {
                                                                'type': 'LineString',
                                                                'coordinates': this.state.rdirections
                                                                }
                                                                }
                                                        });
                                                        this.map.addLayer({
                                                                    'id': '_routedis',
                                                                    'type': 'line',
                                                                    'source': 'routedis',
                                                                    'layout': {
                                                                    'line-join': 'round',
                                                                    'line-cap': 'round'
                                                                    },
                                                                    'paint': {
                                                                    'line-color': 'blue',
                                                                    'line-width': 8
                                                                    }
                                                        });


                                              });





                        }
                     }
                                actionDistance="false";

                               this.setState({colorDIS:"#663333",disabledDIS:false,_latDis:latDis,_lngDis:lngDis})
                   }
           }
     _bld = (event) => {
        var lngLat = event.lngLat;
        fetch('http://1.10.184.64:3000/bldg/point500m.php?lat=' + this.state.lat + "&&lon=" + this.state.lon + "&&m=" + this.state.weaponmin)
            .then(response => response.json())
            .then(data => {

                this.setState({ disabledSBK: false, disabledSSNI: false, point500m: '[' + data[0].point + ']', loading: false, mapzoom: 15 });

                var polygon = JSON.parse(this.state.point500m);
                this.setState({ polygonM: data[0].point })
                var mapLayer = this.map.getLayer('_maine500');
                if (typeof mapLayer !== 'undefined') {

                    this.map.removeLayer('_maine500').removeSource('maine500');
                }
                this.map.addSource('maine500', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': polygon
                        }
                    }
                });
                this.map.addLayer({
                    'id': '_maine500',
                    'type': 'fill',
                    'source': 'maine500',
                    'layout': {},
                    'paint': {
                        'fill-color': "red",
                        'fill-opacity': 0.3
                    }
                });



            });

        fetch('http://1.10.184.64:3000/bldg/point1000m.php?lat=' + this.state.lat + "&&lon=" + this.state.lon + "&&m=" + this.state.weaponmax)
            .then(response => response.json())
            .then(data => {

                this.setState({ point1000m: '[' + data[0].point + ']', loading: false, mapzoom: 15, polygonMBK: data[0].point });

                var polygon = JSON.parse(this.state.point1000m);
                var mapLayer = this.map.getLayer('_maine1000');
                if (typeof mapLayer !== 'undefined') {

                    this.map.removeLayer('_maine1000').removeSource('maine1000');
                }
                this.map.addSource('maine1000', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': polygon
                        }
                    }
                });
                this.map.addLayer({
                    'id': '_maine1000',
                    'type': 'fill',
                    'source': 'maine1000',
                    'layout': {},
                    'paint': {
                        'fill-color': "#FFFF00",
                        'fill-opacity': 0.1
                    }
                });
            });


       var min_url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&cql_filter=DWithin(geom,POINT(" + this.state._x1 + " " + this.state._y1 + ")," + this.state.weaponmin + ",meters)&layers=ctoc:bldg";

        var mpLayer = this.map.getLayer("min_l_showblg");
        if (typeof mpLayer === 'undefined') {
            // No Layer
        } else {

            this.map.removeLayer("min_l_showblg");
            this.map.removeSource("min_s_showblg");
        }
        this.map.addSource('min_s_showblg', {
            type: 'raster',
            tiles: [min_url]
        });
        this.map.addLayer({
            'id': 'min_l_showblg',
            'type': 'raster',
            'source': 'min_s_showblg'
        });

        var url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&cql_filter=DWithin(geom,POINT(" + this.state._x1 + " " + this.state._y1 + ")," + this.state.weaponmax + ",meters) AND !DWithin(geom,POINT(" + this.state._x1 + " " + this.state._y1 + "),"+this.state.weaponmin+",meters)&layers=ctoc:bldg";

        var mpLayer = this.map.getLayer("l_showblg");
        if (typeof mpLayer === 'undefined') {

        } else {

            this.map.removeLayer("l_showblg");
            this.map.removeSource("s_showblg");
        }
        this.map.addSource('s_showblg', {
            type: 'raster',
            tiles: [url]
        });
        this.map.addLayer({
            'id': 'l_showblg',
            'type': 'raster',
            'source': 's_showblg'
        });

        this.setState({ x1: this.state._x1, y1: this.state._y1 });
        this.ClearLayer();


       }

        mapc =()=>{}
        bmapc1 = () =>{

         var mapLayer = this.map.getLayer('oms-map');

                                           if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('dark-map').removeSource('dark-tiles');
                                   }
                                    var mapLayer = this.map.getLayer('dark-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('dark-map').removeSource('dark-tiles');
                                   }
                                   var mapLayer = this.map.getLayer('basic-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('basic-map').removeSource('basic-tiles');
                                   }
                                     var mapLayer = this.map.getLayer('satellite-map');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('satellite-map').removeSource('mapbox-satellite');
                                   }

           //this.setState({typemap:});
        }
        bmapc2 = () =>{


               this.map.setLayoutProperty('basic-map', 'visibility', 'none');
                 this.map.setLayoutProperty('dark-map', 'visibility', 'none');
                   this.map.setLayoutProperty('oms-map', 'visibility', 'none');
                    this.map.setLayoutProperty('satellite-map', 'visibility', 'none');
    var mapLayer = this.map.getLayer('dark-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('dark-map').removeSource('dark-tiles');
                                   }


                        this.map.addSource("dark-tiles", {
            "type": "raster",
            "tiles": ["https://api.mapbox.com/styles/v1/mapbox/dark-v8/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w"],
            "tileSize": 256
            })

                      this.map.addLayer({
                      "type": "raster",
                      "id": 'dark-map',
                      "source": "dark-tiles",
                      "layout": {
                          "visibility": "visible"
                        },
                       });

 this.map.moveLayer('dark-map', 0);
                        this.ClearLayer();


          //this.map.on('styledata')
           //this.setState({typemap:"mapbox://styles/mapbox/dark-v8"});
        }
         bmapc3 = () =>{

            this.map.setLayoutProperty('basic-map', 'visibility', 'none');
                 this.map.setLayoutProperty('dark-map', 'visibility', 'none');
                   this.map.setLayoutProperty('oms-map', 'visibility', 'none');
                    this.map.setLayoutProperty('satellite-map', 'visibility', 'none');
          var mapLayer = this.map.getLayer('basic-map');
                                   if(typeof mapLayer !== 'undefined') {
                                       this.map.removeLayer('basic-map').removeSource('basic-tiles');
                                   }


                        this.map.addSource("basic-tiles", {
            "type": "raster",
            "tiles": ["https://api.mapbox.com/styles/v1/mapbox/streets-v8/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w"],
            "tileSize": 256
            })

                      this.map.addLayer({
                      "type": "raster",
                      "id": 'basic-map',
                      "source": "basic-tiles",
                      "layout": {
                          "visibility": "visible"
                        },
                       });
                            this.map.moveLayer('basic-map', 0);
                         this.ClearLayer();
           //this.setState({typemap:"mapbox://styles/mapbox/streets-v8"});
        }
         bmapc4 = () =>{
         this.map.setLayoutProperty('basic-map', 'visibility', 'none');
                 this.map.setLayoutProperty('dark-map', 'visibility', 'none');
                   this.map.setLayoutProperty('oms-map', 'visibility', 'none');
                    this.map.setLayoutProperty('satellite-map', 'visibility', 'none');

         var mapLayer = this.map.getLayer('satellite-map');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('satellite-map').removeSource('mapbox-satellite');
                                   }


                      this.map.addSource("mapbox-satellite", { "type": "raster", "url": "mapbox://mapbox.satellite", "tileSize": 256 });
                      this.map.addLayer({
                      "type": "raster",
                      "id": 'satellite-map',
                      "source": "mapbox-satellite",
                      "layout": {
                          "visibility": "visible"
                        },
                       });
                        this.map.moveLayer('satellite-map', 0);
                      this.ClearLayer();

           //this.setState({typemap:"mapbox://styles/mapbox/satellite-v9"});
        }
         _3d = ()=>
         {

                if(this.state.open3d=="1")
                {
                     this.ClearLayer();
                    this.map.setLayoutProperty('_planbk', 'visibility', 'none');
                    this.map.setLayoutProperty('_plansni', 'visibility', 'none');
                    this.map.setLayoutProperty('_plan', 'visibility', 'none');
                     this.setState({open3d:"",_3dcolor:"#ffc107"})
                }
                else
                {
                     this.ClearLayer();
                    this.map.setLayoutProperty('_planbk', 'visibility', 'visible');
                    this.map.setLayoutProperty('_plansni', 'visibility', 'visible');
                    this.map.setLayoutProperty('_plan', 'visibility', 'visible');
                     this.setState({open3d:"1",_3dcolor:"#7E4C09"})

                }
         }



      closepopupbk =()=>{
          this.setState({popupbk: false});
      }
       openpopupbk =()=>{
          this.setState({popupbk: true,tablestatus:'bk',tabledata:[],polygonMaster:this.state.polygonM});
      }

             openpopupsni =()=>{
          this.setState({popupbk: true,tablestatus:'sni',tabledata:[],polygonMaster:this.state.polygonMBK});
      }
          _3d_ = ()=>{

            mapboxgl.accessToken = 'pk.eyJ1IjoicHN5cmVuZHVzdCIsImEiOiJjajVsZ3RtMXcyZ2Z0MndsbTM2c2VzZTdnIn0.4SXh1jwWtkfJURT7V8kN4w';
            this.map = new mapboxgl.Map({
                style: 'mapbox://styles/mapbox/light-v10',
                center: [-74.0066, 40.7135],
                zoom: 15.5,
                pitch: 45,
                bearing: -17.6,
                container: 'map',
                antialias: true
            });

                            this.map.on('load', () => {


                               var layers =  this.map.getStyle().layers;
                                var labelLayerId;
                                for (var i = 0; i < layers.length; i++) {
                                if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                                labelLayerId = layers[i].id;
                                break;
                                }
                                }

                                this.map.addLayer(
                                {
                                'id': '3d-buildings',
                                'source': 'composite',
                                'source-layer': 'building',
                                'filter': ['==', 'extrude', 'true'],
                                'type': 'fill-extrusion',
                                'minzoom': 15,
                                'paint': {
                                'fill-extrusion-color': '#aaa',

                                // use an 'interpolate' expression to add a smooth transition effect to the
                                // buildings as the user zooms in
                                'fill-extrusion-height': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                15,
                                0,
                                15.05,
                                ['get', 'height']
                                ],
                                'fill-extrusion-base': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                15,
                                0,
                                15.05,
                                ['get', 'min_height']
                                ],
                                'fill-extrusion-opacity': 0.6
                                }
                                },
                                labelLayerId
                                );

                            this.map.addSource('floorplan', {
                    // GeoJSON Data source used in vector tiles, documented at
                    // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
                    'type': 'geojson',
                    'data':
                    'https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson'
                    });

                    this.map.addLayer({
                                'id': 'room-extrusion',
                                'type': 'fill-extrusion',
                                'source': 'floorplan',
                                'paint': {
                                // See the Mapbox Style Specification for details on data expressions.
                                // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

                                // Get the fill-extrusion-color from the source 'color' property.
                                'fill-extrusion-color': ['get', 'color'],

                                // Get fill-extrusion-height from the source 'height' property.
                                'fill-extrusion-height': ['get', 'height'],

                                // Get fill-extrusion-base from the source 'base_height' property.
                                'fill-extrusion-base': ['get', 'base_height'],

                                // Make extrusions slightly opaque for see through indoor walls.
                                'fill-extrusion-opacity': 0.5
                                    }
                    });
                 });
        }
           _blbk = () =>{

               this.setState({load_data:true});
            fetch('http://1.10.184.64:3000/bldg/polygon.php?lat=' + this.state.lat + "&&lon=" + this.state.lon + "&&m=" + this.state.weaponmin)
                    .then(response => response.json())
                    .then(data => {

                           var polygon500 = data[0].point;

                           fetch('http://1.10.184.64:3000/bldg/polygon.php?lat=' + this.state.lat + "&&lon=" + this.state.lon + "&&m=" + this.state.weaponmax)
                            .then(response => response.json())
                            .then(data => {

                                     var polygon1000 = data[0].point;

                                        const requestBody = {
                                            p500: polygon500,
                                            p1000: polygon1000
                                        }
                                        const config = {
                                            headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded'
                                            }
                                        }
                                        const qs = require('querystring')
                                        axios.post('http://1.10.184.64:3000/bldg/pointOut.php', qs.stringify(requestBody), config)
                                            .then((result) => {

                                                    var  __result = result.data.split('#');
                                                     for (var i = 0; i < __result.length-1; i++) {

                                                     var v  = JSON.parse(__result[i]);


                                                                   var popup = new mapboxgl.Popup({ className: "_out"})
                                                                      .setLngLat([v.X, v.Y])
                                                                      .setHTML('<div padding: 3px;"><div>ชื่อ:'+v.bl_name+'</div><div>จำนวนชั้น:'+v.bl_nstorey+'</div><div>ความจุ:'+v.bl_employ+'</div><div>พื้นที่:'+v.bl_area.toFixed(2)+'</div><div></div></div>')
                                                                      .addTo(this.map);
                                                              }


                                                              this.setState({c_outpopup: __result.length,load_data:false})
                                                              this._closepopupin();
                                                       this.ClearLayer();




                                            });
                            });

                    });
         this._closepopupin()
               if(latLT!=0&&latLT!=0){
                     actionBK ="true";
                         this.map.on('click', function (e) {
                                         var lngLat =e.lngLat.wrap();
                                                lngBK=lngLat.lng;
                                                latBK=lngLat.lat;

                        });

                    this.setState({colorBK:"#400140",disabledBK:true})
                        }

        }

           _Sniper = () =>{





              var  _lbl_nstorey = this.state.lbl_nstorey+2;
                        this.setState({load_data:true});
                                fetch('http://1.10.184.64:3000/bldg/pointIn.php?lat='+this.state.maplng+'&&lon='+this.state.maplat+'&&m=50&&no_m=0&&lbl_nstorey='+_lbl_nstorey)
                                    .then(response => response.json())
                                        .then(data => {


                                                     var  __result = data.split('#');

                                                     for (var i = 0; i < __result.length-1; i++)
                                                     {

                                                                   var v  = JSON.parse(__result[i]);
                                                                   var popup = new mapboxgl.Popup({ className: "_in"})
                                                                      .setLngLat([v.X, v.Y])
                                                                      .setHTML('<div padding: 3px;"><div>ชื่อ:'+v.bl_name+'</div><div>จำนวนชั้น:'+v.bl_nstorey+'</div><div>ความจุ:'+v.bl_employ+'</div><div>พื้นที่:'+v.bl_area.toFixed(2)+'</div><div></div></div>')
                                                                      .addTo(this.map);

                                                     }


                                                     var c_inpopup1  = __result.length;
                                                       var  _lbl_nstorey = this.state.lbl_nstorey+5;
                                                        fetch('http://1.10.184.64:3000/bldg/pointIn.php?lat='+this.state.maplng+'&&lon='+this.state.maplat+'&&m=200&&no_m=50&&lbl_nstorey='+_lbl_nstorey)
                                                            .then(response => response.json())
                                                                .then(data => {


                                                                                          var  __result = data.split('#');
                                                                                         for (var i = 0; i < __result.length-1; i++)
                                                                                         {
                                                                                                       var v  = JSON.parse(__result[i]);
                                                                                                       var popup = new mapboxgl.Popup({ className: "_in"})
                                                                                                          .setLngLat([v.X, v.Y])
                                                                                                          .setHTML('<div padding: 3px;"><div>ชื่อ:'+v.bl_name+'</div><div>จำนวนชั้น:'+v.bl_nstorey+'</div><div>ความจุ:'+v.bl_employ+'</div><div>พื้นที่:'+v.bl_area.toFixed(2)+'</div><div></div></div>')
                                                                                                          .addTo(this.map);
                                                                                         }

                                                                                         var c_inpopup2  = c_inpopup1+__result.length;



                                                                                var  _lbl_nstorey = this.state.lbl_nstorey+10;
                                                                                fetch('http://1.10.184.64:3000/bldg/pointIn.php?lat='+this.state.maplng+'&&lon='+this.state.maplat+'&&m='+this.state.weaponmin+'&&no_m=200&&lbl_nstorey='+_lbl_nstorey)
                                                                                    .then(response => response.json())
                                                                                        .then(data => {


                                                                                                         console.log(JSON.stringify(data)+"_XXX_XX")
                                                                                                         var  __result = data.split('#');
                                                                                                         for (var i = 0; i < __result.length-1; i++)
                                                                                                         {
                                                                                                                       var v  = JSON.parse(__result[i]);
                                                                                                                       var popup = new mapboxgl.Popup({ className: "_in"})
                                                                                                                          .setLngLat([v.X, v.Y])
                                                                                                                          .setHTML('<div padding: 3px;"><div>ชื่อ:'+v.bl_name+'</div><div>จำนวนชั้น:'+v.bl_nstorey+'</div><div>ความจุ:'+v.bl_employ+'</div><div>พื้นที่:'+v.bl_area.toFixed(2)+'</div><div></div></div>')
                                                                                                                          .addTo(this.map);

                                                                                                         }


                                                                                                          var c_inpopup3  = c_inpopup2+__result.length;

                                                                                                          this.setState({c_inpopup: c_inpopup3,load_data:false})


                                                                                        });
                                                                });

                                        });

                        this._closepopupout();




            if(latLT!=0&&latLT!=0){
                     actionSniper ="true";
                         this.map.on('click', function (e) {
                                         var lngLat =e.lngLat.wrap();
                                                lngSP=lngLat.lng;
                                                latSP=lngLat.lat;

                        });
                           this.setState({colorSNI:"#630808",disabledSNI:false})
                        }
        }

         _Distance = () =>{
           if(latLT!=0&&latLT!=0){
                     actionDistance ="true";
                         this.map.on('click', function (e) {
                                         var lngLat =e.lngLat.wrap();
                                                lngDis=lngLat.lng;
                                                latDis=lngLat.lat;

                        });
                          this.setState({colorDIS:"#1c0707",disabledDIS:false})
                        }
        }

        _StreetOpen = () =>{
             //if(lngLT!="0" && latLT!="0"){

                    this.setState({ststyle:""});
                     actionStreetview ="true";
                         this.map.on('click', function (e) {
                                         var lngLat =e.lngLat.wrap();
                                                lngSV=lngLat.lng;
                                                latSV=lngLat.lat;

                        });
                           this.setState({stcolor:"#0d3817"})
                // }
        }
         _cal = () =>
         {
            if(latLT!=="" && latSP !="" )
            {
            let pt1 = point([latLT, lngLT], { 'name': 'Kano State' })
            let pt2 = point([latSP,lngSP], { 'name': 'Lagos State' })

            var  result = distance(pt1, pt2, { units: 'metres' })
            var calresult = parseFloat(result).toFixed(2);
            this.setState({vdistance:calresult});
            }
        }
        _text_rpt = (event, values) =>
		{
			 this.setState({text_rpt:event.target.value});
		}



        initMap() {
            // Initialize you're map here
            // i.e. if you want to load an overlays right away, etc.
        }

        // Wrap mapbox flyTo for easy accessibility
        // Grap map from the store which holds the position that our map should be at
        setMapPosition() {
            var { map } = this.props;
            this.map.flyTo({
                center: map.center,
                zoom: map.zoom,
                pitch: map.pitch || 0,
                bearing: map.bearing || 0
            });
        }
         FormClickOpen = () => {
        this.setState({openForm: true});
      };

      FormRequestClose = () => {
        this.setState({openForm: false});
      };
       /*Formpopup*/
         maphandleChange  = (event, value) =>
         {
             this.setState({tabvalue:value})
         };
        _goToProvince = (event) => {
                      var value= ""+event.target.value;
                       this.setState({Gotoprovince:value})
                        if(value!=""){
                             this.setState({province_code: value });
                              }

                                fetch('http://1.10.184.64:3000/district/index.php?id='+value)
                                    .then(response => response.json())
                                    .then(data => {
                                    this.setState({ district: data, loading: false });
                         });

              };
        _goToDistrict = (event) => {

                           var value= ""+event.target.value;
                           this.setState({Gotodistrict:value})
                               if(value!=""){

                                 this.setState({district_code: value });
                                 }
                       fetch('http://1.10.184.64:3000/subdistrict/index.php?id='+value)
                         .then(response => response.json())
                         .then(data => {
                             this.setState({ subdistrict: data, loading: false });
                         });
              };
        _goToSubDistrict = (event) => {
                    var value= ""+event.target.value;
                   this.setState({Gotosubdistrict:value})
              };

              _name = event => {this.setState({name: event.target.value }); };
              _lname = event => {this.setState({lname: event.target.value }); };
              _p_type = event => {this.setState({p_type: event.target.value }); };
              _p_form = event => {this.setState({p_form: event.target.value }); };
              _topography = event => {this.setState({topography: event.target.value }); };
              _population = event => {this.setState({population: event.target.value }); };
              _dominance = event => {this.setState({dominance: event.target.value }); };
              _religion = event => {this.setState({religion: event.target.value }); };
              _way_land = event => {this.setState({way_land: event.target.value }); };
              _way_water = event => {this.setState({way_water: event.target.value }); };
              _plan_status = event => {this.setState({plan_status: event.target.value }); };
                _img_buildx = (e) => {

                        if (e.target.files && e.target.files[0]) {
                          let img = e.target.files[0];
                          this.setState({
                            image: URL.createObjectURL(img)
                          });

                        }
                };

            _img_buildxx = (e) => {

                // '/files' is your node.js route that triggers our middleware
                axios.post('http://1.10.184.64:3000/upload/upload_file.php', e.target.files).then((response) => {

                });
             }
                 _upload_bldg(event) {
                    this.setState({
                        selectedFile_bldg: event.target.files[0],
                      })
                }

                upload_bldg() {

                        const data = new FormData()
                        data.append('file', this.state.selectedFile_bldg)

                        let url = "http://1.10.184.64:3000/uploads/bldg.php?id="+this.state.name;
                        axios.post(url, data, { // receive two parameter endpoint url ,form data
                        })
                        .then(res => { // then print response status

                        })

                       this.setState({img_build: 'uploads/file/bldg/'+this.state.name+''});



              }
               _upload_enter(event) {
                    this.setState({
                        selectedFile_enter: event.target.files[0],
                      })
                }

              upload_enter() {

                        const data = new FormData()
                        data.append('file', this.state.selectedFile_enter)

                        let url = "http://1.10.184.64:3000/uploads/enter.php?id="+this.state.name;
                        axios.post(url, data, { // receive two parameter endpoint url ,form data
                        })
                        .then(res => { // then print response status

                        })
                         this.setState({img_in: 'uploads/file/enter/'+this.state.name+''});


              }
            _los =()=>{
     this.setState({_srclos:"http://1.10.184.64:3000/map/los.php?lat1="+this.state.maplat+"&lng1="+this.state.maplng+"&lat2="+this.state._latSP+"&lng2="+this.state._lngSP+""})
    this.setState({ststylelos:""})
    }
                _upload_exit(event) {
                    this.setState({
                        selectedFile_exit: event.target.files[0],
                      })
                }
              upload_exit() {

                        const data = new FormData()
                        data.append('file', this.state.selectedFile_exit)

                        let url = "http://1.10.184.64:3000/uploads/exit.php?id="+this.state.name;
                        axios.post(url, data, { // receive two parameter endpoint url ,form data
                        })
                        .then(res => { // then print response status

                        })
                        this.setState({img_out: 'uploads/file/exit/'+this.state.name+''});
              }

     Save = event => {
                          this.upload_bldg()
                          this.upload_enter()
                          this.upload_exit()

                            event.preventDefault();
                            var location = window.location.href;
                                const str = '' + location;
                                const xid = str.split("=")[1];
                            const requestBody =
                            {
                              id : xid,
                              geom: this.state.lon+","+this.state.lat,
                              name: this.state.name,
                              lname: this.state.lname,
                              p_type: this.state.p_type,
                              p_form: this.state.p_form,
                              topography: this.state.topography,
                              population: this.state.population,
                              dominance: this.state.dominance,
                              religion: this.state.religion,
                              way_land: this.state.way_land,
                              way_water: this.state.way_water,
                              way_water: this.state.way_water,
                              img_build: this.state.img_build,
                              img_in: this.state.img_in,
                              img_out: this.state.img_out,
                              province_code: this.state.province_code,
                              district_code: this.state.district_code,
                              sub_district_code: this.state.sub_district_code,
                              plan_status: this.state.plan_status,
                              w_id:this.state.w_id,
                              eventname:this.state.eventname,
                            }


              if(this.state.id==null)
              {
              const config = {
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                          }
                  }

                  if(this.state.lon==0&&this.state.name==null){

                  this.setState({open2: true});
                  }
               var check ="";
                   // if(this.state.lon!=0&&this.state.name!=null){
                const qs = require('querystring')



                axios.post('http://1.10.184.64:3000/actionplan/updateall.php',qs.stringify(requestBody), config)
                .then((result) => {

                  const name = ""+qs.stringify(result);
                  const splitName = name.split("failed");
                  const length = splitName.length;




                                if(length!=1){
                                 this.setState({duperror: true});
                                }
                                })

                                this.FormRequestClose()



                            this.setState({dataComptele:true});



                            //}
                  }
    }
    handleRequestClose  = ()=>
    {
       this.setState({openmsg2:false,duperror:false,dataError:false,dataComptele:false,msgsni:false,openmsg:false})
    }
    _SaveBK = event =>
    {
                         event.preventDefault();
                            const requestBody =
                            {
                              id : this.state.id,
                              geom: this.state._lngBK+" "+this.state._latBK,
                              name: this.state.bl_name,
                              poi_type:'404',
                              plan_id: this.state.idplen,
                              bl_nstorey: this.state.bl_nstorey,
                              bl_employ: this.state.bl_employ

                            }
                          const config = {
                                              headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded'
                                              }
                                        }

                   const qs = require('querystring')
                   axios.post('http://1.10.184.64:3000/poi_plan/update.php',qs.stringify(requestBody), config)
                   .then((result) => {

                    })

    }
    _SaveSNI = event =>
    {
                         event.preventDefault();
                            const requestBody =
                            {
                              id : this.state.id,
                              geom: lngSP+" "+latSP,
                              name: this.state.bs_name,
                              poi_type:'405',
                              plan_id: this.state.idplen,
                              bl_nstorey: this.state.bs_nstorey,
                              bl_employ: this.state.bs_employ

                            }
                          const config = {
                                              headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded'
                                              }
                                        }

                           const qs = require('querystring')
                           axios.post('http://1.10.184.64:3000/poi_plan/update.php',qs.stringify(requestBody), config)
                           .then((result) => {

                            })

    }
      _SaveNVG = event =>
    {
                         event.preventDefault();
                            const requestBody =
                            {
                              id : this.state.id,
                              geom: lngDis+" "+latDis,
                              name: this.state.bs_name,
                              poi_type:'412',
                              plan_id: this.state.idplen,
                              bl_nstorey: this.state.bs_nstorey,
                              bl_employ: this.state.bs_employ

                            }
                          const config = {
                                              headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded'
                                              }
                                        }

                           const qs = require('querystring')
                           axios.post('http://1.10.184.64:3000/poi_plan/update.php',qs.stringify(requestBody), config)
                           .then((result) => {

                            })

    }
         rowdelete =( event, values)=>{
         this.setState({deleteid:values,deleteopen:true})
         //   console.log(values+"sssssssssssssssss")
         }
          rowdelete2 =()=>{
            this.setState({ poi_plan_report: [], loading: false });
             var location = window.location.href;
                                const str = '' + location;
                                const xid = str.split("=")[1];

                  axios.get("http://1.10.184.64:3000/poi_plan_report/delete.php?id="+this.state.deleteid)
                           .then((result) => {


                               this.setState({dataComptele:true});

                                    fetch('http://1.10.184.64:3000/poi_plan_report/index.php?plan_id='+xid)
                                        .then(response => response.json())
                                        .then(data => {

                                       var mpLayer = this.map.getLayer("points");
                                        if (typeof mpLayer === 'undefined') {
                                            // No Layer
                                        } else {

                                            this.map.removeLayer("points");
                                             this.map.removeSource("points");
                                        }
                                                  this.map.loadImage(
                                                       imggeolocation2,
                                                 (error, image) => {
                                                  try{ this.map.addImage("imggeolocation3", image);}catch{}
                                                   var _data = [];
                                                 for(var i=0;i<data.length-1;i++)
                                                 {
                                                     _data.push
                                                     ({
                                                     'type':'Feature',
                                                     'geometry':{'type':'Point','coordinates':[data[i].y,data[i].x]},
                                                     'properties':{'title':'ff'}
                                                     })
                                                 }
                                                  this.map.addSource('points', {
                                                        'type': 'geojson',
                                                        'data': {
                                                        'type': 'FeatureCollection',
                                                                    'features':  JSON.parse(JSON.stringify(_data))
                                                        }
                                                    });

                                                    this.map.addLayer({
                                                            'id': "points",
                                                            'type': 'symbol',
                                                            'source': 'points',
                                                            'layout': {
                                                            'icon-image': 'imggeolocation3',
                                                            'icon-size': 0.1
                                                                }
                                                            });
                                             });

                                               var sdata = data.sort((a, b) => (a.id < b.id ? 1 : -1));

                                              if(JSON.stringify(data[0])=="{}")
                                              {
                                                data =[];
                                              }

                                                 this.setState({ poi_plan_report: data, loading: false });


                                        });



                           })
                           .catch((err) => {
                            console.log(err)
                         })

                        this.setState({deleteopen:false})
         }
        _weapon = event =>
        {                      event.preventDefault();
                                const requestBody =
                                {
                                  id : this.state.idplen,
                                  w_id: this.state.selectWeapon,
                                }
                              const config = {
                                                  headers: {
                                                    'Content-Type': 'application/x-www-form-urlencoded'
                                                  }
                                            }
                               const qs = require('querystring')
                               axios.post('http://1.10.184.64:3000/actionplan/update.php',qs.stringify(requestBody), config)
                               .then((result) => {

                                })
        }
        SAVE_POI = event =>
        {
                            event.preventDefault();
                             var location = window.location.href;
                                const str = '' + location;
                                const xid = str.split("=")[1];

                              const config = {
                                                  headers: {
                                                    'Content-Type': 'application/x-www-form-urlencoded'
                                                  }
                                            }
                             const qs = require('querystring')

                        if(this.state._lngBK >  0 && this.state._latBK > 0){

                           const requestBody =
                            {
                              id : xid,
                              geom: this.state._lngBK+","+this.state._latBK,
                              name: this.state.bl_name,
                              poi_type:'404',
                              plan_id: xid,
                              bl_nstorey: this.state.bl_nstorey,
                              bl_employ: this.state.bl_employ

                            }



                           axios.post('http://1.10.184.64:3000/poi_plan/update.php',qs.stringify(requestBody), config)
                           .then((result) => {

                            });

                            };
                            const  updaterequestBody =
                                {
                                  id : xid,
                                  w_id: this.state.selectWeapon,
                                }

                               axios.post('http://1.10.184.64:3000/actionplan/update.php',qs.stringify(updaterequestBody), config)
                               .then((result) => {

                                })


                if(latSP > 0 && lngSP > 0){




                          const   insertsniperrequestBody =
                            {
                              id : xid,
                              geom: this.state._lngSP+","+this.state._latSP,
                              name: this.state.bs_name,
                              poi_type:'412',
                              plan_id: xid,
                              bl_nstorey: this.state.bs_nstorey,
                              bl_employ: this.state.bs_employ

                            }



                           axios.post('http://1.10.184.64:3000/poi_plan/update.php',qs.stringify(insertsniperrequestBody), config)
                           .then((result) => {

                            });

                 }

                           if(lngDis > 0 && latDis >0){
                           const      insertdisrequestBody =
                            {
                              id : xid,
                              geom: this.state._lngDis+","+this.state._latDis,
                              name: this.state.bs_name,
                              poi_type:'413',
                              plan_id: xid,
                              bl_nstorey: this.state.bs_nstorey,
                              bl_employ: this.state.bs_employ

                            }


                           axios.post('http://1.10.184.64:3000/poi_plan/update.php',qs.stringify(insertdisrequestBody), config)
                           .then((result) => {

                            });

                             }


                             const      route_plan =
                            {
                              id : this.state.id,
                              geom:  JSON.stringify(this.state.rdirections),
                              name: '',
                              poi_type:'',
                              route_desc:'',
                              img_path: '',
                              plan_id: xid

                            }



                           axios.post('http://1.10.184.64:3000/route_plan/update.php',qs.stringify(route_plan), config)
                           .then((result) => {

                            });

                 this.setState({dataComptele:true});
                  this.props.history.push("/app/tableactionmap/data");
        }
        filter =(event)=>
       {
        this.setState({ tabledata: [], loading: false });

                var polygonM=this.state.polygonM
                 var value =event.target.value;
                if(value=="")
                {


                }
                else
                {
                 if(polygonM.length>20)
                 {
                const requestBody = {
                                      id : this.state.polygonMaster,
                                      value:value
                                    }
                const config = {
                                  headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                  }
                              }
                const qs = require('querystring')
                        axios.post('http://1.10.184.64:3000/bldg/indexbl_nstorey.php',qs.stringify(requestBody), config)
                        .then((result) => {

                          if(typeof result.data==='undefined') { }
                          else
                          {
                              if(result.data.length>20){
                                try{
                                 var  data =  result.data.sort((a, b) => (a.bl_name < b.bl_name ? -1 : 1));

                                 this.setState({ tabledata: data, loading: false });
                                    }catch{}
                              }
                          }

                        });
                        }
                        }
       }
       filter2 =(event)=>
       {

                 this.setState({ tabledata: [], loading: false });
                 var polygonM=this.state.polygonMaster
                    var value =event.target.value;

               if(value=="")
                {


                }
                else{
                if(polygonM.length>20)
                 {

                const requestBody = {
                                      id : polygonM,
                                      value:value
                                    }
                const config = {
                                  headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                  }
                              }
                const qs = require('querystring')
                        axios.post('http://1.10.184.64:3000/bldg/indexbl_employ.php',qs.stringify(requestBody), config)
                        .then((result) => {

                          if(typeof result.data==='undefined') { }
                          else
                          {
                              if(result.data.length>20){

                                    try{
                                 var  data =  result.data.sort((a, b) => (a.bl_name < b.bl_name ? -1 : 1));

                                 this.setState({ tabledata: data, loading: false });
                                    }catch{}
                              }
                          }

                        });
                        }
                        }

       }
        filter3 =(event)=>
       {

                 this.setState({ tabledata: [], loading: false });
                 var polygonM=this.state.polygonMaster
                    var value =event.target.value;

               if(value=="")
                {


                }
                else{
                if(polygonM.length>20)
                 {

                const requestBody = {
                                      id : polygonM,
                                      value:value
                                    }
                const config = {
                                  headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                  }
                              }
                const qs = require('querystring')
                        axios.post('http://1.10.184.64:3000/bldg/indexbl_name.php',qs.stringify(requestBody), config)
                        .then((result) => {

                          if(typeof result.data==='undefined') { }
                          else
                          {
                              if(result.data.length>20){

                                 try{
                                 var  data =  result.data.sort((a, b) => (a.bl_name < b.bl_name ? -1 : 1));

                                 this.setState({ tabledata: data, loading: false });
                                    }catch{}
                              }
                          }

                        });
                        }
                        }

       }
       _save_rpt =()=>
         {
           if( this.state.distext==true)
           {

           }else{


            if(_longitude==0 && _latitude.length==0 ){
               this.setState({openpoint:true})
            }
            else{
                  this.setState({latitude:_latitude,longitude:_longitude})
                     //console.log(_latitude+"xxxxxxxxxxxx");
                           const config = {
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                                    }
                            }
                            const qs = require('querystring')


                               var location = window.location.href;
                                const str = '' + location;
                                const xid = str.split("=")[1];
                                var   userid =cookie.load('userId');

                          const   Body =
                            {
                              id : this.state.id,
                              geom: _latitude+","+_longitude,
                              user_id: userid,
                              dt_rpt:'',
                              text_rpt: this.state.text_rpt,
                              plan_id: xid

                            }
                           axios.post('http://1.10.184.64:3000/poi_plan_report/insert.php',qs.stringify(Body), config)
                           .then((result) => {

                               this.setState({dataComptele:true});

                                    fetch('http://1.10.184.64:3000/poi_plan_report/index.php?plan_id='+xid)
                                        .then(response => response.json())
                                        .then(data => {

                                       var mpLayer = this.map.getLayer("points");
                                        if (typeof mpLayer === 'undefined') {
                                            // No Layer
                                        } else {

                                            this.map.removeLayer("points");
                                             this.map.removeSource("points");
                                        }
                                                  this.map.loadImage(
                                                       imggeolocation2,
                                                 (error, image) => {
                                                  try{ this.map.addImage("imggeolocation3", image);}catch{}
                                                   var _data = [];
                                                 for(var i=0;i<data.length-1;i++)
                                                 {
                                                     _data.push
                                                     ({
                                                     'type':'Feature',
                                                     'geometry':{'type':'Point','coordinates':[data[i].y,data[i].x]},
                                                     'properties':{'title':'ff'}
                                                     })
                                                 }
                                                  this.map.addSource('points', {
                                                        'type': 'geojson',
                                                        'data': {
                                                        'type': 'FeatureCollection',
                                                                    'features':  JSON.parse(JSON.stringify(_data))
                                                        }
                                                    });

                                                    this.map.addLayer({
                                                            'id': "points",
                                                            'type': 'symbol',
                                                            'source': 'points',
                                                            'layout': {
                                                            'icon-image': 'imggeolocation3',
                                                            'icon-size': 0.1
                                                                }
                                                            });
                                             });

                                               var sdata = data.sort((a, b) => (a.id < b.id ? 1 : -1));

                                                 this.setState({ poi_plan_report: data, loading: false,distext:true });


                                        });

                            });
                            }

           }

         }
       _goToBK = (event,name) => {

                          if(event.type=="click"){

                                  var _name = this.state.gotoname;

                                   if(typeof name==='undefined') { } else {_name =name}
                                  fetch('http://1.10.184.64:3000/bldg/selectID.php?id='+_name)
                                    .then(response => response.json())
                                    .then(data => {

                                         this.map.flyTo({ center: [data[0].x, data[0].y],zoom:15});
                                           lngBK = data[0].x;
                                           latBK = data[0].y;

                                                    var _bl_name  =data[0].bl_name;
                                                   var _bl_nstorey  =data[0].bl_nstorey;
                                                   var _bl_employ  =data[0].bl_employ;
                                                  this.setState({bbk:"", bl_name: _bl_name,bl_nstorey:_bl_nstorey,bl_employ :_bl_employ });

                                 var mapLayer = this.map.getLayer('_pointsbk');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_pointsbk').removeSource('pointbk');
                                   }
                                   this.map.loadImage(
                                    imgmarkerbk,
                                    (error, image) => {
                                   try{ this.map.addImage('imgmarker', image); } catch { }
                                     try{
                                    this.map.addSource('pointbk', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [data[0].x,data[0].y]
                                                }
                                                }
                                            ]
                                        }
                                    });

                                    this.map.addLayer({
                                            'id': '_pointsbk',
                                            'type': 'symbol',
                                            'source': 'pointbk',
                                            'layout': {
                                            'icon-image': 'imgmarker',
                                            'icon-size': 0.1
                                                }
                                            });
                                        } catch{}
                                        }
                                    );

                                     actionBK="false";

                                 this.setState({colorBK:"#990099",disabledBK:false,_lngBK:data[0].x,_latBK:data[0].y})

                                  this.ClearLayer()


                                 });



                                 }


                             }

                _goToSNI = (event,name) => {





                         if(lngLT!=0 && latLT!=0){

                                  var _name = this.state.gotoname;

                                   if(typeof name==='undefined') { } else {_name =name}
                                  fetch('http://1.10.184.64:3000/bldg/selectID.php?id='+_name)
                                    .then(response => response.json())
                                    .then(data => {

                                         this.map.flyTo({ center: [data[0].x, data[0].y],zoom:15});
                                           lngSP = data[0].x;
                                           latSP = data[0].y;

                                                   var _bs_name  =data.bl_name;
                                                                            var _bs_nstorey  =data.bl_nstorey;
                                                                               var _bs_employ =data.bl_employ;
                                                                            this.setState({bs:"", bs_name:_bs_name,bs_nstorey:_bs_nstorey,bs_employ:_bs_employ});


                                var mapLayer = this.map.getLayer('pointssniper');
                                   if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('pointssniper').removeSource('pointsniper');
                                   }
                                   this.map.loadImage(imgmarkersniper,
                                    (error, image) => {
                                   try{this.map.addImage('imgmarkersniper', image); }catch{ }
                                try{
                                    this.map.addSource('pointsniper', {
                                    'type': 'geojson',
                                        'data': {
                                        'type': 'FeatureCollection',
                                        'features': [
                                                {
                                                'type': 'Feature',
                                                'geometry': {
                                                'type': 'Point',
                                                'coordinates': [data[0].x,data[0].y]
                                                }
                                                }
                                            ]
                                        }
                                    });

                                    this.map.addLayer({
                                            'id': 'pointssniper',
                                            'type': 'symbol',
                                            'source': 'pointsniper',
                                            'layout': {
                                            'icon-image': 'imgmarkersniper',
                                            'icon-size': 0.1
                                                }
                                            });
                                             } catch{}
                                        }
                                    );



                                      var mapLayer = this.map.getLayer('_route');
                                      if(typeof mapLayer !== 'undefined') {

                                       this.map.removeLayer('_route').removeSource('route');
                                   }

                                   try{
                                     this.map.addSource('route', {
                                        'type': 'geojson',
                                        'data': {
                                        'type': 'Feature',
                                        'properties': {},
                                        'geometry': {
                                        'type': 'LineString',
                                        'coordinates': [
                                        [lngLT,latLT],
                                        [data[0].x,data[0].y],
                                        ]
                                        }
                                        }
                                });

                                this.map.addLayer({
                                            'id': '_route',
                                            'type': 'line',
                                            'source': 'route',
                                            'layout': {
                                            'line-join': 'round',
                                            'line-cap': 'round'
                                            },
                                            'paint': {
                                            'line-color': '#888',
                                            'line-width': 8
                                            }

                                });
                              } catch{}


                                     actionSniper="false";
                                        this.setState({colorSNI:"#FF4500",_lngSP:data[0].x,_latSP:data[0].y})
                                        this.setState({});

                           });

                      }else{
                      this.setState({msgsni:true})
                      }


                             }
                                  handleChangePage = (event, page) => {
                this.setState({page});
              };
           handleChangeRowsPerPage = event => {

            this.setState({rowsPerPage: event.target.value});
          };


       _inmap = (event) =>
    {

       if(event.target.checked==true)
       {

        try{
                  this.setState({inmap:true})
                 var polygon = JSON.parse(this.state.point500m);
                     this.map.addSource('maine500', {
                            'type': 'geojson',
                            'data': {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Polygon',
                                    'coordinates': polygon
                                }
                            }
                        });
                        this.map.addLayer({
                            'id': '_maine500',
                            'type': 'fill',
                            'source': 'maine500',
                            'layout': {},
                            'paint': {
                                'fill-color': "red",
                                'fill-opacity': 0.3
                            }
                        });
               }
               catch
               {

               }


       }else{
              this.setState({inmap:false})
           try{
                this.map.removeLayer('_maine500').removeSource('maine500');
           }
           catch
           {

           }

       }
    }
      _roadinmap = () =>
    {




         if(this.state.roadmin==0)
          {

                var  _x1 = this.state._x1;
                 var  _y1 = this.state._y1;
                 if(this.state.lon_terrorist==0 && this.state.lat_terrorist==0)
                 {
                                   this.setState({load_data:true})
                                    var min_url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&cql_filter=DWithin(geom,POINT("+ this.state.lat +" "+this.state.lon+"),"+this.state.weaponmin+",meters)&layers=ctoc:roads";
                                    var mpLayer = this.map.getLayer("road_min_l_showblg");
                                    if (typeof mpLayer === 'undefined') {
                                    } else {

                                        this.map.removeLayer("road_min_l_showblg");
                                        this.map.removeSource("road_min_s_showblg");
                                    }
                                    this.map.addSource('road_min_s_showblg', {
                                        type: 'raster',
                                        tiles: [min_url]
                                    });
                                    this.map.addLayer({
                                        'id': 'road_min_l_showblg',
                                        'type': 'raster',
                                        'source': 'road_min_s_showblg'
                                    });


                              fetch('http://1.10.184.64:3000/roads/roadin.php?lat='+this.state.lat+'&&lon='+this.state.lon+'&&m='+this.state.weaponmin+'')
                                .then(response => response.json())
                                .then(data => {
                                      data = data.filter(x=>x.name!=null);
                                                                 for (var i = 0; i < data.length; i++) {

                                                                       if(data[i].point=="")
                                                                          {

                                                                          }
                                                                          else
                                                                          {
                                                                  var POINT = data[i].point.split(' ');
                                                                   var popup = new mapboxgl.Popup({ className: "_roadin"})
                                                                                        .setLngLat([POINT[0], POINT[1]])
                                                                                       .setHTML('<div  style="padding: 3px;width:100%;word-wrap: break-word"><div>'+data[i].name+'</div></div>')
                                                                                         .addTo(this.map);
                                                                          }

                                                                   }
                                                             this.setState({roadincount:data.length,load_data:false})
                                                    });
                                 this.setState({roadmin:1})
                }
                else
                {

                                 this.setState({load_data:true})
                                 var lat =  this.state.lat_terrorist;
                                 var lon =  this.state.lon_terrorist;

                                    var min_url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&cql_filter=DWithin(geom,POINT("+ this.state.lon_terrorist +" "+this.state.lat_terrorist+"),"+this.state.weaponmin+",meters)&layers=ctoc:roads";
                                    var mpLayer = this.map.getLayer("road_min_l_showblg");
                                    if (typeof mpLayer === 'undefined') {
                                    } else {

                                        this.map.removeLayer("road_min_l_showblg");
                                        this.map.removeSource("road_min_s_showblg");
                                    }
                                    this.map.addSource('road_min_s_showblg', {
                                        type: 'raster',
                                        tiles: [min_url]
                                    });
                                    this.map.addLayer({
                                        'id': 'road_min_l_showblg',
                                        'type': 'raster',
                                        'source': 'road_min_s_showblg'
                                    });


                              fetch('http://1.10.184.64:3000/roads/roadin.php?lat='+this.state.lon_terrorist+'&&lon='+this.state.lat_terrorist+'&&m='+this.state.weaponmin+'')
                                .then(response => response.json())
                                .then(data => {
                                      data = data.filter(x=>x.name!=null);
                                                                 for (var i = 0; i < data.length; i++) {

                                                                       if(data[i].point=="")
                                                                          {

                                                                          }
                                                                          else
                                                                          {
                                                                  var POINT = data[i].point.split(' ');
                                                                   var popup = new mapboxgl.Popup({ className: "_roadin"})
                                                                                        .setLngLat([POINT[0], POINT[1]])
                                                                                       .setHTML('<div  style="padding: 3px;width:100%;word-wrap: break-word"><div>'+data[i].name+'</div></div>')
                                                                                         .addTo(this.map);
                                                                          }

                                                                   }
                                                             this.setState({roadincount:data.length,load_data:false})
                                                    });
                                 this.setState({roadmin:1})


                }


        }
        else
        {

                                         for(var i=0;i <this.state.roadincount;i++)
                                            {

                                                  try{
                                                  const _popup = document.getElementsByClassName('mapboxgl-popup _roadin');
                                                 _popup[0].remove();
                                                 }catch{

                                                 }



                                            }

            var mpLayer = this.map.getLayer("road_min_l_showblg");
                if (typeof mpLayer === 'undefined') {
                    // No Layer
                } else {

                    this.map.removeLayer("road_min_l_showblg");
                    this.map.removeSource("road_min_s_showblg");
                }
             this.setState({roadmin:0})
        }
          this.ClearLayer();
    }
     _poi_info =(event)=>
    {

     this.setState({ch_poi_info:event.target.checked})
     if(event.target.checked==false)
       {
           for(var i=0;i <8;i++)
            {
                 try{
                 const _popup = document.getElementsByClassName('mapboxgl-popup poi_info');
                 _popup[0].remove();
                 }catch{
                    return;
                 }

            }

       }
       else
       {

       var popup = new mapboxgl.Popup({ className:"poi_info"}).setLngLat([this.state.lat, this.state.lon])
                                                                        .setHTML('<div padding: 3px;"><div><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path></svg> พื้นที่เกิดเหตุ</div></div>')
                                                                         .addTo(this.map);

        var popup = new mapboxgl.Popup({ className:"poi_info"}).setLngLat([this.state._lngSP, this.state._latSP])
                                                                        .setHTML('<div padding: 3px;"><div> <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path></svg> พลซุ่มยิง</div></div>')
                                                                         .addTo(this.map);

        var popup = new mapboxgl.Popup({ className:"poi_info"}).setLngLat([this.state.lon_terrorist, this.state.lat_terrorist])
                                                                        .setHTML('<div padding: 3px;"><div>'+
                                                                            '<svg version="1.0" xmlns="http://www.w3.org/2000/svg"'+
                                                                             'width="20pt" height="20pt" viewBox="0 0 100.000000 100.000000"'+
                                                                             'preserveAspectRatio="xMidYMid meet">'+
                                                                            '<g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"'+
                                                                            'fill="back" stroke="none">'+
                                                                            '<path d="M360 928 c-76 -29 -159 -113 -191 -193 -20 -51 -23 -77 -23 -175 1'+
                                                                            ' -148 26 -248 85 -330 9 -12 4 -26 -21 -63 -40 -60 -35 -68 73 -98 118 -34 316'+
                                                                            ' -34 434 0 108 30 113 38 73 98 -25 37 -30 51 -21 63 58 81 84 181 85 330 0 99'+
                                                                            ' -3 124 -23 175 -33 82 -115 164 -194 193 -78 30 -200 29 -277 0z m57 -373 c23'+
                                                                            ' -14 43 -30 43 -35 0 -18 -87 -60 -123 -60 -41 0 -77 28 -77 60 0 59 83 78 157'+
                                                                            ' 35z m303 5 c42 -42 9 -100 -57 -100 -36 0 -123 42 -123 60 0 5 19 20 43 34 52'+
                                                                            ' 32 109 34 137 6z m-188 -151 c50 -27 63 -130 22 -178 -35 -42 -73 -42 -108 0'+
                                                                            ' -30 35 -34 81 -12 133 21 51 57 67 98 45z"/>'+
                                                                            '</g>'+
                                                                            '</svg>'
                                                                        +'ผู้ก่อการร้าย</div></div>')
                                                                         .addTo(this.map);



									   var popup = new mapboxgl.Popup({ className:"poi_info"})
                                                                        .setLngLat([this.state._lngBK, this.state._latBK])
                                                                        .setHTML('<div padding: 3px;"><div><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M23 11.01L18 11c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-9c0-.55-.45-.99-1-.99zM23 20h-5v-7h5v7zM20 2H2C.89 2 0 2.89 0 4v12c0 1.1.89 2 2 2h7v2H7v2h8v-2h-2v-2h2v-2H2V4h18v5h2V4c0-1.11-.9-2-2-2zm-8.03 7L11 6l-.97 3H7l2.47 1.76-.94 2.91 2.47-1.8 2.47 1.8-.94-2.91L15 9h-3.03z"></path></svg> บ.ก.เหตุการณ์</div></div>')
                                                                         .addTo(this.map);


       }
    }
    _roadoutmap = () =>
    {

         if(this.state.roadmax==0)
            {
               if(this.state.lon_terrorist==0 && this.state.lat_terrorist==0)
                {
                     this.setState({load_data:true})
                        var min_url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&cql_filter=DWithin(geom,POINT(" + this.state.lat + " " + this.state.lon + ")," + this.state.weaponmax + ",meters) AND !DWithin(geom,POINT(" + this.state.lat + " " + this.state.lon + "),"+this.state.weaponmin+",meters)&layers=ctoc:roads";
                        var mpLayer = this.map.getLayer("road_max_l_showblg");
                        if (typeof mpLayer === 'undefined') {
                            // No Layer
                        } else {

                            this.map.removeLayer("road_max_l_showblg");
                            this.map.removeSource("road_max_s_showblg");
                        }
                        this.map.addSource('road_max_s_showblg', {
                            type: 'raster',
                            tiles: [min_url]
                        });
                        this.map.addLayer({
                            'id': 'road_max_l_showblg',
                            'type': 'raster',
                            'source': 'road_max_s_showblg'
                        });


                         fetch('http://1.10.184.64:3000/roads/roadout.php?lat='+this.state.lat+'&&lon='+this.state.lon+'&&max='+this.state.weaponmax+'&&min='+this.state.weaponmin)
                            .then(response => response.json())
                            .then(data => {
                                                           data = data.filter(x=>x.name!=null);
                                                             for (var i = 0; i < data.length; i++) {

                                                               /*    var popup = new mapboxgl.Popup({ className: "_roadout"})
                                                                                    .setLngLat([data[i].x, data[i].y])
                                                                                    .setHTML('<div padding: 3px;"><div>ชื่อถนน:'+data[i].name+'</div></div>')
                                                                                     .addTo(this.map);*/
                                                                   if(data[i].point=="")
                                                                      {

                                                                      }
                                                                      else
                                                                      {
                                                              var POINT = data[i].point.split(' ');
                                                               var popup = new mapboxgl.Popup({ className: "_roadout"})
                                                                                    .setLngLat([POINT[0], POINT[1]])
                                                                                   .setHTML('<div  style="padding: 3px;width:100%;word-wrap: break-word"><div>'+data[i].name+'</div></div>')
                                                                                     .addTo(this.map);
                                                                      }

                                                               }
                                                         this.setState({roadoutcount:data.length,load_data:false})
                                          });
                             this.setState({roadmax:1})

                        }
                        else
                        {

                                       this.setState({load_data:true})
                                        var min_url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&cql_filter=DWithin(geom,POINT(" + this.state.lon_terrorist + " " + this.state.lat_terrorist + ")," + this.state.weaponmax + ",meters) AND !DWithin(geom,POINT(" + this.state.lat + " " + this.state.lon + "),"+this.state.weaponmin+",meters)&layers=ctoc:roads";
                                        var mpLayer = this.map.getLayer("road_max_l_showblg");
                                        if (typeof mpLayer === 'undefined') {
                                        } else {
                                            this.map.removeLayer("road_max_l_showblg");
                                            this.map.removeSource("road_max_s_showblg");
                                        }
                                        this.map.addSource('road_max_s_showblg', {
                                            type: 'raster',
                                            tiles: [min_url]
                                        });
                                        this.map.addLayer({
                                            'id': 'road_max_l_showblg',
                                            'type': 'raster',
                                            'source': 'road_max_s_showblg'
                                        });


                                         fetch('http://1.10.184.64:3000/roads/roadout.php?lat='+this.state.lon_terrorist+'&&lon='+this.state.lat_terrorist+'&&max='+this.state.weaponmax+'&&min='+this.state.weaponmin)
                                            .then(response => response.json())
                                            .then(data => {
                                                                           data = data.filter(x=>x.name!=null);
                                                                             for (var i = 0; i < data.length; i++) {

                                                                                   if(data[i].point=="")
                                                                                      {

                                                                                      }
                                                                                      else
                                                                                      {
                                                                              var POINT = data[i].point.split(' ');
                                                                               var popup = new mapboxgl.Popup({ className: "_roadout"})
                                                                                                    .setLngLat([POINT[0], POINT[1]])
                                                                                                   .setHTML('<div  style="padding: 3px;width:100%;word-wrap: break-word"><div>'+data[i].name+'</div></div>')
                                                                                                     .addTo(this.map);
                                                                                      }

                                                                               }
                                                                         this.setState({roadoutcount:data.length,load_data:false})
                                                          });
                                             this.setState({roadmax:1})


                        }

                     }
     else
     {

                                           for(var i=0;i <this.state.roadoutcount;i++)
                                            {

                                                  try{
                                                  const _popup = document.getElementsByClassName('mapboxgl-popup _roadout');
                                                 _popup[0].remove();
                                                 }catch{

                                                 }
                                            }



       var mpLayer = this.map.getLayer("road_max_l_showblg");
                if (typeof mpLayer === 'undefined') {
                    // No Layer
                } else {

                    this.map.removeLayer("road_max_l_showblg");
                    this.map.removeSource("road_max_s_showblg");
                }
        this.setState({roadmax:0})

     }
       this.ClearLayer();


    }
    _outmap =(event) =>
    {
        if(event.target.checked==true)
       {

         try{
           this.setState({outmap:true})
                    var polygon = JSON.parse(this.state.point1000m);
                     this.map.addSource('maine1000', {
                            'type': 'geojson',
                            'data': {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Polygon',
                                    'coordinates': polygon
                                }
                            }
                        });
                        this.map.addLayer({
                            'id': '_maine1000',
                            'type': 'fill',
                            'source': 'maine1000',
                            'layout': {},
                            'paint': {
                                'fill-color': "#FFFF00",
                                'fill-opacity': 0.1
                            }
                        });

            }
               catch
            {

            }


       }
       else
       {
          this.setState({outmap:false})
           try{
                this.map.removeLayer('_maine1000').removeSource('maine1000');
           }
           catch
           {

           }
       }

    }


    _f_road_area_dwr =(event) =>
    {



        if(event.target.checked==true)
       {
                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:road_area";
                            var mpLayer = this.map.getLayer("l_road_area");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_area");
                                this.map.removeSource("s_road_area");
                            }
                            this.map.addSource('s_road_area', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_road_area',
                                'type': 'raster',
                                'source': 's_road_area'
                            });
          this.setState({road_area_dwr:true})

       }
       else
       {
          this.setState({road_area_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_road_area");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_area");
                                this.map.removeSource("s_road_area");
                            }
           }
           catch
           {

           }
       }

    }
     o_f_road_area_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                      var mpLayer = this.map.getLayer("l_road_area");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_area");
                                this.map.removeSource("s_road_area");
                            }
           }
           catch
           {

           }

             try{

              var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:road_area";
                            var mpLayer = this.map.getLayer("l_road_area");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_area");
                                this.map.removeSource("s_road_area");
                            }
                            this.map.addSource('s_road_area', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_road_area',
                                'type': 'raster',
                                'source': 's_road_area'
                            });
                               this.map.setPaintProperty(
                                'l_road_area',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

    _f_building_NMA_dwr =(event) =>
    {
        if(event.target.checked==true)
       {
                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:building_NMA";
                            var mpLayer = this.map.getLayer("l_building_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_building_NMA");
                                this.map.removeSource("s_building_NMA");
                            }
                            this.map.addSource('s_building_NMA', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_building_NMA',
                                'type': 'raster',
                                'source': 's_building_NMA'
                            });
          this.setState({building_NMA_dwr:true})

       }
       else
       {
          this.setState({building_NMA_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_building_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_building_NMA");
                                this.map.removeSource("s_building_NMA");
                            }
           }
           catch
           {

           }
       }
    }
     o_f_building_NMA_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                       var mpLayer = this.map.getLayer("l_building_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_building_NMA");
                                this.map.removeSource("s_building_NMA");
                            }
           }
           catch
           {

           }

             try{

              var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:building_NMA";
                            var mpLayer = this.map.getLayer("l_building_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_building_NMA");
                                this.map.removeSource("s_building_NMA");
                            }
                            this.map.addSource('s_building_NMA', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_building_NMA',
                                'type': 'raster',
                                'source': 's_building_NMA'
                            });
                               this.map.setPaintProperty(
                                'l_building_NMA',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

      _f_poi_NMA_dwr =(event) =>
    {
        if(event.target.checked==true)
       {
                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:poi_NMA";
                            var mpLayer = this.map.getLayer("l_poi_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_poi_NMA");
                                this.map.removeSource("s_poi_NMA");
                            }
                            this.map.addSource('s_poi_NMA', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_poi_NMA',
                                'type': 'raster',
                                'source': 's_poi_NMA'
                            });
          this.setState({poi_NMA_dwr:true})

       }
       else
       {
          this.setState({poi_NMA_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_poi_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_poi_NMA");
                                this.map.removeSource("s_poi_NMA");
                            }
           }
           catch
           {

           }
       }
    }
     o_f_poi_NMA_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                       var mpLayer = this.map.getLayer("l_poi_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_poi_NMA");
                                this.map.removeSource("s_poi_NMA");
                            }
           }
           catch
           {

           }

             try{

              var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:poi_NMA";
                            var mpLayer = this.map.getLayer("l_poi_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_poi_NMA");
                                this.map.removeSource("s_poi_NMA");
                            }
                            this.map.addSource('s_poi_NMA', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_poi_NMA',
                                'type': 'raster',
                                'source': 's_poi_NMA'
                            });
                               this.map.setPaintProperty(
                                'l_poi_NMA',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

      _f_road_NMA_dwr =(event) =>
    {
        if(event.target.checked==true)
       {
                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:road_NMA";
                            var mpLayer = this.map.getLayer("l_road_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_NMA");
                                this.map.removeSource("s_road_NMA");
                            }
                            this.map.addSource('s_road_NMA', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_road_NMA',
                                'type': 'raster',
                                'source': 's_road_NMA'
                            });
          this.setState({road_NMA_dwr:true})

       }
       else
       {
          this.setState({road_NMA_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_road_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_NMA");
                                this.map.removeSource("s_road_NMA");
                            }
           }
           catch
           {

           }
       }
    }
     o_f_road_NMA_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                       var mpLayer = this.map.getLayer("l_road_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_NMA");
                                this.map.removeSource("s_road_NMA");
                            }
           }
           catch
           {

           }

             try{

              var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:road_NMA";
                            var mpLayer = this.map.getLayer("l_road_NMA");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_NMA");
                                this.map.removeSource("s_road_NMA");
                            }
                            this.map.addSource('s_road_NMA', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_road_NMA',
                                'type': 'raster',
                                'source': 's_road_NMA'
                            });
                               this.map.setPaintProperty(
                                'l_road_NMA',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

      _f_contour20_dwr =(event) =>
    {
        if(event.target.checked==true)
       {
                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:contour20";
                            var mpLayer = this.map.getLayer("l_contour20");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_contour20");
                                this.map.removeSource("s_contour20");
                            }
                            this.map.addSource('s_contour20', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_contour20',
                                'type': 'raster',
                                'source': 's_contour20'
                            });
          this.setState({contour20_dwr:true})

       }
       else
       {
          this.setState({contour20_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_contour20");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_contour20");
                                this.map.removeSource("s_contour20");
                            }
           }
           catch
           {

           }
       }
    }
     o_f_contour20_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                       var mpLayer = this.map.getLayer("l_contour20");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_contour20");
                                this.map.removeSource("s_contour20");
                            }
           }
           catch
           {

           }

             try{

              var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:contour20";
                            var mpLayer = this.map.getLayer("l_contour20");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_contour20");
                                this.map.removeSource("s_contour20");
                            }
                            this.map.addSource('s_contour20', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_contour20',
                                'type': 'raster',
                                'source': 's_contour20'
                            });
                               this.map.setPaintProperty(
                                'l_contour20',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

      _f_dem12_5_dwr =(event) =>
    {
        if(event.target.checked==true)
       {
                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:dem12_5";
                            var mpLayer = this.map.getLayer("l_dem12_5");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dem12_5");
                                this.map.removeSource("s_dem12_5");
                            }
                            this.map.addSource('s_dem12_5', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_dem12_5',
                                'type': 'raster',
                                'source': 's_dem12_5'
                            });
          this.setState({dem12_5_dwr:true})

       }
       else
       {
          this.setState({dem12_5_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_dem12_5");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dem12_5");
                                this.map.removeSource("s_dem12_5");
                            }
           }
           catch
           {

           }
       }
    }
     o_f_dem12_5_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                      var mpLayer = this.map.getLayer("l_dem12_5");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dem12_5");
                                this.map.removeSource("s_dem12_5");
                            }
           }
           catch
           {

           }

             try{

               var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:dem12_5";
                            var mpLayer = this.map.getLayer("l_dem12_5");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dem12_5");
                                this.map.removeSource("s_dem12_5");
                            }
                            this.map.addSource('s_dem12_5', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_dem12_5',
                                'type': 'raster',
                                'source': 's_dem12_5'
                            });

                               this.map.setPaintProperty(
                                'l_dem12_5',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

       _f_dem30_hillshade_dwr =(event) =>
    {
        if(event.target.checked==true)
       {
                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:dem30_hillshade";
                            var mpLayer = this.map.getLayer("l_dem30_hillshade");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dem30_hillshade");
                                this.map.removeSource("s_dem30_hillshade");
                            }
                            this.map.addSource('s_dem30_hillshade', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_dem30_hillshade',
                                'type': 'raster',
                                'source': 's_dem30_hillshade'
                            });
          this.setState({dem30_hillshade_dwr:true})

       }
       else
       {
          this.setState({dem30_hillshade_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_dem30_hillshade");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dem30_hillshade");
                                this.map.removeSource("s_dem30_hillshade");
                            }
           }
           catch
           {

           }
       }
    }
      o_f_dem30_hillshade_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                       var mpLayer = this.map.getLayer("l_dem30_hillshade");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dem30_hillshade");
                                this.map.removeSource("s_dem30_hillshade");
                            }
           }
           catch
           {

           }

             try{

              var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:dem30_hillshade";
                            var mpLayer = this.map.getLayer("l_dem30_hillshade");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dem30_hillshade");
                                this.map.removeSource("s_dem30_hillshade");
                            }
                            this.map.addSource('s_dem30_hillshade', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_dem30_hillshade',
                                'type': 'raster',
                                'source': 's_dem30_hillshade'
                            });

                               this.map.setPaintProperty(
                                'l_dem30_hillshade',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

        _f_NEXTMapOneTerrainHillshadeDSM_dwr =(event) =>
    {
        if(event.target.checked==true)
       {
       var url ="";
                 //var url = "https://api.intermap.com/api/opengis/wms?username=JKinser%2Bproddata%40intermap.com&password=WvUWr9AJd%40AL&layers=dwr_gweb:NEXTMapOne-Terrain-Hillshade-DSM";
                            var mpLayer = this.map.getLayer("l_NEXTMapOneTerrainHillshadeDSM");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_NEXTMapOneTerrainHillshadeDSM");
                                this.map.removeSource("s_NEXTMapOneTerrainHillshadeDSM");
                            }
                            this.map.addSource('s_NEXTMapOneTerrainHillshadeDSM', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_NEXTMapOneTerrainHillshadeDSM',
                                'type': 'raster',
                                'source': 's_NEXTMapOneTerrainHillshadeDSM'
                            });
          this.setState({NEXTMapOneTerrainHillshadeDSM_dwr:true})

       }
       else
       {
          this.setState({NEXTMapOneTerrainHillshadeDSM_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_NEXTMapOneTerrainHillshadeDSM");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_NEXTMapOneTerrainHillshadeDSM");
                                this.map.removeSource("s_NEXTMapOneTerrainHillshadeDSM");
                            }
           }
           catch
           {

           }
       }
    }

    o_f_NEXTMapOneTerrainHillshadeDSM_dwr =(val) =>
    {
    }



       _f_dsm_dwr =(event) =>
    {
        if(event.target.checked==true)
       {
                 var url = "http://119.59.110.217:8888/geoserver/gwc/service/tms/1.0.0/dsm@EPSG:900913@png/{z}/{x}/{-y}.png";
                            var mpLayer = this.map.getLayer("l_dsm");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dsm");
                                this.map.removeSource("s_dsm");
                            }
                            this.map.addSource('s_dsm', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_dsm',
                                'type': 'raster',
                                'source': 's_dsm'
                            });
          this.setState({dsm_dwr:true})

       }
       else
       {
          this.setState({dsm_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_dsm");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dsm");
                                this.map.removeSource("s_dsm");
                            }
           }
           catch
           {

           }
       }
    }

       o_f_dsm_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                        var mpLayer = this.map.getLayer("l_dsm");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dsm");
                                this.map.removeSource("s_dsm");
                            }
           }
           catch
           {

           }

             try{

               var url = "http://119.59.110.217:8888/geoserver/gwc/service/tms/1.0.0/dsm@EPSG:900913@png/{z}/{x}/{-y}.png";
                            var mpLayer = this.map.getLayer("l_dsm");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_dsm");
                                this.map.removeSource("s_dsm");
                            }
                            this.map.addSource('s_dsm', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_dsm',
                                'type': 'raster',
                                'source': 's_dsm'
                            });

                               this.map.setPaintProperty(
                                'l_dsm',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

      _f_LDD_RASTER_WM_CACHE_dwr =(event) =>
    {
       if(event.target.checked==true)
       {
                 var url = "http://119.59.110.217:8888/geoserver/gwc/service/tms/1.0.0/dsm@EPSG:900913@png/{z}/{x}/{-y}.png";
                            var mpLayer = this.map.getLayer("l_LDD_RASTER_WM_CACHE");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_LDD_RASTER_WM_CACHE");
                                this.map.removeSource("s_LDD_RASTER_WM_CACHE");
                            }
                            this.map.addSource('s_LDD_RASTER_WM_CACHE', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_LDD_RASTER_WM_CACHE',
                                'type': 'raster',
                                'source': 's_LDD_RASTER_WM_CACHE'
                            });
          this.setState({LDD_RASTER_WM_CACHE_dwr:true})

       }
       else
       {
          this.setState({LDD_RASTER_WM_CACHE_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_LDD_RASTER_WM_CACHE");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_LDD_RASTER_WM_CACHE");
                                this.map.removeSource("s_LDD_RASTER_WM_CACHE");
                            }
           }
           catch
           {

           }
       }
    }

        o_f_LDD_RASTER_WM_CACHE_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                       var mpLayer = this.map.getLayer("l_LDD_RASTER_WM_CACHE");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_LDD_RASTER_WM_CACHE");
                                this.map.removeSource("s_LDD_RASTER_WM_CACHE");
                            }
           }
           catch
           {

           }

             try{

              var url = "http://119.59.110.217:8888/geoserver/gwc/service/tms/1.0.0/dsm@EPSG:900913@png/{z}/{x}/{-y}.png";
                            var mpLayer = this.map.getLayer("l_LDD_RASTER_WM_CACHE");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_LDD_RASTER_WM_CACHE");
                                this.map.removeSource("s_LDD_RASTER_WM_CACHE");
                            }
                            this.map.addSource('s_LDD_RASTER_WM_CACHE', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_LDD_RASTER_WM_CACHE',
                                'type': 'raster',
                                'source': 's_LDD_RASTER_WM_CACHE'
                            });

                               this.map.setPaintProperty(
                                'l_LDD_RASTER_WM_CACHE',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

      _f_L7018L7018_dwr =(event) =>
    {
       if(event.target.checked==true)
       {
                     var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:L7018";

                            var mpLayer = this.map.getLayer("l_LDD_RASTER_WM_CACHE");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_L7018L7018");
                                this.map.removeSource("s_L7018L7018");
                            }
                            this.map.addSource('s_L7018L7018', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_L7018L7018',
                                'type': 'raster',
                                'source': 's_L7018L7018'
                            });
          this.setState({L7018L7018_dwr:true})

       }
       else
       {
          this.setState({L7018L7018_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_L7018L7018");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_L7018L7018");
                                this.map.removeSource("s_L7018L7018");
                            }
           }
           catch
           {

           }
       }
    }

      o_f_L7018L7018_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                       var mpLayer = this.map.getLayer("l_L7018L7018");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_L7018L7018");
                                this.map.removeSource("s_L7018L7018");
                            }
           }
           catch
           {

           }

             try{

                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:L7018";

                            var mpLayer = this.map.getLayer("l_LDD_RASTER_WM_CACHE");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_L7018L7018");
                                this.map.removeSource("s_L7018L7018");
                            }
                            this.map.addSource('s_L7018L7018', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_L7018L7018',
                                'type': 'raster',
                                'source': 's_L7018L7018'
                            });

                               this.map.setPaintProperty(
                                'l_L7018L7018',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }



      _f_smpklandcheck_dwr =(event) =>
    {
       if(event.target.checked==true)
       {
                     var url = "https://smpklandcheck.org/geoserver/SPK_MAP/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=SPK_MAP:smpk_bldg";

                            var mpLayer = this.map.getLayer("l_smpklandcheck");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_smpklandcheck");
                                this.map.removeSource("s_smpklandcheck");
                            }
                            this.map.addSource('s_smpklandcheck', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_smpklandcheck',
                                'type': 'raster',
                                'source': 's_smpklandcheck'
                            });
          this.setState({smpklandcheck_dwr:true})

       }
       else
       {
          this.setState({smpklandcheck_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_smpklandcheck");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_smpklandcheck");
                                this.map.removeSource("s_smpklandcheck");
                            }
           }
           catch
           {

           }
       }
    }

      o_f_smpklandcheck_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                      var mpLayer = this.map.getLayer("l_smpklandcheck");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_smpklandcheck");
                                this.map.removeSource("s_smpklandcheck");
                            }
           }
           catch
           {

           }

             try{

               var url = "https://smpklandcheck.org/geoserver/SPK_MAP/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=SPK_MAP:smpk_bldg";

                            var mpLayer = this.map.getLayer("l_smpklandcheck");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_smpklandcheck");
                                this.map.removeSource("s_smpklandcheck");
                            }
                            this.map.addSource('s_smpklandcheck', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_smpklandcheck',
                                'type': 'raster',
                                'source': 's_smpklandcheck'
                            });

                               this.map.setPaintProperty(
                                'l_smpklandcheck',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

      _f_bldg_dwr =(event) =>
    {
       if(event.target.checked==true)
       {
       var url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=ctoc:bldg";

                    var mpLayer = this.map.getLayer("l_bldg");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_bldg");
                                this.map.removeSource("s_bldg");
                            }
                            this.map.addSource('s_bldg', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_bldg',
                                'type': 'raster',
                                'source': 's_bldg'
                            });
          this.setState({bldg_dwr:true})

       }
       else
       {
          this.setState({bldg_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_bldg");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_bldg");
                                this.map.removeSource("s_bldg");
                            }
           }
           catch
           {

           }
       }
    }

      o_f_bldg_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                     var mpLayer = this.map.getLayer("l_bldg");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_bldg");
                                this.map.removeSource("s_bldg");
                            }
           }
           catch
           {

           }

             try{

               var url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=ctoc:bldg";

                    var mpLayer = this.map.getLayer("l_bldg");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_bldg");
                                this.map.removeSource("s_bldg");
                            }
                            this.map.addSource('s_bldg', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_bldg',
                                'type': 'raster',
                                'source': 's_bldg'
                            });

                               this.map.setPaintProperty(
                                'l_bldg',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

     o_f_road_dwr =(val) =>
    {
      var  value = (100-val)/100;
         try{

                  var mpLayer = this.map.getLayer("l_road");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road");
                                this.map.removeSource("s_road");
                            }
           }
           catch
           {

           }

             try{

                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:road";
                            var mpLayer = this.map.getLayer("l_road");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road");
                                this.map.removeSource("s_road");
                            }


                            this.map.addSource('s_road', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_road',
                                'type': 'raster',
                                'source': 's_road'
                             });
                               this.map.setPaintProperty(
                                'l_road',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }
    }

     _f_road_dwr =(event) =>
    {
        if(event.target.checked==true)
       {
                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:road";
                            var mpLayer = this.map.getLayer("l_road");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road");
                                this.map.removeSource("s_road");
                            }
                            this.map.addSource('s_road', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_road',
                                'type': 'raster',
                                'source': 's_road'
                            });
          this.setState({road_dwr:true})

       }
       else
       {
          this.setState({road_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_road");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road");
                                this.map.removeSource("s_road");
                            }
           }
           catch
           {

           }
       }
    }


      _f_waterway_dwr =(event) =>
    {
       if(event.target.checked==true)
       {
                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:waterway";
                            var mpLayer = this.map.getLayer("l_waterway");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_waterway");
                                this.map.removeSource("s_waterway");
                            }
                            this.map.addSource('s_waterway', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_waterway',
                                'type': 'raster',
                                'source': 's_waterway'
                            });
          this.setState({waterway_dwr:true})

       }
       else
       {
          this.setState({waterway_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_waterway");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_waterway");
                                this.map.removeSource("s_waterway");
                            }
           }
           catch
           {

           }
       }

    }

    o_f_waterway_dwr =(val) =>
    {
      var  value = (100-val)/100;

         try{

                   var mpLayer = this.map.getLayer("l_waterway");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_waterway");
                                this.map.removeSource("s_waterway");
                            }
           }
           catch
           {

           }

             try{

                  var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:waterway";
                            var mpLayer = this.map.getLayer("l_waterway");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_waterway");
                                this.map.removeSource("s_waterway");
                            }
                            this.map.addSource('s_waterway', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_waterway',
                                'type': 'raster',
                                'source': 's_waterway'
                            });



                               this.map.setPaintProperty(
                                'l_waterway',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }




    }

       _f_road_esri_dwr =(event) =>
    {
       if(event.target.checked==true)
       {
                 var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:road_esri";
                            var mpLayer = this.map.getLayer("l_road_esri");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_esri");
                                this.map.removeSource("s_road_esri");
                            }
                            this.map.addSource('s_road_esri', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_road_esri',
                                'type': 'raster',
                                'source': 's_road_esri'
                            });
          this.setState({road_esri_dwr:true})

       }
       else
       {
          this.setState({road_esri_dwr:false})
           try{

                  var mpLayer = this.map.getLayer("l_road_esri");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_esri");
                                this.map.removeSource("s_road_esri");
                            }
           }
           catch
           {

           }
       }

    }
     o_f_road_esri_dwr =(val) =>
    {
      var  value = (100-val)/100;

         try{

                           var mpLayer = this.map.getLayer("l_road_esri");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_esri_");
                                this.map.removeSource("s_road_esri_");
                            }
           }
           catch
           {

           }

             try{

                    var url = "https://managethaiwater.com:8080/geoserver/dwr_gweb/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=dwr_gweb:road_esri";
                            var mpLayer = this.map.getLayer("l_road_esri");
                            if (typeof mpLayer === 'undefined') {

                            } else {

                                this.map.removeLayer("l_road_esri");
                                this.map.removeSource("s_road_esri");
                            }
                            this.map.addSource('s_road_esri', {
                                type: 'raster',
                                tiles: [url]
                            });
                            this.map.addLayer({
                                'id': 'l_road_esri',
                                'type': 'raster',
                                'source': 's_road_esri'
                            });



                               this.map.setPaintProperty(
                                'l_road_esri',
                                'raster-opacity',
                                value
                              );



           }
           catch
           {

           }




    }

     _closepopupin = () =>{



          for(var i=0;i <this.state.c_inpopup-1;i++)
        {
           try{
             const _popup = document.getElementsByClassName('mapboxgl-popup _in');
             _popup[0].remove();
             }catch{
              return;
             }

        }
    }
      _closepopupout = () =>{



        for(var i=0;i <this.state.c_outpopup-1;i++)
        {
         try{

             const _popup = document.getElementsByClassName('mapboxgl-popup _out');
             _popup[0].remove();

             }catch{
             return;
             }

        }

    }
    _layerout = () =>
    {

         var  _x1 = this.state._x1;
         var  _y1 = this.state._y1;
         if(this.state.lon_terrorist==0 && this.state.lat_terrorist==0)
        {

                    var url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&cql_filter=DWithin(geom,POINT(" + _x1 + " " + _y1 + ")," + this.state.weaponmax + ",meters) AND !DWithin(geom,POINT(" + _x1 + " " + _y1 + "),"+this.state.weaponmin+",meters)&layers=ctoc:bldg";

                    var mpLayer = this.map.getLayer("l_showblg");
                    if (typeof mpLayer === 'undefined') {

                    } else {

                        this.map.removeLayer("l_showblg");
                        this.map.removeSource("s_showblg");
                    }
                    this.map.addSource('s_showblg', {
                        type: 'raster',
                        tiles: [url]
                    });
                    this.map.addLayer({
                        'id': 'l_showblg',
                        'type': 'raster',
                        'source': 's_showblg'
                    });

                if(this.state.layerout==true)
                  {
                    this.map.setLayoutProperty("l_showblg", 'visibility', 'none');
                    this.map.setLayoutProperty("s_showblg", 'visibility', 'none');
                    this.setState({layerout:false})
                  }
                  else{

                   this.map.setLayoutProperty("l_showblg", 'visibility', 'visible');
                    this.map.setLayoutProperty("s_showblg", 'visibility', 'visible');
                    this.setState({layerout:true})
                  }

                  this.ClearLayer();
      }
      else
      {

                  var  _x1 = this.state.lon_terrorist;
                  var  _y1 = this.state.lat_terrorist;


                 fetch('http://1.10.184.64:3000/terrorist/transform.php?lon='+_y1+'&&lat='+_x1+'')
                  .then(response => response.json())
                   .then(data => {
                                   var _x1 = data.x;
                                   var _y1 = data.y;
                             var url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&cql_filter=DWithin(geom,POINT(" + _x1 + " " + _y1 + ")," + this.state.weaponmax + ",meters) AND !DWithin(geom,POINT(" + _x1 + " " + _y1 + "),"+this.state.weaponmin+",meters)&layers=ctoc:bldg";

                                var mpLayer = this.map.getLayer("l_showblg");
                                if (typeof mpLayer === 'undefined') {

                                } else {

                                    this.map.removeLayer("l_showblg");
                                    this.map.removeSource("s_showblg");
                                }
                                this.map.addSource('s_showblg', {
                                    type: 'raster',
                                    tiles: [url]
                                });
                                this.map.addLayer({
                                    'id': 'l_showblg',
                                    'type': 'raster',
                                    'source': 's_showblg'
                                });

                            if(this.state.layerout==true)
                              {
                                this.map.setLayoutProperty("l_showblg", 'visibility', 'none');
                                this.map.setLayoutProperty("s_showblg", 'visibility', 'none');
                                this.setState({layerout:false})
                              }
                              else{

                               this.map.setLayoutProperty("l_showblg", 'visibility', 'visible');
                                this.map.setLayoutProperty("s_showblg", 'visibility', 'visible');
                                this.setState({layerout:true})
                              }

                              this.ClearLayer();
                       })
      }


    }
    _layerin = () =>
    {
         var  _x1 = this.state._x1;
         var  _y1 = this.state._y1;
         if(this.state.lon_terrorist==0 && this.state.lat_terrorist==0)
                                        {
                                              _x1 = this.state._x1;
                                              _y1 = this.state._y1;

                                             var min_url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&cql_filter=DWithin(geom,POINT(" + _x1 + " " + _y1 + ")," + this.state.weaponmin + ",meters)&layers=ctoc:bldg";

                                            var mpLayer = this.map.getLayer("min_l_showblg");
                                            if (typeof mpLayer === 'undefined') {
                                                // No Layer
                                            } else {

                                                this.map.removeLayer("min_l_showblg");
                                                this.map.removeSource("min_s_showblg");
                                            }
                                            this.map.addSource('min_s_showblg', {
                                                type: 'raster',
                                                tiles: [min_url]
                                            });
                                            this.map.addLayer({
                                                'id': 'min_l_showblg',
                                                'type': 'raster',
                                                'source': 'min_s_showblg'
                                            });


                                                   if(this.state.layerin==true)
                                              {
                                                this.map.setLayoutProperty("min_l_showblg", 'visibility', 'none');
                                                this.map.setLayoutProperty("min_s_showblg", 'visibility', 'none');
                                                this.setState({layerin:false})
                                              }
                                              else{

                                               this.map.setLayoutProperty("min_l_showblg", 'visibility', 'visible');
                                                this.map.setLayoutProperty("min_s_showblg", 'visibility', 'visible');
                                                this.setState({layerin:true})
                                              }
                                               this.ClearLayer();
                                        }else{

                                          fetch('http://1.10.184.64:3000/terrorist/transform.php?lon='+this.state.lat_terrorist+'&&lat='+this.state.lon_terrorist+'')
                                            .then(response => response.json())
                                            .then(data => {
                                                var _x1 = data.x;
                                                var _y1 = data.y;
                                                 console.log(_x1+"ssss")
                                           var min_url = "http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&cql_filter=DWithin(geom,POINT(" + _x1 + " " + _y1 + ")," + this.state.weaponmin + ",meters)&layers=ctoc:bldg";

                                            var mpLayer = this.map.getLayer("min_l_showblg");
                                            if (typeof mpLayer === 'undefined') {
                                                // No Layer
                                            } else {

                                                this.map.removeLayer("min_l_showblg");
                                                this.map.removeSource("min_s_showblg");
                                            }
                                            this.map.addSource('min_s_showblg', {
                                                type: 'raster',
                                                tiles: [min_url]
                                            });
                                            this.map.addLayer({
                                                'id': 'min_l_showblg',
                                                'type': 'raster',
                                                'source': 'min_s_showblg'
                                            });


                                                   if(this.state.layerin==true)
                                              {
                                                this.map.setLayoutProperty("min_l_showblg", 'visibility', 'none');
                                                this.map.setLayoutProperty("min_s_showblg", 'visibility', 'none');
                                                this.setState({layerin:false})
                                              }
                                              else{

                                               this.map.setLayoutProperty("min_l_showblg", 'visibility', 'visible');
                                                this.map.setLayoutProperty("min_s_showblg", 'visibility', 'visible');
                                                this.setState({layerin:true})
                                              }
                                               this.ClearLayer();
                                            })

                                        }

    }

        render() {

         const {classes, theme} = this.props;

       const {
       tablestatus,stcolor,_3dcolor,_disabledBK,_disabledSNI,
       colorBK,disabledBK,disabledSC,colorSC,colorSNI,disabledSNI,
       colorDIS,disabledDIS,
       _SVy,_SVx,ststyle,listblg,stmarkerlng,stmarkerlat,
       films,typemap,nmarkerlng,selectWeapon,
       nmarkerlat,rdirections,
       tabledata,order, orderBy,selected,rowsPerPage, page,
       lbl_name,lbl_nstorey,vdistance,ststylelos,
       bs_nstorey,markerlng,markerlat,
       smarkerlng,smarkerlat,bl_name,
       bs_name,bl_nstorey,bl_employ,
       mapzoom,maplng,maplat,datapolygon,nonelayer,nonedis,
       arraydatapolygon,arraypolygon1000m,weaponmin,weaponmax,
       point500m,point1000m,lon,lat,x1,y1,weapon,datainfo,apiduration,apidistance,
       /*Formpopup*/
       province,_lngSP,_latSP,_latSV,_lngSV,
       district,
       subdistrict,
       tabvalue,
       Gotoprovince,
       Gotodistrict,
       Gotosubdistrict,
       name
       /*Formpopup*/
       } = this.state;

          let _poi_plan_report = this.state.poi_plan_report;
        let poi_plan_reportItems = _poi_plan_report.map((_poi_plan_report) =>

           <div   key={_poi_plan_report.id} >


                <div className="row ml-3" style={{color:'#fff'}}>
                         <div className="col-md-12 col-12  p-0 m-0">
                                   <div className="row" >
                                    <div>
                                    <Avatar
                                               alt="http://via.placeholder.com/150x150"
                                                      src="http://via.placeholder.com/150x150"
                                              className="user-avatar"
                                            />
                                    </div>
                                  <div  style={{color:'#fff'}}
                                        onClick={(e)=> this._goTo(e,_poi_plan_report.x,_poi_plan_report.y)}>

                                       {_poi_plan_report.fname}
                                       <spen className="ml-1">
                                       <i class="zmdi zmdi-pin zmdi-hc-2x ml-2" ></i>
                                       </spen>

                                  </div>
                                  {this.state.usersType!=2?
                                      <div  onClick={(e)=> this.rowdelete(e,_poi_plan_report.id)}>
                                         <spen className="ml-4" >
                                           <DeleteIcon />
                                           </spen>
                                       </div>
                                       :''}
                                   </div>


                        </div>

                        <div className="col-md-8 col-12  p-0 m-0 ml-1 ">
                              <div className="row" >


                             </div>
                              <div className="row" style={{color:'#fff'}}>

                                         {_poi_plan_report.text_rpt}
                             </div>
                              <div className="row" style={{color:'#fff'}}>

                                   {
                                    _poi_plan_report.dt_rpt==null?'':
                                    _poi_plan_report.dt_rpt.substring(8,10)+'-'+
                                    _poi_plan_report.dt_rpt.substring(5,7)+'-'+
                                    _poi_plan_report.dt_rpt.substring(0,4)+' '+
                                     _poi_plan_report.dt_rpt.substring(11,19)
                                    }
                             </div>

                              <div className="row" style={{color:'#fff'}}>
                                  {_poi_plan_report.x} {_poi_plan_report.y}
                             </div>
                        </div>
                </div>





                  <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
           </div>

        );
         /*Formpopup*/
        let provinces = this.state.province;
        let provinceItems = provinces.map((province) =>
          <MenuItem style={{fontFamily: 'Kanit'}} selected="{this.state.province}"    key={province.pro_c} value={province.pro_c}>{province.on_pro_tha}</MenuItem>
        );
         let _district = this.state.district;
        let districtItems = district.map((_district) =>
          <MenuItem  style={{fontFamily: 'Kanit'}} selected="{this.state.district}"    key={_district.dis_c} value={_district.dis_c}>{_district.on_dis_tha}</MenuItem>
        );
        let _subdistrict = this.state.subdistrict;
        let subdistrictItems = subdistrict.map((_subdistrict) =>
          <MenuItem style={{fontFamily: 'Kanit'}} selected="{this.state.subdistrict}"    key={_subdistrict.id} value={_subdistrict.id}>{_subdistrict.on_sub_tha}</MenuItem>
        );
         /*Formpopup*/

        /*const MyMapComponent = withScriptjs(withGoogleMap((props) =>
                  <GoogleMap
                    defaultZoom={8}
                    defaultCenter={{ lat: 13.700174, lng: 100.4088213 }}

                  >
                   <StreetViewPanorama  visible  >
                           </StreetViewPanorama>
                  </GoogleMap>
                ))*/

       let _datainfo = this.state.datainfo;
            let datainfoItems = _datainfo.map((datainfo) =>
              <div  style={{fontFamily: 'Kanit'}}  style={{ padding:"5px",margin:"5px",color:"#fff"}}>{datainfo}</div>

            );


          let weapons = this.state.weapon;

            let weaponItems = weapons.map((weapon) =>
              <MenuItem style={{fontFamily: 'Kanit'}} selected="{this.state.weapon}"
              onClick={()=>  this.setState({weaponmin:weapon.shoot_min,weaponmax:weapon.shoot_min*2}) }

              data-min={weapon.shoot_min}  data-max={weapon.shoot_max} key={weapon.id} value={weapon.id}>{weapon.w_name}</MenuItem>
            );
            let listroadins = this.state.listroadin;
            let listroadinItems = listroadins.map((listroadin) =>
                <div>- {listroadin.name}</div>
            );
              let listroadouts = this.state.listroadout;
            let listroadoutItems = listroadouts.map((listroadout) =>
                <div>- {listroadout.name}</div>
            );
     //  var _datapolygon ="[[[100.13734351262877, 13.137451890638886],[-66.96466, 44.8097],[-68.03252, 44.3252], [-67.13734351262877, 45.137451890638886]]]";
      //  var _datapolygon3 ="[[[-17.13734351262877, 15.137451890638886],[-66.96466, 44.8097],[-68.03252, 44.3252], [-67.13734351262877, 35.137451890638886]]]";

            const googleMapsApiKey = 'AIzaSyArCEg7xzt0faTF1PZmcKT3q7-pFaQCR_0';

       const streetViewPanoramaOptions = {
                position: {lat: 46.9171876, lng: 17.8951832},
                pov: {heading: 100, pitch: 0},
                zoom: 1
            };
             //var  _src="http://1.10.184.64:3000/map/streetview.php?x="+_latSV+"&y="+_lngSV;

            return (




          <div style={{backgroundColor: 'rgb(181 63 63 / 0%)'}} className={classes.root}>




            <div className={classes.appFrame}  >

              <AppBar
               className={classNames('rgb(181 63 63 / 0%)', classes.appBar, this.state.open && classes.appBarShift)}
                  style={{backgroundColor: 'rgb(181 63 63 / 0%)',
                    boxShadow:'0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%)'
                  }}

              >

                <Toolbar disableGutters={!this.state.open}  style={{backgroundColor: 'rgb(181 63 63 / 0%)'}}>
                <div className='m-2  p-0 ' style={{backgroundColor: '#3f51b5', top: 70, position: 'fixed' }}>
                  <IconButton

                    aria-label="open drawer"
                    onClick={this.handleDrawerOpen}
                    style={{backgroundColor: '#3f51b5',margin:0}}
                    className={classNames(classes.menuButton, this.state.open && classes.hide)}>

                    <MenuIcon className="text-white"/>
                  </IconButton>

              </div>


                </Toolbar>

                <div
                style={{

                position: 'fixed',
                top: 80,
                right: 10,
                }} >
                     <div  onClick={this._nonelayer} className="bg-blue text-white p-2"   >
                         <LayersIcon />
                    </div>


                </div>

               <div
                style={{

                position: 'fixed',
                top: 140,
                right: 10,
                }} >
                     <div  onClick={this._nonelayer2} className="bg-orange text-white p-2"   >
                         <EmojiTransportationIcon />
                    </div>


              </div>
              <div
                style={{
                position: 'fixed',
                top: 190,
                right: 10,
                height:this.state.ly_height,
                overflowY: this.state.ly_overflowY,
                display: this.state.nonelayer2,
                backgroundColor:'#3f51b5',
                borderRadius: '5px'
                }} >


                <div className="nav-menu p-2" >
                        <div>

                           <div style={{display:this.state.layer11=="none"?"block":"none",cursor: "pointer"}} onClick={()=> this.setState({layer11: "",ly_height:350,ly_overflowY:"scroll"}) }>
                                <spen >วงรอบชั้นใน</spen>
                                  <ArrowDropDownIcon  />
                                </div>
                                <div style={{display:this.state.layer11=="none"?"none":"block",cursor: "pointer"}} onClick={()=> this.setState({layer11: "none",ly_height:"",ly_overflowY:""}) }>
                                <spen >วงรอบชั้นใน</spen>
                                  <ArrowDropUpIcon  />
                           </div>
                        </div>
                        <div style={{display:this.state.layer11}}>
                        <div>
                          <Checkbox  name="checkedB" color="indigo" checked={this.state.inmap}  onChange={(event) => this._inmap(event)} />
                           <spen>- รัศมีชั้นใน</spen>
                        </div>
                         <div>
                          <Checkbox  name="checkedB" color="indigo"   onChange={this._layerin}  />
                           <spen>- อาคาร</spen>
                        </div>
                        <div>
                          <Checkbox  name="checkedB" color="indigo" onClick={this._roadinmap}    />
                           <spen>- เส้นถนน</spen>
                        </div>
                        </div>

               </div>
               <div className="nav-menu p-2">
                          <div>


                                <div style={{display:this.state.layer22=="none"?"block":"none",cursor: "pointer"}} onClick={()=> this.setState({layer22: "",ly_height:350,ly_overflowY:"scroll"}) }>
                                <spen >วงรอบชั้นนอก</spen>
                                  <ArrowDropDownIcon  />
                                </div>
                                <div style={{display:this.state.layer22=="none"?"none":"block",cursor: "pointer"}} onClick={()=> this.setState({layer22: "none",ly_height:"",ly_overflowY:""}) }>
                                <spen >วงรอบชั้นนอก</spen>
                                  <ArrowDropUpIcon  />
                                </div>

                           </div>
                           <div style={{display:this.state.layer22}}>
                           <div>
                              <Checkbox  name="checkedB" color="indigo" checked={this.state.outmap} onChange={(event) => this._outmap(event)} />
                              <spen>- รัศมีชั้นนอก</spen>
                           </div>
                           <div>
                              <Checkbox  name="checkedB" color="indigo"   onChange={this._layerout}  />
                              <spen>- อาคาร</spen>
                           </div>
                           <div>
                              <Checkbox  name="checkedB" color="indigo" onClick={this._roadoutmap}    />
                              <spen>- เส้นถนน</spen>
                           </div>
                           </div>

               </div>
               <div className="nav-menu p-2">
                        <div>
                          <Checkbox  name="checkedB" color="indigo" checked={this.state.ch_poi_info}  onChange={(event)=>this._poi_info((event))}    />
                           <spen>แสดงชื่อตำแหน่งบนแผนที่</spen>
                        </div>
               </div>
               <div className="nav-menu p-2">
                          <div>

                                <div style={{display:this.state.layer1=="none"?"block":"none",cursor: "pointer"}} onClick={()=> this.setState({layer1: "",ly_height:350,ly_overflowY:"scroll"}) }>
                                <spen >การคมนาคมขนส่ง</spen>
                                  <ArrowDropDownIcon  />
                                </div>
                                <div style={{display:this.state.layer1=="none"?"none":"block",cursor: "pointer"}} onClick={()=> this.setState({layer1: "none",ly_height:"",ly_overflowY:""}) }>
                                <spen >การคมนาคมขนส่ง</spen>
                                  <ArrowDropUpIcon  />
                                </div>

                           </div>
                         <div style={{display:this.state.layer1}}>
                           <div>
                                <img
                                            width="30" height="30"
                                              src={"http://managethaiwater.com:8585/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=dwr_gweb:road"}>
                                </img>
                              <Checkbox  name="checkedB" color="indigo" checked={this.state.road_dwr} onChange={(event) => this._f_road_dwr(event)} />
                              <spen> เส้นทางคมนาคม</spen>
                           </div>
                           <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.road_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_road_dwr(value) }
                                           />
                           </div>
                           <div>
                                <img
                                            width="30" height="30"
                                              src={"http://managethaiwater.com:8585/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=dwr_gweb:waterway"}>
                                </img>
                              <Checkbox  name="checkedB" color="indigo"   checked={this.state.waterway_dwr} onChange={(event) => this._f_waterway_dwr(event)}   />
                              <spen> เส้นทางน้ำ</spen>
                           </div>
                           <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.waterway_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_waterway_dwr(value) }
                                           />
                           </div>
                           <div>
                                <img
                                            width="30" height="30"
                                              src={"http://managethaiwater.com:8585/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=dwr_gweb:road_esri"}>
                                </img>
                              <Checkbox  name="checkedB" color="indigo" checked={this.state.road_esri_dwr} onChange={(event) => this._f_road_esri_dwr(event)}     />
                              <spen> ทางหลวง</spen>
                           </div>
                           <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.road_esri_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_road_esri_dwr(value) }
                                           />
                           </div>
                           <div>
                               <img
                                            width="30" height="30"
                                              src={"http://managethaiwater.com:8585/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=dwr_gweb:road_area"}>
                               </img>
                              <Checkbox  name="checkedB" color="indigo" checked={this.state.road_area_dwr} onChange={(event) => this._f_road_area_dwr(event)}    />
                              <spen> แนวเขตทาง</spen>
                           </div>
                            <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.road_area_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_road_area_dwr(value) }
                                           />
                           </div>
                        </div>
               </div>
               <div className="nav-menu p-2">
                          <div>
                              <div style={{display:this.state.layer2=="none"?"block":"none",cursor: "pointer"}} onClick={()=> this.setState({layer2: "",ly_height:350,ly_overflowY:"scroll"}) }>
                                <spen >สภาพภูมิประเทศ</spen>
                                  <ArrowDropDownIcon  />
                                </div>
                                <div style={{display:this.state.layer2=="none"?"none":"block",cursor: "pointer"}} onClick={()=> this.setState({layer2: "none",ly_height:"",ly_overflowY:""}) }>
                                <spen >สภาพภูมิประเทศ</spen>
                                  <ArrowDropUpIcon  />
                               </div>

                           </div>
                           <div style={{display:this.state.layer2}}>
                               <div>
                                 <img
                                            width="30" height="30"
                                              src={"https://managethaiwater.com:8080/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=dwr_gweb:contour20"}>
                                 </img>
                                  <Checkbox  name="checkedB" color="indigo" checked={this.state.contour20_dwr} onChange={(event) => this._f_contour20_dwr(event)}  />
                                  <spen> เส้นชั้นความสูง (Contour) 20 เมตร</spen>
                               </div>
                               <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.contour20_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_contour20_dwr(value) }
                                           />
                              </div>
                               <div>
                                  <img
                                            width="30" height="30"
                                              src={"https://managethaiwater.com:8080/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=dwr_gweb:dem12_5"}>
                                 </img>
                                  <Checkbox  name="checkedB" color="indigo"   checked={this.state.dem12_5_dwr} onChange={(event) => this._f_dem12_5_dwr(event)}   />
                                  <spen> ระดับชั้นความสูง (DEM 12.5 เมตร)</spen>
                               </div>
                               <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.dem12_5_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_dem12_5_dwr(value) }
                                           />
                              </div>
                               <div>
                                 <img
                                            width="30" height="30"
                                              src={"https://managethaiwater.com:8080/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=dwr_gweb:dem30_hillshade"}>
                                 </img>
                                  <Checkbox  name="checkedB" color="indigo" checked={this.state.dem30_hillshade_dwr} onChange={(event) => this._f_dem30_hillshade_dwr(event)}     />
                                  <spen> ระดับชั้นความสูง (DEM 30 เมตร)</spen>
                               </div>
                               <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.dem30_hillshade_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_dem30_hillshade_dwr(value) }
                                           />
                              </div>
                                <div>
                                 <img
                                            width="30" height="30"
                                              src={"https://managethaiwater.com:8080/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=dwr_gweb:dem30_hillshade"}>
                                 </img>
                                  <Checkbox  name="checkedB" color="indigo" checked={this.state.NEXTMapOneTerrainHillshadeDSM_dwr} onChange={(event) => this._f_NEXTMapOneTerrainHillshadeDSM_dwr(event)}     />
                                  <spen> NextMap DSM 10 เมตร</spen>
                               </div>
                                 <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.NEXTMapOneTerrainHillshadeDSM_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_NEXTMapOneTerrainHillshadeDSM_dwr(value) }
                                           />
                              </div>
                               <div>
                                <img
                                            width="30" height="30"
                                              src={"https://managethaiwater.com:8080/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=dwr_gweb:dem30_hillshade"}>
                                 </img>
                                  <Checkbox  name="checkedB" color="indigo" checked={this.state.dsm_dwr} onChange={(event) => this._f_dsm_dwr(event)}    />
                                  <spen> ระดับความสูง (DEM 10 เมตร)</spen>
                               </div>
                                 <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.dsm_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_dsm_dwr(value) }
                                           />
                              </div>
                           </div>
               </div>
              
               <div className="nav-menu p-2">
                          <div>
                              <div style={{display:this.state.layer3=="none"?"block":"none",cursor: "pointer"}} onClick={()=> this.setState({layer3: "",ly_height:350,ly_overflowY:"scroll"}) }>
                                <spen >อื่น ๆ</spen>
                                  <ArrowDropDownIcon  />
                                </div>
                                <div style={{display:this.state.layer3=="none"?"none":"block",cursor: "pointer"}} onClick={()=> this.setState({layer3: "none",ly_height:"",ly_overflowY:""}) }>
                                <spen >อื่น ๆ</spen>
                                  <ArrowDropUpIcon  />
                               </div>

                           </div>
                           <div style={{display:this.state.layer3}}>
                               <div>
                                  <img
                                            width="30" height="30"
                                              src={"https://managethaiwater.com:8080/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=dwr_gweb:dem30_hillshade"}>
                                 </img>
                                  <Checkbox  name="checkedB" color="indigo" checked={this.state.LDD_RASTER_WM_CACHE_dwr} onChange={(event) => this._f_LDD_RASTER_WM_CACHE_dwr(event)}   />
                                  <spen> แผนที่ 1:4000</spen>
                               </div>
                                 <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.LDD_RASTER_WM_CACHE_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_LDD_RASTER_WM_CACHE_dwr(value) }
                                           />
                              </div>
                               <div>
                                   <img
                                            width="30" height="30"
                                              src={"https://managethaiwater.com:8080/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=dwr_gweb:L7018"}>
                                 </img>
                                  <Checkbox  name="checkedB" color="indigo"   checked={this.state.L7018L7018_dwr} onChange={(event) => this._f_L7018L7018_dwr(event)}    />
                                  <spen> แผนที่ภูมิประเทศ 7018 (1:50,000)</spen>
                               </div>
                                <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.L7018L7018_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_L7018L7018_dwr(value) }
                                           />
                              </div>
                               <div>
                               <img
                                            width="30" height="30"
                                              src={"https://smpklandcheck.org/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=SPK_MAP:smpk_bldg"}>
                                 </img>
                                  <Checkbox  name="checkedB" color="indigo" checked={this.state.smpklandcheck_dwr} onChange={(event) => this._f_smpklandcheck_dwr(event)}      />
                                  <spen> ขอบเขตอาคาร (สมุทรปราการ)</spen>
                               </div>
                               <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.smpklandcheck_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_smpklandcheck_dwr(value) }
                                           />
                              </div>
                               <div>
                                <img
                                            width="30" height="30"
                                              src={"http://1.10.184.64:8080/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=ctoc:bldg"}>
                                 </img>
                                  <Checkbox  name="checkedB" color="indigo" checked={this.state.bldg_dwr} onChange={(event) => this._f_bldg_dwr(event)}     />
                                  <spen> ขอบเขตอาคาร (กรุงเทพมหานคร)</spen>
                               </div>
                               <div className="ml-2 mr-2">
                                 <Slider
                                             size="small"
                                             className="mr-1"
                                             style={{width:"100%",color:"#343a40",display:this.state.bldg_dwr==true?"":"none"}}
                                             defaultValue={10}
                                             aria-label="Small"
                                             valueLabelDisplay="auto"
                                             onChange={(event, value) => this.o_f_bldg_dwr(value) }
                                           />
                              </div>
                           </div>
               </div>

              </div>


               <div
                style={{

                position: 'fixed',
                top: 130,
                right: 10,

                 display: nonelayer,
                backgroundColor:'#3f51b5',
                borderRadius: '5px'

                }} >

                 <RadioGroup  >
                <ul className="nav-menu">
                       <li className="menu no-arrow ml-1" >
                         <FormControlLabel
                            onClick={this.bmapc1}
                            value="Streetmap"
                            control={<Radio/>}
                            label="แผนที่ OSM"/>
                      </li>
               </ul>
               <ul className="nav-menu">
                       <li className="menu no-arrow ml-1" >
                         <FormControlLabel
                             onClick={this.bmapc2}
                              value="Dark"
                              control={<Radio/>}
                              label="แผนที่แบบมืด"
                         />
                      </li>
               </ul>

                <ul className="nav-menu">
                       <li className="menu no-arrow ml-1" >
                         <FormControlLabel
                         onClick={this.bmapc3}
                          value="Streets"
                          control={<Radio/>}
                          label="แผนที่ Mapbox"
                        />
                      </li>
               </ul>
               <ul className="nav-menu">
                       <li className="menu no-arrow ml-1">
                        <FormControlLabel
                         onClick={this.bmapc4}
                          value="Satellite"
                          control={<Radio/>}
                          label="ภาพถ่ายดาวเทียม"
                        />
                      </li>
               </ul>
                </RadioGroup >
              </div>
              </AppBar>

              <Drawer
                variant="persistent"
                classes={{
                  paper: classes.drawerPaper,
                }}
                open={this.state.open}
              >
                  <CustomScrollbars className="module-side-scroll scrollbar"
                              style={{backgroundColor: '#030852',height: this.props.width >= 1200 ? 'calc(100vh - 70px)' : 'calc(100vh - 70px)'}}>

                <div className={classes.drawerInner} style={{color:'#fff' ,white:'300px'}} >
                  <div className={classes.drawerHeader} style={{color:'#fff'}} >
                    <IconButton onClick={this.handleDrawerClose} style={{color:'#fff'}} >
                      {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                  </div>
             <Divider/>

                <div className="ml-3 mt-0">
                 <div><LabelIcon /> ชื่อเหตุการณ์</div>
                   <div> {this.state.eventname}</div>



                <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                   <div><PlaceIcon /> พื้นที่เกิดเหตุ</div>
                   <div>{lbl_name} </div>
                   <div className="mb-1"> ความสูงอาคาร  {lbl_nstorey}  ชั้น
                </div>

                <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                   <div><SportsHandballIcon /> อาวุธผู้ก่อการร้าย </div>
                  <div>{this.state.wname}</div>
                  <div>- วงรอบชั้นใน {this.state.weaponmin} เมตร</div>

                  <div>- วงรอบชั้นนอก {this.state.weaponmax} เมตร</div>

                 <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                          <div >   <ImportantDevicesIcon /> บก.เหตุการณ์  </div>
                          <div >   {bl_name}   </div>
                 <div className="mb-1">ความสูงอาคาร  {bl_nstorey} ชั้น      </div>
                   <div className="mb-1">ความจุอาคาร   {bl_employ} คน</div>


                 <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                          <div>  <MyLocationIcon /> ที่วางตัวพลซุ่มยิง</div>
                          <div>  {bs_name} </div>
                 <div className="mb-1">ความสูงอาคาร  {bs_nstorey}  ชั้น
                 </div>

                  <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                      <div><RoomIcon /> เส้นทางเข้าสู่ที่หมาย</div>
                      <div> ระยะ: {vdistance} เมตร</div>


                    <hr style={{borderWidth: 2,borderColor: "#00bcd4"}} />
                            <div>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA/0lEQVRIie2SMVJCMRCGv3WsobFQcbiHY0Gn4Og9vI3eCT2BHkAbaKwshIHit/AFkrzkkTAWFnxVkt38f3azcOBfIskkPUlaaMvUi79459+SHmsNRkrgxVOMUlpHGY/7qhf9clecKeltjwpeS8UvMgIlDGO9VItui0ttc1Ni0EpyWEONQZAs6Rj4BPo5gyZPqTjwBZyY2dodxBVc5cQL6QGX/kFskG1PBYFGbDDpuhmPa4ZAY/MHkk6BGdG/7IGAgZnNIaxgvEu8YIpoNK7dxjf4i/63tPwWzYCzzqftHlPH3MzOIaxgVf3OPEu38A0egI+OS1Nv/dyR995oHSjjBxxe8OZ1klvBAAAAAElFTkSuQmCC"/>
                                ถนนที่ปิดเส้นทาง
                            </div>
                             <div>
                                วงรอบชั้นใน
                             </div>
                            <div>
                                {listroadinItems}
                            </div>
                            <div>
                                วงรอบชั้นนอก
                            </div>
                            <div>
                                {listroadoutItems}
                            </div>
                        <br/>
             </div>

             <hr class="MuiDivider-root"/>
             <div className="m-4 bg-primary">

                {this.state.usersType!=2?
                  <TextField
                    required
                    onChange={this._text_rpt}
                    value={this.state.text_rpt}
                    id="fname"
                    defaultValue=""
                    margin="normal"
                    disabled={this.state.distext}
                    fullWidth
                   placeholder='คลิกบนแผนที่เพื่อระบุตำแหน่งและกรอกข้อมูล'
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px",
                                width:"100%"
                            }
                        }}
                      style={{margin:0}}
                      InputProps={{

                          style: {
                                height: "40px"
                                 , color:"#fff",
                                  width:"100%"

                            }
                        }}
                />
                :''}
             </div>
              <div className="ml-4">

                        <Button  onClick={this._save_rpt}   variant="contained" className="bg-blue text-white"   >
                                  <RoomIcon />  ส่ง
                        </Button>

                    <Button   onClick={this._3d}
                      style={{backgroundColor:_3dcolor}}
                     className="text-white ml-2"   >
                       3D
                    </Button>
                    <Button   onClick={this._los}
                             className="text-white ml-2" style={{backgroundColor:colorSNI}} >
                                LOS
                        </Button>
             </div>
               <div className="m-4">
                 {poi_plan_reportItems}
               </div>
            <br/>

             <Divider/>
                    </div>
                     </CustomScrollbars>
                        </Drawer>

                          <main className={classNames(classes.content, this.state.open && classes.contentShift)}
                          style={{ padding: '0px',marginTop:-10}}>
                <div state={{backgroundColor:'red'}}>
                <div id='map' style={{height:'100%',width:'100%',top: 0,left:0,right:0,position:'fixed'}}
                ///className={"MuiPaper-root  MuiAppBar-positionFixed"}
                onClick={this.CreatePoint} >

             </div>



    <div
                style={{
                opacity:"0.8",
               display:nonedis,
                position: 'fixed',
                bottom: 20,
                right: 10,
                height:'320px',
                width:'320px',
                overflowY: 'scroll',
                backgroundColor:'#000'
                }} >
                   <div className="bg-dark color-white m-0">
                   <spen style={{color:"white"}} className="m-2">
                   Duration: {apiduration} Distance:{apidistance}
                   </spen></div>
                  <div >
                    <div className="m-2">

                       {datainfoItems}
                          </div>

                 </div>
                </div>

                <div
                style={{
                display:ststyle,
                position: 'fixed',
                bottom: 30,
                right: 10,
                height:'320px',
                width:'320px',

               backgroundColor:'#030852'
                }} >

                      <button  onClick={this._Close}
                       className="MuiButtonBase-root MuiButton-text text-white bg-red p-1 m-1"
                       >
                            <spen className="m-0 p-0"> X</spen>
                        </button>


                      <iframe
                           frameBorder="0"
                          style={{ width:'100%',  height:'300px' }}
                           src={this.state._src}
                          ref={(f) => { this.ifr = f; }}
                        />
                       {/* <MyMapComponent
                              isMarkerShown
                              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCeuN-U26oP2_XLQ6RuWUjHIoeNiAYoLWA"
                              loadingElement={<div style={{ height: `100%` }} />}
                              containerElement={<div style={{ height: `200px` }} />}
                              mapElement={<div style={{ height: `100%` }} />}

                            >

                        </MyMapComponent>*/}
                    </div>
                </div>


             <div
                style={{
                    display:ststylelos,
                position: 'fixed',
                bottom: 30,
                right: 10,
                height:'200px',
                width:'45%',
                 backgroundColor:'#030852',

                }} >

                            <div className="bg-geekblue m-1" >

                       <button  onClick={this._Closelos}
                       className="MuiButtonBase-root MuiButton-text text-white bg-red p-1 m-0"
                       >
                            <spen className="m-0 p-0"> X</spen>
                        </button>

                            </div>
                                  <iframe frameBorder="0"

                                      style={{ width:'100%'}}
                                      src={this.state._srclos}
                                    />

                           </div>


            </main>
            <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.openmsg}
            autoHideDuration={1000}
              onClose={() => this.setState({openmsg: false})}
            message="รอข้อมูล"
             action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />
          	   <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.dataComptele}
            autoHideDuration={1000}
              onClose={() => this.setState({dataComptele: false})}
            message="บันทึกสำเร็จ"
               action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />

           <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.dataError}
            autoHideDuration={1000}
              onClose={() => this.setState({dataError: false})}
            message="ไม่พบข้อมูล"
               action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.duperror}
            autoHideDuration={1000}
              onClose={() => this.setState({duperror: false})}
            message="มีข้อมูลสถานที่นี้แล้วในระบบ"
               action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.openmsg2}
                  onClose={() => this.setState({openmsg2: false})}
            autoHideDuration={1000}
            message="แสดงข้อมูล"
               action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />
          <Dialog maxWidth={'xs'}
                style={{overflow: 'hidden',marginLeft:'calc(100% - 500px)' }}
                fullWidth={true}  open={this.state.popupbk} onClose={this.closepopupbk} >

                        <div className="p-0 m-0  bg-geekblue">

                        <div className="jr-tabs jr-task-list pt-0 pr-2 pl-2" style={{overflow: 'hidden'}}   >


     <div className="bg-geekblue"   >
     <div >

         <div className=" mb-5" >
          <label className="mb-0 mt-3" style={{color:'white'}}>
                  ชื่อ
          </label>
          <TextField

                                                                    required
                                                                        onChange={this.filter3}
                                                                    defaultValue=""
                                                                    margin="normal"
                                                                    fullWidth
                                                                    className="bg-primary"
                                                                    variant="outlined"
                                                                        InputProps={{
                                                                            style: {
                                                                                height: "40px"
                                                                            }
                                                                        }}
                                                                      style={{margin:0}}
                                                                      InputProps={{
                                                                          startAdornment: (
                                                                            <InputAdornment position="start" >
                                                                            </InputAdornment>
                                                                          ),
                                                                          style: {
                                                                                height: "40px"
                                                                                 , color:"#fff"

                                                                            }
                                                                        }}
                                                                />

          <label className="mb-0 mt-3" style={{color:'white'}}>
                  ความสูง
          </label>
          <TextField

                                                                    onChange={this.filter}
                                                                    defaultValue=""
                                                                    margin="normal"
                                                                    fullWidth
                                                                     className="bg-primary"
                                                                    variant="outlined"
                                                                        InputProps={{
                                                                            style: {
                                                                                height: "40px"
                                                                            }
                                                                        }}
                                                                      style={{margin:0}}
                                                                      InputProps={{
                                                                          startAdornment: (
                                                                            <InputAdornment position="start" >

                                                                            </InputAdornment>
                                                                          ),
                                                                          style: {
                                                                                height: "40px"
                                                                                 , color:"#fff"

                                                                            }
                                                                        }}
                                                                />

          <label className="mb-0 mt-3" style={{color:'white'}}>
                  ความจุ
          </label>
          <TextField

                                                                     onChange={this.filter2}
                                                                    defaultValue=""
                                                                    margin="normal"
                                                                    fullWidth
                                                                    className="bg-primary"
                                                                    variant="outlined"
                                                                        InputProps={{
                                                                            style: {
                                                                                height: "40px"
                                                                            }
                                                                        }}
                                                                      style={{margin:0}}
                                                                      InputProps={{
                                                                          startAdornment: (
                                                                            <InputAdornment position="start" >
                                                                            </InputAdornment>
                                                                          ),
                                                                          style: {
                                                                                height: "40px"
                                                                                 , color:"#fff"

                                                                            }
                                                                        }}
                                                                />
          </div>

     </div>
        <DataTableToolbar />

        <div className="flex-auto mb-2">
          <div className="table-responsive-material bg-geekblue border border-primary">
            <Table className="" size="small" >
              <DataTableHead
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={tabledata.length}
              />
              <TableBody>
                {tabledata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {

                  return (
                    <TableRow
                      hover

                      onClick={event =>  tablestatus=="bk"?this._goToBK(event, n.objectid_1): this._goToSNI(event, n.objectid_1)}

                      role="checkbox"

                      key={n.id}

                    >
                           <TableCell align="left"  style={{color:'white',cursor: 'pointer'}}>
                           {n.bl_name}
                           </TableCell>

                          <TableCell align="left"  style={{color:'white',cursor: 'pointer'}}>
                           {n.bl_nstorey}
                          </TableCell>
                           <TableCell align="left"  style={{color:'white',cursor: 'pointer'}}>
                           {n.bl_employ}
                          </TableCell>


                            <div>


              </div>
                     </TableRow>
                  );
                })}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TablePagination  style={{color:'white'}}
                    count={tabledata.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>

      </div>


                                                                <div className="pb-2 pl-3">
                                                                   <Row  >
                                                                        <Button  onClick={this.closepopupbk}  variant="contained" className="bg-blue text-white"   >
                                                                                   ตกลง
                                                                        </Button>
                                                                         <Button   onClick={this.closepopupbk}  variant="contained" className="bg-blue text-white ml-2"   >
                                                                                   ยกเลิก
                                                                        </Button>
                                                                        </Row  >
                                                                </div>



                                          </div>
                                       </div>
             </Dialog>
           {/*Formpopup*/}
            <Dialog maxWidth={'xl'}  fullWidth={true}  open={this.state.openForm} onClose={this.FormRequestClose} >
                        <div className="p-0 m-0  bg-geekblue"
                                       style={{
                                        overflowX: "hidden"
                                        }}
                                        >

                                    <form className="" noValidate autoComplete="off" onSubmit={this.handleSubmit}>

                                    <div className="jr-tabs jr-task-list pt-0 pr-2 pl-2  " >
                                     >


                                     <AppBar className="bg-primary" position="static" className="mt-2">
                                      <Tabs value={this.state.tabvalue} onChange={this.maphandleChange}  variant="scrollable" scrollButtons="on">
                                        <Tab  style={{fontFamily: 'Kanit'}} className={this.state.tabvalue===0 ? "tab bg-red" :"tab"} icon={<InfoIcon/>} label="ข้อมูล"/>
                                        <Tab  style={{fontFamily: 'Kanit'}} className={this.state.tabvalue===1 ? "tab bg-red" :"tab"} icon={<ImageIcon/>}  label="รูปภาพ"/>
                                        <Tab  style={{fontFamily: 'Kanit'}} className={this.state.tabvalue===2 ? "tab bg-red" :"tab"} icon={<InsertDriveFileIcon/>}  label="เอกสาร"/>
                                      </Tabs>
                                    </AppBar>
                                        {this.state.tabvalue === 0 &&
                                      <TabContainer>
                                        <div className="row">
 <div className="col-md-12 col-12">
                                                    <label className="mb-0 mt-3" style={{ color: 'white' }}>
                                                        เหตุการณ์
                                                     </label>
                                                     <div className="bg-primary">
                                                        <TextField

                                                            required
                                                            value={this.state.eventname}
                                                            onChange={(event)=> this.setState({eventname:event.target.value})}
                                                            defaultValue=""
                                                            margin="normal"
                                                            fullWidth
                                                            variant="outlined"

                                                            style={{ margin: 0 }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start" >
                                                                        <LocationCityIcon height="40px" />
                                                                    </InputAdornment>
                                                                ),
                                                                style: {
                                                                    height: "40px"
                                                                    , color: "#fff"
                                                                    ,fontFamily: 'Kanit'

                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            <div className="col-md-6 col-12">

                                                         <label className="mb-0 mt-3" style={{color:'white'}}>
                                                            จังหวัด
                                                         </label>
                                                         <div className="bg-primary">
                                                                  <TextField
                                                                        select
                                                                        required
                                                                        id="user_no"
                                                                        value={Gotoprovince}
                                                                        onChange={this._goToProvince}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"

                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">
                                                                                  <LanguageIcon position="start" />
                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {
                                                                                    height: "40px"
                                                                                     ,color:"#fff"
                                                                                     ,fontFamily: 'Kanit'

                                                                                }
                                                                            }}

                                                                    >

                                                                   <MenuItem value={0}>---</MenuItem>
                                                                         {provinceItems}
                                                                   </TextField>
                                                         </div>
                                            </div>

                                            <div className="col-md-6 col-12">
                                                         <label className="mb-0 mt-3" style={{color:'white'}}>
                                                            อำเภอ
                                                         </label>
                                                         <div className="bg-primary">
                                                                  <TextField
                                                                        select
                                                                        required
                                                                          value={Gotodistrict}
                                                                        onChange={this._goToDistrict}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"

                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">
                                                                                  <LanguageIcon position="start" />
                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {
                                                                                    height: "40px"
                                                                                     ,color:"#fff"
                                                                                     ,fontFamily: 'Kanit'

                                                                                }
                                                                            }}

                                                                    >

                                                                     <MenuItem value={0}>---</MenuItem>
                                                                          {districtItems}
                                                                  </TextField>
                                                        </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                         <label className="mb-0 mt-3" style={{color:'white'}}>
                                                                ตำบล
                                                          </label>
                                                         <div className="bg-primary">
                                                           <TextField
                                                                        select
                                                                        required
                                                                        id="user_no"
                                                                        value={Gotosubdistrict}
                                                                         onChange={this._goToSubDistrict}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"

                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">
                                                                                  <LanguageIcon position="start" />
                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {
                                                                                    height: "40px"
                                                                                     ,color:"#fff"
                                                                                     ,fontFamily: 'Kanit'


                                                                                }
                                                                            }}

                                                                    >

                                                                     <MenuItem value={0}>---</MenuItem>
                                                                           {subdistrictItems}
                                                                    </TextField>
                                                        </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                 <label className="mb-0 mt-3" style={{color:'white'}}>
                                                 สถานะเหตุการณ์
                                                 </label>
                                                         <div className="bg-primary">
                                                                        <TextField
                                                                        select
                                                                        required
                                                                        value={this.state.plan_status}
                                                                        onChange={this._plan_status}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"

                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">
                                                                                  <LanguageIcon position="start" />
                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {
                                                                                    height: "40px"
                                                                                     ,color:"#fff"
                                                                                     ,fontFamily: 'Kanit'

                                                                                }
                                                                            }}

                                                                    >
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={1}>รายงาน</MenuItem>
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={0}>แผนงาน</MenuItem>
                                                                    </TextField>
                                                         </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                            <label className="mb-0 mt-3" style={{color:'white'}}>
                                                ชื่อสถานที่/พื้นที่
                                            </label>
                                                        <div className="bg-primary">
                                                                <TextField

                                                                    required
                                                                    id="fname"
                                                                    onChange={this._name}
                                                                    value={name}
                                                                    defaultValue=""
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"

                                                                      style={{margin:0}}
                                                                      InputProps={{
                                                                          startAdornment: (
                                                                            <InputAdornment position="start" >
                                                                              <LocationCityIcon height="40px" />
                                                                            </InputAdornment>
                                                                          ),
                                                                          style: {
                                                                                height: "40px"
                                                                                 , color:"#fff"
                                                                                 ,fontFamily: 'Kanit'

                                                                            }
                                                                        }}
                                                                />
                                                        </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                            <label className="mb-0 mt-3" style={{color:'white'}}>
                                            ประเภทการก่อการร้าย
                                            </label>
                                                        <div className="bg-primary">
                                                              <TextField
                                                                    select
                                                                    required
                                                                    value={this.state.p_type}
                                                                    onChange={this._p_type}
                                                                    defaultValue=""
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"

                                                                      style={{margin:0}}
                                                                         InputProps={{
                                                                          startAdornment: (
                                                                            <InputAdornment className="mr-1">
                                                                              <AccountBalanceIcon position="start" />
                                                                            </InputAdornment>

                                                                          ),
                                                                          style: {
                                                                                height: "40px"
                                                                                 ,color:"#fff"
                                                                                 ,fontFamily: 'Kanit'

                                                                            }
                                                                        }}

                                                                >
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={1}> ชาตินิยม </MenuItem>
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={2}>ทางศาสนา</MenuItem>
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={3}>สนับสนุนจากรัฐ</MenuItem>
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={4}>ฝ่ายซ้ายจัด</MenuItem>
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={5}>ฝ่ายขวาจัด</MenuItem>
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={6}>อนาธิปไตย</MenuItem>
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={7}>การก่อการร้ายอื่น ๆ</MenuItem>
                                                              </TextField>
                                                        </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                            <label className="mb-0 mt-3" style={{color:'white'}}>
                                            รูปแบบการก่อการร้าย
                                            </label>
                                                        <div className="bg-primary">
                                                              <TextField
                                                                    select
                                                                    required
                                                                    onChange={this._p_form}
                                                                     value={this.state.p_form}
                                                                    defaultValue=""
                                                                    margin="normal"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                        InputProps={{
                                                                            style: {
                                                                                height: "40px"
                                                                            }
                                                                        }}
                                                                      style={{margin:0}}
                                                                         InputProps={{
                                                                          startAdornment: (
                                                                            <InputAdornment className="mr-1">
                                                                              <AccountBalanceIcon position="start" />
                                                                            </InputAdornment>

                                                                          ),
                                                                          style: {
                                                                                height: "40px"
                                                                                 ,color:"#fff"
                                                                                 ,fontFamily: 'Kanit'

                                                                            }
                                                                        }}
                                                                >
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={1}>กำลังนักรบกลุ่มใหญ่</MenuItem>
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={2}>กำลังนักรบขนาดเล็ก</MenuItem>
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={3}>ก่อเหตุเพียงคนเดียว</MenuItem>
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={4}>วินาศกรรมวางระเบิด</MenuItem>
                                                                     <MenuItem style={{fontFamily: 'Kanit'}} value={5}>ลักพาตัวประกัน</MenuItem>
                                                                </TextField>
                                                        </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                            <label className="mb-0 mt-3" style={{color:'white'}}>
                                            ลักษณะภูมิประเทศ
                                            </label>
                                                        <div className="">
                                                             <CKEditor
                                                                    editor={ this.state._editor }
                                                                    data={this.state._content+"<p></p><p></p><p></p><p></p><p></p>"}
                                                                    config={{ckfinder: {
                                                                      // Upload the images to the server using the CKFinder QuickUpload command.

			                                                            uploadUrl: 'https://ckeditor.com/docs/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json'


                                                                    }}}
                                                                    onInit={ editor => {
                                                                        // You can store the "editor" and use when it is needed.
                                                                    } }
                                                                    onChange={ ( event, editor ) => {
                                                                        const data = editor.getData();
                                                                        //this.state.handleWYSIWYGInput(this.props.id, data);

                                                                    } }
                                                                    onBlur={ editor => {

                                                                    } }
                                                                    onFocus={ editor => {

                                                                    } }
                                                                />
                                                         </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                            <label className="mb-0 mt-3" style={{color:'white'}}>
                                            ประชาชน
                                            </label>
                                                        <div className="bg-primary">
                                                                 <TextField
                                                                      onChange={this._population}
                                                                      value={this.state.population}
                                                                      multiline={true}
                                                                      rows={5}
                                                                    fullWidth
                                                                    variant="outlined"

                                                                      style={{margin:0}}
                                                                      InputProps={{
                                                                          startAdornment: (
                                                                            <InputAdornment position="start" >
                                                                               <AccessibilityNewIcon height="40px" />
                                                                            </InputAdornment>
                                                                          ),
                                                                          style: {

                                                                                  color:"#fff"
                                                                                  ,height: "40px"
                                                                                  ,fontFamily: 'Kanit'
                                                                            }
                                                                        }}
                                                                />
                                                        </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                            <label className="mb-0 mt-3" style={{color:'white'}}>
                                            การปกครอง
                                            </label>
                                                        <div className="bg-primary">
                                                                 <TextField
                                                                     multiline={true}
                                                                      rows={5}
                                                                    onChange={this._dominance}
                                                                    value={this.state.dominance}
                                                                    fullWidth
                                                                    variant="outlined"

                                                                      style={{margin:0}}
                                                                      InputProps={{
                                                                          startAdornment: (
                                                                            <InputAdornment position="start" >
                                                                              <AccessibilityNewIcon height="40px" />
                                                                            </InputAdornment>
                                                                          ),
                                                                          style: {

                                                                                     height: "40px"
                                                                                 , color:"#fff"
                                                                                 , fontFamily: 'Kanit'
                                                                            }
                                                                        }}
                                                                />
                                                        </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                             <label className="mb-0 mt-3" style={{color:'white'}}>
                                             ศาสนา
                                             </label>
                                                        <div className="bg-primary">
                                                                   <TextField
                                                                         multiline={true}
                                                                          rows={5}
                                                                          onChange={this._religion}
                                                                          value={this.state.religion}
                                                                         fullWidth
                                                                         variant="outlined"

                                                                          style={{margin:0}}
                                                                          InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment position="start" >
                                                                                   <AccountBalanceIcon height="40px" />
                                                                                </InputAdornment>
                                                                              ),
                                                                              style: {

                                                                                      color:"#fff"
                                                                                      ,fontFamily: 'Kanit'
                                                                                      ,  height: "40px"
                                                                                     , color:"#fff"
                                                                                }
                                                                            }}
                                                                    />
                                                        </div>
                                             </div>
                                             <div className="col-md-6 col-12">
                                             <label className="mb-0 mt-3" style={{color:'white'}}>
                                                การคมนาคมทางบก
                                             </label>
                                                        <div className="bg-primary">

                                                                   <TextField

                                                                     multiline={true}
                                                                      rows={5}

                                                                    onChange={this._way_land}
                                                                    value={this.state.way_land}
                                                                    fullWidth
                                                                    variant="outlined"

                                                                      style={{margin:0}}
                                                                      InputProps={{
                                                                          startAdornment: (
                                                                            <InputAdornment position="start" >
                                                                               <DirectionsWalkIcon height="40px" />
                                                                            </InputAdornment>
                                                                          ),
                                                                          style: {

                                                                                  color:"#fff"
                                                                                      ,fontFamily: 'Kanit'
                                                                                      ,  height: "40px"
                                                                                     , color:"#fff"
                                                                            }
                                                                        }}
                                                                    />
                                                        </div>
                                             </div>
                                             <div className="col-md-6 col-12">
                                             <label className="mb-0 mt-3" style={{color:'white'}}>
                                             การคมนาคมทางน้ำ
                                             </label>
                                                         <div className="bg-primary">
                                                                    <TextField
                                                                         onChange={this._way_water}
                                                                         value={this.state.way_water}
                                                                         multiline={true}
                                                                          rows={5}
                                                                        fullWidth
                                                                        variant="outlined"

                                                                          style={{margin:0}}
                                                                          InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment position="start" >
                                                                                   <PoolIcon height="40px" />
                                                                                </InputAdornment>
                                                                              ),
                                                                              style: {

                                                                                          color:"#fff"
                                                                                      ,fontFamily: 'Kanit'
                                                                                      ,  height: "40px"
                                                                                     , color:"#fff"
                                                                                }
                                                                            }}
                                                                    />
                                                          </div>
                                                   </div>
                                        </div>
                                      </TabContainer>}
                                      {this.state.tabvalue === 1 &&
                                      <TabContainer>

                                            <div className="row">
                                                        <div className="col-md-12 col-12">
                                                        <label className="mb-0 mt-3" style={{color:'white'}}>
                                                        Path รูปผังอาคาร
                                                        </label>
                                                                    <div className="bg-primary">
                                                                            <TextField
                                                                               type="file"
                                                                                  accept="image/*"
                                                                                  id="raised-button-file"
                                                                                  multiple
                                                                                 onChange={this._upload_bldg}
                                                                                required
                                                                                id="fname"

                                                                                defaultValue=""
                                                                                margin="normal"
                                                                                fullWidth
                                                                                variant="outlined"

                                                                                  style={{margin:0}}
                                                                                  InputProps={{
                                                                                      startAdornment: (
                                                                                        <InputAdornment position="start" >
                                                                                        </InputAdornment>
                                                                                      ),
                                                                                      style: {
                                                                                               color:"#fff"
                                                                                      ,fontFamily: 'Kanit'
                                                                                      ,  height: "40px"
                                                                                     , color:"#fff"
                                                                                        }
                                                                                    }}
                                                                            />
                                                                    </div>


                                                 </div>
                                                        <div className="col-md-12 col-12">
                                                        <label className="mb-0 mt-3" style={{color:'white'}}>
                                                        Path รูปลักษณะทางเข้าออก (ปกติ)
                                                        </label>
                                                                    <div className="bg-primary">
                                                                            <TextField
                                                                             type="file"
                                                                                required
                                                                                id="fname"
                                                                                onChange={this._upload_enter}
                                                                                defaultValue=""
                                                                                margin="normal"
                                                                                fullWidth
                                                                                variant="outlined"
                                                                                    InputProps={{
                                                                                        style: {
                                                                                            height: "40px"
                                                                                        }
                                                                                    }}
                                                                                  style={{margin:0}}
                                                                                  InputProps={{
                                                                                      startAdornment: (
                                                                                        <InputAdornment position="start" >

                                                                                        </InputAdornment>
                                                                                      ),
                                                                                      style: {
                                                                                              color:"#fff"
                                                                                      ,fontFamily: 'Kanit'
                                                                                      ,  height: "40px"
                                                                                     , color:"#fff"

                                                                                        }
                                                                                    }}
                                                                            />
                                                                    </div>

                                                </div>
                                                        <div className="col-md-12 col-12">
                                                        <label className="mb-0 mt-3" style={{color:'white'}}>
                                                        Path รูปลักษณะทางเข้าออก (อาคารจอดรถ)
                                                        </label>
                                                                    <div className="bg-primary">
                                                                            <TextField
                                                                             type="file"
                                                                                required
                                                                                id="fname"
                                                                                 onChange={this._upload_exit}
                                                                                defaultValue=""
                                                                                margin="normal"
                                                                                fullWidth
                                                                                variant="outlined"

                                                                                  style={{margin:0}}
                                                                                  InputProps={{
                                                                                      startAdornment: (
                                                                                        <InputAdornment position="start" >

                                                                                        </InputAdornment>
                                                                                      ),
                                                                                      style: {
                                                                                             color:"#fff"
                                                                                      ,fontFamily: 'Kanit'
                                                                                      ,  height: "40px"
                                                                                     , color:"#fff"

                                                                                        }
                                                                                    }}
                                                                            />
                                                                    </div>

                                                        </div>

                                                </div>
                                            </TabContainer>}
                                            {this.state.tabvalue === 2 &&
                                            <TabContainer>
                                                      <div className="col-md-2 col-12">

                                                             <label className="mb-0 mt-3" style={{color:'white'}}>แนบเอกสาร</label>
                                                                          <div className="">
                                                                 <FullScreen  />
                                                                       </div>
                                                              </div>
                                                                <div className="col-md-10 col-12">
                                                             <label className="mb-0 mt-3" style={{color:'white'}}></label>
                                                     </div>
                                            </TabContainer>}

                                                             <Row  >
                                                               <div className="p-3" >
                                                                        <Button  style={{fontFamily: 'Kanit'}}  onClick={this.Save}  variant="contained" className="bg-blue text-white"   >
                                                                                             บันทึก
                                                                        </Button>
                                                                         </div>
                                                             <div className="p-3">
                                                                        <Button  style={{fontFamily: 'Kanit'}} onClick={this.FormRequestClose}  variant="contained" className="bg-blue text-white"   >
                                                                                              ยกเลิก
                                                                        </Button>
                                                                </div>
                                                          </Row>
                                    </div>
                              </form>
                        </div>
            </Dialog>
            <Dialog fullWidth={true}
                        maxWidth={'xs'} open={this.state.load_data} >
                        <DialogContent className="bg-geekblue text-white"  >
                            <QueryBuilderIcon className="text-primary " />  กรุณารอข้อมูล
                        <hr />
                        </DialogContent>
                        <DialogActions className="bg-geekblue">

                        </DialogActions>
            </Dialog>
                <Dialog open={this.state.deleteopen} onClose={this.deletehandleRequestClose}
                          fullWidth={true}
                          maxWidth = {'sm'}
                          >
                          <DialogTitle>
                            {"ยืนยันการลบ"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              ยืนยันดำเนินการ
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button  className="bg-primary text-white"

                            onClick={event => this.rowdelete2()}
                            >
                              ตกลง
                          </Button>
                            <Button  className="bg-primary text-white" onClick={() => this.setState({deleteopen: false})}  color="secondary">
                              ยกเลิก
                            </Button>
                          </DialogActions>
            </Dialog>
          </div>
          </div>

            );
        }
    }
    index.propTypes = {
      classes: PropTypes.object.isRequired,
      theme: PropTypes.object.isRequired,
    };
    export default withStyles(styles, {withTheme: true})(index);