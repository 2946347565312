import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import axios from "axios";
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {NavLink, withRouter,Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import EditIcon from '@material-ui/icons/Edit';
import { TextField, InputAdornment } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';

import cookie from 'react-cookies';
import moment from "moment";
let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}

const columnData = [
  {id: '1', align: true, disablePadding: false, label: 'เวลา'},
  {id: '2', align: true, disablePadding: false, label: 'หัวข้อข่าวสำคัญ'},
  {id: '7', align: true, disablePadding: false, label: 'เวรติดตามสถานการณ์'},
  {id: '8', align: true, disablePadding: false, label: 'Action'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 5,
      name:'',
      type:'',
      path:'',
      news_group1:'',
      news_group2:'',
      news_group3:'',
      news_group4:'',
      Period:moment().format("YYYY-MM-DDThh:mm:ss"),
      title:'',
      name_job:'',
      users:[],
      openmsg:false,
      sEdit:'',
      id:'',
      delid:''

    };
     var url = 'http://1.10.184.64:3000'
     fetch(url+'/news/select.php')
           .then(response => response.json())
           .then(_data => {
               var data  = _data.sort((a, b) => (a.id > b.id ? -1 : 1))
               this.setState({ data: data });
          });

     fetch(url+'/users/index.php')
          .then(response => response.json())
          .then(data => {
              this.setState({ users: data, loading: false });
          });

       var location = window.location.href;
        const str = '' + location;
        const id = str.split("=")[1];



       fetch(url+'/news/find.php?id='+id)
            .then(response => response.json())
            .then(data => {
                   console.log(data[0].period + "dataid");
                  this.setState({
                  id:data[0].id,
                  title: data[0].title,
                  news_group1: data[0].news_group1,
                  news_group2: data[0].news_group2,
                  news_group3: data[0].news_group3,
                  news_group4: data[0].news_group4,
                  Period:data[0].period.split('+')[0],
                  name_job:data[0].name_job
                  });
             });

           // this.setState({ Period: '2021-03-12T10:29' });


  }


    submit =()=>
    {
         if(this.state.id=="")
         {

                 const qs = require('querystring')
                       const config =
                       {
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                }
                       }
                        var usersid =parseInt(cookie.load('userId'));
                          const requestBody =
                            {

                                Period: this.state.Period.replace('T',' '),
                                title: this.state.title,
                                news_group1:this.state.news_group1,
                                news_group2:this.state.news_group2,
                                news_group3:this.state.news_group3,
                                news_group4:this.state.news_group4,
                                name_job:usersid,

                            }
                             axios.post('http://1.10.184.64:3000/news/insert.php', qs.stringify(requestBody), config)
                                .then((result) =>
                                {
                                    this.props.history.push("/app/news/Index");
                                });

         }
         else{
                       const qs = require('querystring')
                       const config =
                       {
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                }
                       }
                        var id_ =parseInt(cookie.load('userId'));
                          const requestBody =
                            {
                                id: this.state.id,
                                Period: this.state.Period.replace('T',' '),
                                title: this.state.title,
                                news_group1:this.state.news_group1,
                                news_group2:this.state.news_group2,
                                news_group3:this.state.news_group3,
                                news_group4:this.state.news_group4,
                                name_job:this.state.name_job,

                            }
                             axios.post('http://1.10.184.64:3000/news/update.php', qs.stringify(requestBody), config)
                                .then((result) =>
                                {
                                     this.props.history.push("/app/newslast/Index");
                                });

         }

    }
  _name =(event) =>
   {
      this.setState({name:event.target.value })
    }
     _group1 = (event)=>
    {

                   this.setState({news_group1:event.target.value})
    }
    _group2 = (event)=>
    {

                   this.setState({news_group2:event.target.value})
    }
    _group3 = (event)=>
    {

                   this.setState({news_group3:event.target.value})
    }
    _group4 = (event)=>
    {

                   this.setState({news_group4:event.target.value})
    }
    _Period =(event)=>
    {
                     console.log(event.target.value+"dddddddd")
                    this.setState({Period: event.target.value});
    }
    _title =(event)=>
    {
                   this.setState({title:event.target.value})
    }
    _name_job =(event)=>
    {
                   this.setState({name_job:event.target.value})
    }

  render() {
            const {data, order, orderBy, selected, rowsPerPage, page} = this.state;

            let __users = this.state.users;
                    let usersItems = __users.map((users) =>
                       <MenuItem    key={users.id} value={users.id}>
                       <span className="ml-2"> {users.fname} </span></MenuItem>
            );


    return (
    <div>

    <div class="page-heading d-sm-flex justify-content-sm-between align-items-sm mb-0 p-2  bg-geekblue ">
          <h5 class="title p-1 mb-sm-0"></h5>
              <nav class="mb-0" aria-label="breadcrumb">
                      <ol class="breadcrumb">
                                    <div class="breadcrumb-item">
                                        <div   style={{color: 'gray', textDecoration: 'none'}}  >
                                         สถานการณ์ปกติ
                                        </div>
                                        <div className="pl-1 pr-1"> > </div>
                                         <NavLink   style={{color: 'primary', textDecoration: 'none'}} to={{ pathname:'/app/newslast' }} >
                                          ด้านการข่าว
                                        </NavLink>
                                        <div className="pl-1 pr-1"> > </div>
                                        <div   style={{color: 'white', textDecoration: 'none'}}  >
                                          {this.state.id==''?"เพิ่ม":"แก้ไข"}
                                        </div>
                                     </div>
                      </ol>
              </nav>
    </div>

    <div className="p-2 pt-3" >
    <ProfileHeader/>

    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                        <label className="ml-4 mt-4 text-white"><h3></h3></label>
                            <div className="row ml-3 mr-3 mb-3" style={{color:'#fff'}}>
                                <div className="col-md-6 col-12 mb-3">
                                   <label className="">เวลา</label>
                                       <div className="">

                                            <TextField
                                                    id="datetime-local"
                                                    type="datetime-local"
                                                    //defaultValue={this.state.getDate}
                                                    onChange={this._Period}
                                                    value={this.state.Period}
                                                    InputProps={{
                                                                 style: {
                                                                        height: "40px"
                                                                         ,width: "100%"
                                                                         ,backgroundColor:"#3f51b5"
                                                                         ,color:"#fff"
                                                                     }
                                                         }}
                                                   //className={classes.textField}
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                  />
                                       </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                   <label className="">หัวข้อข่าวสำคัญ</label>
                                       <div className="">

                                            <TextField
                                                    id="fname"
                                                    margin="normal"
                                                    fullWidth
                                                    onChange={this._title}
                                                    value={this.state.title}
                                                    variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                height: "40px"
                                                            ,backgroundColor:"#3f51b5"
                                                            }
                                                        }}
                                                      style={{margin:0}}
                                                      InputProps={{

                                                          style: {
                                                                height: "40px"
                                                                ,width: "100%"
                                                                 ,backgroundColor:"#3f51b5"
                                                                    ,color:"#fff"

                                                            }
                                                        }}
                                           />
                                       </div>
                                </div>

                                <div className="col-md-6 col-12 mb-3">
                                   <label className="">ข้อมูลข่าวเหตุการณ์ที่อยู่ในความสนใจ</label>
                                       <div className="">

                                            <TextField
                                                    id="fname"
                                                    margin="normal"
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    onChange={this._group1}
                                                    value={this.state.news_group1}
                                                    variant="outlined"
                                                        InputProps={{
                                                            style: {

                                                            backgroundColor:"#3f51b5"
                                                            }
                                                        }}
                                                      style={{margin:0}}
                                                      InputProps={{

                                                          style: {

                                                                width: "100%"
                                                                 ,backgroundColor:"#3f51b5"
                                                                    ,color:"#fff"

                                                            }
                                                        }}
                                           />
                                       </div>
                                </div>

                                <div className="col-md-6 col-12 mb-3">
                                   <label className="">ข้อมูลข่าวเหตุการณ์ก่อความไม่สงบภายในประเทศ</label>
                                       <div className="">
                                            <TextField
                                                    id="fname"
                                                    margin="normal"
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    onChange={this._group2}
                                                    value={this.state.news_group2}
                                                    variant="outlined"
                                                        InputProps={{
                                                            style: {

                                                            backgroundColor:"#3f51b5"
                                                            }
                                                        }}
                                                      style={{margin:0}}
                                                      InputProps={{

                                                          style: {

                                                                width: "100%"
                                                                 ,backgroundColor:"#3f51b5"
                                                                    ,color:"#fff"

                                                            }
                                                        }}
                                           />
                                       </div>
                                </div>

                                <div className="col-md-6 col-12 mb-3">
                                   <label className="">ข้อมูลข่าวเหตุการณ์ก่อการร้ายสากล</label>
                                       <div className="">
                                            <TextField
                                                    id="fname"
                                                    margin="normal"
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    onChange={this._group3}
                                                    value={this.state.news_group3}
                                                    variant="outlined"
                                                        InputProps={{
                                                            style: {

                                                            backgroundColor:"#3f51b5"
                                                            }
                                                        }}
                                                      style={{margin:0}}
                                                      InputProps={{

                                                          style: {

                                                                width: "100%"
                                                                 ,backgroundColor:"#3f51b5"
                                                                    ,color:"#fff"

                                                            }
                                                        }}
                                           />
                                       </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                   <label className="">ข้อมูลอื่นๆ</label>
                                       <div className="">
                                            <TextField
                                                    id="fname"
                                                    margin="normal"
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    onChange={this._group4}
                                                    value={this.state.news_group4}
                                                    variant="outlined"
                                                        InputProps={{
                                                            style: {

                                                            backgroundColor:"#3f51b5"
                                                            }
                                                        }}
                                                      style={{margin:0}}
                                                      InputProps={{

                                                          style: {

                                                                width: "100%"
                                                                 ,backgroundColor:"#3f51b5"
                                                                    ,color:"#fff"

                                                            }
                                                        }}
                                           />

                                       </div>
                                </div>
                            </div>

                   <div className="row m-3" style={{color:'#fff'}}>
                     <Button   className="bg-blue text-white mt-0 ml-3" onClick={()=>this.submit()}>
                          <SaveIcon/>
                          <span className="ml-1">
                                บันทึก
                          </span>
                     </Button>

                      <NavLink    to={{ pathname:'/app/news'}} >
                                 <Button   className="bg-blue text-white mt-0 ml-3" >
                                      <CancelIcon/>
                                      <span className="ml-1">
                                            ยกเลิก
                                      </span>
                                 </Button>
                    </NavLink>
                   </div>
                 </div>
   </div>
 </div>
 </div>
 </div>
      <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.state.openmsg}
            autoHideDuration={1000}
              onClose={() => this.setState({openmsg: false})}
            message="บันทึกสำเร็จ"
             action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />
           <Dialog fullWidth={true}
                        maxWidth={'xs'} open={this.state.open1} onClose={this.handleRequestClose}>
                        <DialogContent className="bg-geekblue text-white"  >
                            <InfoIcon className="text-primary " />   ยืนยันการลบ.
                        <hr />
                        </DialogContent>
                        <DialogActions className="bg-geekblue">
                            <Button onClick={this._del} className="bg-success text-white">
                                    <CheckCircleIcon /> ใช่
                            </Button>

                            <Button onClick={()=>{this.setState({open1:false})}} className="bg-danger text-white">
                                     <CancelIcon /> ยกเลิก
                            </Button>
                        </DialogActions>
          </Dialog>
      </div>
      </div>
    );
  }
}

export default index;