import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import { NavLink, withRouter,useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { TextField, InputAdornment } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}

const columnData = [
            { id: '2', align: true, disablePadding: false, label: 'ชื่อผู้ใช้ระบบ'},
            { id: '4', align: true, disablePadding: false, label: 'ชื่อ'},
            { id: '5', align: true, disablePadding: false, label: 'นามสกุล' },
            { id: '6', align: true, disablePadding: false, label: 'ประเภทผู้ใช้ระบบ' },
            { id: '8', align: true, disablePadding: true, label: 'สถานะ' },
            { id: '11', align: false, disablePadding: false, label: 'ผู้บริหาร' },
            { id: '12', align: false, disablePadding: false, label: 'ส่วนงานข้อมูล' },
            { id: '13', align: false, disablePadding: false, label: 'จัดการแผนที่' },
            { id: '14', align: false, disablePadding: false, label: 'ผู้จัดการระบบ' },
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white',fontFamily: 'Kanit'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [
        createData('เอกสารประจำการ', '', 'พลทหาร วีระ','ยก', '20/10/2020'),
        createData('เอกสารหน่วย', '', 'พลทหาร ชิด','ยก','20/10/2020'),
        createData('เอกสารทั่วไป', '',  'พลทหาร ยอด','ยก','20/10/2020'),
      ].sort((a, b) => (a.calories < b.calories ? -1 : 1)),
      page: 0,
      rowsPerPage: 5,
    };

        fetch('http://1.10.184.64:3000/users/index.php')
          .then(response => response.json())
          .then(data => {
           var   data= data.sort((a, b) => (a.user_id < b.user_id ? -1 : 1))

              this.setState({ data: data, loading: false });
          });
  }
  Admin =(event,id)=>
  {

     event.preventDefault();
        var  type=0;
            if(event.target.checked==false)
               {}
            else
              {type=1;}
                            const requestBody =
                            {
                              id : id,
                              type: type,
                            }
      const config = {
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                          }
                  }
               var check ="";
                   // if(this.state.lon!=0&&this.state.name!=null){
                const qs = require('querystring')
               axios.post('http://1.10.184.64:3000/users/updaterole.php',qs.stringify(requestBody), config)
               .then((result) => {

                       fetch('http://1.10.184.64:3000/users/index.php')
                          .then(response => response.json())
                          .then(data => {
                          var   data= data.sort((a, b) => (a.user_id < b.user_id ? -1 : 1))

                              this.setState({ data: data, loading: false });
                          });
                    })
  }
  managerMap =(event,id)=>
  {


             event.preventDefault();
             var  type=0;
            if(event.target.checked==false)
               {}
            else
              {type=4;}
               const config = {
                                          headers: {
                                            'Content-Type': 'application/x-www-form-urlencoded'
                                          }
                                  }
                 const requestBody =
                                            {
                                              id : id,
                                              type: type
                                            }
              const qs = require('querystring')
                               axios.post('http://1.10.184.64:3000/users/updaterole.php',qs.stringify(requestBody), config)
                               .then((result) => {

                                              fetch('http://1.10.184.64:3000/users/index.php')
                                                  .then(response => response.json())
                                                  .then(data => {
                                                  var   data= data.sort((a, b) => (a.user_id < b.user_id ? -1 : 1))
                                                      this.setState({ data: data, loading: false });
                                                  });
                                    })
  }
  Sectiondata =(event,id)=>
  {


     event.preventDefault();

            var  type=0;
            if(event.target.checked==false)
               {}
            else
              {type=3;}
                            const requestBody =
                            {
                              id : id,
                              type: type
                            }
      const config = {
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                          }
                  }
               var check ="";
                   // if(this.state.lon!=0&&this.state.name!=null){
                const qs = require('querystring')
               axios.post('http://1.10.184.64:3000/users/updaterole.php',qs.stringify(requestBody), config)
               .then((result) => {
                                             fetch('http://1.10.184.64:3000/users/index.php')
                                                  .then(response => response.json())
                                                  .then(data => {
                                                    var   data= data.sort((a, b) => (a.user_id < b.user_id ? -1 : 1))
                                                    this.setState({ data: data, loading: false });
                                                  });
                    })
  }
  Manager =(event,id)=>
  {

     event.preventDefault();

        var  type=0;
            if(event.target.checked==false)
               {}
            else
              {type=2;}
                            const requestBody =
                            {
                              id : id,
                              type: type
                            }
      const config = {
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                          }
                  }
               var check ="";
                   // if(this.state.lon!=0&&this.state.name!=null){
                const qs = require('querystring')
               axios.post('http://1.10.184.64:3000/users/updaterole.php',qs.stringify(requestBody), config)
               .then((result) => {
                                         fetch('http://1.10.184.64:3000/users/index.php')
                                                  .then(response => response.json())
                                                  .then(data => {
                                                    var   data= data.sort((a, b) => (a.user_id < b.user_id ? -1 : 1))
                                                      this.setState({ data: data, loading: false });
                                                  });
                    })
  }


  render() {
    const {data, order, orderBy, selected, rowsPerPage, page} = this.state;

    return (
    <div>
     <div class="page-heading d-sm-flex justify-content-sm-between align-items-sm mb-0 p-2  bg-geekblue ">
          <h5 class="title p-1 mb-sm-0"></h5>
              <nav class="mb-0" aria-label="breadcrumb">
                      <ol class="breadcrumb">
                                    <div class="breadcrumb-item">
                                        <div   style={{color: 'gray', textDecoration: 'none'}}  >
                                         การจัดการระบบ
                                        </div>
                                        <div className="pl-1 pr-1"> > </div>
                                        <div   style={{color: 'white', textDecoration: 'none'}}  >
                                         กำหนดสิทธ์ผู้ใช้งาน
                                        </div>
                                     </div>
                      </ol>
              </nav>
     </div>
    <div className="p-2 pt-3" >
    <ProfileHeader/>

    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                       <label className="ml-4 mt-4 text-white"><h3></h3></label>
                       <div className="row m-3" style={{color:'#fff'}}>
                 </div>
                 </div>

        <div className="flex-auto">
          <div className="table-responsive-material bg-geekblue border border-primary">
            <Table className="" size="small" >
              <DataTableHead

                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover

                    >

                     <TableCell align="left" style={{color:'white',fontFamily: 'Kanit'}}   >
                                {n.user_id}
                         </TableCell>
                          <TableCell align="left" style={{color:'white',fontFamily: 'Kanit'}}>
                                {n.fname}
                          </TableCell>
                           <TableCell align="left" style={{color:'white',fontFamily: 'Kanit'}}>
                                {n.lname}
                           </TableCell>
                            <TableCell align="left" style={{color:'white',fontFamily: 'Kanit'}}>

                                  {n.user_type=="1"?"ผู้จัดการระบบ":""}
                                {n.user_type=="2"?"ผู้บริหาร/หัวหน้า":""}
                                {n.user_type=="3"?"ส่วนงานข้อมูล":""}
                                {n.user_type=="4"?"จัดการแผนที่":""}
                             </TableCell>
                          <TableCell align="left" style={{color:'white',fontFamily: 'Kanit'}}>
                                {n.status=="1"?"ใช้งาน":""}
                                {n.status=="2"?"ยกเลิก":""}

                          </TableCell>
                          <TableCell align="center">
                            <div className="user-profile d-flex flex-row align-items-center">
                                 <Checkbox
                                      onChange={(event)=> {this.Manager(event,n.id)}}
                                       checked={n.user_type==2?true:false}
                                       //  onChange={(event)=>{console.log(event.target.checked+"TEST")}}
                                       // n.user_type==true?defaultChecked:''
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  />
                            </div>
                          </TableCell>
                           <TableCell align="center">
                            <div className="user-profile d-flex flex-row align-items-center">
                                 <Checkbox
                                      onChange={(event)=> {this.Sectiondata(event,n.id)}}
                                       checked={n.user_type==3?true:false}
                                       //onClick={this.Admin}

                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  />
                            </div>
                          </TableCell>
                           <TableCell align="center">

                            <div className="user-profile d-flex flex-row align-items-center">
                                 <Checkbox
                                   onChange={(event)=> {this.managerMap(event,n.id)}}
                                         checked={n.user_type==4?true:false}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  />
                            </div>
                          </TableCell>
                           <TableCell align="center">
                            <div className="user-profile d-flex flex-row align-items-center">
                                 <Checkbox
                                  onChange={(event)=> {this.Admin(event,n.id)}}
                                 checked={n.user_type==1?true:false}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  />
                            </div>
                          </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                     style={{color:'white'}}
                    count={data.length}
                         labelDisplayedRows={({ from, to, count }) =>  <div style={{ color:'white',fontFamily: 'Kanit' }}>{from}-{to} จาก {count}</div>}
                    labelRowsPerPage=<div style={{ color:'white',fontFamily: 'Kanit' }}>รายการ</div>
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>
   </div>
 </div>
 </div>
 </div>
      </div>
      </div>
    );
  }
}

export default index;