import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import { NavLink, withRouter,useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
 import Button from '@material-ui/core/Button';
 import Widget from "components/Widget/index";
import { TextField, InputAdornment } from "@material-ui/core";
 import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';

import WidgetsIcon from '@material-ui/icons/Widgets';
import WebIcon from '@material-ui/icons/Web';
import WbCloudyIcon from '@material-ui/icons/WbCloudy';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import TelegramIcon from '@material-ui/icons/Telegram';
let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}

const columnData = [
  {id: 'name', align: true, disablePadding: false, label: 'ชื่อ'},
  {id: 'calories', align: true, disablePadding: false, label: 'ไฟล์แนบ'},
  {id: 'fat', align: true, disablePadding: false, label: 'ผูใช้สร้าง'},
  {id: 'carbs', align: true, disablePadding: false, label: 'หน่ยวงาน'},
  {id: 'protein', align: true, disablePadding: false, label: 'วันที่สร้าง'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [
        createData('เอกสารประจำการ', '', 'พลทหาร วีระ','ยก', '20/10/2020'),
        createData('เอกสารหน่วย', '', 'พลทหาร ชิด','ยก','20/10/2020'),
        createData('เอกสารทั่วไป', '',  'พลทหาร ยอด','ยก','20/10/2020'),
      ].sort((a, b) => (a.calories < b.calories ? -1 : 1)),
      page: 0,
      rowsPerPage: 5,
    };
  }

  render() {
    const {data, order, orderBy, selected, rowsPerPage, page} = this.state;

    return (
       <div className="p-2 pt-3" >
            <ProfileHeader/>

            <div className="jr-profile-content ">
                <div className="">
                        <div className="row m-2">

                                                                                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                                                                         <NavLink  to={{  pathname:'/app/eventmove/',   search:'?id=1'  }}  >
                                                                                                          <Widget styleName="bg-teal text-white">
                                                                                                            <div className="row">
                                                                                                              <div className="col-1">
                                                                                                                        <WidgetsIcon  />
                                                                                                              </div>
                                                                                                              <div className="col-10" style={{fontSize:"18px"}}>
                                                                                                                        การเคลื่อนเข้าพื้นที่เกิดเหตุ
                                                                                                              </div>

                                                                                                            </div>
                                                                                                         </Widget>
                                                                                                         </NavLink>
                                                                                                      </div>
                                                                                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                                                                         <NavLink  to={{  pathname:'/app/eventBK/',   search:'?id=2'  }}  >
                                                                                                          <Widget styleName="bg-cyan text-white ">
                                                                                                           <div className="row">
                                                                                                              <div className="col-1">
                                                                                                                        <WebIcon  />
                                                                                                              </div>
                                                                                                               <div className="col-10" style={{fontSize:"18px"}}>
                                                                                                                       การเลือกที่ตั้งก่อบัญชาการเหตุการณ์
                                                                                                              </div>

                                                                                                            </div>
                                                                                                         </Widget>
                                                                                                           </NavLink>
                                                                                                      </div>
                                                                                                       <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                                                                          <NavLink  to={{  pathname:'/app/eventplan/',   search:'?id=3'  }}  >
                                                                                                          <Widget styleName="bg-green text-white">
                                                                                                            <div className="row">
                                                                                                              <div className="col-1">
                                                                                                                        <WbCloudyIcon  />
                                                                                                              </div>
                                                                                                                <div className="col-10" style={{fontSize:"18px"}}>
                                                                                                                      การวางแผนการตรวจเหตุการณ์และการหาข่าวทางยุทธวิธี
                                                                                                              </div>

                                                                                                            </div>
                                                                                                         </Widget>
                                                                                                           </NavLink>
                                                                                                      </div>
                                                                                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                                                                         <NavLink  to={{  pathname:'/app/eventnegotiate/',   search:'?id=4'  }}  >
                                                                                                          <Widget styleName="bg-warning text-white ">
                                                                                                           <div className="row">
                                                                                                              <div className="col-1">
                                                                                                                        <VpnLockIcon  />
                                                                                                              </div>
                                                                                                                <div className="col-10" style={{fontSize:"18px"}}>
                                                                                                                        การเจรจาต่อรอง
                                                                                                              </div>

                                                                                                            </div>
                                                                                                         </Widget>
                                                                                                           </NavLink>
                                                                                                      </div>
                                                                                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                                                                         <NavLink  to={{  pathname:'/app/eventnews/',   search:'?id=5'  }}  >
                                                                                                          <Widget styleName="bg-orange text-white">
                                                                                                            <div className="row">
                                                                                                              <div className="col-1">
                                                                                                                        <VisibilityIcon  />
                                                                                                              </div>
                                                                                                             <div className="col-10" style={{fontSize:"18px"}}>
                                                                                                                      การข่าวรองทางยุทธ์วิธี
                                                                                                              </div>

                                                                                                            </div>
                                                                                                         </Widget>
                                                                                                           </NavLink>
                                                                                                      </div>
                                                                                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                                                                         <NavLink  to={{  pathname:'/app/eventfavorable/',   search:'?id=6'  }}  >
                                                                                                          <Widget styleName="bg-blue text-white ">
                                                                                                           <div className="row">
                                                                                                              <div className="col-1">
                                                                                                                        <VerifiedUserIcon  />
                                                                                                              </div>
                                                                                                               <div className="col-10" style={{fontSize:"18px"}}>
                                                                                                                        การอำนวยการยุทธ์
                                                                                                              </div>

                                                                                                            </div>
                                                                                                         </Widget>
                                                                                                           </NavLink>
                                                                                                      </div>
                                                                                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                                                                         <NavLink  to={{  pathname:'/app/eventsupport/',   search:'?id=7'  }}  >
                                                                                                          <Widget styleName="bg-secondary text-white">
                                                                                                            <div className="row">
                                                                                                              <div className="col-1">
                                                                                                                        <TrendingUpIcon  />
                                                                                                              </div>
                                                                                                                <div className="col-10" style={{fontSize:"18px"}}>
                                                                                                                      การสนับสนุนการปฎิบัติ
                                                                                                              </div>

                                                                                                            </div>
                                                                                                         </Widget>
                                                                                                           </NavLink>
                                                                                                      </div>
                                                                                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                                                                         <NavLink  to={{  pathname:'/app/eventrelations/',   search:'?id=8'  }}  >
                                                                                                          <Widget styleName="bg-purple text-white ">
                                                                                                           <div className="row">
                                                                                                              <div className="col-1">
                                                                                                                        <TrackChangesIcon  />
                                                                                                              </div>
                                                                                                                <div className="col-10" style={{fontSize:"18px"}}>
                                                                                                                        การประสานหน่วยที่เกี่ยวข้อง
                                                                                                              </div>

                                                                                                            </div>
                                                                                                         </Widget>
                                                                                                           </NavLink>
                                                                                                      </div>
                                                                                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                                                                         <NavLink  to={{  pathname:'/app/manageMaster/',   search:'?id=9'  }}  >
                                                                                                          <Widget styleName="bg-sepia text-white">
                                                                                                            <div className="row">
                                                                                                              <div className="col-1">
                                                                                                                        <TimelapseIcon  />
                                                                                                              </div>
                                                                                                               <div className="col-10" style={{fontSize:"18px"}}>
                                                                                                                      การปฎิบัติการทางยุทธวิธี
                                                                                                              </div>

                                                                                                            </div>
                                                                                                         </Widget>
                                                                                                           </NavLink>
                                                                                                      </div>
                                                                                                    </div>



                </div>
            </div>
      </div>
    );
  }
}

export default index;