import React from 'react';
import UndockedDrawer from './temporary/TemporaryDrawer';

import PersistentDrawer from './persistent/PersistentDrawer';
import MiniDrawer from './mini/MiniDrawer';
import ResponsiveDrawer from './responsive/ResponsiveDrawer';
import CardBox from 'components/CardBox';
import ContainerHeader from 'components/ContainerHeader'
import IntlMessages from 'util/IntlMessages';
import qs from "query-string";
const Ctocmap = ({match}) => {

const requestBody = {
      users: 'xx', //, [CUSTOMER_CODE]
      password: 'xx', //, [CUSTOMER_CODE]
    }

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
      var ddd ="xxxx";
      const axios = require('axios')
      const qs = require('querystring')
      axios.post('http://1.10.184.64:3000/getuser.php', qs.stringify(requestBody), config)
        .then((result) => {
          // Do somthing
          console.log(result)
        })
        .catch((err) => {
          // Do somthing
          console.log(err)
        })

  return (
      <div >
          <PersistentDrawer/>
      </div>


  );
};

export default (Ctocmap);

