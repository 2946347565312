import React, {Component} from 'react';
import {NavLink, withRouter,Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SideNavOption from '../Customizer/SideNavOption';
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import StorageIcon from '@material-ui/icons/Storage';
import InboxIcon from '@material-ui/icons/Inbox';
import MapIcon from '@material-ui/icons/Map';
import CallIcon from '@material-ui/icons/Call';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DnsIcon from '@material-ui/icons/Dns';
import LaptopIcon from '@material-ui/icons/Laptop';
import PublicIcon from '@material-ui/icons/Public';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import FaceIcon from '@material-ui/icons/Face';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LanguageIcon from '@material-ui/icons/Language';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import HighlightIcon from '@material-ui/icons/Highlight';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import cookie from 'react-cookies'

import ReactFontLoader from 'react-font-loader'

class SidenavContent extends Component {
  componentDidMount() {
    const {history} = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {


      menuLi[i].onclick = function (event) {

        const parentLiEle = that.closest(this, 'li');
        if(menuLi[i].classList.contains('menu') && parentLiEle !== null) {
          event.stopPropagation();

          if(menuLi[i].classList.contains('open')) {
            menuLi[i].classList.remove('open', 'active');
          } else {
            menuLi[i].classList.add('open', 'active');
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, 'li');
            if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
              menuLi[j].classList.remove('open');
            } else {
              if(menuLi[j].classList.contains('open')) {
                menuLi[j].classList.remove('open');
              } else {
                menuLi[j].classList.add('open');
              }
            }
          }
        }
      }


    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  componentWillReceiveProps(nextProps) {
    const {history} = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      usersdata: '',
      data: '',
      type:'',
      status:'none',
      open1:'menu collapse-box'
    };

    var id_ =parseInt(cookie.load('userId'));
                fetch('http://1.10.184.64:3000/users/role.php?id='+id_)
                                        .then(response => response.json())
                                         .then(data => {

                                            //console.log(JSON.stringify(data)+"cookiecookie")
                                            if(data[0].user_type==1)
                                            {
                                                this.setState({status:''})
                                            }

                               });

     var open1 =window.location.href.includes("perpared")?"menu collapse-box open":
     window.location.href.includes("report")?"menu collapse-box open":
     window.location.href.includes("guard")?"menu collapse-box open":"menu collapse-box";

     this.setState({open1:open1})

  }

/*TYPE 1 = ADMIN*/
/*TYPE 2 = Manager*/
/*TYPE 3 = Sectiondata*/
/*TYPE 4 = MAP*/
  render() {
    return (

    <CustomScrollbars className=" scrollbar bg-primary">
    <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Kanit' />
      <div >
        <ul className="nav-menu"  >
          <li className="menu collapse-box" >
                <NavLink className="" to="/app/dashboard/crypto">
                        <span  style={{color: '#fff'}}>
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                            <span className="nav-text" style={{ fontFamily: 'Kanit' }}> สรุปสถานการณ์</span>
                        </span>
                </NavLink>
          </li>
          <li className={window.location.href.includes("perpared")?"menu collapse-box open":
          window.location.href.includes("report")?"menu collapse-box open":
          window.location.href.includes("guard")?"menu collapse-box open":
          window.location.href.includes("protection")?"menu collapse-box open":
          window.location.href.includes("provision")?"menu collapse-box open":
          window.location.href.includes("reportDaily")?"menu collapse-box open":
          window.location.href.includes("news")?"menu collapse-box open":
          window.location.href.includes("masteryk")?"menu collapse-box open":
          window.location.href.includes("strategy")?"menu collapse-box open":
          "menu collapse-box"} >

            <Button>
              <span  style={{color: '#fff'}}>
               <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                 <span style={{color: '#fff',fontFamily: 'Kanit'}}> สถานการณ์ปกติ </span>
              </span>
            </Button>
            <ul className="sub-menu">
              <li className="menu">
                    <Button href="">
                           <EmojiPeopleIcon />
                           <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> ด้านกำลังพลเดิม</span>
                    </Button>

                    <ul className="sub-menu">
                      <li>

                           <NavLink className="" to="/app/perpared/index">
                            <DescriptionIcon />
                                    <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> การเตรียมพร้อม</span>
                           </NavLink>
                      </li>
                      <li>

                           <NavLink className="m-0" to="/app/report/index">
                             <PersonAddIcon />
                                    <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> กำลังพลประจำวัน</span>
                           </NavLink>
                      </li>
                      <li>

                           <NavLink className="" to="/app/guard/index">
                            <HighlightIcon />
                                    <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> เวรรักษาการณ์</span>
                           </NavLink>
                      </li>
                    </ul>
              </li>
              <li className="menu">
                    <Button href="">
                           <EmojiPeopleIcon />
                           <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> ด้านกำลังพล</span>
                    </Button>

                    <ul className="sub-menu">
                      <li>

                           <NavLink className="" to="/app/provision/index">
                            <DescriptionIcon />
                                    <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> การเตรียมพร้อม</span>
                           </NavLink>
                      </li>
                      <li>
                           <NavLink className="m-0" to="/app/reportDaily/index">
                             <PersonAddIcon />
                                    <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> กำลังพลประจำวัน</span>
                           </NavLink>
                      </li>

                      <li>
                           <NavLink className="" to="/app/protection/index">
                            <HighlightIcon />
                                    <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> เวรรักษาการณ์</span>
                           </NavLink>
                      </li>
                    </ul>
              </li>
			  <li>
                <NavLink className="prepend-icon" to="/app/news">
                    <AnnouncementIcon />
                  <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> ด้านการข่าว</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/newslast">
                    <AnnouncementIcon />
                  <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> ด้านการข่าวเดิม</span>
                </NavLink>
              </li>
               <li>
                <NavLink className="prepend-icon" to="/app/strategy">
                  <RecordVoiceOverIcon />
                  <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> ด้านยุทธการ</span>
                </NavLink>
              </li>
         

               <li>
                <NavLink className="prepend-icon" to="/app/mastertn">
                 <SportsKabaddiIcon />
                  <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> ด้านการฝึก</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/masterkb">
                  <AssistantPhotoIcon />
                  <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> ด้านส่งกำลังบำรุง</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/masterkr">
                 <FaceIcon />
                  <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> ด้านกิจการพลเรือน</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/masterss">
                  <PhoneInTalkIcon />
                  <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> ด้านสื่อสารและสารสนเทศ</span>
                </NavLink>
              </li>
            </ul>


          </li>
          <li className={window.location.href.includes("map_main")?"menu collapse-box open":window.location.href.includes("map_event")?"menu collapse-box open":window.location.href.includes("updatemap")?"menu collapse-box open":"menu collapse-box"} >
            <Button>
              <span  style={{color: '#fff'}}>
               <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                 <span style={{color: '#fff',fontFamily: 'Kanit'}}> เมื่อเกิดเหตุ</span>
              </span>
            </Button>
            <ul className="sub-menu">
              <li className="menu">
            <Button>
               <MapIcon />
                <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> <IntlMessages id="แผนที่สถานการณ์"/></span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink to="/app/map_main/index" className="prepend-icon m-0"  >
                        <MapIcon />
                      <span className="nav-text ml-1" style={{color: '#fff',fontFamily: 'Kanit'}}>เหตุการณ์</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/map_event" className="prepend-icon"  >
                        <InboxIcon />
                        <span className="nav-text ml-1" style={{color: '#fff',fontFamily: 'Kanit'}}>สร้างเหตุการณ์</span>
                </NavLink>
              </li>
            </ul>
          </li>













            </ul>
          </li>

          <li className="menu collapse-box" style={{display:this.state.status}}>
            <Button>
              <span  style={{color: '#fff'}}>
               <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                 <span style={{color: '#fff',fontFamily: 'Kanit'}}> การจัดการระบบ</span>
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/tableusers">
                  <PersonOutlineIcon />
                  <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> ผู้ใช้</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/usersconfig">
                  <PersonOutlineIcon />
                  <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> กำหนดสิทธ์ผู้ใช้งาน</span>
                </NavLink>
              </li>
               <li>
                <NavLink className="prepend-icon" to="/app/log">
                 <EqualizerIcon />
                  <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> สถิติการเข้าใช้ระบบ</span>
                </NavLink>
              </li>
              <li>
                 <NavLink className="prepend-icon" to="/app/database">
                    <LaptopIcon />
                  <span className="nav-text" style={{color: '#fff',fontFamily: 'Kanit'}}> ระบบฐานข้อมูล</span>
                </NavLink>
              </li>


              <li className="menu no-arrow"  >
                  <a target="_blank" href="http://1.10.184.64:8080/geoserver/web/" >
                       <div  style={{color: ''}}>
                          <PublicIcon />
                              <span className="nav-text m-2" style={{color: '#fff',fontFamily: 'Kanit'}}>
                               <IntlMessages id="เผยแพร่ WMS"/>
                          </span>
                       </div>
                  </a>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box" style={{display:this.state.status}}>
            <Button>
              <span  style={{color: '#fff'}}>
               <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                 <span style={{color: '#fff',fontFamily: 'Kanit'}}> บริหารข้อมูลแผนที่</span>
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                 <NavLink className="prepend-icon" to="/app/datamap/index">
                   <MapIcon />
                  <span className="nav-text"> ข้อมูลอาคาร</span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
        </div>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
