import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
 import Button from '@material-ui/core/Button';
import { TextField, InputAdornment } from "@material-ui/core";
import { NavLink, withRouter,useHistory } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
 import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import cookie from 'react-cookies';
let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}
var url = 'http://1.10.184.64:3000';
const columnData = [
  {id: 'name', align: true, disablePadding: false, label: 'ชื่อ'},
  {id: 'path', align: true, disablePadding: false, label: 'ไฟล์แนบ'},
  {id: 'createby', align: true, disablePadding: false, label: 'ผูใช้สร้าง'},
  {id: 'dept', align: true, disablePadding: false, label: 'ส่วน'},
  {id: 'dunit', align: true, disablePadding: false, label: 'หน่ยวงาน'},
  {id: 'createdate', align: true, disablePadding: false, label: 'วันที่สร้าง'},
   {id: 'datetime', align: true, disablePadding: false, label: 'ดำเนินการ'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [].sort((a, b) => (a.calories < b.calories ? -1 : 1)),
      page: 0,
      rowsPerPage: 5,
      pageid:'',
      name:'',
      type:'',
      path:'',
      depts:[],
      dept:'',
      dunits:[],
      dunit:'',
      vdunits:[],
      users:[],
      selectedFile:'',
      pageid:'',
      id:'',
      lastname:'',
      delid:''
    };
       this._upload = this._upload.bind(this);
       var location = window.location.href;
        const str = '' + location;
        const xid = str.split("=")[1].split("&&")[0];


       fetch('http://1.10.184.64:3000/users/dept.php')
                                     .then(response => response.json())
                                     .then(data => {
                                     data.push({id:0,name:'เลือกรายการ'})
                                           this.setState({depts:data});
          });
      fetch('http://1.10.184.64:3000/users/index.php')
                                     .then(response => response.json())
                                     .then(data => {
                                     data.push({id:0,name:'เลือกรายการ'})
                                           this.setState({users:data});

          fetch(url+'/basemaster/select.php')
            .then(response => response.json())
           .then(data => {
             var id = parseInt(xid);
             var _data = data.filter(x=>x.flat=='0'&&x.type==id)
             this.setState({ data: _data,pageid:xid });
          });

        });

           fetch(url+'/users/dunit.php')
                                                 .then(response => response.json())
                                                 .then(data => {
                                                  data.push({id:0,name:'เลือกรายการ'})
                                                  this.setState({dunits:data});
                      });
  }
  submit =()=>
    {

     console.log(this.state.id+"_D_E_E_W_")
     if(this.state.id=='')
     {

        this.upload()

       const qs = require('querystring')
       const config =
       {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
       }
        var id_ =parseInt(cookie.load('userId'));
          const requestBody =
            {
                id: '',
                name: this.state.name,
                path: this.state.path+"."+this.state.lastname,
                dunit:this.state.dunit,
                dept:this.state.dept,
                type: this.state.pageid.trim(),
                c_by: id_
            }
             axios.post(url+'/basemaster/insert.php', qs.stringify(requestBody), config)
                .then((result) =>
                {
                    fetch(url+'/basemaster/select.php')
                        .then(response => response.json())
                       .then(data => {
                         var _data = data.filter(x=>x.flat=='0'&&x.type.trim()==this.state.pageid)

                          this.setState({
                                  data: _data,
                                  id:'',
                                  name:'',
                                  type:'',
                                  path:'',
                                  dept:'',
                                  dunit:''
                                  });
                      });
                });
       }
       else
       {


        this.upload()
               const qs = require('querystring')
               const config =
               {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
               }
                var id_ =parseInt(cookie.load('userId'));
                  const requestBody =
                    {
                        id: this.state.id,
                        name: this.state.name,
                        dunit:this.state.dunit,
                        dept:this.state.dept,
                        type: this.state.pageid.trim(),
                        m_by: id_
                    }
                     axios.post(url+'/basemaster/update.php', qs.stringify(requestBody), config)
                        .then((result) =>
                        {
                            fetch(url+'/basemaster/select.php')
                                .then(response => response.json())
                               .then(data => {
                                 var _data = data.filter(x=>x.flat=='0'&&x.type.trim()==this.state.pageid)

                                 this.setState({
                                  data: _data,
                                  id:'',
                                  name:'',
                                  type:'',
                                  path:'',
                                  dept:'',
                                  dunit:''
                                  });
                              });
                        });



       }
    }

    _name =(event) =>
   {
      this.setState({name:event.target.value })
   }
    _dunit =(event) =>
   {
      this.setState({dunit:event.target.value })
   }
   _upload(event) {

      var name = new Date().toLocaleString().replace(',','').replaceAll(':','').replaceAll(' ','')
        this.setState({
            selectedFile: event.target.files[0],
            path:'uploads/file/manage/'+this.state.name,
            lastname:event.target.value.split('.')[1]
        })


    }

    upload()
    {

        const data = new FormData()
        data.append('file', this.state.selectedFile)
        let url = "http://1.10.184.64:3000/uploads/manage.php?id="+this.state.name;
        axios.post(url, data,
             {
                 //this.setState({ path:'uploads/file/master/'+this.state.name+''});
             })
            .then(res =>
            {

            })


    }

    select_dept = (event)=>
    {
                   var vdunits = this.state.dunits.filter(x=>x.p_id==event.target.value);
                   this.setState({vdunits :vdunits,dept:event.target.value})
    }
     handleDownload = (url, filename) => {
             axios({
                  url: url,
                  method: 'GET',
                  responseType: 'blob', // important
                }).then((response) => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', filename);
                  document.body.appendChild(link);
                  link.click();
                });

    }
    _del =()=>
    {

             const qs = require('querystring')
               const config =
               {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
               }
            const requestBody =
            {
                id: this.state.delid,
            }
           axios.post(url+'/basemaster/delete.php', qs.stringify(requestBody), config)
                .then((result) =>
                {
                    fetch(url+'/basemaster/select.php')
                        .then(response => response.json())
                        .then(_data => {

                           var data  = _data.filter(x=>x.flat=='0'&&x.type==2).sort((a, b) => (a.id > b.id ? -1 : 1))
                           this.setState({data: data,open1:false});
                      });
                });
    }
    edite =(id)=>{

                   fetch(url+'/basemaster/find.php?id='+id)
                            .then(response => response.json())
                            .then(data => {
                                   //console.log(data[0].period + "dataid");
                                  this.setState({
                                  id:data[0].id,
                                  name:data[0].name,
                                  type:data[0].type,
                                  path:data[0].path,
                                  dept:data[0].dept,
                                  dunit:data[0].dunit
                                  });

                                  var dept = data[0].dept;
                                    fetch(url+'/users/dunit.php')
                                                 .then(response => response.json())
                                                 .then(data => {
                                                 data.push({id:0,name:'เลือกรายการ'})
                                                 var vdunits = data.filter(x=>x.p_id==dept);
                                                  this.setState({vdunits:vdunits});
                                        });


                             });

    }

  render() {
    const {data, order, orderBy, selected, rowsPerPage, page} = this.state;
   //  var location = window.location.href;
     //                               const str = '' + location;
       //                             const pageid = str.split("=")[1];


            let __depts = this.state.depts;
                    let deptItems = __depts.map((dept) =>
                       <MenuItem    key={dept.id} value={dept.id}>
                       <span className="ml-2"> {dept.name}</span></MenuItem>
            );
            let dunits = this.state.vdunits;
                    let dunitsItems = dunits.map((dunit) =>
                       <MenuItem selected="{this.state.dunit}"
                         key={dunit.id} value={dunit.id}>
                       <span className="ml-2"> {dunit.name}</span></MenuItem>
                );
    return (


    <div>
    <div class="page-heading d-sm-flex justify-content-sm-between align-items-sm mb-0 p-2  bg-geekblue ">
          <h5 class="title p-1 mb-sm-0"></h5>
              <nav class="mb-0" aria-label="breadcrumb">
                      <ol class="breadcrumb">
                                     <div class="breadcrumb-item">
                                        <div   style={{color: 'gray', textDecoration: 'none'}}  >
                                         การเตรียมพร้อมรองรับสถานการณ์
                                        </div>
                                        <div className="pl-1 pr-1"> > </div>
                                         <NavLink   style={{color: 'primary', textDecoration: 'none'}} to={{ pathname:'/app/tableactionmap/data' }} >
                                          แผนที่สถานการณ์
                                        </NavLink>
                                        <div className="pl-1 pr-1"> > </div>



                                          {
                                            this.state.pageid<40?
                                         <NavLink   style={{color: 'primary', textDecoration: 'none'}} to={{ pathname:'/app/tableactionmap/data/manage' }} >
                                          บริหารเหตุการณ์
                                         </NavLink>
                                         : <NavLink   style={{color: 'primary', textDecoration: 'none'}} to={{ pathname:'/app/tableactionmap/data/baseMaster' }} >
                                          เตรียมพร้อมสถานการณ์
                                         </NavLink>
                                          }

                                        <div className="pl-1 pr-1"> > </div>
                                        <div   className="text-white" style={{color: 'white', textDecoration: 'none'}}  >
                                          {this.state.pageid==21?'การเคลื่อนย้ายเข้าพื้นที่เกิดเหตุ':""}
                                          {this.state.pageid==22?'การเลือกที่ตั้งกองบัญชาการเหตุการณ์':""}
                                          {this.state.pageid==23?'การวางแผนการตรวจการณ์และการหาข่าวทางยุทธวิธี':""}
                                          {this.state.pageid==24?'การเจรจาต่อรอง':""}
                                          {this.state.pageid==25?'การข่าวรองทางยุทธวิธี':""}
                                          {this.state.pageid==26?'การอำนวยการยุกธ์':""}
                                          {this.state.pageid==27?'การสนับสนุนการปฎิบัติ':""}
                                          {this.state.pageid==28?'การประสานหน่วยที่เกี่ยวข้อง':""}
                                          {this.state.pageid==29?'การปฎิบัติการทางยุทธวิธี':""}
                                          {this.state.pageid==30?'การประชาสัมพันธ์ในสภาวะวิกฤต':""}
                                          {this.state.pageid==31?'การรายงานการปฎิบัติ':""}


                                          {this.state.pageid==41?'การกำลังพล':""}
                                          {this.state.pageid==42?'การข่าวกรอง':""}
                                          {this.state.pageid==43?'การยุทธการและการฝึก':""}
                                          {this.state.pageid==44?'การส่งกำลังบำรุง':""}
                                          {this.state.pageid==45?'การกิจการพลเรือน':""}
                                          {this.state.pageid==46?'การสื่อสารสารสนเทศ':""}
                                          {this.state.pageid==47?'การสนับสนุนการปฎิบัติ':""}
                                          {this.state.pageid==48?'การประสานหน่วยที่เกี่ยวข้อง':""}
                                          {this.state.pageid==49?'การปฎิบัติการทางยุทธวิธี':""}

                                        </div>
                                     </div>
                      </ol>
              </nav>
    </div>
    <div className="p-2 pt-3" >
    <ProfileHeader/>
    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                       <label className="ml-4 mt-4 text-white">

                       </label>
                            <div className="row ml-3 mr-3 mb-3" style={{color:'#fff'}}>
                                   <div className="col-md-6 col-12 mb-3">
                                   <label className="">ชื่อ</label>
                                       <div className="">
                                            <TextField
                                            id="fname"
                                            margin="normal"
                                            fullWidth
                                            onChange={this._name}
                                            value={this.state.name}
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    ,backgroundColor:"#3f51b5"
                                                    }
                                                }}
                                              style={{margin:0}}
                                              InputProps={{

                                                  style: {
                                                        height: "40px"
                                                        ,width: "100%"
                                                         ,backgroundColor:"#3f51b5"
                                                            ,color:"#fff"

                                                    }
                                                }}
                                        />
                                       </div>
                                   </div>
                                   <div className="col-md-6 col-12">
                                   <label className="">ส่วน</label>
                                       <div className="bg-primary">
                                            <TextField
                                                                        select
                                                                        required
                                                                        id="user_no"
                                                                        value={this.state.dept}
                                                                        onChange={this.select_dept}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                            InputProps={{
                                                                                style: {
                                                                                    height: "40px"
                                                                                }
                                                                            }}
                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">

                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {

                                                                                    height: "40px"
                                                                                     ,width: "100%"
                                                                                     ,color:"#fff"

                                                                                }
                                                                            }}
                                                                    >
                                                                  {deptItems}
                                             </TextField>
                                       </div>
                                   </div>
                                   <div className="col-md-6 col-12">
                                   <label className="">หน่ยวงาน</label>
                                       <div className="bg-primary">
                                            <TextField
                                                                        select
                                                                        required
                                                                        id="user_no"
                                                                        value={this.state.dunit}
                                                                        onChange={this._dunit}
                                                                        defaultValue=""
                                                                        margin="normal"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                            InputProps={{
                                                                                style: {
                                                                                    height: "40px"
                                                                                }
                                                                            }}
                                                                          style={{margin:0}}
                                                                             InputProps={{
                                                                              startAdornment: (
                                                                                <InputAdornment className="mr-1">

                                                                                </InputAdornment>

                                                                              ),
                                                                              style: {

                                                                                    height: "40px"
                                                                                     ,width: "100%"
                                                                                     ,color:"#fff"

                                                                                }
                                                                            }}

                                                                    >
                                                                  {dunitsItems}
                                            </TextField>
                                       </div>
                                   </div>
                                   <div className="col-md-6 col-12">
                                   <label>ไฟล์แนบที่เกี่ยวข้อง</label>
                                       <div >
                                            <TextField
                                                            type="file"
                                                            accept="image/*"
                                                            id="raised-button-file"
                                                            multiple

                                                            onChange={this._upload}
                                                            required
                                                            id="fname"

                                                            defaultValue=""
                                                            margin="normal"
                                                            fullWidth
                                                            variant="outlined"
                                                            InputProps={{
                                                                style: {
                                                                    height: "40px"
                                                                }
                                                            }}
                                                            style={{ margin: 0 }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start" >
                                                                    </InputAdornment>
                                                                ),
                                                                style: {
                                                                    height: "40px"
                                                                    , color: "#fff"
                                                                }
                                                            }}
                                            />
                                       </div>
                                   </div>
                            </div>

                   <div className="row m-3" style={{color:'#fff'}}>
                     <Button   className="bg-blue text-white mt-0 ml-3" onClick={()=>this.submit()}>
                          <SaveIcon/>
                          <span className="ml-1">
                                บันทึก
                          </span>
                     </Button>
            </div>
        </div>

                    <div className="flex-auto">
                      <div className="table-responsive-material bg-geekblue border border-primary">
                        <Table className="" size="small" >
                          <DataTableHead

                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={data.length}
                          />
                          <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                              const isSelected = this.isSelected(n.id);
                              return (
                                <TableRow   hover >
                                  <TableCell style={{color:'white'}} >{n.name}</TableCell>
                                  <TableCell style={{color:'white'}} >

                                   {n.path.trim()=='.undefined'?'':
                                          <Button   className="bg-blue text-white mt-0 ml-3"
                                                onClick={() => {this.handleDownload(url+'/'+n.path, n.path.split('/')[n.path.split('/').length-1].trim() )}}>
                                                <InsertDriveFileIcon />
                                            </Button>
                                    }
                                  </TableCell>
                                  <TableCell style={{color:'white'}} >
                                   {
                                     this.state.users.filter(x => x.id == n.c_by).length != 0 ?
                                     this.state.users.filter(x => x.id == n.c_by)[0].fname :""
                                   }
                                  </TableCell>
                                  <TableCell style={{color:'white'}} >
                                  {
                                     this.state.depts.filter(x => x.id == n.dept).length != 0 ?
                                     this.state.depts.filter(x => x.id == n.dept)[0].name :""
                                  }
                                  </TableCell>
                                  <TableCell style={{color:'white'}} >
                                   {
                                     this.state.dunits.filter(x => x.id == n.dunit).length != 0 ?
                                     this.state.dunits.filter(x => x.id == n.dunit)[0].name :""
                                   }

                                  </TableCell>
                                  <TableCell style={{color:'white'}} >
                                   {
                                           n.c_dt==null?'':
                                           n.c_dt.substring(8,10)+n.c_dt.split('T')[1].split('.')[0].replaceAll(':','').substring(0,4)+' '
                                        }
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="01"?"ม.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="02"?"ก.พ.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="03"?"มี.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="04"?"เม.ย.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="05"?"พ.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="06"?"มิ.ย.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="07"?"ก.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="08"?"ส.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="09"?"ก.ย.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="10"?"ต.ค.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="11"?"พ.ย.":""}
                                        { n.c_dt==null?'':n.c_dt.substring(5,7)=="12"?"ธ.ค.":""}
                                        {
                                            n.c_dt==null?'':" "+String((parseInt(n.c_dt.substring(0,4))+543)).substring(2,4)
                                        }
                                  </TableCell>
                                  <TableCell style={{color:'white',width:'200px'}} >
                                       <div className="row">
                                                 <Button   className="bg-blue text-white mt-0 ml-3" onClick={()=>this.edite(n.id)}>
                                                                <EditIcon />
                                                 </Button>

                                            <Button   className="bg-blue text-white mt-0 ml-3" onClick={()=>this.setState({delid:n.id,open1:true})} >
                                                <DeleteIcon/>
                                            </Button>
                                       </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                 style={{color:'white'}}
                                count={data.length}
                                  labelDisplayedRows={({ from, to, count }) => ` ${from}-${to} จาก ${count}`}
                                                                                     labelRowsPerPage='รายการ'
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                              />
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog fullWidth={true}
                        maxWidth={'xs'} open={this.state.open1} onClose={this.handleRequestClose}>
                        <DialogContent className="bg-geekblue text-white"  >
                            <InfoIcon className="text-primary " />   ยืนยันการลบ.
                        <hr />
                        </DialogContent>
                        <DialogActions className="bg-geekblue">
                            <Button onClick={this._del} className="bg-success text-white">
                                    <CheckCircleIcon /> ใช่
                            </Button>

                            <Button onClick={()=>{this.setState({open1:false})}} className="bg-danger text-white">
                                     <CancelIcon /> ยกเลิก
                            </Button>
                        </DialogActions>
                </Dialog>
      </div>
      </div>
    );
  }
}

export default index;