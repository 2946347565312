import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import Dashboard from './routes/dashboard';
import Widgets from './routes/widgets'
import Metrics from './routes/metrics'
import Components from './routes/components';
import Icons from './routes/icons/index';
import Form from './routes/form';
import Editors from './routes/editors';
import Pickers from './routes/pickers';
import Extensions from './routes/extensions';
import Table from './routes/table';
import Chart from './routes/charts';
import Map from './routes/map';
import Calendar from './routes/calendar';
import TimeLine from './routes/timeLine';
import CustomViews from './routes/customViews';
import ExtraElements from './routes/extraElements'
import eCommerce from './routes/eCommerce'
import AppModule from './routes/appModule'
import ExtraPages from './routes/extraPages';
import Tour from '../components/Tour/index';
import FormUsers from './routes/usersform/index';
import TableUsers from './routes/users/index';
import FormActionPlan from './routes/actionPlan/form';
import TableActionPlan from './routes/actionPlan/table';
import FormActionMap from './routes/actionMap/form';
import TableActionMap from './routes/actionMap/table';
import actionConfig from './routes/actionConfig/index';

import usersconfig from './routes/usersconfig/index';
import Report from './routes/actionReport/index';
import Reporttable from './routes/actionReport/index';
import Ctocmap from './routes/ctocmap';
import map_event from './routes/map_event';
import reportmap from './routes/reportmap';
import master from './routes/master/index';
import mastertn from './routes/mastertn/index';
import masterkp from './routes/masterkp/index';
import fmasterkp from './routes/masterkp/form';
import masterss from './routes/masterss/index';
import masterkr from './routes/masterkr/index';
import masterkv from './routes/masterkv/index';
import fmasterkv from './routes/masterkv/form';
import fmastertn from './routes/mastertn/form';
import fmasteryk from './routes/masteryk/form';
import fmasterkb from './routes/masterkb/form';
import fmasterkr from './routes/masterkr/form';
import fmasterss from './routes/masterss/form';
import masterkb from './routes/masterkb/index';
import manageMaster from './routes/manageMaster/index';
import baseMaster from './routes/baseMaster/index';
import masteryk from './routes/masteryk/index';
import manage from './routes/manage/index';
import log from './routes/log/index';
import database from './routes/database/index';
import conclude from './routes/conclude/index';
import updatemap from './routes/updatemap';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import SocialApps from "./routes/socialApps";
import ColorOption from 'containers/Customizer/ColorOption';
import eventmove from './routes/eventmove/index';
import eventBK from './routes/eventBK/index';
import eventnegotiate from './routes/eventnegotiate';
import eventnews from './routes/eventnews/index';
import feventnews from './routes/eventnews/form';
import eventfavorable from './routes/eventfavorable';
import eventsupport from './routes/eventsupport/index';
import feventsupport from './routes/eventsupport/form';
import eventcoordinate from './routes/eventcoordinate';
import eventrelations from './routes/eventrelations';
import eventoperational from './routes/eventoperational/index';
import feventoperational from './routes/eventoperational/form';
import eventcritical from './routes/eventcritical';
import eventreport from './routes/eventreport';
import eventplan from './routes/eventplan/index';
import feventplan from './routes/eventplan/form';
import feventrelations from './routes/eventrelations/form';
import eventcontact from './routes/eventcontact/index';
import feventcontact from './routes/eventcontact/form';
import baseEvent from './routes/baseEvent';
import news from './routes/news';
import newslast from './routes/newslast';
import createnewslast from './routes/createnewslast';
import detailnews from './routes/detailnews';
import changepassword from './routes/changepassword';
import manageMap from './routes/manageMap/index';
import fmanageMap from './routes/manageMap/form';
import fdatamap from './routes/datamap/form';
import datamap from './routes/datamap/index';
import fcommandant from './routes/commandant/form';
import commandant from './routes/commandant/index';
import fmfile from './routes/mfile/form';
import mfile from './routes/mfile/index';
import fduty from './routes/duty/form';
import duty from './routes/duty/index';
import farmy_monthy from './routes/army_monthy/form';
import army_monthy from './routes/army_monthy/index';
import perpared from './routes/perpared/index';
import report from './routes/report/index';
import guard from './routes/guard/index';
import map_main from './routes/map_main/index';
import reportDaily from './routes/reportDaily/index';
import provision from './routes/provision/index';
import protection from './routes/protection/index';
import strategy from './routes/strategy';


class App extends React.Component {

  render() {
    const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Tour/>

        <Sidebar/>
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
            <TopNav styleName="app-top-header"/>}
            <Header/>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
            <TopNav/>}
          </div>
          <main className="app-main-content-wrapper">
            <div className="app-main-content bg-geekblue">
              <Switch>

                <Route path={`${match.url}/strategy`} component={strategy}/>
                <Route path={`${match.url}/protection/index`} component={protection}/>
                <Route path={`${match.url}/provision/index`} component={provision}/>
                <Route path={`${match.url}/reportDaily/index`} component={reportDaily}/>
                <Route path={`${match.url}/map_main/index`} component={map_main}/>
                <Route path={`${match.url}/guard/index`} component={guard}/>
				<Route path={`${match.url}/report/index`} component={report}/>
			    <Route path={`${match.url}/perpared/index`} component={perpared}/>
                <Route path={`${match.url}/datamap/index`} component={datamap}/>
                <Route path={`${match.url}/datamap/form`} component={fdatamap}/>
                <Route path={`${match.url}/tableactionmap/data/manageMap`} component={manageMap}/>
                <Route path={`${match.url}/tableactionmap/data/fmanageMap`} component={fmanageMap}/>
		        <Route path={`${match.url}/changepassword`} component={changepassword}/>
                <Route path={`${match.url}/eventoperational/feventoperational`} component={feventoperational}/>
                <Route path={`${match.url}/eventcontact/feventcontact`} component={feventcontact}/>
                <Route path={`${match.url}/eventsupport/feventsupport`} component={feventsupport}/>
                <Route path={`${match.url}/eventrelations/feventrelations`} component={feventrelations}/>
                <Route path={`${match.url}/eventplan/feventplan`} component={feventplan}/>
                <Route path={`${match.url}/eventnews/feventnews`} component={feventnews}/>
                <Route path={`${match.url}/masterss/fmasterss`} component={fmasterss}/>
                <Route path={`${match.url}/masterkr/fmasterkr`} component={fmasterkr}/>
                <Route path={`${match.url}/masterkb/fmasterkb`} component={fmasterkb}/>
                <Route path={`${match.url}/mastertn/fmastertn`} component={fmastertn}/>
                <Route path={`${match.url}/fmasterkp`} component={fmasterkp}/>
			  	<Route path={`${match.url}/masterkv/fmasterkv`} component={fmasterkv}/>
			  	<Route path={`${match.url}/masteryk/fmasteryk`} component={fmasteryk}/>
			    <Route path={`${match.url}/createnewslast`} component={createnewslast}/>
			    <Route path={`${match.url}/eventcontact`} component={eventcontact}/>
			    <Route path={`${match.url}/detailnews`} component={detailnews}/>
                <Route path={`${match.url}/news`} component={news}/>
			    <Route path={`${match.url}/newslast`} component={newslast}/>

                <Route path={`${match.url}/database`} component={database}/>
                <Route path={`${match.url}/reportmap`} component={reportmap}/>
                <Route path={`${match.url}/conclude`} component={conclude}/>
                <Route path={`${match.url}/log`} component={log}/>
                <Route path={`${match.url}/tableactionmap/data/baseMaster`} component={baseMaster}/>
                 <Route path={`${match.url}/baseEvent`} component={baseEvent}/>
                //---ติดตามสถานณ์การณ์
		        <Route path={`${match.url}/mastertn`} component={mastertn}/>
                <Route path={`${match.url}/masterkp`} component={masterkp}/>
                <Route path={`${match.url}/masterss`} component={masterss}/>
                <Route path={`${match.url}/masterkr`} component={masterkr}/>
                <Route path={`${match.url}/masterkv`} component={masterkv}/>
                <Route path={`${match.url}/masterkb`} component={masterkb}/>
                <Route path={`${match.url}/masteryk`} component={masteryk}/>
                //---บริหารเหตุกาณ์
                <Route path={`${match.url}/eventmove`} component={eventmove}/>
                <Route path={`${match.url}/eventBK`} component={eventBK} />
                <Route path={`${match.url}/eventplan`} component={eventplan} />
                <Route path={`${match.url}/eventnegotiate`} component={eventnegotiate} />
                <Route path={`${match.url}/eventnews`} component={eventnews} />
                <Route path={`${match.url}/eventfavorable`} component={eventfavorable} />
                <Route path={`${match.url}/eventsupport`} component={eventsupport} />
                <Route path={`${match.url}/eventcoordinate`} component={eventcoordinate} />
                <Route path={`${match.url}/eventrelations`} component={eventrelations} />
                <Route path={`${match.url}/eventoperational`} component={eventoperational} />
                <Route path={`${match.url}/eventcritical`} component={eventcritical} />
                <Route path={`${match.url}/eventreport`} component={eventreport} />

                <Route path={`${match.url}/usersconfig`} component={usersconfig}/>
                <Route path={`${match.url}/tableactionmap/data/manageMaster`} component={manageMaster}/>
                <Route path={`${match.url}/tableactionmap/data/manage`} component={manage}/>
                <Route path={`${match.url}/map_event`} component={map_event}/>
                <Route path={`${match.url}/updatemap`} component={updatemap}/>
                <Route path={`${match.url}/report`} component={Report}/>
                <Route path={`${match.url}/reporttable`} component={Reporttable}/>
                <Route path={`${match.url}/config`} component={actionConfig}/>
                <Route path={`${match.url}/tableactionmap`} component={TableActionMap}/>
                <Route path={`${match.url}/formactionmap`} component={FormActionMap}/>
                <Route path={`${match.url}/tableactionplan`} component={TableActionPlan}/>
                <Route path={`${match.url}/formactionplan`} component={FormActionPlan}/>
                 <Route path={`${match.url}/tableusers/formusers`} component={FormUsers}/>
                <Route path={`${match.url}/tableusers`} component={TableUsers}/>

                <Route path={`${match.url}/dashboard`} component={Dashboard}/>
                 <Route path={`${match.url}/ctocmap`} component={Ctocmap}/>
                <Route path={`${match.url}/social-apps`} component={SocialApps}/>
                <Route path={`${match.url}/components`} component={Components}/>
                <Route path={`${match.url}/icons`} component={Icons}/>
                <Route path={`${match.url}/form`} component={Form}/>
                <Route path={`${match.url}/editor`} component={Editors}/>
                <Route path={`${match.url}/pickers`} component={Pickers}/>
                <Route path={`${match.url}/extensions`} component={Extensions}/>
                <Route path={`${match.url}/table`} component={Table}/>
                <Route path={`${match.url}/chart`} component={Chart}/>
                <Route path={`${match.url}/map`} component={Map}/>
                <Route path={`${match.url}/calendar`} component={Calendar}/>
                <Route path={`${match.url}/time-line`} component={TimeLine}/>
                <Route path={`${match.url}/custom-views`} component={CustomViews}/>

                <Route path={`${match.url}/widgets`} component={Widgets}/>
                <Route path={`${match.url}/metrics`} component={Metrics}/>
                <Route path={`${match.url}/extra-elements`} component={ExtraElements}/>
                <Route path={`${match.url}/ecommerce`} component={eCommerce}/>
                <Route path={`${match.url}/app-module`} component={AppModule}/>
                <Route path={`${match.url}/to-do`}
                       component={asyncComponent(() => import('./routes/todo/basic/index'))}/>
                <Route path={`${match.url}/to-do-redux`}
                       component={asyncComponent(() => import('./routes/todo/redux/index'))}/>
                <Route path={`${match.url}/mail`}
                       component={asyncComponent(() => import('./routes/mail/basic/index'))}/>
                <Route path={`${match.url}/mail-redux`}
                       component={asyncComponent(() => import('./routes/mail/redux/index'))}/>
                <Route path={`${match.url}/chat`}
                       component={asyncComponent(() => import('./routes/chatPanel/basic/index'))}/>
                <Route path={`${match.url}/chat-redux`}
                       component={asyncComponent(() => import('./routes/chatPanel/redux/index'))}/>
                <Route path={`${match.url}/contact`}
                       component={asyncComponent(() => import('./routes/contact/basic/index'))}/>
                <Route path={`${match.url}/contact-redux`}
                       component={asyncComponent(() => import('./routes/contact/redux/index'))}/>
                <Route path={`${match.url}/extra-pages`} component={ExtraPages}/>
                <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
              </Switch>
            </div>

          </main>
        </div>

      </div>
    );
  }
}


const mapStateToProps = ({settings}) => {
  const {drawerType, navigationStyle, horizontalNavPosition} = settings;
  return {drawerType, navigationStyle, horizontalNavPosition}
};
export default withRouter(connect(mapStateToProps)(App));