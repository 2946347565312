import React from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
const ProfileHeader = () => {
  return (
    <div className="jr-profile-banner pt-0" style={{backgroundColor:'#3f51b5'}}>
    </div>
  )
}

export default ProfileHeader;

