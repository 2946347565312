import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';


const Form = ({match}) => (
  <div className="">
    <Switch>

      <Redirect exact from={`${match.url}/`} to={`${match.url}/provision`}/>
      <Route path={`${match.url}/response`} component={asyncComponent(() => import('./routes/response'))}/>
      <Route path={`${match.url}/response_form`} component={asyncComponent(() => import('./routes/response_form'))}/>
      <Route path={`${match.url}/index_army`} component={asyncComponent(() => import('./routes/index_army'))}/>
      <Route path={`${match.url}/form_army`} component={asyncComponent(() => import('./routes/form_army'))}/>
      <Route path={`${match.url}/call_telephone`} component={asyncComponent(() => import('./routes/call_telephone'))}/>
      <Route path={`${match.url}/fmfile`} component={asyncComponent(() => import('./routes/mfile/form'))}/>
      <Route path={`${match.url}/provision`} component={asyncComponent(() => import('./routes/provision/index'))}/>
      <Route path={`${match.url}/mfile`} component={asyncComponent(() => import('./routes/mfile'))}/>
      <Route path={`${match.url}/duty`} component={asyncComponent(() => import('./routes/duty'))}/>
      <Route path={`${match.url}/army_monthy`} component={asyncComponent(() => import('./routes/army_monthy'))}/>
      <Route path={`${match.url}/commandant`} component={asyncComponent(() => import('./routes/commandant'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Form;
