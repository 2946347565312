import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../../util/asyncComponent';


const Table = ({match}) => (
  <div>
  <div class="page-heading d-sm-flex justify-content-sm-between align-items-sm mb-0  p-2 bg-geekblue ">
                      <h5 class="title p-1 mb-sm-0"></h5>
                          <nav class="mb-0" aria-label="breadcrumb">
                                  <ol class="breadcrumb">
                                                <div class="breadcrumb-item">
                                                    <div   style={{color: 'gray', textDecoration: 'none'}}  >
                                                     การบริหารเหตุการณ์
                                                    </div>
                                                    <div className="pl-1 pr-1"> > </div>
                                                    <div   style={{color: 'white', textDecoration: 'none'}}  >
                                                     แผนที่สถานการณ์
                                                    </div>
                                                 </div>
                                  </ol>
                          </nav>
     </div>
   <div className="ml-2 mr-2 mt-3">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/data`}/>
      <Route path={`${match.url}/data`} component={asyncComponent(() => import('./routes/data/index'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
  </div>
);

export default Table;
