
import React from 'react';
import axios from "axios";
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'util/IntlMessages';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import About from "components/profile/About/index";
import Biography from "components/profile/Biography/index";
import Events from "components/profile/Events/index";
import Contact from "components/profile/Contact/index";
import {Link, Router, Route, hashHistory} from "react-router";
import { NavLink, withRouter,useHistory } from 'react-router-dom';
import Friends from "components/profile/Friends/index";
import Photos from "components/profile/Photos/index";
import ProfileHeader from "../Profile/ProfileHeader/index";
import Auxiliary from "../../../../../util/Auxiliary";
import {friendList} from '../Profile/Profile/data'
import {photoList} from "../Profile/Wall/data";
import FullScreen from '../../dropzone/FullScreen/index';

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GroupIcon from '@material-ui/icons/Group';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import GetAppIcon from '@material-ui/icons/GetApp';
import CallIcon from '@material-ui/icons/Call';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import ContainerHeader from 'components/ContainerHeader/index';
import PoolIcon from '@material-ui/icons/Pool';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import LanguageIcon from '@material-ui/icons/Language';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ImageIcon from '@material-ui/icons/Image';
import Draw from '@urbica/react-map-gl-draw';
import ReactMapGL, {Marker,WMSTile ,LinearInterpolator,NavigationControl, FlyToInterpolator,WebMercatorViewport,InteractiveMap } from 'react-map-gl';
import InfoIcon from '@material-ui/icons/Info';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import RoomIcon from '@material-ui/icons/Room';
import {convertToRaw, EditorState} from 'draft-js';

import {mapEditor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {json as requestJson} from 'd3-request';
import  './mapbox-gl.css';
import  './mapbox-gl-draw.css';
import {dataLayer} from './map-style.js';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {updatePercentiles} from './utils';
import {
  Editor,
  EditingMode,
  DrawLineStringMode,
  DrawPolygonMode,
   DrawPointMode,
} from "react-map-gl-draw";

import markerIcon from "./Google_Maps_pin.svg";
import ReactMapboxGl, { Layer,Feature,Source } from 'react-mapbox-gl';
import {useDropzone} from "react-dropzone";
function handleClick(map, event) {

  console.log("sss");
    }
    const DEFAULT_VIEWPORT = {
      width: 400,
      height: 400,
      longitude: -122.45,
      latitude: 37.78,
      zoom: 14,
    };


    const thumbsContainer = {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 16
    };

    const thumb = {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: 'border-box'
    };

    const thumbInner = {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden'
    };

    const img = {
      display: 'block',
      width: 'auto',
      height: '100%'
    };



  function TabContainer(props) {
          return (
            <div style={{padding: 0}}>
              {props.children}
            </div>
          );
        }

        TabContainer.propTypes = {
          children: PropTypes.node.isRequired,
        };


    const Map = ReactMapboxGl({
      accessToken:
        ''
    });


class TextFields extends React.Component {


      constructor(props, context) {
      super(props, context);
      this.state = {
      classNameM:"d-none",
      markers:[],
      province: [],
      provinceGS: [],
      district:[],
      subdistrict:[],
      images: [],
       id: props.id,
        _content: props.content,
        handleWYSIWYGInput: props.handleWYSIWYGInput,
        _editor: ClassicEditor,
      lat:0,
      data:null,
      lon:0,
      maplng: 101.490104,
     maplat: 13.03887,
     mapzoom: 5,
      value: 0,
         transformOriginHorizontal: 'center',
        viewport: DEFAULT_VIEWPORT,
      modeId: "DrawPointMode",
      modeHandler: new EditingMode,
      startlang: [[-122.4, 37.7], [-122.5, 37.8]],
      viewport: {
                      latitude: 13.03887,
                      longitude: 101.490104,
                      zoom: 8,
                      bearing: 0,
                      pitch: 0,
                      width: '100%',
                      height: 400,

                    }
      };
      fetch('http://1.10.184.64:3000/province/index.php')
          .then(response => response.json())
          .then(data => {
              this.setState({ province: data, loading: false });
          });
      }



  _renderToolbar = () => {
    return (
      <div  style={{ position: "absolute", top: 0, right: 0, maxWidth: "320px" }}>
      </div>
    );
  };

  componentDidMount () {

    window.addEventListener('resize', this.onResize)

  }




  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };


      handleChange = (event, value) => {
        this.setState({value});
      };



        fun_district = (value) => {

         const {longitude, latitude, zoom} = new WebMercatorViewport(this.state.viewport)
            .fitBounds([[-122.4, 37.7], [-122.5, 37.8]], {
              padding: 20,
              offset: [0, -100]
            });
             const viewport = {
            ...this.state.viewport,
            longitude,
            latitude,
            zoom,
            transitionDuration: 500,
            transitionInterpolator: new FlyToInterpolator(),
          }
          this.setState({viewport});
      };

     onBlur(evt) {
        console.log('onBlur event called with event info: ', evt);
      }

      afterPaste(evt) {
        console.log('afterPaste event called with event info: ', evt);
      }
       CKEonChange(evt) {
        const newContent = evt.editor.getData();

        this.setState({
          topography: newContent
        })
      }
           _onViewportChange = viewport => {
        this.setState({viewport});
    };
    _goToSF = () => {
        const {longitude, latitude, zoom} = new WebMercatorViewport(this.state.viewport)
            .fitBounds([[-122.4, 37.7], [-122.5, 37.8]], {
              padding: 20,
              offset: [0, -100]
            });
        const viewport = {
            ...this.state.viewport,
            longitude,
            latitude,
            zoom,
            transitionDuration: 5000,
            transitionInterpolator: new FlyToInterpolator(),

        }
        this.setState({viewport});
    };


     maphandleChange  = (event, value) => {
     console.log("ss");

  };
      _goToNYC = (event) => {
        //console.log("eeeeeeeewwwwwww");
        const viewport = {
            ...this.state.viewport,
            longitude: -74.1,
            latitude: 40.7,
            zoom: 14,
            transitionDuration: 5000,
            transitionInterpolator: new FlyToInterpolator(),

        };
        this.setState({viewport});
    };

              _resize = () => this._onViewportChange({
                width: this.props.width || window.innerWidth,
                height: this.props.height || window.innerHeight
              });

              _goToProvince = (event) => {
                      var ddddd= ""+event.target.value;

                        if(ddddd!=""){
                             this.setState({province_code: ddddd });
                              }



                              fetch('http://1.10.184.64:3000/province/get.php?id=' + ddddd)
                                .then(response => response.json())
                                .then(data => {

                                    this.setState({mapzoom:8,maplng:data[0].x,maplat: data[0].y });

                                });

                              fetch('http://1.10.184.64:3000/district/index.php?id='+ddddd)
                            .then(response => response.json())
                                 .then(data => {
                             this.setState({ district: data, loading: false });
                         });



              };


             _goToDistrict = (event) => {


                           var ddddd= ""+event.target.value;
                               if(ddddd!=""){
                                 this.setState({district_code: ddddd });
                                 }
                           fetch('http://1.10.184.64:3000/district/get.php?id=' + ddddd)
                                .then(response => response.json())
                                .then(data => {


                                    this.setState({mapzoom:10,maplng:data[0].x,maplat: data[0].y });

                                });


                       fetch('http://1.10.184.64:3000/subdistrict/index.php?id='+ddddd)
                         .then(response => response.json())
                         .then(data => {
                             this.setState({ subdistrict: data, loading: false });
                         });


              };

              _goToSubDistrict = (event) => {
                           var ddddd= ""+event.target.value;
                           if(ddddd!=""){
                                 this.setState({sub_district_code: ddddd });
                                 }

                           fetch('http://1.10.184.64:3000/subdistrict/get.php?id=' + ddddd)
                                .then(response => response.json())
                                .then(data => {
                                    this.setState({mapzoom:12,maplng:data[0].x,maplat: data[0].y });
                                });

              };

              componentDidMount() {

                window.addEventListener('resize', this.resize)
                this.resize()
              }

              componentWillUnmount() {
                window.removeEventListener('resize', this.resize)
              }

              resize = () => {
                this.handleViewportChange({
                  width: "100%",
                  height: "450px"
                })
              }

              handleViewportChange = (viewport) => {
                this.setState({
                  viewport: { ...this.state.viewport, ...viewport }
                })
              }




                 B_goToViewport = ({longitude, latitude}) => {



                this._onViewportChange({
                  longitude,
                  latitude,
                  zoom: 4,
                  transitionInterpolator: new FlyToInterpolator(),

                });
              };

             handleRequestClose =()=>{
             this.setState({open2:false,open:false})
             }
           handleClickmap = (e) => {


                this.setState({
                pointData:e,
                lat:e[0] ,
                lon:e[1],
                 modeHandler: new DrawPointMode,
                 modeId: "DrawPointMode",
                 })

             console.log(this.state.lat+"wwww"+this.state.lon);

             };
      _name = event => {this.setState({name: event.target.value }); };
      _lname = event => {this.setState({lname: event.target.value }); };
      _p_type = event => {this.setState({p_type: event.target.value }); };
      _p_form = event => {this.setState({p_form: event.target.value }); };
      _topography = event => {this.setState({topography: event.target.value }); };
      _population = event => {this.setState({population: event.target.value }); };
      _dominance = event => {this.setState({dominance: event.target.value }); };
      _religion = event => {this.setState({religion: event.target.value }); };
      _way_land = event => {this.setState({way_land: event.target.value }); };
      _way_water = event => {this.setState({way_water: event.target.value }); };
      _img_build = event => {this.setState({img_build: event.target.value }); };
      _img_in = event => {this.setState({img_in: event.target.value }); };
      _img_out = event => {this.setState({img_out: event.target.value }); };


      _plan_status = event => {this.setState({plan_status: event.target.value }); };
             Save = event => {

   console.log(this.state.province_code+"eeeexxxxxxxeee");
    event.preventDefault();
    const requestBody = {


      id : this.state.id,
      geom: this.state.lon+","+this.state.lat,
      name: this.state.name,
      lname: this.state.lname,
      p_type: this.state.p_type,
      p_form: this.state.p_form,
      topography: this.state.topography,
      population: this.state.population,
      dominance: this.state.dominance,
      religion: this.state.religion,
      way_land: this.state.way_land,
      way_water: this.state.way_water,
      way_water: this.state.way_water,
      img_build: this.state.img_build,
      img_in: this.state.img_in,
      img_out: this.state.img_out,
      province_code: this.state.province_code,
      district_code: this.state.district_code,
      sub_district_code: this.state.sub_district_code,
      plan_status: this.state.plan_status,
      }
      if(this.state.id==null)
      {
      const config = {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  }
          }

          if(this.state.lon==0&&this.state.name==null){

          this.setState({open2: true});
          }
            if(this.state.lon!=0&&this.state.name!=null){

       const qs = require('querystring')
        axios.post('http://1.10.184.64:3000/actionplan/insert.php',qs.stringify(requestBody), config)
        .then((result) => {
          // Do somthing
          const name = ""+qs.stringify(result);
          const splitName = name.split("failed");
          const length = splitName.length;
          console.log(length);
        if(length==1){
         this.setState({open: true});
        }
        if(length!=1){
         this.setState({open2: true});
        }
        })
        .catch((err) => {
         this.setState({open2: true});
          console.log(err)
        })
        }
          }
    }
      _renderTooltip() {
    const {hoveredFeature, x, y} = this.state;

    return (
      hoveredFeature && (
        <div className="tooltip" style={{left: x, top: y}}>
          <div>State: {hoveredFeature.properties.name}</div>
          <div>Median Household Income: {hoveredFeature.properties.value}</div>
          <div>Percentile: {(hoveredFeature.properties.percentile / 8) * 100}</div>
        </div>
      )
    );
  }
   _onViewportChange = viewport => this.setState({viewport});

    _updateSettings = (name, value) => {
    //if (name === 'year') {
      this.setState({year: value});

      const {data} = this.state;
      if (data) {
        // trigger update
        this.setState({
          data: updatePercentiles(data, f => f.properties.id)
        });
      }
    //}
  };



  componentDidMount() {

    requestJson(
      'http://1.10.184.64:8080/geoserver/ctoc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ctoc%3Aprovince&maxFeatures=50&outputFormat=application%2Fjson',
      (error, response) => {
        if (!error) {
          this._loadData(response);
        }
      }
    );

  }
  _loadData = data => {

    this.setState({
      data: updatePercentiles(data, f => f.properties.id)
    });
  };
   MaphandleClick = (map, event)=> {
  var lngLat = event.lngLat;
    console.log(this.map.getZoom());

    this.setState({maplng:lngLat.lng, maplat:lngLat.lat,  lat:lngLat.lng, lon:lngLat.lat,mapzoom:this.map.getZoom()})


}
         onClickHandler = () => {
           const data = new FormData()
           console.log(data+"xddddddddddd");
           data.append('file', this.state.selectedFile)
           axios.post("http://1.10.184.64:3000/static/media", data, {
              // receive two    parameter endpoint url ,form data
          })
          .then(res => { // then print response status
            console.log(res.statusText)
         })
        }

     handleCapture = ({ target }) => {
      console.log(target.files[0]);
        const fileReader = new FileReader();
        const name = target.accept.includes('image') ? 'images' : 'videos';

        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            this.setState((prevState) => ({
                [name]: [...prevState[name], e.target.result]
            }));
        };
    };

 render() {

   const {
   mapzoom,transformOriginVertical,
   maplng,maplat, data,lon,lat,value,province,viewport,
   classNameM,modeHandler, settings,startlang,subdistrict,
   district,markers} = this.state;

    const image = new Image(30, 40);
    image.src = markerIcon;
    const images = ['customImage', image];

            const PROVINCE_SOURCE_OPTIONS = {
              "type": "raster",
              "tiles": [
                'http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=ctoc:province'
              ],

              "scheme": "tms",
              "tileSize": 256,
            };

              const DISTRICT_SOURCE_OPTIONS = {
              "type": "raster",
              "tiles": [
                'http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=ctoc:district'
              ],

              "scheme": "tms",
              "tileSize": 256,
            };

              const SUBDISTRICE_SOURCE_OPTIONS = {
              "type": "raster",
              "tiles": [
                'http://1.10.184.64:8080/geoserver/ctoc/wms?bbox={bbox-epsg-3857}&transparent=true&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=ctoc:subdistrict'
              ],

              "scheme": "tms",
              "tileSize": 256,
            };



              let provinces = this.state.province;
        let provinceItems = provinces.map((province) =>
          <MenuItem selected="{this.state.province}"    key={province.pro_c} value={province.pro_c}>{province.on_pro_tha}</MenuItem>
        );

        let _district = this.state.district;
        let districtItems = district.map((_district) =>
          <MenuItem selected="{this.state.district}"    key={_district.dis_c} value={_district.dis_c}>{_district.on_dis_tha}</MenuItem>
        );


        let _subdistrict = this.state.subdistrict;
        let subdistrictItems = subdistrict.map((_subdistrict) =>
          <MenuItem selected="{this.state.subdistrict}"    key={_subdistrict.id} value={_subdistrict.id}>{_subdistrict.on_sub_tha}</MenuItem>
        );

          return (
     <div>


    <div className="">
       <Auxiliary>
       <ProfileHeader />

        <form className="" noValidate autoComplete="off" onSubmit={this.handleSubmit}>
        <div className="jr-profile-content">
          <div className="jr-card jr-card-widget jr-card-full jr-card-tabs-right jr-card-profile card bg-geekblue border border-primary">
           <div className="m-2"   >




                <Map

                         ref={(c) => this._map = c}
                         onStyleLoad={ el => this.map = el }
                          style={{
                                  "version": 8,
                                  "name": "OSM",
                                  "metadata": {

                                  },
                                  "sources": {

                                    "osm": {
                                      "type": "raster",
                                      "tiles": [
                                        "http://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                      ]
                                    }

                                  },

                                  "layers": [
                                    {
                                      "id": "osm",
                                      "type": "raster",
                                      "source": "osm"
                                    }
                                  ],
                                  "id": "klokantech-basic"
                                }}
                          zoom={[mapzoom]}
                          center={[maplng, maplat]}

                          containerStyle={{ height:"450px" ,width: this.props.width >= 1200 ? 'calc(100%)' : 'calc(100%)'}}
                      onClick={this.MaphandleClick}
                >
                <Source id="province" tileJsonSource={PROVINCE_SOURCE_OPTIONS} />
                   <Layer type="raster" id="1" sourceId="province" />
                    <Source id="districe" tileJsonSource={DISTRICT_SOURCE_OPTIONS} />
                   <Layer type="raster" id="2" sourceId="districe" />
                   <Source id="subdistrice" tileJsonSource={SUBDISTRICE_SOURCE_OPTIONS} />
                   <Layer type="raster" id="3" sourceId="subdistrice" />



                <Layer
        type="symbol"
        id="marker"
        layout={{
        "icon-image": "customImage",
        "icon-allow-overlap": false
        }}
        images={images}
    >
       <Feature coordinates={[lat,lon]}/>
    </Layer>



              </Map>


           </div>
         <div>

 <div className="jr-tabs jr-task-list pt-0 pr-2 pl-2">
        <AppBar className="bg-primary" position="static" className="mt-2">
          <Tabs value={value} onChange={this.maphandleChange}  variant="scrollable" scrollButtons="on">
            <Tab   className={this.state.value===0 ? "tab bg-red" :"tab"} icon={<InfoIcon/>} label="ข้อมูล"/>
            <Tab className={this.state.value===1 ? "tab bg-red" :"tab"} icon={<ImageIcon/>}  label="รูปภาพ"/>
            <Tab className={this.state.value===2 ? "tab bg-red" :"tab"} icon={<InsertDriveFileIcon/>}  label="เอกสาร"/>
          </Tabs>
        </AppBar>
        {value === 0 &&
        <TabContainer>
        <div className="row">


        <div className="col-md-6 col-12">
                             <label className="mb-0 mt-3" style={{color:'white'}}>จังหวัด</label>
                             <div className="bg-primary">
                                      <TextField
                                            select
                                            required
                                            id="user_no"

                                             value={this.state.id_religion}
                                            onChange={this._goToProvince}
                                            defaultValue=""
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    }
                                                }}
                                              style={{margin:0}}
                                                 InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment className="mr-1">
                                                      <LanguageIcon position="start" />
                                                    </InputAdornment>

                                                  ),
                                                  style: {
                                                        height: "40px"
                                                         ,color:"#fff"

                                                    }
                                                }}

                                        >

                                         <MenuItem value={0}>---</MenuItem>
                                          {provinceItems}
                                        </TextField>
                            </div>
         </div>

         <div className="col-md-6 col-12">
                             <label className="mb-0 mt-3" style={{color:'white'}}>อำเภอ</label>
                             <div className="bg-primary">
                                      <TextField
                                            select
                                            required
                                            id="user_no"
                                            onChange={this._religion}
                                            value={this.state._religion}
                                            onChange={this._goToDistrict}
                                            defaultValue=""
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    }
                                                }}
                                              style={{margin:0}}
                                                 InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment className="mr-1">
                                                      <LanguageIcon position="start" />
                                                    </InputAdornment>

                                                  ),
                                                  style: {
                                                        height: "40px"
                                                         ,color:"#fff"

                                                    }
                                                }}

                                        >

                                         <MenuItem value={0}>---</MenuItem>
                                          {districtItems}
                                        </TextField>
                            </div>
            </div>
               <div className="col-md-6 col-12">
                             <label className="mb-0 mt-3" style={{color:'white'}}>ตำบล</label>
                             <div className="bg-primary">

                               <TextField
                                            select
                                            required
                                            id="user_no"
                                            onChange={this._goToSubDistrict}
                                             value={this.state._religion}

                                            defaultValue=""
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    }
                                                }}
                                              style={{margin:0}}
                                                 InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment className="mr-1">
                                                      <LanguageIcon position="start" />
                                                    </InputAdornment>

                                                  ),
                                                  style: {
                                                        height: "40px"
                                                         ,color:"#fff"


                                                    }
                                                }}

                                        >

                                         <MenuItem value={0}>---</MenuItem>
                                          {subdistrictItems}
                                        </TextField>

                            </div>
                    </div>


<div className="col-md-6 col-12">
                     <label className="mb-0 mt-3" style={{color:'white'}}>สถานะของแผนงาน</label>
                                  <div className="bg-primary">
                                            <TextField
                                            select
                                            required
                                            id="user_no"
                                            onChange={this._plan_status}
                                                value={this.state._religion}

                                            defaultValue=""
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: "40px"
                                                    }
                                                }}
                                              style={{margin:0}}
                                                 InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment className="mr-1">
                                                      <LanguageIcon position="start" />
                                                    </InputAdornment>

                                                  ),
                                                  style: {
                                                        height: "40px"
                                                         ,color:"#fff"

                                                    }
                                                }}

                                        >
                                         <MenuItem value={1}>ใช้งาน</MenuItem>
                                         <MenuItem value={2}>ยกเลิก</MenuItem>
                                        </TextField>
                                      </div>
                       </div>
                    <div className="col-md-12 col-12">
                <label className="mb-0 mt-3" style={{color:'white'}}>ชื่อสถานที่/พื้นที่</label>
           <div className="bg-primary">


                <TextField

                    required
                    id="fname"
                    onChange={this._name}
                    value={this.state.name}

                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                            }
                        }}
                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                              <LocationCityIcon height="40px" />
                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                 , color:"#fff"

                            }
                        }}
                />
            </div>
        </div>

           <div className="col-md-6 col-12">
             <label className="mb-0 mt-3" style={{color:'white'}}>ประเภทการก่อการร้าย</label>
            <div className="bg-primary">
              <TextField
                    select
                    required
                    id="user_no"
                    onChange={this._p_type}
                        value={this.state._religion}

                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                            }
                        }}
                      style={{margin:0}}
                         InputProps={{
                          startAdornment: (
                            <InputAdornment className="mr-1">
                              <AccountBalanceIcon position="start" />
                            </InputAdornment>

                          ),
                          style: {
                                height: "40px"
                                 ,color:"#fff"

                            }
                        }}

                >

                 <MenuItem value={1}> ชาตินิยม </MenuItem>
                 <MenuItem value={2}>ทางศาสนา</MenuItem>
                 <MenuItem value={3}>สนับสนุนจากรัฐ</MenuItem>
                 <MenuItem value={4}>ฝ่ายซ้ายจัด</MenuItem>
                  <MenuItem value={5}>ฝ่ายขวาจัด</MenuItem>
                 <MenuItem value={6}>อนาธิปไตย</MenuItem>
                 <MenuItem value={7}>การก่อการร้ายอื่น ๆ</MenuItem>
                </TextField>

            </div>
            </div>

                 <div className="col-md-6 col-12">
             <label className="mb-0 mt-3" style={{color:'white'}}>รูปแบบการก่อการร้าย</label>
            <div className="bg-primary">
              <TextField
                    select
                    required
                    id="user_no"
                    onChange={this._p_form}
                        value={this.state._religion}

                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                            }
                        }}
                      style={{margin:0}}
                         InputProps={{
                          startAdornment: (
                            <InputAdornment className="mr-1">
                              <AccountBalanceIcon position="start" />
                            </InputAdornment>

                          ),
                          style: {
                                height: "40px"
                                 ,color:"#fff"

                            }
                        }}

                >

                 <MenuItem value={1}>กำลังนักรบกลุ่มใหญ่</MenuItem>
                 <MenuItem value={2}>กำลังนักรบขนาดเล็ก</MenuItem>
                 <MenuItem value={3}>ก่อเหตุเพียงคนเดียว</MenuItem>
                 <MenuItem value={4}>วินาศกรรมวางระเบิด</MenuItem>
                  <MenuItem value={5}>ลักพาตัวประกัน</MenuItem>

                </TextField>



            </div>
            </div>



            <div className="col-md-12 col-12">
             <label className="mb-0 mt-3" style={{color:'white'}}>ลักษณะภูมิประเทศ</label>


        <div className="App">

                <CKEditor
                    editor={ this.state._editor }
                    data={this.state._content+"<p></p><p></p><p></p><p></p><p></p>"}
                    config={{ckfinder: {
                      // Upload the images to the server using the CKFinder QuickUpload command.
                      uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json'
                    }}}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //this.state.handleWYSIWYGInput(this.props.id, data);
                        console.log( { event, editor, data } );
                        console.log(this.state.content);
                    } }
                    onBlur={ editor => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ editor => {
                        console.log( 'Focus.', editor );
                    } }
                />
            </div>
    </div>

    <div className="col-md-6 col-12">
             <label className="mb-0 mt-3" style={{color:'white'}}>ประชาชน</label>

          <div className="bg-primary">


                 <TextField

                     multiline={true}
                      rows={5}
                   onChange={this._population}

                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                                 , color:"#fff"
                            }
                        }}
                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                               <AccessibilityNewIcon height="40px" />
                            </InputAdornment>
                          ),
                          style: {

                                  color:"#fff"
                            }
                        }}
                />


            </div>
      </div>


      <div className="col-md-6 col-12">
             <label className="mb-0 mt-3" style={{color:'white'}}>การปกครอง</label>

          <div className="bg-primary">

                 <TextField

                     multiline={true}
                      rows={5}
                   onChange={this._dominance}

                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                                 , color:"#fff"
                            }
                        }}
                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                              <AccessibilityNewIcon height="40px" />
                            </InputAdornment>
                          ),
                          style: {

                                  color:"#fff"
                            }
                        }}
                />
            </div>
      </div>
  <div className="col-md-6 col-12">
             <label className="mb-0 mt-3" style={{color:'white'}}>ศาสนา</label>
  <div className="bg-primary">

               <TextField

                     multiline={true}
                      rows={5}
                   onChange={this._religion}

                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                                 , color:"#fff"
                            }
                        }}
                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                               <AccountBalanceIcon height="40px" />
                            </InputAdornment>
                          ),
                          style: {

                                  color:"#fff"
                            }
                        }}
                />



            </div>
            </div>






            <div className="col-md-6 col-12">
               <label className="mb-0 mt-3" style={{color:'white'}}>การคมนาคมทางบก</label>

                <div className="bg-primary">

                   <TextField

                     multiline={true}
                      rows={5}
                   onChange={this._way_land}

                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                                 , color:"#fff"
                            }
                        }}
                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                               <DirectionsWalkIcon height="40px" />
                            </InputAdornment>
                          ),
                          style: {

                                  color:"#fff"
                            }
                        }}
                />



          </div>
            </div>
               <div className="col-md-6 col-12">
                     <label className="mb-0 mt-3" style={{color:'white'}}>การคมนาคมทางน้ำ</label>
                                  <div className="bg-primary">

                                  <TextField

                     multiline={true}
                      rows={5}
                   onChange={this._way_water}

                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                                 , color:"#fff"
                            }
                        }}
                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                               <PoolIcon height="40px" />
                            </InputAdornment>
                          ),
                          style: {

                                  color:"#fff"
                            }
                        }}
                />



                                      </div>
                       </div>

     </div>

        </TabContainer>}
        {value === 1 &&
        <TabContainer>
        <div className="row">

        <div className="col-md-12 col-12">
                <label className="mb-0 mt-3" style={{color:'white'}}>Path รูปผังอาคาร</label>
           <div className="bg-primary">
                <TextField
                   type="file"
                      accept="image/*"

                      id="raised-button-file"
                      multiple
                    required
                    id="fname"
                    onChange={this._img_build}
                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                            }
                        }}
                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                 , color:"#fff"
                            }
                        }}
                />
            </div>
        </div>
        <div className="col-md-12 col-12">
                <label className="mb-0 mt-3" style={{color:'white'}}>Path รูปลักษณะทางเข้าออก (ปกติ)</label>
           <div className="bg-primary">
                <TextField
                 type="file"
                    required
                    id="fname"
                    onChange={this._img_in}


                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                            }
                        }}
                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >

                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                 , color:"#fff"

                            }
                        }}
                />
            </div>
        </div>
        <div className="col-md-12 col-12">
                <label className="mb-0 mt-3" style={{color:'white'}}>Path รูปลักษณะทางเข้าออก (อาคารจอดรถ)</label>
           <div className="bg-primary">


                <TextField
                 type="file"
                    required
                    id="fname"
                    onChange={this._img_out}


                    defaultValue=""
                    margin="normal"
                    fullWidth
                    variant="outlined"
                        InputProps={{
                            style: {
                                height: "40px"
                            }
                        }}
                      style={{margin:0}}
                      InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >

                            </InputAdornment>
                          ),
                          style: {
                                height: "40px"
                                 , color:"#fff"

                            }
                        }}
                />
            </div>
        </div>
        </div>
        </TabContainer>}
        {value === 2 &&
        <TabContainer>
              <div className="col-md-2 col-12">

                     <label className="mb-0 mt-3" style={{color:'white'}}>แนบเอกสาร</label>
                                  <div className="">
                         <FullScreen  />
                               </div>
                      </div>
                        <div className="col-md-10 col-12">
                     <label className="mb-0 mt-3" style={{color:'white'}}></label>
             </div>
        </TabContainer>}
                 <div className="mt-3 mb-2" onClick={this.Save} >
                <Button    variant="contained" className="bg-blue text-white"   >
                    บันทึก
                </Button>
            </div>

            </div>
          </div>
            </div>
          </div>

       </form>

        <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        message="บันทึกสำเร็จ"
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={this.state.open2}
        autoHideDuration={6000}
        message="บันทึกไม่สำเร็จ"
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
      />
      </Auxiliary>
</div>
</div>
    );
  }
}


export default TextFields;
