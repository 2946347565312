import React, {Component} from 'react';
import {GoogleMap, withGoogleMap,} from 'react-google-maps';
import MapGL,{ NavigationControl }   from '@urbica/react-map-gl';
import Draw from '@urbica/react-map-gl-draw';
import  './mapbox-gl.css';
import  './mapbox-gl-draw.css';
/*
 * Sample From: https://developers.google.com/maps/documentation/javascript/examples/map-simple
 */



/*
 * Add <script src="https://maps.googleapis.com/maps/api/js"></script> to your HTML to provide google.maps reference
 */

export default class SimpleMap extends Component {


  render() {


    return (

      <MapGL
     style={{  height:'100%', width: '100%' }}
    mapStyle='https://s3.amazonaws.com/cdn.brianbancroft.io/assets/osmstyle.json'
    accessToken=''
    latitude={37.78}
    longitude={-122.41}
    zoom={11}
  >

<NavigationControl showCompass showZoom position='top-right'/>
  <Draw position='top-right' />
  </MapGL>
    );
  }
}