import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import axios from "axios";
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { TextField, InputAdornment } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import { Container, Row, Col, Label,Card,Left } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ProfileHeader from "./ProfileHeader/index";
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import cookie from 'react-cookies';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
let counter = 0;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {id: counter, name, calories, fat, carbs, protein};
}
 var url = 'http://1.10.184.64:3000';
 var urlupload = 'http://1.10.184.64:3000';
const columnData = [
  {id: 'name', align: true, disablePadding: false, label: 'ชื่อ'},
  {id: 'path', align: true, disablePadding: false, label: 'ไฟล์แนบ'},
  {id: 'createby', align: true, disablePadding: false, label: 'ผูใช้สร้าง'},
  {id: 'dept', align: true, disablePadding: false, label: 'ส่วน'},
  {id: 'dunit', align: true, disablePadding: false, label: 'หน่ยวงาน'},
  {id: 'createdate', align: true, disablePadding: false, label: 'วันที่สร้าง'},
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead className="bg-primary" >
        <TableRow>

          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                style={{color:'white'}}
                align={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


let DataTableToolbar = props => {
  const {numSelected} = props;

  return (
    <Toolbar className="table-header bg-geekblue border border-primary">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer"/>
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


class index extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({selected: this.state.data.map(n => n.id)});
      return;
    }
    this.setState({selected: []});
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };
  handleChangePage = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 5,
      last:'',
      password:'',
      confirm:'',
      open:false,
      openmsg:false

    };

  }
    submit =()=>
    {

    const qs = require('querystring')
                           const config =
                           {
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    }
                           }
        var _id =parseInt(cookie.load('userId'));

                         const requestBody =
                        {
                            id: _id,
                            user_pw: this.state.last,

                        }
                        axios.post(url+'/users/requestpassword.php', qs.stringify(requestBody), config)
                        .then((result) =>
                        {
                             console.log(result.data)
                             if(result.data!='0'){

                                         if(this.state.password==this.state.confirm && this.state.password!="")
                                               {

                                                                var _id =parseInt(cookie.load('userId'));

                                                                 const requestBody =
                                                                {
                                                                    id: _id,
                                                                    user_pw: this.state.password,

                                                                }
                                                                axios.post(url+'/users/changepassword.php', qs.stringify(requestBody), config)
                                                                .then((result) =>
                                                                {

                                                                           this.setState({openmsg:true})
                                                                });

                                                                /*const requestBody =
                                                                {
                                                                    id: _id,
                                                                    user_pw: this.state.password,

                                                                }
                                                                axios.post(url+'/users/changepassword.php', qs.stringify(requestBody), config)
                                                                .then((result) =>
                                                                {
                                                                           this.setState({openmsg:true})
                                                                });*/
                                               }
                                               else
                                               {
                                                  this.setState({open:true})
                                               }


                             }else{
                                this.setState({open:true})
                             }


                        });


    }
  _password =(event) =>
   {
      this.setState({password:event.target.value })
   }
    _confirm =(event) =>
   {
      this.setState({confirm:event.target.value })
   }
    _last =(event) =>
   {
      this.setState({last:event.target.value })
   }



  render() {

    return (
    <div className="p-2 pt-3" >
    <ProfileHeader/>

    <div className="jr-profile-content ">
        <div className="">
            <div className="" Style={{marginTop:"-50px"}}  >
                <div className="jr-tabs-content jr-task-list">
                     <div className=" bg-geekblue  border border-primary"  >
                       <label className="ml-4 mt-4 text-white"><h3>เปลี่ยน Password</h3></label>
                            <div className="row ml-3 mr-3 mb-3" style={{color:'#fff'}}>
                                  <div className="col-md-6 col-12 mb-3">
                                        <label className="">Password เก่า</label>
                                           <div className="">
                                                <TextField
                                                id="fname"
                                                margin="normal"
                                                type="password"
                                                fullWidth
                                                onChange={this._last}
                                                value={this.state.last}
                                                variant="outlined"
                                                    InputProps={{
                                                        style: {
                                                            height: "40px"
                                                        ,backgroundColor:"#3f51b5"
                                                        }
                                                    }}
                                                  style={{margin:0}}
                                                  InputProps={{

                                                      style: {
                                                            height: "40px"
                                                            ,width: "100%"
                                                             ,backgroundColor:"#3f51b5"
                                                                ,color:"#fff"

                                                        }
                                                    }}
                                            />
                                           </div>
                                 </div>
                                 <div className="col-md-6 col-12">

                                 </div>
                                  <div className="col-md-6 col-12 mb-3">
                                        <label className="">New Password</label>
                                           <div className="">
                                                <TextField
                                                id="fname"
                                                margin="normal"
                                                type="password"
                                                fullWidth
                                                onChange={this._password}
                                                value={this.state.password}
                                                variant="outlined"
                                                    InputProps={{
                                                        style: {
                                                            height: "40px"
                                                        ,backgroundColor:"#3f51b5"
                                                        }
                                                    }}
                                                  style={{margin:0}}
                                                  InputProps={{

                                                      style: {
                                                            height: "40px"
                                                            ,width: "100%"
                                                             ,backgroundColor:"#3f51b5"
                                                                ,color:"#fff"

                                                        }
                                                    }}
                                            />
                                           </div>
                                 </div>
                                 <div className="col-md-6 col-12">

                                 </div>
                                 <div className="col-md-6 col-12">
                                   <label className="">Password Confirm</label>
                                       <div className="bg-primary">
                                             <TextField
                                                id="fname"
                                                margin="normal"
                                                type="password"
                                                fullWidth
                                                onChange={this._confirm}
                                                value={this.state.confirm}
                                                variant="outlined"
                                                    InputProps={{
                                                        style: {
                                                            height: "40px"
                                                        ,backgroundColor:"#3f51b5"
                                                        }
                                                    }}
                                                  style={{margin:0}}
                                                  InputProps={{

                                                      style: {
                                                            height: "40px"
                                                            ,width: "100%"
                                                             ,backgroundColor:"#3f51b5"
                                                                ,color:"#fff"

                                                        }
                                                    }}
                                            />
                                       </div>
                                 </div>
                            </div>

                   <div className="row m-3" style={{color:'#fff'}}>
                     <Button   className="bg-blue text-white mt-0 ml-3" onClick={()=>this.submit()}>
                          <SaveIcon/>
                          <span className="ml-1">
                                บันทึก
                          </span>
                    </Button>
                 </div>
                 </div>

                 <Dialog fullWidth={true}
                        maxWidth={'xs'} open={this.state.open} onClose={this.handleRequestClose}>
                        <DialogContent className="bg-geekblue text-white"  >
                            <InfoIcon className="text-primary " />   รหัสผ่านไม่ถูกต้อง.
                        <hr />
                        </DialogContent>
                        <DialogActions className="bg-geekblue">
                            <Button onClick={()=>{this.setState({open:false})}} className="bg-success text-white">
                                     <CheckCircleIcon /> ตกลง
                            </Button>
                        </DialogActions>
                </Dialog>
           </div>
         </div>
     </div>
 </div>
         <Snackbar
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            open={this.state.openmsg}
                            autoHideDuration={1000}
                              onClose={() => this.setState({openmsg: false})}
                            message="บันทึกสำเร็จ"
                             action={[
                            <IconButton
                              key="close"
                              aria-label="Close"
                              color="inherit"
                              onClick={this.handleRequestClose}
                            >
                              <CloseIcon/>
                            </IconButton>,
                          ]}
                          />
      </div>
    );
  }
}

export default index;